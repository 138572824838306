import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, Spin, Modal, Tooltip, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import { Line, Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";
import './newUserStatistics.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
export default class NewUserStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            searchModel: { startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), pageSize: 10, page: 1, },
            chartList: [],
            charData: {},
            isExport: false,
            oneLayerList: [],
            twoLayerList: [],
            threeLayerList: [],
            newUsersTotal: 0
        }

    }

    componentDidMount() {
        this.chartSearch();
        this.threeLevelNestingSearch()
    }

    threeLevelNestingSearch() {
        fetch(Config.apiBackServer + "area/threeLevelNestingSearch", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    oneLayerList: data.object
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    check() {
        let model = this.state.searchModel
        let str = ""
        if (Common.isNotEmpty(model.startDate) && Common.isNotEmpty(model.endDate) ) {
            if(model.startDate > model.endDate){
                str += "注册由日期不能大于注册至日期;"
           }
        }
        return str
    }

    chartSearch() {
        /* let str = this.check()
        if (Common.isNotEmpty(str)) {
            Message.openMsgError("错误", str)
            return
        }
        let model = this.state.searchModel */
        let model = this.state.searchModel
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "注册由日期不能大于注册至日期;");
                return;
            }
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "newUserStatistics/chartSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object;
                let xAxisContentList = [];
                let regionList = [];
                let newUsersTotal = 0
                list.forEach(element => {
                    newUsersTotal += parseInt(element.userAmount || 0)
                    let str = "".concat((Common.isEmpty(element.provinceName) ? "" : element.provinceName) +
                        (Common.isEmpty(element.cityName) ? "" : element.cityName) +
                        (Common.isEmpty(element.areaName) ? "" : element.areaName))
                    xAxisContentList.push(str);
                    regionList.push(element.userAmount);
                });
                let datasets = []
                let colorList = ["#37a2da", "#32c5e9", "#67e0e3", "#9fe6b8", "#ffdb5c", "#ff9f7f", "#fb7293", "#e062ae",
                    "#e690d1", "#e7bcf3", "#9d96f5", "#96bfff",]
                let datesetsItem = {
                    label: "数量",
                    data: regionList,
                    backgroundColor: "#37a2da",
                    borderColor: "#37a2da",
                    borderWidth: 1,
                    hoverBackgroundColor: "#37a2da",
                    hoverBorderColor: "#37a2da",
                    barThickness: "flex",
                    fill: false
                }

                let chartData = {
                    labels: xAxisContentList,
                    datasets: [datesetsItem]
                }
                this.setState({
                    chartList: data.object,
                    chartData: chartData,
                    newUsersTotal: newUsersTotal
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    fromSearch() {
        /* let str = this.check()
        if (Common.isNotEmpty(str)) {
            Message.openMsgError("错误", str)
            return
        }
        let model = this.state.searchModel */
        let model = this.state.searchModel
        let startDate = model.startDate != null ? moment(model.startDate).format("YYYYMMDD") : null;
        let endDate = model.endDate != null ? moment(model.endDate).format("YYYYMMDD") : null;
        if (startDate!=null&& endDate != null ) {
            if(endDate < startDate){
                Message.openMsgError("错误", "注册由日期不能大于注册至日期;");
                return;
            }
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "newUserStatistics/fromSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = []
            if ("SUCCESS" === data.msg) {
                list = JSON.parse(JSON.stringify(data.object.list.list))
                let newModel = { amountAll: data.object.amountAll || {} };
                newModel.userId = "userId";
                list.push(newModel);
                model.total = data.object.list.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list,
                amountAll: data.object.amountAll || {},
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    tableChange(value) {
        this.reset();
        this.setState({
            tabIndex: value,
        }, () => {
            if (value === "2") {
                this.fromSearch();
            } else {
                this.chartSearch();
            }
        })
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        if (k === "provinceId") {
            newModel.cityId = ""
            newModel.areaId = ""
            let twoLayerList = []
            this.state.oneLayerList.forEach((item) => {
                if (e === item.id) {
                    newModel.provinceName = item.name
                    newModel.cityName = ""
                    newModel.areaName = ""
                    twoLayerList = item.areaList
                    this.setState({
                        twoLayerList: twoLayerList,
                        threeLayerList: [],
                    })
                }
            })
        } else if (k === "cityId") {
            newModel.areaId = ""
            let threeLayerList = []
            this.state.twoLayerList.forEach((item) => {
                if (e === item.id) {
                    newModel.cityName = item.name
                    newModel.areaName = ""
                    threeLayerList = item.areaList
                    this.setState({
                        threeLayerList: threeLayerList,
                    })
                }
            })
        } else if (k === "areaId") {
            this.state.threeLayerList.forEach((item) => {
                if (e === item.id) {
                    newModel.areaName = item.name
                } else {
                    newModel.areaName = ""
                }
            })
        }

        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "", startDate: new Date().setMonth(new Date().getMonth() - 1), endDate: new Date(), },
            twoLayerList: [],
            threeLayerList: [],
        }, () => {
            if (this.state.tabIndex === "1") {
                this.chartSearch()
            } else {
                this.fromSearch()
            }
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize - 1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.fromSearch();
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "newUserStatistics/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "区县业务员用户统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }
    exportChartData() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "newUserStatistics/exportChartData", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "区县业务员用户图表统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="amount">总计</span>,
                            props: {
                                colSpan: 4,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '注册日期',
                dataIndex: 'createdDate',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return Common.dateFormat(text, 'yyyy/MM/dd')
                    }
                }
            },
            {
                title: '所属地区',
                dataIndex: 'nickName',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return (Common.isEmpty(item.provinceName) ? "" : item.provinceName) +
                            (Common.isEmpty(item.cityName) ? "" : item.cityName) +
                            (Common.isEmpty(item.areaName) ? "" : item.areaName)
                    }
                }
            },
            // {
            //     title: '注册人数',
            //     dataIndex: 'userAmount',
            // },
            {
                title: '推荐卡号',
                dataIndex: 'whetherMasterCard',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span style={{ textAlign: "right" }} className="amount">{parseInt(item.amountAll) || 0}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
        ];

        return (
            <div className="newUserStatistics">
                <div className="zhRoot">
                    <div className="zhTitle">区县业务员用户统计</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="统计" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">所属地区：</Col>
                                        <Col span={5} className="zhInput" >
                                            <Select className="newUserStatisticsSelect" placeholder="省" value={this.state.searchModel.provinceId || ''}
                                                onChange={(e) => this.searchChange('provinceId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.oneLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="newUserStatisticsSelect" placeholder="市" value={this.state.searchModel.cityId || ''}
                                                onChange={(e) => this.searchChange('cityId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.twoLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="newUserStatisticsSelect" placeholder="县" value={this.state.searchModel.areaId || ''}
                                                onChange={(e) => this.searchChange('areaId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.threeLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="newUserStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startDate == null ? null : moment(new Date(this.state.searchModel.startDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startDate", value)}
                                                format={Config.dateFormat} />
                                                 &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="newUserStatisticsDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick={() => this.chartSearch()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportChartData()} icon="download">导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                        {Common.isNotEmpty(this.state.chartData) ?
                                            <Bar
                                                width={1200}
                                                data={this.state.chartData}
                                                // data={{
                                                //     labels: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'],
                                                //     datasets: [{
                                                //         label: 'My Dataset',
                                                //         data: [60, 90, 130, 110, 100, 90, 80, 70, 80, 0],
                                                //         backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                                //         borderColor: 'rgb(0, 0, 0)',
                                                //         borderWidth: 1,
                                                //         // barThickness:"flex",
                                                //         fill: false
                                                //     }, {
                                                //         label: 'My ssss',
                                                //         data: [60, 90, 130, 110, 100, 90, 80, 70, 80, 100],
                                                //         backgroundColor: '#282a36',
                                                //         borderColor: 'rgb(0, 0, 0)',
                                                //         borderWidth: 1,
                                                //         // barThickness:"flex",
                                                //         fill: false
                                                //     }]
                                                // }}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: "区县业务员用户统计",
                                                        position: "top",
                                                    },
                                                    tooltips: {
                                                        // Disable the on-canvas tooltip
                                                        // callbacks: {
                                                        //     title: () => { return "" },
                                                        //     label: (tooltipItem, data) => {
                                                        //         var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                                        //         if (label) {
                                                        //             label += ': ';
                                                        //         }
                                                        //         label += Math.round(tooltipItem.yLabel * 100) / 100;
                                                        //         return label;
                                                        //     },
                                                        //     afterLabel: (tooltipItem, data) => {
                                                        //         let key = data.datasets[tooltipItem.datasetIndex].label + tooltipItem.label
                                                        //         var label = this.state.rightMap.get(key) ? this.state.rightMap.get(key).orderDepositAmt : '';
                                                        //         if (label) {
                                                        //             label = "訂金(" + this.state.currencyName + '): ' + label;
                                                        //         } else {
                                                        //             return ""
                                                        //         }
                                                        //         return label;
                                                        //     }
                                                        // }
                                                    },
                                                    maintainAspectRatio: false,
                                                    responsive: true,
                                                    scales: {
                                                        xAxes: [{
                                                            // stacked: true,
                                                            // ticks: {
                                                            //     beginAtZero: true,
                                                            //     maxTicksLimit: this.state.rightxAxisList ? this.state.rightxAxisList.length : 14,
                                                            // }
                                                        }],
                                                        yAxes: [{
                                                            // stacked: true,
                                                            ticks: {
                                                                beginAtZero: true,
                                                                callback: function (value) { if (value % 1 === 0) { return value } }
                                                            }
                                                        }]
                                                    },
                                                    legend: {
                                                        position: "bottom",
                                                        align: "center",
                                                        labels: {
                                                            boxWidth: 20
                                                        }
                                                    },
                                                    hover: {
                                                        animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                    },
                                                    animation: {           // 这部分是数值显示的功能实现
                                                        onComplete: function () {
                                                            var chartInstance = this.chart,
                                                                ctx = chartInstance.ctx;
                                                            // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                            ctx.fillStyle = "black";
                                                            ctx.textAlign = 'center';
                                                            ctx.textBaseline = 'bottom';

                                                            this.data.datasets.forEach(function (dataset, i) {
                                                                var meta = chartInstance.controller.getDatasetMeta(i);
                                                                meta.data.forEach(function (bar, index) {
                                                                    var data = dataset.data[index];
                                                                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                                });
                                                            });
                                                        }
                                                    }
                                                }}
                                            />
                                            : ""}
                                    </Row>
                                    <Row>用户总数：{this.state.newUsersTotal || 0}</Row>
                                </TabPane>
                                <TabPane tab="明细" key="2">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">所属地区：</Col>
                                        <Col span={5} className="zhInput">
                                            <Select className="newUserStatisticsSelect" placeholder="省" value={this.state.searchModel.provinceId || ''}
                                                onChange={(e) => this.searchChange('provinceId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.oneLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="newUserStatisticsSelect" placeholder="市" value={this.state.searchModel.cityId || ''}
                                                onChange={(e) => this.searchChange('cityId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.twoLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="newUserStatisticsSelect" placeholder="县" value={this.state.searchModel.areaId || ''}
                                                onChange={(e) => this.searchChange('areaId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.threeLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>

                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="newUserStatisticsDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startDate == null ? null : moment(new Date(this.state.searchModel.startDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="newUserStatisticsDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">推荐卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="推荐卡号"
                                                value={this.state.searchModel.whetherMasterCard || ''}
                                                onChange={(e) => this.searchChange('whetherMasterCard', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick={() => this.fromSearch()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table
                                            // rowSelection={false}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            dataSource={this.state.tables || []} //数据
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            pagination={{  //分页
                                                pageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1
                                                ),             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize + 1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1                            //默认当前页为1
                                            }}
                                        // onRow={(record) => {
                                        //     return {
                                        //         onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                        //     };
                                        // }}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}