import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select,  DatePicker, Upload ,Icon ,Modal, Spin } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './withdrawalBankCard.css';
const { TextArea } = Input;

// 提现审核
export default class WithdrawalBankCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tables:[],
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: { pageSize: 10, page: 1, withdrawalTimeAction: new Date().setDate(new Date().getDate() - 7), withdrawalTimeEnd: new Date(), type:"withdrawalBankCard"},
            total: 0,
            searchModel: {},
            notAllowEditing: false,
            previewVisible: false,
            saveModel:{},
            loading: false,
            isExport: false,
            imageFileFour: [],//返利凭证
            weChatAddress: [],//微信收款码
            alipayAddress: [],//支付宝收款码
            ImageNum: 1,
            paymentMethod: null,
        }
    }

  //组件渲染之后调用
    componentDidMount() {
        this.search();
    }

    search(){
        let newmodel = this.state.model;
        fetch(Config.apiBackServer + "withdrawalBankCard/search", {
            method: 'POST',
            body: JSON.stringify(newmodel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if("SUCCESS" === data.msg){
                this.setState({
                    tables: data.object.list,
                    total: data.object.total,
                    isLoading: false,
                    isAuditflag: false,
                    imageFileFour: [],
                })
            }else{
                this.setState({
                    isLoading: false,
                    isAuditflag: false,
                    imageFileFour: [],
                })
                Message.openMsgError("错误", data.object.msg);
            }
        });
    }

    pageChange = (current, pageSize) => {
        let newModel = this.state.model;
        newModel.pageSize = pageSize;
        newModel.page = current;
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            model: newModel,
        },() => {
            this.search();
        });
        
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    onChange(k ,e){
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({ model: newModel,})
    }

    reset(){
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            model: {
                pageSize: 10, 
                page: 1, 
                auditTimeAction:null, 
                auditTimeEnd: null,
                phone: null,
                auditStatus: null,
                cardNumber: null,
                withdrawalTimeAction: new Date().setDate(new Date().getDate() - 7),
                withdrawalTimeEnd: new Date(),
                type:"withdrawalBankCard",
            }
        })
    }

    render(){
        const { TabPane } = Tabs;
        const { Option } = Select;

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>上传图片</div>
            </div>
        );

        const columns = [
            {
                title: '提现人卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth11",
            },
            {
                title: '提现人手机号',
                dataIndex: 'phone',
                className: "tableWidth11",
            },
            {
                title: '提现金额',
                dataIndex: 'withdrawalAmount',
                className: 'tableWidth11',
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '提现时间',
                dataIndex: 'withdrawalTime',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
                // sorter: (a, b) => { return Common.sortMethod(a, b, 'withdrawalTime') },
            },
            {
                title: '审核人手机号',
                dataIndex: 'auditPhone',
                className: "tableWidth11",
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth11",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: 'tableWidth11',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '审核备注',
                dataIndex: 'auditRemark',
                className: "tableWidth11",
            },
            {
                title: '提现人状态',
                dataIndex: 'status',
                className: "tableWidth11",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ] 

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        return(
            <div className="withdrawalBankCard">
                <div className="zhRoot">
                    <div className="zhTitle">大额提现审核</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">提现日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.withdrawalTimeAction == null? null : moment(new Date(this.state.model.withdrawalTimeAction), Config.dateFormat)}
                                                onChange={(value) => this.onChange("withdrawalTimeAction",value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.withdrawalTimeEnd == null?null : moment (new Date(this.state.model.withdrawalTimeEnd), Config.dateFormat)}
                                                onChange={(value) => this.onChange("withdrawalTimeEnd",value)}
                                                format={Config.dateFormat}  />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="卡号"
                                                value={this.state.model.cardNumber}
                                                onChange={(e) => this.onChange("cardNumber",e.target.value)}
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员手机号"
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.onChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                    <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.auditTimeAction == null? null : moment(new Date(this.state.model.auditTimeAction), Config.dateFormat)}
                                                onChange={(value) => this.onChange("auditTimeAction",value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.auditTimeEnd == null? null : moment(new Date(this.state.model.auditTimeEnd), Config.dateFormat)}
                                                onChange={(value) => this.onChange("auditTimeEnd",value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.model.auditStatus|| ''}
                                                onChange={(value) => this.onChange('auditStatus', value)}>
                                                <Option key="" value={""}>全部</Option>
                                                <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                                <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                            </Select>
                                        </Col>
                                        
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary"  icon="search" onClick={() => this.search()}>搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toRebateAudit()} icon="export">审核</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toRebate()} icon="eye">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="memberWithdrawalId"
                                            scroll={{ x: 1000 }}
                                            bordered={true}                                     //边线
                                            columns={columns}                                   //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables}                      //数据
                                            pagination={{
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.total,                        //总共多少条数据
                                                current: this.state.model.page,                 //当前页
                                                pageSize: this.state.model.pageSize,            //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'memberWithdrawalId', 'selectedRows', 'selectedRowKeys'),// 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="审核" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.cardNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.phone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>提现金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={Common.numberFormat(this.state.saveModel.withdrawalAmount|| 0, 2)}/>
                                        </Col>
                                        <Col span={4} className='zhTxt'>提现时间:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.withdrawalTime == null ? null : moment(this.state.saveModel.withdrawalTime).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核状态:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled
                                            value={ContextHandler.getMapValue('auditStatusMap', this.state.saveModel.auditStatus)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>提现人状态：</Col>
                                        <Col span={6} className='zhInput'>
                                        <Input className='disableFontColor' maxLength={30} disabled value={ContextHandler.getMapValue('statusMap', this.state.saveModel.status)} />
                                        </Col>
                                    </Row>
                                    {this.state.notAllowEditing ? 
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核人手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.auditPhone} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核时间:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.auditDate == null ? null : moment(this.state.saveModel.auditDate).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    : null}
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>微信付款码：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Upload
                                                key={"1"}
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                disabled={true}
                                                fileList={this.state.weChatAddress}
                                                onPreview={this.handlePreview}
                                                onChange={(file) => this.handleChange(file, "weChatAddress")}>
                                                {this.state.weChatAddress.length >= this.state.ImageNum ? null : uploadButton}
                                            </Upload>
                                        </Col>

                                        <Col span={4} className='zhTxt'>支付宝付款码：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Upload
                                                key={"1"}
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                disabled={true}
                                                fileList={this.state.alipayAddress}
                                                onPreview={this.handlePreview}
                                                onChange={(file) => this.handleChange(file, "alipayAddress")}>
                                                {this.state.alipayAddress.length >= this.state.ImageNum ? null : uploadButton}
                                            </Upload>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>银行卡用户名:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.bankCardnumberName} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>银行卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.bankCardnumber} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>银行支行：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.subBranch} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>转款联系电话:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.bankCardnumberPhone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>收款方式<span className='redStar'>*</span>:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                disabled={this.state.notAllowEditing}
                                                value={this.state.saveModel.paymentMethod || this.state.paymentMethod || ''}
                                                onChange={(e) => this.selectChange('paymentMethod', e)} >
                                                <Option key="WECHAT" value={"WECHAT"}>微信</Option>
                                                <Option key="ALIPAY"    value={"ALIPAY"}>支付宝</Option>
                                                <Option key="BANK"    value={"BANK"}>银行卡</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核备注：</Col>
                                        <Col span={16} className='RemarkInput'>
                                            <TextArea
                                                value={this.state.saveModel.auditRemark || ""}
                                                className='disableFontColor'
                                                onChange={(e) => this.modelChange('auditRemark', e.target.value)}
                                                placeholder="备注"
                                                disabled={this.state.notAllowEditing}
                                                maxLength={500}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                    </Modal>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核凭证<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Upload
                                                key={"1"}
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                disabled={this.state.saveModel.auditStatus =="UNCHECKED"? false: true}
                                                listType="picture-card"
                                                fileList={this.state.imageFileFour}
                                                onPreview={this.handlePreview}
                                                onChange={(file) => this.handleChange(file, "imageFileFour")}>
                                                {this.state.imageFileFour.length >= this.state.ImageNum ? null : uploadButton}
                                            </Upload>
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonLeft'>
                                        {!this.state.notAllowEditing ? <Button disabled={this.state.loading} type="primary" icon="check-circle" onClick={() => { this.saveRebate() }}>审核</Button> : null}
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }

    selectChange(k,e){
        let state = this.state;
        state[k] = e;
        this.setState(state)
    }

    tableChange(value) {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (value === "2") {
            let model = this.state.selectedRows[0];
            if(model.auditStatus === "UNCHECKED"){
                this.toRebateAudit();
            }
            if(model.auditStatus === "CHECKED"){
                this.toRebate();
            }
        } else {
            this.setState({ tab: value, })
            this.cancel();
        }
    }

    /**图片改变事件 */
    handleChange(file, flag) {
        let state = this.state
        state[flag] = file.fileList
        this.setState(state)
    }

    /**关闭图片弹窗 */
    handleCancel = () => this.setState({ previewVisible: false, isMap: false })

    /**查看图片 */
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    /**审核进编辑页（审核页） */
    toRebateAudit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        if (model.auditStatus !== "UNCHECKED") {
            Message.openMsgError("错误", "已审核数据无法再审核");
            return;
        }
        fetch(Config.apiBackServer + "withdrawalBankCard/searchAddress", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if("SUCCESS" === data.msg){
                this.toView(model,data.object,false);
            }else{
                Message.openMsgError("错误", data.msg);
            }
        });
    }

    /**查看   进编辑页（审核页） */
    toRebate() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        if (model.auditStatus !== "CHECKED") {
            Message.openMsgError("错误", "请先审核数据");
            return
        }
        fetch(Config.apiBackServer + "withdrawalBankCard/searchAddress", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if("SUCCESS" === data.msg){
                this.toView(model,data.object,true);
            }else{
                Message.openMsgError("错误", data.msg);
            }
        },() => {});
        
    }

    toView(model,object,flag){
        let imgFour = null;
        let weChatAddress = null;
        let alipayAddress = null;
        if (Common.isNotEmpty(model.shopIntroduceImgThree)) {
            imgFour = model.shopIntroduceImgThree;
        }
        if (Common.isNotEmpty(object)) {
            if (Common.isNotEmpty(object.weChatAddress)) {
                weChatAddress = object.weChatAddress;
            }
            if (Common.isNotEmpty(object.alipayAddress)) {
                alipayAddress = object.alipayAddress;
            }
        }
        let imageFileFour = [{ uid: '-1', }]
        let imageweChatAddress = [{ uid: '-1', }]
        let imagealipayAddress = [{ uid: '-1', }]
        if (imgFour != null && imgFour !== "") {
            imageFileFour[0].url = Config.imgServer + "classify/" + imgFour;
            imageFileFour[0].name = imgFour;
        } else {
            imageFileFour = []
        }

        if (weChatAddress != null && weChatAddress !== "") {
            imageweChatAddress[0].url = Config.consumptionRebate + weChatAddress;
            imageweChatAddress[0].name = weChatAddress;
        } else {
            imageweChatAddress = []
        }
        
        if (alipayAddress != null && alipayAddress !== "") {
            imagealipayAddress[0].url = Config.consumptionRebate + alipayAddress;
            imagealipayAddress[0].name = alipayAddress;
        } else {
            imagealipayAddress = []
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            saveModel: model,
            notAllowEditing:flag,
            imageFileFour: imageFileFour,
            weChatAddress: imageweChatAddress,
            alipayAddress: imagealipayAddress,
        })
    }
        
    cancel() {
        this.setState({
            loading: false,
            tabIndex: "1",
            flag: "",
            saveModel: {},
            selectedRowKeys: [],
            selectedRows: [],
            notAllowEditing: false,
            imageFileFour: [],//返利凭证
            weChatAddress: [],//微信收款码
            alipayAddress: [],//支付宝收款码
            paymentMethod: null,
            model: { pageSize: 10, page: 1, withdrawalTimeAction: new Date().setDate(new Date().getDate() - 7), withdrawalTimeEnd: new Date(),},
        },() => {
            this.search();
        })
    }

    modelChange(k, e) {
        let saveModel = this.state.saveModel;
        saveModel[k] = e;
        this.setState({ saveModel });
    }

    /**确认审核 */
    saveRebate() {
        this.obtainImgData();
       if(Common.isEmpty(this.state.paymentMethod)){
            Message.openMsgError("错误", "收款方式不能为空");
            return
        } else {
            if (this.state.paymentMethod === 'WECHAT') {
                if (this.state.weChatAddress.length <= 0 ||Common.isEmpty(this.state.weChatAddress)) {
                    Message.openMsgError("错误", "微信收款码不能为空");
                    return
                }
            }
            if (this.state.paymentMethod === 'ALIPAY') {
                if (this.state.alipayAddress.length <=  0 || Common.isEmpty(this.state.alipayAddress)) {
                    Message.openMsgError("错误", "支付宝收款码不能为空");
                    return
                }
            }
            if (this.state.paymentMethod === 'BANK') {
                if (Common.isEmpty(this.state.saveModel.bankCardnumberName)) {
                    Message.openMsgError("错误", "银行卡用户名不能为空");
                    return
                }
                if (Common.isEmpty(this.state.saveModel.bankCardnumber)) {
                    Message.openMsgError("错误", "银行卡号不能为空");
                    return
                }
                if (Common.isEmpty(this.state.saveModel.subBranch)) {
                    Message.openMsgError("错误", "银行支行不能为空");
                    return
                }
                if (Common.isEmpty(this.state.saveModel.bankCardnumberPhone)) {
                    Message.openMsgError("错误", "转款联系电话不能为空");
                    return
                }
            }
        }
        let saveModel = this.state.saveModel;
        if (saveModel.shopIntroduceImgThree == null || saveModel.shopIntroduceImgThree === '') {
            Message.openMsgError("错误", "审核凭证不能为空");
            return
        }
        this.setState({ loading: true });
        if(saveModel.status === "FROZEN"){
            Message.openMsgError("错误", "处于冻结状态无法审核");
            return;
        }
        saveModel.userId = Config.userInfo.userId;
        saveModel.paymentMethod = this.state.paymentMethod;
        fetch(Config.apiBackServer + "withdrawalBankCard/audit", {
            method: 'POST',
            body: JSON.stringify(saveModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel();
                Message.openMsgSuccess("成功", "审核成功")
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        });
    }

    obtainImgData(){
        let model = this.state.saveModel;
        let imageFileFour = this.state.imageFileFour;
        if (imageFileFour.length !== 0) {
            if (Common.isEmpty(imageFileFour[0].url)) {
                model.shopIntroduceImgThree = imageFileFour[0].thumbUrl;
            } else {
                model.shopIntroduceImgThree = imageFileFour[0].name;
            }
        } else {
            model.shopIntroduceImgThree = null;
        }
        this.setState({
            model: model
        })
    }

    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "withdrawalBankCard/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "大额提现审核" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false })});
    }
}