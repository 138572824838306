import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker ,Upload,Icon} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import $ from 'jquery'

import './promotionDetails.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class PromotionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: true,
            saveFlag: true,//重复点击标志
            secondarySave: true,
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,

            previewVisibleZFB:false,
            imageFileZFB: [],
            ImageNumZFB: 1,
            previewVisibleWX:false,
            imageFileWX: [],
            ImageNumWX: 1,
            imageFile: [],
            ImageNum: 1,
            previewVisible: false,
            imageFileMember: [],
            ImageNumMember: 1,
            previewVisibleMember: false,
        }
        this.init()
    }

    init() {
    }

    componentWillMount = () => {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        console.log(this.state.searchModel);
        fetch(Config.apiBackServer + "promotionDetails/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            let list = JSON.parse(JSON.stringify(data.object.list.list));
            if ("SUCCESS" === data.msg) {
                model.total =  data.object.list.total;
                if(data.object.amountAll != null && data.object.amountAll != undefined){
                    let newModel=data.object.amountAll
                    newModel.cardNumber="cardNumber";
                    list.push(newModel)
                }
               
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: list,
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        var model = this.state.searchModel;
        model.settlementStatus ='';
        model.promotionCardNumber='';
        model.promotionPhone ='';
        model.cardNumber='';
        model.phone='';
        model.openDate=null;
        model.endDate=null;
        this.setState({
            searchModel: { page: 1, pageSize: 10, searchModel: model},
        })
        this.cancel();
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "promotionDetails/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推广会员明细" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }

    handlePreviewZFB = (file) => {
        this.setState({
            previewImageZFB: file.url || file.thumbUrl,
            previewVisibleZFB: true,
        });
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handlePreviewWX = (file) => {
        this.setState({
            previewImageWX: file.url || file.thumbUrl,
            previewVisibleWX: true,
        });
    }

    handlePreviewMember = (file) => {
        this.setState({
            previewImageMember: file.url || file.thumbUrl,
            previewVisibleMember: true,
        });
    }

    handleCancel2 (){
        this.setState({ previewVisibleZFB: false })
      } 
    handleCancel3 (){
    this.setState({ previewVisibleWX: false })
    } 
    handleCancel (){
        this.setState({ previewVisible: false })
     } 

    handleCancelMember (){
    this.setState({ previewVisibleMember: false })
    } 

    handleChangeMember(file) {
        this.setState({
            imageFileMember: file.fileList
        })
    }

    handleChangeZFB(file) {
        this.setState({
            imageFileZFB: file.fileList
        })
    }

    handleChangeWX(file) {
        this.setState({
            imageFileWX: file.fileList
        })
    }

    handleChange(file) {
        this.setState({
            imageFile: file.fileList
        })
    }


    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        if("cardNumber"=== module.cardNumber){
            Message.openMsgError("错误", "请选择一条有效数据进行查看")
            return
        }
        var weChatAddress = module.weChatAddress;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') != -1) {
            file1[0].url = weChatAddress;
            file1[0].name = weChatAddress;
        } else if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') == -1) {
            file1[0].url = Config.consumptionRebate + weChatAddress;
            file1[0].name = weChatAddress;
        } else {
            file1 = []
        }
        var alipayAddress = module.alipayAddress;
        let file2 = [{ uid: '-1', }]
        if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') != -1) {
            file2[0].url = alipayAddress;
            file2[0].name = alipayAddress;
        } else if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') == -1) {
            file2[0].url = Config.consumptionRebate + alipayAddress;
            file2[0].name = alipayAddress;
        } else {
            file2 = []
        }
        var settlementVoucher = module.settlementVoucher;
        let file3 = [{ uid: '-1', }]
        if (Common.isNotEmpty(settlementVoucher) && settlementVoucher.indexOf('http') != -1) {
            file3[0].url = settlementVoucher;
            file3[0].name = settlementVoucher;
        } else if (Common.isNotEmpty(settlementVoucher) && settlementVoucher.indexOf('http') == -1) {
            file3[0].url = Config.consumptionRebate + settlementVoucher;
            file3[0].name = settlementVoucher;
        } else {
            file3 = []
        }

        var headPortrait = module.headPortrait;
        let file4 = [{ uid: '-1', }]
        if (Common.isNotEmpty(headPortrait) && headPortrait.indexOf('http') != -1) {
            file4[0].url = headPortrait;
            file4[0].name = headPortrait;
        } else if (Common.isNotEmpty(headPortrait) && headPortrait.indexOf('http') == -1) {
            file4[0].url = Config.headPortraitImgs + headPortrait;
            file4[0].name = headPortrait;
        } else {
            file4 = []
        }
        this.setState({
            tabIndex: "2",
            model: module,
            flag: "edit",
            imageFileWX: file1,
            imageFileZFB: file2,
            imageFile:file3,
            imageFileMember:file4,
        })
    }

    Mul(a, b) {
        var str1 = a.toString(),str2 = b.toString(),m=0;
        try{m += str1.split('.')[1].length;}catch(e){}
        try{m += str2.split('.')[1].length;}catch(e){}
        return Number(str1.replace(".", "")) * Number(str2.replace(".", "")) / Math.pow(10, m)
    }

    searchSecondaryReset(){
        let module = this.state.model;
        module.openDate = null;
        module.endDate = null;
        this.setState({
            model: module,
        })
        this.searchSecondary();
    }

  

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize-1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        }, () => {
            this.search();
        })
       
    }


    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        console.log(k);
        console.log(e);
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add"
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
               
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }

    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButtonZFB = (
            <div>
                <Icon type="plus" />
                <div>支付宝收款码</div>
            </div>
        );
        const uploadButtonWX = (
            <div>
                <Icon type="plus" />
                <div>微信收款码</div>
            </div>
        );
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>结算凭证</div>
            </div>
        );
        const uploadButtonMember = (
            <div>
                <Icon type="plus" />
                <div>会员头像</div>
            </div>
        );
        const columns = [
            {
                title: '推广会员卡号',
                dataIndex: 'promotionCardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 5,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广会员手机号',
                dataIndex: 'promotionPhone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: '',
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: '',
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: '',
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '结算状态',
                dataIndex: 'settlementStatus',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: '',
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text == 'Y' ? "已结算" : "未结算"
                    }
                }
            },
            {
                title: '结算金额',
                dataIndex: 'newMembersCommission',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(item.newMembersCommission, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '注册日期',
                dataIndex: 'registrationDate',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: '',
                            props: {
                                colSpan:1,
                            },
                        }
                    } else {
                        return text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },
           

        ];    
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
            },
            {
                title: '注册日期',
                dataIndex: 'registrationDate',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '地区',
                dataIndex: 'region',
            },
            {
                title: '详细地址',
                dataIndex: 'address',
            },
           
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="promotionDetails">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                    <div className="zhTitle">推广会员明细</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                <Row className="row">
                                        <Col span={2} className="zhTxt">推广会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员卡号"
                                                value={this.state.searchModel.promotionCardNumber || ''}
                                                onChange={(e) => this.searchChange('promotionCardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">推广会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                value={this.state.searchModel.promotionPhone || ''}
                                                onChange={(e) => this.searchChange('promotionPhone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                    </Row>

                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">结算状态：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.settlementStatus || ''}
                                                onChange={(e) => this.searchChange('settlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="开始日期"
                                                className="Picker1"
                                                allowClear={false}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                                 &nbsp;至&nbsp;
                                              <DatePicker placeholder="结束日期"
                                                className="Picker1"
                                                allowClear={false}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                pageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1
                                                ),             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize+1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Modal title="放大图" visible={this.state.previewVisibleZFB} footer={null} onCancel={()=>this.handleCancel2()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageZFB} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisibleWX} footer={null} onCancel={()=>this.handleCancel3()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageWX} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={()=>this.handleCancel()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisibleMember} footer={null} onCancel={()=>this.handleCancelMember()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImageMember} />
                                </Modal>
                                <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广会员卡号"
                                                value={this.state.model.promotionCardNumber || ''}
                                                onChange={(e) => this.modelChange('promotionCardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >推广会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                disabled
                                                value={this.state.model.promotionPhone || ''}
                                                onChange={(e) => this.modelChange('promotionPhone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>注册日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="注册日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.registrationDate == null ? null : moment(new Date(this.state.model.registrationDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("registrationDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>结算日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="结算日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.settlementDate == null ? null : moment(new Date(this.state.model.settlementDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("settlementDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className="zhTxt">结算状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                disabled
                                                value={this.state.model.settlementStatus || ''}
                                                onChange={(e) => this.modelChange('settlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={4} className='zhTxt'>推广会员提成金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广新会员提成金额"
                                                value={Common.numberFormat(this.state.model.newMembersCommission, 2) || ''}
                                                onChange={(e) => this.modelChange('newMembersCommission', e.target.value)} />
                                        </Col>
                                    </Row>
                                   
                                   
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt' >地区：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="地区"
                                                disabled
                                                value={this.state.model.region || ''}
                                                onChange={(e) => this.modelChange('region', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >详细地址：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="详细地址"
                                                disabled
                                                value={this.state.model.address || ''}
                                                onChange={(e) => this.modelChange('address', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员头像：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileMember}
                                                onPreview={this.handlePreviewMember}
                                                onChange={(file) => this.handleChangeMember(file)}>
                                                {this.state.imageFileMember.length >= this.state.ImageNumMember ? null : uploadButtonMember}
                                            </Upload>
                                        </Col>

                                        <Col span={4} className='zhTxt'>结算凭证：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFile}
                                                onPreview={this.handlePreview}
                                                onChange={(file) => this.handleChange(file)}>
                                                {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                            </Upload>
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileZFB}
                                                onPreview={this.handlePreviewZFB}
                                                >
                                                {this.state.imageFileZFB.length >= this.state.ImageNumZFB ? null : uploadButtonZFB}
                                            </Upload>
                                        </Col>
                                        <Col span={4} className='zhTxt'>微信收款码：</Col>
                                        <Col span={6} className='imgUpload zhInput'>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFileWX}
                                                onPreview={this.handlePreviewWX}
                                               >
                                                {this.state.imageFileWX.length >= this.state.ImageNumWX ? null : uploadButtonWX}
                                            </Upload>
                                        </Col>
                                    </Row>

                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>返回</Button>
                                   </Row>
                                  
                            </TabPane>
                        </Tabs>
                    </div>
                    </Spin>
                </div>
            </div >
        )
    }


}