import React, { Component } from "react";
import { Button, Row, Col, Tabs, Table, Select, DatePicker, Spin, AutoComplete } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { tuple } from "antd/lib/_util/type";
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";
import './shopPayoffStatement.css'

export default class ShopPayoffStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: '1',
            searchModel: { pageSize: 30, page: 1, startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date()},
            model: { pageSize: 10, page: 1,  startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date()},
            isLoading: true,
            isExport: false,
            tables: [],
            selectedRows: [],
            selectedRowKeys: [],
            chartList: [],
            chartData: {},
            foodAmountTotal: 0,
            wineAmountTotal: 0,
            rebateAmountTotal: 0,
            tradeAmountTotal: 0,
            paymentAmountTotal: 0,

            oneLayerList: [],
            twoLayerList: [],
            threeLayerList: [],

            startValue: null,
            endValue: null,
            endOpen: false,

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }
   

    componentDidMount() {
        this.search();
        this.chartSearch();
        this.threeLevelNestingSearch()
        this.searchShop();
    }

    searchChange(k,e) {
        let newModel = this.state.model;
        if (k === "provinceId") {
            newModel.cityId = ""
            newModel.areaId = ""
            newModel.area = ""
            newModel.provinceName = ""
            newModel.cityName = "";
            newModel.areaName = "";
            let twoLayerList = []
            for (let item of this.state.oneLayerList) {
                if (e === item.id) {
                    if (item.name === "北京" || item.name === "上海" || item.name === "天津" || item.name === "重庆") {
                        newModel.provinceName = item.name + "市"
                    } else if (item.name === "西藏") {
                        newModel.provinceName = item.name + "自治区"
                    } else if (item.name === "新疆") {
                        newModel.provinceName = item.name + "维吾尔自治区"
                    } else {
                        newModel.provinceName = item.name + "省"
                    }
                    newModel.cityName = "";
                    newModel.areaName = "";
                    twoLayerList = item.areaList
                    this.setState({
                        twoLayerList: twoLayerList,
                        threeLayerList: [],
                    });
                    break;
                }
            }
        } else if (k === "cityId") {
            newModel.areaId = ""
            let threeLayerList = []
            for (let item of this.state.twoLayerList) {
                if (e === item.id) {
                    if (newModel.provinceName === "北京市" || newModel.provinceName === "上海市" || newModel.provinceName === "天津市" || newModel.provinceName === "重庆市") {
                        newModel.cityName = ""
                    } else {
                        newModel.cityName = item.name + "市"
                    }
                    newModel.areaName = ""
                    threeLayerList = item.areaList
                    this.setState({
                        threeLayerList: threeLayerList,
                    })
                    break
                }
            }
        } else if (k === "areaId") {
            for (let item of this.state.threeLayerList) {
                if (e === item.id) {
                    newModel.areaName = item.name
                    break
                } else {
                    newModel.areaName = ""
                }
            }
        }
        newModel[k] = e;
        this.setState({ model: newModel });
    }

    handleEndDisabledDate(current) {
        let {startTime} = this.state.searchModel
        if (!current || !startTime) {
            return true;
        }
        return current > moment(startTime).add(1, 'month') || current < moment(startTime);
    }

    searchChartChange(k,e) {
        let newModel = this.state.searchModel;
        if (k === "provinceId") {
            newModel.cityId = ""
            newModel.areaId = ""
            newModel.area = ""
            newModel.provinceName = ""
            newModel.cityName = "";
            newModel.areaName = "";
            let twoLayerList = []
            for (let item of this.state.oneLayerList) {
                if (e === item.id) {
                    if (item.name === "北京" || item.name === "上海" || item.name === "天津" || item.name === "重庆") {
                        newModel.provinceName = item.name + "市"
                    } else if (item.name === "西藏") {
                        newModel.provinceName = item.name + "自治区"
                    } else if (item.name === "新疆") {
                        newModel.provinceName = item.name + "维吾尔自治区"
                    } else {
                        newModel.provinceName = item.name + "省"
                    }
                    newModel.cityName = "";
                    newModel.areaName = "";
                    twoLayerList = item.areaList
                    this.setState({
                        twoLayerList: twoLayerList,
                        threeLayerList: [],
                    });
                    break;
                }
            }
        } else if (k === "cityId") {
            newModel.areaId = ""
            let threeLayerList = []
            for (let item of this.state.twoLayerList) {
                if (e === item.id) {
                    if (newModel.provinceName === "北京市" || newModel.provinceName === "上海市" || newModel.provinceName === "天津市" || newModel.provinceName === "重庆市") {
                        newModel.cityName = ""
                    } else {
                        newModel.cityName = item.name + "市"
                    }
                    newModel.areaName = ""
                    threeLayerList = item.areaList
                    this.setState({
                        threeLayerList: threeLayerList,
                    })
                    break
                }
            }
        } else if (k === "areaId") {
            for (let item of this.state.threeLayerList) {
                if (e === item.id) {
                    newModel.areaName = item.name
                    break
                } else {
                    newModel.areaName = ""
                }
            }
        }
        if (k === "startTime") {
            newModel.endTime = null;
        }
        newModel[k] = e;
        this.setState({ searchModel: newModel });
    }

    threeLevelNestingSearch() {
        fetch(Config.apiBackServer + "area/threeLevelNestingSearch", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    oneLayerList: data.object
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    chartSearch() {
        let model = this.state.searchModel
        fetch(Config.apiBackServer + "shopPayoffStatement/chartSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object;
                let xAxisContentList = [];
                let regionList = [];
                let foodAmountList = [];
                let wineAmountList = [];
                let paymentAmountList = [];
                let rebateAmountTotal = 0;
                let tradeAmountTotal = 0;
                let foodAmountTotal = 0;
                let wineAmountTotal = 0;
                let paymentAmountTotal = 0;
                list.forEach(element => {
                    xAxisContentList.push(element.shopName)
                    regionList.push(element.rebateAmount || 0)
                    foodAmountList.push(element.foodAmount || 0);
                    wineAmountList.push(element.wineAmount || 0);
                    paymentAmountList.push(element.paymentAmount || 0);
                    rebateAmountTotal += parseFloat(element.rebateAmountTotal || 0);
                    tradeAmountTotal += parseFloat(element.tradeAmountTotal || 0);
                    foodAmountTotal += parseFloat(element.foodAmountTotal || 0);
                    wineAmountTotal += parseFloat(element.wineAmountTotal || 0);
                    paymentAmountTotal += parseFloat(element.paymentAmountTotal || 0);
                });
                let datesetsItem = {
                    label: "奖励金额",
                    data: regionList,
                    backgroundColor: "#37a2da",
                    borderColor: "#37a2da",
                    borderWidth: 1,
                    hoverBackgroundColor: "#37a2da",
                    hoverBorderColor: "#37a2da",
                    barThickness: "flex",
                    fill: false
                }
                let foodAmountDateItem = {
                    label: "饭菜金额",
                    data: foodAmountList,
                    backgroundColor: "#ee3f4d",
                    borderColor: "#ee3f4d",
                    borderWidth: 1,
                    hoverBackgroundColor: "#ee3f4d",
                    hoverBorderColor: "#ee3f4d",
                    barThickness: "flex",
                    fill: false
                }
                let wineAmountDateItem = {
                    label: "酒水金额",
                    data: wineAmountList,
                    backgroundColor: "#FF9933",
                    borderColor: "#FF9933",
                    borderWidth: 1,
                    hoverBackgroundColor: "#FF9933",
                    hoverBorderColor: "#FF9933",
                    barThickness: "flex",
                    fill: false
                }
                let paymentAmountDateItem = {
                    label: "店铺到账金额",
                    data: paymentAmountList,
                    backgroundColor: "#19CAAD",
                    borderColor: "#19CAAD",
                    borderWidth: 1,
                    hoverBackgroundColor: "#19CAAD",
                    hoverBorderColor: "#19CAAD",
                    barThickness: "flex",
                    fill: false
                }
                let chartData = {
                    labels: xAxisContentList,
                    datasets: [foodAmountDateItem,wineAmountDateItem,datesetsItem,paymentAmountDateItem]
                }
                this.setState({
                    chartList: data.object,
                    chartData: chartData,
                    rebateAmountTotal: rebateAmountTotal,
                    tradeAmountTotal: tradeAmountTotal, 
                    foodAmountTotal: foodAmountTotal,
                    wineAmountTotal: wineAmountTotal,
                    paymentAmountTotal: paymentAmountTotal
                })
            }
        })
    }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.model;
        fetch(Config.apiBackServer + "shopPayoffStatement/search", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
             })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    
    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    reset() {
        this.setState({
            searchModel: { pageSize: 30, page: 1, startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date()},
            model: { pageSize: 10, page: 1,startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date()},
            twoLayerList:[],
            threeLayerList:[],
        }, () => {
            if (this.state.tabIndex === "1") {
                this.chartSearch()
            } else {
                this.search()
            }
        })
    }

    tableChange(value) {
        this.setState({
            tabIndex: value,
        }, () => {
            this.reset();
            if (value === "2") {
                this.search();
            } else {
                this.chartSearch();
            }
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        }, () => {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    exportChartExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopPayoffStatement/exportChartExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {

                    }else {
                       // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺营业报表.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        })
    }

    exportConsumptionExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopPayoffStatement/exportConsumptionExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {

                    }else {
                       // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            }else {
                var filename = "店铺营业报表.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth10",
            },
            {
                title: '店铺电话',
                dataIndex: 'shopPhone',
                className: "tableWidth10",
            },
            {
                title: '详细地址',
                dataIndex: 'address',
                className: "tableWidth30",
                render: (text, record) => record.area + record.address
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '酒水金额',
                dataIndex: 'wineAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费总计',
                dataIndex: 'tradeAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text, record) => Common.numberFormat(text, 2)
            },
            {
                title: '店铺到账总计',
                dataIndex: 'paymentAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return(
            <div className="shopPayoffStatement">
                <div className="zhRoot">
                    <div className="zhTitle">店铺营业报表</div>
                    <Spin spinning={this.state.isExport} tip="下载中">
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="统计" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">所属地区：</Col>
                                        <Col span={5} className="zhInput" >
                                            <Select className="shopPayoffStatementSelect" placeholder="省" value={this.state.searchModel.provinceId || ''}
                                                onChange={(e) => this.searchChartChange('provinceId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.oneLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="shopPayoffStatementSelect" placeholder="市" value={this.state.searchModel.cityId || ''}
                                                onChange={(e) => this.searchChartChange('cityId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.twoLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="shopPayoffStatementSelect" placeholder="县" value={this.state.searchModel.areaId || ''}
                                                onChange={(e) => this.searchChartChange('areaId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.threeLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="shopPayoffStatementDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChartChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                             &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="shopPayoffStatementDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => this.handleEndDisabledDate(e)}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChartChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" onClick = {() => this.chartSearch()} icon="search">搜索</Button>
                                            <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportChartExcel()}  icon="download">导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                        <Bar
                                            width={1200}
                                            data={this.state.chartData}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "店铺营业报表",
                                                    position: "top", 
                                                },
                                                tooltips: {
                                                    enabled: true,
                                                    intersect: false
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: [{

                                                    }],
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            callback: function (value) { if (value % 1 === 0) { return value } }
                                                        }
                                                    }]
                                                },
                                                legend: {
                                                    position: "bottom",
                                                    align: "center",
                                                    labels: {
                                                        boxWidth: 20
                                                    }
                                                },
                                                hover: {
                                                    animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                },
                                                animation: {
                                                    onComplete: function() {
                                                        var chartInstance = this.chart,
                                                        ctx = chartInstance.ctx;
                                                        // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                        ctx.fillStyle = "black";
                                                        ctx.textAlign = 'center';
                                                        ctx.textBaseline = 'bottom';

                                                        this.data.datasets.forEach(function (dataset, i) {
                                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                                            meta.data.forEach(function (bar, index) {
                                                                var data = dataset.data[index];
                                                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                            });
                                                        });
                                                    } 
                                                }
                                            }}
                                        
                                        />
                                    </Row>
                                    <Row><span>饭菜总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.foodAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>酒水总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.wineAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>奖励总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.rebateAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>店铺到账总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.paymentAmountTotal || 0, 2)}</span></Row>
                                </TabPane>
                                <TabPane tab="列表" key="2">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">所属地区：</Col>
                                        <Col span={5} className="zhInput" >
                                            <Select className="shopPayoffStatementSelect" placeholder="省" value={this.state.model.provinceId || ''}
                                                onChange={(e) => this.searchChange('provinceId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.oneLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="shopPayoffStatementSelect" placeholder="市" value={this.state.model.cityId || ''}
                                                onChange={(e) => this.searchChange('cityId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.twoLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                            <Select className="shopPayoffStatementSelect" placeholder="县" value={this.state.model.areaId || ''}
                                                onChange={(e) => this.searchChange('areaId', e)}>
                                                <Option key={"a"} value={""}> </Option>
                                                {this.state.threeLayerList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchModel.shopName || ''}
                                                onSelect={(e) => this.onSelect(e)}
                                                onChange={this.onChange}
                                                onSearch={this.onSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="shopPayoffStatementDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                             &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="shopPayoffStatementDatePicker"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.exportConsumptionExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className="zhRow">
                                        <Table rowSelection={rowSelection}
                                                rowKey="shopId"
                                                bordered={true} //边线
                                                columns={columns} //列名
                                                dataSource={this.state.tables} //数据
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                pagination={{  //分页
                                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                    onChange: this.pageChange,            //上页下页或者跳页方法
                                                    onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                    total: this.state.searchModel.total,             //总共多少条数据
                                                    current: this.state.searchModel.page,         //当前页
                                                    pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                    defaultCurrent: 1,                               //默认当前页为1
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: event => Common.selectRadioRow.call(this, record, 'shopId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                    };
                                                }}
                                            />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}