import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, DatePicker, Modal } from 'antd';
import moment from 'moment';//日期格式化导包

import E from 'wangeditor'
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './newsManagementUrl.css';

const confirm = Modal.confirm;
export default class NewsManagementUrl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            saveModel: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, },
            allowEditing: false,
            allowEditing1: false,
            editorContent: '', //富文本框内容
            editorFlag: 'start',//文本框还未初始化,end已经初始化
            isLoading: true,
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "newsManagement/searchNewsList", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { newsTitle: "", newsStartTime: null, newsEndTime: null, activeInd: null, page: 1, pageSize: 10, },
        }, function () {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    toAdd() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            saveModel: { activeInd: "Y", },
        }, function () {
            this.creatEditor();
        })
    }

    save() {
        let msg = this.check();
        let url = "";
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "newsManagement/insertNews";
        } else {
            url = "newsManagement/updateNews"
        }
        let saveModel = this.state.saveModel
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(saveModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });

    }

    check() {
        let msg = ""
        let saveModel = this.state.saveModel
        if (Common.isEmpty(saveModel)) {
            msg += "新闻标题不能为空;"
        } else {
            if (Common.isEmpty(saveModel.newsTitle)) {
                msg += "新闻标题不能为空;"
            }
            if (Common.isEmpty(saveModel.newsContentStr)) {
                msg += "新闻内容不能为空;"
            }
            if (Common.isEmpty(saveModel.newsTime)) {
                msg += "新闻发布日期不能为空;"
            }
            if (Common.isEmpty(saveModel.dispSeq)) {
                msg += "序号不能为空;"
            }
            if (Common.isEmpty(saveModel.activeInd)) {
                msg += "激活状态不能为空;"
            }
        }
        return msg
    }

    toEdit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;
        this.setState({
            tabIndex: "2",
            flag: "edit",
            saveModel: this.state.selectedRows[0],
        }, function () { that.creatEditor(); })
    }

    toDelete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "newsManagement/deleteNews", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    creatEditor() {
        const elemMenu = this.refs.editorElemMenu;
        const editor = new E(elemMenu);
        editor.customConfig.onchange = html => { //使用 onchange 函数监听内容的变化，并实时更新到 state 中
            let saveModel = this.state.saveModel;
            saveModel.newsContentStr = editor.txt.html();
            this.setState({ saveModel });
        }
        editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            // 'list',  // 列表
            'justify',  // 对齐方式
            // 'quote',  // 引用
            // 'emoticon',  // 表情
            'image',  // 插入图片
            'table',  // 表格
            // 'video',  // 插入视频
            // 'code',  // 插入代码
            'undo',  // 撤销
            'redo'  // 重复
        ];
        editor.customConfig.colors = [
            '#000000',
            '#0000ff',
            '#800000',
            '#ff0000',
            '#f47920',
            '#ea66a6',
            '#afdfe4',
            '#563624',
            '#3e4145',
            '#90d7ec',
            '#ffffff'
        ];
        editor.customConfig.lang = {
            '设置标题': 'Title',
            '字号': 'Size',
            '文字颜色': 'Color',
            '设置列表': 'List',
            '有序列表': '',
            '无序列表': '',
            '对齐方式': 'Align',
            '靠左': '',
            '居中': '',
            '靠右': '',
            '正文': 'p',
            '链接文字': 'link text',
            '链接': 'link',
            '上传图片': 'Upload',
            '网络图片': 'Web',
            '图片link': 'image url',
            '插入视频': 'Video',
            '格式如': 'format',
            '上传': 'Upload',
            '创建': 'init'
        };
        editor.customConfig.customUploadImg = function (files, insert) {
            // files 是 input 中选中的文件列表
            // insert 是获取图片 url 后，插入到编辑器的方法
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                insert(reader.result)
            };
            // 上传代码返回结果之后，将图片插入到编辑器中
        }
        editor.create();
        let value = '';
        if (this.state.saveModel != null && Common.isNotEmpty(this.state.saveModel.newsContentStr)) {
            value = this.state.saveModel.newsContentStr;
        }
        editor.txt.html(value);
        this.setState({ editorFlag: 'end' })//已经初始化 
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.saveModel;
        newModel[k] = e;
        this.setState({
            saveModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        // console.log(moment(model.fromDate, App.dateFormat))
        // console.log(moment(model.toDate, App.dateFormat))
        // if (flag === "fromDate") {
        //     if (Common.isEmpty(model.fromDate)) {
        //         callback("由日期不能為空");
        //     } else if (Common.isNotEmpty(model.toDate) && Common.afterDate(model.fromDate, model.toDate)) {
        //         callback("由日期不能大於至日期");
        //     } else {
        //         this.props.form.validateFields(['toDate'], { force: true });
        //         callback();
        //     }
        // } else {
        //     if (Common.isEmpty(model.toDate)) {
        //         callback("至日期不能為空");
        //     } else if (Common.isNotEmpty(model.fromDate) && Common.isNotEmpty(model.toDate) && Common.afterDate(model.fromDate, model.toDate)) {
        //         callback("至日期不能小於由日期");
        //     } else {
        //         this.props.form.validateFields(['fromDate'], { force: true });
        //         callback();
        //     }
        // }
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            saveModel: {},
        })
        this.search();
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.toEdit();
            } else {
                this.toAdd();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '新闻标题',
                dataIndex: 'newsTitle',
                className: "tableWidth50",
            },
            {
                title: '新闻发布日期',
                dataIndex: 'newsTime',
                className: "tableWidth20",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '序号',
                dataIndex: 'dispSeq',
                className: "tableWidth15",
            },
            {
                title: '激活',
                dataIndex: 'activeInd',
                className: "tableWidth15",
                render: (text) => ContextHandler.getIndValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">新闻管理</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">新闻标题：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="新闻标题"
                                            value={this.state.searchModel.newsTitle || ''}
                                            onChange={(e) => this.searchChange('newsTitle', e.target.value)} />
                                    </Col>

                                    <Col span={2} className="zhTxt">发布日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.newsStartTime == null ? null : moment(new Date(this.state.searchModel.newsStartTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("newsStartTime", value)}
                                            format={Config.dateFormat}
                                        />
                                    </Col>
                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.newsEndTime == null ? null : moment(new Date(this.state.searchModel.newsEndTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("newsEndTime", value)}
                                            format={Config.dateFormat}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">激活：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.activeInd || ''}
                                            onChange={(e) => this.searchChange('activeInd', e)} >
                                            <Option value={""}>全部</Option>
                                            {context.inds.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.toAdd()} icon="plus">新增</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toEdit()} icon="edit">修改</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toDelete()} icon="delete">删除</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>新闻标题<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={30}
                                            placeholder="新闻标题"
                                            value={this.state.saveModel.newsTitle || ''}
                                            onChange={(e) => this.modelChange('newsTitle', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>新闻发布日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="新闻发布日期"
                                            className="parts-numberInput zIndexDate"
                                            allowClear={false}
                                            value={this.state.saveModel.newsTime == null ? null : moment(new Date(this.state.saveModel.newsTime), Config.dateFormat)}
                                            onChange={(value) => this.modelChange("newsTime", value)}
                                            format={Config.dateFormat}
                                        />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>序号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={30}
                                            placeholder="序号"
                                            value={this.state.saveModel.dispSeq || ''}
                                            onChange={(e) => this.modelChange('dispSeq', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.saveModel.activeInd || ''}
                                            onChange={(e) => this.modelChange('activeInd', e)} >
                                            {context.inds.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>新闻内容<span className='redStar'>*</span>：</Col>
                                </Row>
                                <Row className='row'>
                                    <div className="zIndexWEditor">
                                        <div ref='editorElemMenu' />
                                    </div>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}