import React, { Component } from 'react';
import { Spin, InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker, Icon, Upload } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './registeredMember.css';
import context from '../../public/js/context';
const { TextArea } = Input;
const confirm = Modal.confirm;
var css;
export default class RegisteredMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryList: [],
            secondaryFlag: "add",
            secondaryModel: {},
            model: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, },
            funcList: [],
            searchShopList: [],
            isLoading: true,
            allowEditing: false,
            paymentAmount: [{}],
            isAdd: false,
            secondaryRestrictions: false,
            previewVisibleZFB: false,
            imageFileZFB: [],
            ImageNumZFB: 1,
            previewVisibleWX: false,
            imageFileWX: [],
            ImageNumWX: 1,
            isExport: false
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
        this.searchPaymentAmount();
    }

    searchPaymentAmount() {
        let model = { segment: "RENEWAL_AMOUNT", code: "NUMERICAL_VALUE" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                paymentAmount: data.object
            })
        }).catch(err => { });
    }
    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShopById", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list
            }, () => {
                if (data.object.list != null && data.object.list.length > 0) {
                    let model = { shopId: this.state.searchShopList[0].shopId, cardLimit: this.state.searchShopList[0].cardLimit }
                    this.setState({
                        model: model,
                        cardLimit: this.state.searchShopList[0].cardLimit
                    })
                }

            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "openMembership/searchByShopId", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }

    add() {
        if (this.state.searchShopList != null && this.state.searchShopList.length > 0) {
            this.setState({
                tabIndex: "2",
                flag: "add",
                model: {
                    openDate: new Date(),
                    endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                    active: 'Y',
                    shopId: this.state.searchShopList[0].shopId,
                    transactionType: 'CASH',
                },
            })
        }

    }
    //升级银卡
    upgradeSilverCard() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (this.state.selectedRows[0].userId == null) {
            Message.openMsgError("错误", "请先绑定用户")
            return
        }
        if (this.state.selectedRows[0].whetherMasterCardId == null) {
            Message.openMsgError("错误", "已是金卡,不能升级")
            return
        }
        if (this.state.searchShopList != null && this.state.searchShopList.length > 0) {
            this.setState({
                tabIndex: "2",
                flag: "upgradeSilverCard",
                model: {
                    openDate: new Date(),
                    endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                    active: 'Y',
                    shopId: this.state.searchShopList[0].shopId,
                    id: this.state.selectedRows[0].id,
                    transactionType: 'CASH',
                },
            })
        }

    }
    addChildren() {
        if (this.state.searchShopList != null && this.state.searchShopList.length > 0) {
            this.setState({
                tabIndex: "2",
                flag: "addChildren",
                model: {
                    openDate: new Date(),
                    endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                    active: 'Y',
                    shopId: this.state.searchShopList[0].shopId,
                },
            })
        }
    }


    save() {
        let msg = this.check();
        let url = "";
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        if (this.state.flag === "add") {
            url = "openMembership/insert";
        } else if (this.state.flag === "addChildren") {
            url = "openMembership/giveInsert";
        } else if (this.state.flag === "upgradeSilverCard") {
            url = "memberPromotion/insert";
        } else {
            url = "openMembership/update"
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (this.state.flag === "addChildren" || this.state.flag === "upgradeSilverCard") {
                    this.cancel()
                } else if (this.state.flag === "add") {
                    let model = this.state.model
                    model.id = data.object.substr(0, 20)
                    this.setState({
                        model: model,
                    }, () => {
                        this.setState({
                            isAdd: true
                        })
                    })
                }
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                })
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });

    }

    check() {
        let msg = ""
        let model = this.state.model
        if (this.state.flag !== "addChildren" && (model.cardNumber == null || model.cardNumber === '')) {
            msg += "金卡卡号不能为空;"
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "HU")) {
                msg += "会员卡号不能以'SC'或'HU'开头;"
            } else if (model.cardNumber.length < 7) {
                msg += "会员卡号长度至少为7;"
            } else if (model.cardNumber.length == 9) {
                msg += "会员卡号长度不能为9;"
            } else if (Common.isEmpty(model.cardSource) && model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6") {
                msg += "会员卡号为10位时，不能以'6'开头;"
            }
        }
        if (model.phone) {
            if (!model.userId) {
                msg += "会员卡未绑定,不能修改手机号;"
            }
        }

        return msg
    }
    //自动加上三天
    getNextDay(d, t) {
        console.log(d, t)//格式为---2019-02-13 3
        d = new Date(d);
        console.log(d)//格式为---Wed Feb 13 2019 08:00:00 GMT+0800 (中国标准时间)
        d = +d + (1000 * 60 * 60 * 24) * t;
        console.log(d)//格式为--时间戳1550275200000
        // d = new Date(d);
        // console.log(d)//格式为---Sat Feb 16 2019 08:00:00 GMT+0800 (中国标准时间)
        return new Date(d)   //格式为"2019-02-16 00:00:00"
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        if (module.createdDate != null) {
            let date = Common.dateFormat(new Date().setDate(new Date().getDate() - 3), "yyyy/MM/dd")
            if (Common.dateFormat(module.createdDate, "yyyy/MM/dd") >= date && Common.dateFormat(module.createdDate, "yyyy/MM/dd") <= Common.dateFormat(new Date(), "yyyy/MM/dd")) {
                this.setState({//还未超过3天可以编辑
                    restrictions: false,
                })
            } else {
                this.setState({//超过3天 不可编辑
                    restrictions: true,
                })
            }
            var endDate = new Date(module.createdDate);
            endDate.setFullYear(endDate.getFullYear() + 1);
            module.endDate = endDate;
        }
        var weChatAddress = module.weChatAddress;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') != -1) {
            file1[0].url = weChatAddress;
            file1[0].name = weChatAddress;
        } else if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') == -1) {
            file1[0].url = Config.consumptionRebate + weChatAddress;
            file1[0].name = weChatAddress;
        } else {
            file1 = []
        }
        var alipayAddress = module.alipayAddress;
        let file2 = [{ uid: '-1', }]
        if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') != -1) {
            file2[0].url = alipayAddress;
            file2[0].name = alipayAddress;
        } else if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') == -1) {
            file2[0].url = Config.consumptionRebate + alipayAddress;
            file2[0].name = alipayAddress;
        } else {
            file2 = []
        }
        fetch(Config.apiBackServer + "openMembership/editSearch", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                this.setState({
                    tabIndex: "2",
                    flag: "edit",
                    model: module,
                    imageFileWX: file1,
                    imageFileZFB: file2,
                }, () => {
                   // this.editSearch()
                })
            }
        }).catch(err => { });


    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    handlePreviewZFB = (file) => {
        this.setState({
            previewImageZFB: file.url || file.thumbUrl,
            previewVisibleZFB: true,
        });
    }

    handlePreviewWX = (file) => {
        this.setState({
            previewImageWX: file.url || file.thumbUrl,
            previewVisibleWX: true,
        });
    }

    handleCancel2() {
        this.setState({ previewVisibleZFB: false })
    }
    handleCancel3() {
        this.setState({ previewVisibleWX: false })
    }

    handleChangeZFB(file) {
        this.setState({
            imageFileZFB: file.fileList
        })
    }

    handleChangeWX(file) {
        this.setState({
            imageFileWX: file.fileList
        })
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            restrictions: false,
            isAdd: false,
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add",
            secondaryRestrictions: false
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length === 1) {
                    this.edit();
                } else {
                    this.add();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "openMembership/memberShopReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }
    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButtonZFB = (
            <div>
                <Icon type="plus" />
                <div>设置支付宝收款码</div>
            </div>
        );
        const uploadButtonWX = (
            <div>
                <Icon type="plus" />
                <div>设置微信收款码</div>
            </div>
        );
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '推荐卡号',
                dataIndex: 'whetherMasterCard',
                className: 'tableWidth10',
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth10',
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth8',
                render: (text, record, index) => (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                    (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                        (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                    ))
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: 'tableWidth10',
            },
            // {
            //     title: '备注',
            //     dataIndex: 'remark',
            //     className: 'tableWidth14',
            // },
            {
                title: '售卡人',
                dataIndex: 'cardSellerName',
                className: 'tableWidth7',
            },
            {
                title: '售卡人手机号',
                dataIndex: 'cardSellerPhone',
                className: 'tableWidth7',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getActiveValue(text)
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            }
        ];

        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth15',
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: 'tableWidth15',
            },
            {
                title: '预留手机号',
                className: 'tableWidth15',
                dataIndex: 'reservedIdCard',
            },
            {
                title: '售卡人',
                className: 'tableWidth15',
                dataIndex: 'cardSellerName',
            },
            {
                title: '售卡人手机号',
                className: 'tableWidth15',
                dataIndex: 'cardSellerPhone',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: 'tableWidth20',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth5',
                render: (text) => ContextHandler.getActiveValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };

        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">会员管理</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>

                                        <Col span={2} className="zhTxt">卡号类型：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.cardNumberType || ''}
                                                onChange={(e) => this.searchChange('cardNumberType', e)} >
                                                <Option value={""}>全部</Option>
                                                <Option value={"masterCard"}>金卡</Option>
                                                <Option value={"subCard"}>银卡</Option>
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">是否绑定用户：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.binding || ''}
                                                onChange={(e) => this.searchChange('binding', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.bindingMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡日期：</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">至</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="至日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">用户手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="用户手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">开通金卡</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.upgradeSilverCard()} icon="plus">银卡升级</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.addChildren()} icon="plus">赠送银卡</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出报表</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>

                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Modal title="放大图" visible={this.state.previewVisibleZFB} footer={null} onCancel={() => this.handleCancel2()}>
                                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImageZFB} />
                                    </Modal>
                                    <Modal title="放大图" visible={this.state.previewVisibleWX} footer={null} onCancel={() => this.handleCancel3()}>
                                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImageWX} />
                                    </Modal>
                                    {this.state.flag === "edit" ? <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'><h3>仅可修改最近三天开卡会员信息</h3></Col>
                                        </Row>
                                    </> : ""}
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>开卡店铺名称<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select style={{ width: "100%" }} placeholder="开卡店铺名称" value={this.state.model.shopId || ''}
                                                disabled
                                                onChange={(e) => this.modelChange('shopId', e)}>
                                                {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4} className='zhTxt'>开通会员日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开通会员卡日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.openDate == null ? moment(new Date(this.state.model.createdDate), Config.dateFormat) : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员结束日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="会员结束日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        {this.state.flag !== "addChildren" ?
                                            <>
                                                <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.transactionType || ''}
                                                        disabled={this.state.flag === "edit"}
                                                        onChange={(e) => this.modelChange('transactionType', e)}>
                                                        {context.transactionTypeList.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                                                    </Select>

                                                </Col>
                                            </>
                                            :
                                            <>
                                                <Col span={4} className='zhTxt'>银卡卡号<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        placeholder="银卡卡号"
                                                        value={this.state.model.cardNumber || ''}
                                                        disabled={Common.isNotEmpty(this.state.model.cardSource) && this.state.model.cardSource == "SYS"}
                                                        onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                                </Col>
                                            </>}
                                        {this.state.flag === "edit" ? <>
                                            <Col span={4} className='zhTxt' >手机号：</Col>
                                            <Col span={6} className='zhInput' >
                                                <Input type="phone" maxLength={11}
                                                    disabled={this.state.restrictions}
                                                    value={this.state.model.phone || ''}
                                                    onChange={(e) => this.modelChange('phone', e.target.value)} />
                                            </Col>
                                        </> : this.state.flag === "addChildren" ?
                                                <>
                                                    <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={11}
                                                            disabled={this.state.allowEditing}
                                                            disabled={this.state.restrictions}
                                                            placeholder="预留手机号"
                                                            value={this.state.model.reservedIdCard || ''}
                                                            onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                                    </Col>
                                                </> : <>
                                                    <Col span={4} className='zhTxt' >缴费金额：</Col>
                                                    <Col span={6} className='zhInput' >
                                                        <Input type="phone" maxLength={11} disabled
                                                            value={this.state.paymentAmount[0].parmValue1 || ''} />
                                                    </Col>
                                                </>}
                                    </Row>
                                    {this.state.flag === "upgradeSilverCard" ? <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>金卡卡号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="金卡卡号"
                                                    value={this.state.model.cardNumber || ''}
                                                    disabled={Common.isNotEmpty(this.state.model.cardSource) && this.state.model.cardSource == "SYS"}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="预留手机号"
                                                    value={this.state.model.reservedIdCard || ''}
                                                    onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>售卡人：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="售卡人"
                                                    value={this.state.model.cardSellerName || ''}
                                                    onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="售卡人手机号"
                                                    value={this.state.model.cardSellerPhone || ''}
                                                    onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                            </Col>
                                        </Row>

                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>备注：</Col>
                                            <Col span={6} className='zhInput'>
                                                <TextArea
                                                    value={this.state.model.remark || ""}
                                                    onChange={(e) => this.modelChange('remark', e.target.value)}
                                                    placeholder="备注"
                                                    maxLength={100}
                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                />
                                            </Col>
                                        </Row>
                                        {this.state.secondaryTabIndex === "2" ? "" :
                                            <Row className='zhRow zhRowButtonRight'>
                                                <Button disabled={this.state.allowEditing} type="primary" disabled={this.state.isAdd} icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                                <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                            </Row>}
                                    </> : this.state.flag === "addChildren" ? <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>售卡人：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="售卡人"
                                                    value={this.state.model.cardSellerName || ''}
                                                    onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="售卡人手机号"
                                                    value={this.state.model.cardSellerPhone || ''}
                                                    onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>备注：</Col>
                                            <Col span={6} className='zhInput'>
                                                <TextArea
                                                    value={this.state.model.remark || ""}
                                                    onChange={(e) => this.modelChange('remark', e.target.value)}
                                                    placeholder="备注"
                                                    maxLength={100}
                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                />
                                            </Col>
                                        </Row>
                                        {this.state.secondaryTabIndex === "2" ? "" :
                                            <Row className='zhRow zhRowButtonRight'>
                                                <Button disabled={this.state.allowEditing} type="primary" disabled={this.state.isAdd} icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                                <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                            </Row>}

                                    </> : this.state.flag === "add" ? <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>金卡卡号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="金卡卡号"
                                                    value={this.state.model.cardNumber || ''}
                                                    disabled={Common.isNotEmpty(this.state.model.cardSource) && this.state.model.cardSource == "SYS"}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="预留手机号"
                                                    value={this.state.model.reservedIdCard || ''}
                                                    onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>售卡人：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="售卡人"
                                                    value={this.state.model.cardSellerName || ''}
                                                    onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="售卡人手机号"
                                                    value={this.state.model.cardSellerPhone || ''}
                                                    onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>备注：</Col>
                                            <Col span={6} className='zhInput'>
                                                <TextArea
                                                    value={this.state.model.remark || ""}
                                                    onChange={(e) => this.modelChange('remark', e.target.value)}
                                                    placeholder="备注"
                                                    maxLength={100}
                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                />
                                            </Col>
                                        </Row>
                                        {this.state.secondaryTabIndex === "2" ? "" :
                                            <Row className='zhRow zhRowButtonRight'>
                                                <Button disabled={this.state.allowEditing} type="primary" disabled={this.state.isAdd} icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                                <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                            </Row>}

                                        <Row className='zhRow'>
                                            <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                                <TabPane tab="银卡列表" key="1">
                                                    <Row className='zhRow'>
                                                        <Button type="primary" onClick={() => this.secondaryAdd()} icon="plus">添加</Button>
                                                        <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryEdit()} icon="edit">修改</Button>
                                                        <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryDelete()} icon="delete">删除</Button>
                                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                                    </Row>
                                                    <Row className='zhRow'></Row>
                                                    <Row className='row'>
                                                        <Table rowSelection={secondaryRowSelection}
                                                            rowKey="id"
                                                            bordered={true} //边线
                                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                            columns={secondaryColumns} //列名
                                                            dataSource={this.state.secondaryList} //数据
                                                            pagination={false}
                                                            onRow={(record) => {
                                                                return {
                                                                    onClick: event => Common.selectRadioRow.call(this, record, 'id', 'secondarySelectedRows', 'secondarySelectedRowKeys'), // 点击行
                                                                };
                                                            }}
                                                            rowClassName="tableColor"
                                                        />
                                                    </Row>
                                                </TabPane>
                                                <TabPane tab="银卡详情" key="2">
                                                    <Row className='row'>
                                                        <Col span={4} className='zhTxt'>银卡卡号<span className='redStar'>*</span>：</Col>
                                                        <Col span={6} className='zhInput'>
                                                            <Input maxLength={10}
                                                                placeholder="银卡卡号"
                                                                value={this.state.secondaryModel.cardNumber || ''}
                                                                disabled={Common.isNotEmpty(this.state.secondaryModel.cardSource) && this.state.secondaryModel.cardSource == "SYS"}
                                                                onChange={(e) => this.secondaryChange('cardNumber', e.target.value)} />
                                                        </Col>
                                                        {this.state.secondaryFlag === "add" ? <>
                                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                            <Col span={6} className='zhInput'>
                                                                <Input placeholder="预留手机号"
                                                                    maxLength={11}
                                                                    value={this.state.secondaryModel.reservedIdCard || ''}
                                                                    onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                            </Col>
                                                        </> : <>
                                                                <Col span={4} className='zhTxt' >用户手机号：</Col>
                                                                <Col span={6} className='zhInput' >
                                                                    <Input placeholder="用户手机号"
                                                                        maxLength={11}
                                                                        value={this.state.secondaryModel.phone || ''}
                                                                        onChange={(e) => this.secondaryChange('phone', e.target.value)} />
                                                                </Col>

                                                            </>}
                                                    </Row>
                                                    {this.state.secondaryFlag === "add" ?
                                                        <>
                                                            <Row className='row'>
                                                                <Col span={4} className='zhTxt'>售卡人：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <Input maxLength={10}
                                                                        placeholder="售卡人"
                                                                        value={this.state.secondaryModel.cardSellerName || ''}
                                                                        onChange={(e) => this.secondaryChange('cardSellerName', e.target.value)} />
                                                                </Col>
                                                                <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <Input maxLength={11}
                                                                        placeholder="售卡人手机号"
                                                                        value={this.state.secondaryModel.cardSellerPhone || ''}
                                                                        onChange={(e) => this.secondaryChange('cardSellerPhone', e.target.value)} />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={4} className='zhTxt'>备注：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <TextArea
                                                                        value={this.state.secondaryModel.remark || ""}
                                                                        onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                                        placeholder="备注"
                                                                        maxLength={100}
                                                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Row className='row'>
                                                                <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <Input maxLength={11}
                                                                        placeholder="售卡人手机号"
                                                                        value={this.state.secondaryModel.cardSellerPhone || ''}
                                                                        onChange={(e) => this.secondaryChange('cardSellerPhone', e.target.value)} />
                                                                </Col>
                                                                <Col span={4} className='zhTxt'>售卡人：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <Input maxLength={10}
                                                                        placeholder="售卡人"
                                                                        value={this.state.secondaryModel.cardSellerName || ''}
                                                                        onChange={(e) => this.secondaryChange('cardSellerName', e.target.value)} />
                                                                </Col>

                                                            </Row>
                                                            <Row className='row'>
                                                                <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <Input placeholder="预留手机号"
                                                                        maxLength={11}
                                                                        value={this.state.secondaryModel.reservedIdCard || ''}
                                                                        onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                                </Col>
                                                                <Col span={4} className='zhTxt'>备注：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <TextArea
                                                                        value={this.state.secondaryModel.remark || ""}
                                                                        onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                                        placeholder="备注"
                                                                        maxLength={100}
                                                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                    <Row className='zhRow zhRowButtonRight'>
                                                        <Button type="primary" icon="check-circle" onClick={() => { this.secondarySave(this.state.flag) }}>保存</Button>
                                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.secondaryCancel() }}>取消</Button>
                                                    </Row>
                                                </TabPane>
                                            </Tabs>
                                        </Row>
                                    </> : this.state.flag === "edit" ? <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>卡号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    disabled={this.state.restrictions || (Common.isNotEmpty(this.state.model.cardSource) && this.state.model.cardSource == "SYS")}
                                                    value={this.state.model.cardNumber || ''}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="预留手机号"
                                                    disabled={this.state.restrictions}
                                                    value={this.state.model.reservedIdCard || ''}
                                                    onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>售卡人：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="售卡人"
                                                    disabled={this.state.restrictions}
                                                    value={this.state.model.cardSellerName || ''}
                                                    onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="售卡人手机号"
                                                    disabled={this.state.restrictions}
                                                    value={this.state.model.cardSellerPhone || ''}
                                                    onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                            <Col span={6} className='imgUpload zhInput'>
                                                <Upload
                                                    key={"1"}
                                                    disabled
                                                    accept=".png, .jpg, .jpeg"
                                                    action={Config.apiBackServer + "test/test"}
                                                    listType="picture-card"
                                                    fileList={this.state.imageFileZFB}
                                                    onPreview={this.handlePreviewZFB}
                                                >
                                                    {this.state.imageFileZFB.length >= this.state.ImageNumZFB ? null : uploadButtonZFB}
                                                </Upload>
                                            </Col>
                                            <Col span={4} className='zhTxt'>微信宝收款码：</Col>
                                            <Col span={6} className='imgUpload zhInput'>
                                                <Upload
                                                    key={"1"}
                                                    disabled
                                                    accept=".png, .jpg, .jpeg"
                                                    action={Config.apiBackServer + "test/test"}
                                                    listType="picture-card"
                                                    fileList={this.state.imageFileWX}
                                                    onPreview={this.handlePreviewWX}
                                                >
                                                    {this.state.imageFileWX.length >= this.state.ImageNumWX ? null : uploadButtonWX}
                                                </Upload>
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>备注：</Col>
                                            <Col span={6} className='zhInput'>
                                                <TextArea
                                                    value={this.state.model.remark || ""}
                                                    onChange={(e) => this.modelChange('remark', e.target.value)}
                                                    placeholder="备注"
                                                    maxLength={100}
                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                />
                                            </Col>
                                        </Row>
                                        {this.state.secondaryTabIndex === "2" ? "" :
                                            <Row className='zhRow zhRowButtonRight'>
                                                <Button disabled={this.state.allowEditing} type="primary" disabled={this.state.isAdd} icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                                <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                            </Row>}

                                        {this.state.model.whetherMasterCardId == null && this.state.model.cardNumberType === "Y" ? <>
                                            <Row className='zhRow'>
                                                <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                                    <TabPane tab="银卡列表" key="1">
                                                        <Row className='zhRow'>
                                                            <Button type="primary" onClick={() => this.secondaryAdd()} icon="plus">添加</Button>
                                                            <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryEdit()} icon="edit">修改</Button>
                                                            <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryDelete()} icon="delete">删除</Button>
                                                            {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                                        </Row>
                                                        <Row className='zhRow'></Row>
                                                        <Row className='row'>
                                                            <Table rowSelection={secondaryRowSelection}
                                                                rowKey="id"
                                                                bordered={true} //边线
                                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                                columns={secondaryColumns} //列名
                                                                dataSource={this.state.secondaryList} //数据
                                                                pagination={false}
                                                                onRow={(record) => {
                                                                    return {
                                                                        onClick: event => Common.selectRadioRow.call(this, record, 'id', 'secondarySelectedRows', 'secondarySelectedRowKeys'), // 点击行
                                                                    };
                                                                }}
                                                                rowClassName="tableColor"
                                                            />
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tab="银卡详情" key="2">
                                                        <Row className='row'>
                                                            <Col span={4} className='zhTxt'>银卡卡号<span className='redStar'>*</span>：</Col>
                                                            <Col span={6} className='zhInput'>
                                                                <Input maxLength={10}
                                                                    placeholder="银卡卡号"
                                                                    disabled={this.state.secondaryRestrictions || (Common.isNotEmpty(this.state.secondaryModel.cardSource) && this.state.secondaryModel.cardSource == "SYS")}
                                                                    value={this.state.secondaryModel.cardNumber || ''}
                                                                    onChange={(e) => this.secondaryChange('cardNumber', e.target.value)} />
                                                            </Col>
                                                            {this.state.secondaryFlag === "add" ? <>
                                                                <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                                <Col span={6} className='zhInput'>
                                                                    <Input placeholder="预留手机号"
                                                                        maxLength={11}
                                                                        disabled={this.state.secondaryRestrictions}
                                                                        value={this.state.secondaryModel.reservedIdCard || ''}
                                                                        onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                                </Col>
                                                            </> : <>
                                                                    <Col span={4} className='zhTxt' >用户手机号：</Col>
                                                                    <Col span={6} className='zhInput' >
                                                                        <Input placeholder="用户手机号"
                                                                            maxLength={11}
                                                                            disabled={this.state.secondaryRestrictions}
                                                                            value={this.state.secondaryModel.phone || ''}
                                                                            onChange={(e) => this.secondaryChange('phone', e.target.value)} />
                                                                    </Col>

                                                                </>}
                                                        </Row>
                                                        {this.state.secondaryFlag === "add" ?
                                                            <>
                                                                <Row className='row'>
                                                                    <Col span={4} className='zhTxt'>售卡人：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <Input maxLength={10}
                                                                            placeholder="售卡人"
                                                                            value={this.state.secondaryModel.cardSellerName || ''}
                                                                            onChange={(e) => this.secondaryChange('cardSellerName', e.target.value)} />
                                                                    </Col>
                                                                    <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <Input maxLength={11}
                                                                            placeholder="售卡人手机号"
                                                                            value={this.state.secondaryModel.cardSellerPhone || ''}
                                                                            onChange={(e) => this.secondaryChange('cardSellerPhone', e.target.value)} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className='row'>
                                                                    <Col span={4} className='zhTxt'>备注：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <TextArea
                                                                            value={this.state.secondaryModel.remark || ""}
                                                                            onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                                            placeholder="备注"
                                                                            maxLength={100}
                                                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            :
                                                            <>
                                                                <Row className='row'>
                                                                    <Col span={4} className='zhTxt'>售卡人：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <Input maxLength={10}
                                                                            placeholder="售卡人"
                                                                            value={this.state.secondaryModel.cardSellerName || ''}
                                                                            onChange={(e) => this.secondaryChange('cardSellerName', e.target.value)} />
                                                                    </Col>
                                                                    <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <Input maxLength={11}
                                                                            placeholder="售卡人手机号"
                                                                            value={this.state.secondaryModel.cardSellerPhone || ''}
                                                                            onChange={(e) => this.secondaryChange('cardSellerPhone', e.target.value)} />
                                                                    </Col>

                                                                </Row>
                                                                <Row className='row'>
                                                                    <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <Input placeholder="预留手机号"
                                                                            maxLength={11}
                                                                            value={this.state.secondaryModel.reservedIdCard || ''}
                                                                            onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                                    </Col>
                                                                    <Col span={4} className='zhTxt'>备注：</Col>
                                                                    <Col span={6} className='zhInput'>
                                                                        <TextArea
                                                                            value={this.state.secondaryModel.remark || ""}
                                                                            onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                                            placeholder="备注"
                                                                            maxLength={100}
                                                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                        }
                                                        <Row className='zhRow zhRowButtonRight'>
                                                            <Button type="primary" icon="check-circle" onClick={() => { this.secondarySave(this.state.flag) }}>保存</Button>
                                                            <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.secondaryCancel() }}>取消</Button>
                                                        </Row>
                                                    </TabPane>
                                                </Tabs>
                                            </Row>
                                        </> : ""}
                                    </> : ""}

                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }

    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    // editSearch() {
    //     this.setState({ isLoading: true })
    //     let model = { whetherMasterCardId: this.state.model.id, pageSize: 50, page: 1, }
    //     fetch(Config.apiBackServer + "openMembership/search", {
    //         method: 'POST',
    //         body: JSON.stringify(model),
    //         headers: Config.header,
    //         mode: 'cors',
    //         cache: 'default'
    //     }).then(res => res.json()).then((data) => {
    //         if ("SUCCESS" === data.msg) {
    //             this.setState({
    //                 secondaryList: data.object.list
    //             })
    //         } else {
    //             Message.openMsgError("错误", data.msg, data.errorCode);
    //         }
    //     }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    // }


    secondaryCancel() {
        this.setState({
            secondaryFlag: "add",
            secondaryTabIndex: "1",
            secondaryModel: {},
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryRestrictions: false
        })
        // this.editSearch()
    }
    secondaryAdd() {
        if (this.state.secondaryList.length >= this.state.cardLimit) {
            Message.openMsgError("错误", "银卡会员已达到上限,不能添加")
            return
        }
        if (!this.state.model.id) {
            Message.openMsgError("错误", "请先保存金卡信息")
            return
        }
        this.setState({
            secondaryFlag: "add",
            secondaryTabIndex: "2",
            secondaryModel: { active: "Y" }
        })
    }

    secondaryEdit() {

        if (this.state.secondarySelectedRows == null || this.state.secondarySelectedRows.length != 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.secondarySelectedRows[0];
        if (module.createdDate != null) {
            let date = Common.dateFormat(new Date().setDate(new Date().getDate() - 3), "yyyy/MM/dd")
            if (Common.dateFormat(module.createdDate, "yyyy/MM/dd") >= date && Common.dateFormat(module.createdDate, "yyyy/MM/dd") <= Common.dateFormat(new Date(), "yyyy/MM/dd")) {
                this.setState({//还未超过3天可以编辑
                    secondaryRestrictions: false,
                })
            } else {
                this.setState({//超过3天 不可编辑
                    secondaryRestrictions: true,
                })
            }
        }
        this.setState({
            secondaryFlag: "edit",
            secondaryTabIndex: "2",
            secondaryModel: this.state.secondarySelectedRows[0],
        })
    }
    secondarySave() {
        let model = this.state.secondaryModel
        if (this.state.secondaryFlag === "edit") {
            if (model.phone) {
                if (!model.userId) {
                    Message.openMsgError("错误", "会员卡未绑定,不能修改手机号");
                    return
                }
            }
        }
        if (!model.cardNumber) {
            Message.openMsgError("错误", "会员卡号不能为空");
            return
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "SC")) {
                Message.openMsgError("错误", "会员卡号不能以'SC'或'HU'开头");
                return
            } else if (model.cardNumber.length < 7) {
                Message.openMsgError("错误", "会员卡号长度至少为7");
                return
            } else if (model.cardNumber.length == 9) {
                Message.openMsgError("错误", "会员卡号长度不能为9");
                return
            } else if (Common.isEmpty(model.cardSource) && model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6") {
                Message.openMsgError("错误", "会员卡号为10位时，不能以'6'开头");
                return
            }
        }
        let url = "openMembership/update"
        if (this.state.secondaryFlag === "add") {
            url = "openMembership/secondaryInsert";
            model.whetherMasterCardId = this.state.model.id
        }
        model.shopId = this.state.model.shopId
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("保存成功", "");
                this.secondaryCancel()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { })
    }
    secondaryDelete() {
        if (this.state.secondarySelectedRows == null || this.state.secondarySelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.secondarySelectedRows[0]
        if (model.userId) {
            Message.openMsgError("错误", "会员已被绑定不能删除");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "openMembership/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.secondaryCancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }
}