import { notification } from 'antd';
import React from 'react';
import { Common } from '../../public/js/common';
/**
 * 提示消息处理类
 * author ： gx
 */
export class Message {
    
    /**
     * 关闭所有提示消息
     */
    static closeMsg(){
        notification.destroy();
    }

    /**
     * 根据传入类型 type 和提示信息 message 显示提示消息
     * @param {*} type 提示消息类型（success，error，info，warning，open）
     * @param {*} message 提示信息
     * @param {*} description 详细提示信息
     */
    static openMsg(type , message , description){
        if(type == null || message == null || message.trim() === ''){
            return;
        }
        // notification[type]({message,description});
        let replaceFun = function(content){
            if(content != null && content.trim() !== ''){
                let msgs = content.split(";");
                if(msgs.length > 1){
                    let index = 1;
                    content = '';
                    msgs.map((item) => {
                        if (item.trim() !== '') {
                            content += index++ + '.' + item + '<br/>'
                        }
                    });
                }
            }
            return content;
        }
        notification[type]({
            message : <p dangerouslySetInnerHTML={{ __html: replaceFun(message) }} />
            ,description : <p dangerouslySetInnerHTML={{ __html: replaceFun(description) }} />
        });
    }

    /**
     * 成功提示信息
     * @param {*} message 提示信息
     * @param {*} description 详细提示信息
     */
    static openMsgSuccess(message , description){
        Message.openMsg('success', message , description);
    }

    /**
     * 失败提示信息
     * @param {*} message 提示信息
     * @param {*} description 详细提示信息
     * @param {*} errorCode 错误编码
     */
    static openMsgError(message , description,errorCode){
        Message.openMsg('error', message , description);
        if (errorCode==="OVERTIME") {
            Common.loginOvertime()
        }
    }
}