/**
 * 公共方法
 */
export class Common {

    /**
     * 校验参数是否为空
     * String 类型校验，当值为（null,undefined,'','  '）时都为空返回true
     * Array 类型校验，当值为（null,undefined,Array.length === 0）时都为空返回true
     * @param {*} param 要校验的参数  
     */
    static isEmpty(param) {
        if (param == null) {
            return true;
        }
        let flag = false;
        // 基本类型判断（还可以判断 number,boolean 类型）
        if (typeof param === "string") {
            if (param.trim() === '') {
                flag = true;
            }
        }// 对象类型判断（还可以判断 Object，Function 类型）
        else if (param instanceof Array) {
            if (param.length === 0) {
                flag = true;
            }
        }
        return flag;
    }

    /**
     * 校验参数是否不为空，跟isEmpty方法相反
     * @param {*} param 要校验的参数  
     */
    static isNotEmpty(param) {
        return !Common.isEmpty(param);
    }

    /**
     * 获取当前相对路径
     */
    static getUrlRelativePath() {
        var url = document.location.toString();
        var arrUrl = url.split("//");

        var start = arrUrl[1].indexOf("/");
        var relUrl = arrUrl[1].substring(start);

        if (relUrl.indexOf("?") !== -1) {
            relUrl = relUrl.split("?")[0];
        }
        return relUrl;
    }

    static stopClickFirstTime = 0;//记录用户上一次点击的时间
    /**防止重复点击 */
    static stopClick(time = 3000) {
        let secondTime = new Date().getTime();
        if (secondTime - Common.stopClickFirstTime > time) {
            Common.stopClickFirstTime = secondTime;
            return true;
        }
        return false;
    };

    /**
     * 选中或取消选中当前行
     * @param {*} record 当前点击行记录
     * @param {string} rowKey 当前table的主键 
     * @param {string} rows 选中记录集合名
     * @param {string} rowKeys 选中记录key集合名
     */
    static selectRow = function (record, rowKey, rows, rowKeys) {
        let selectedRowKeys = this.state[rowKeys];
        let checkboxState = this.state[rows];
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys.splice(i, 1);
            checkboxState.splice(i, 1);
        } else {
            selectedRowKeys.push(record[rowKey]);
            checkboxState.push(record);
        }
        this.setState({ [rowKeys]: selectedRowKeys, [rows]: checkboxState });
    }

    /**
     * 选中或取消选中当前行，处理radio选中
     * @param {*} record 当前点击行记录
     * @param {String} rowKey 当前table的主键 
     * @param {String} rows 选中记录集合名
     * @param {String} rowKeys 选中记录key集合名
     */
    static selectRadioRow = function (record, rowKey, rows, rowKeys) {
        let selectedRowKeys = this.state[rowKeys];
        let checkboxState = this.state[rows];
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record[rowKey]];
            checkboxState = [record];
        }
        this.setState({ [rowKeys]: selectedRowKeys, [rows]: checkboxState });
    }

    /**
     * 数字千分位格式化
     * @param {number} number 
     */
    static numberFormat(number, len = 0) {
        if (Common.isEmpty(number)) {
            return "";
        }
        if (Object.is(parseFloat(number), NaN)) {//如果不是数字
            return number;
        }
        if (Object.is(parseFloat(len), NaN)) {//如果不是数字
            return number;
        }
        if (Common.isNotEmpty(len)) {
            return `${parseFloat(number).toFixed(len)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return `${parseFloat(number)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    /**
    * 时间格式化处理
    * @param date 要處理的時間
    * @param fmt 要轉換的格式
    */
    static dateFormat(date, fmt = "dd/MM/yyyy") { //author: meizz
        if (date == null) {
            return;
        }
        date = new Date(date);
        var o = {
            "M+": date.getMonth() + 1,                 //月份   
            "d+": date.getDate(),                    //日   
            "h+": date.getHours(),                   //小时   
            "m+": date.getMinutes(),                 //分   
            "s+": date.getSeconds(),                 //秒   
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度   
            "S": date.getMilliseconds()             //毫秒   
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }

    /**
 * 排序方法
 * @param {*} a 
 * @param {*} b 
 * @param {*} name 栏位名
 */
    static sortMethod(a, b, name) {
        if (a[name] == null && b[name] != null) {
            return -1;
        } else if (a[name] != null && b[name] == null) {
            return 1;
        } else if (a[name] != null && b[name] != null) {
            var stringA = a[name];
            var stringB = b[name];
            if (a[name].constructor === String || b[name].constructor === String) {
                stringA = stringA.toUpperCase(); // ignore upper and lowercase
                stringB = stringB.toUpperCase();
            }
            if (stringA < stringB) {
                return -1;
            }
            if (stringA > stringB) {
                return 1;
            }
        }
        return 0;
    }

    static timeDifference(startTime, endTime) { //可以传日期时间或时间戳
        let start = typeof (startTime) == "number" ? startTime : new Date(startTime).getTime(),
            end = typeof (endTime) == "number" ? endTime : new Date(endTime).getTime(),
            difference = end - start, //时间差的毫秒数
            days = Math.floor(difference / (24 * 3600 * 1000)), //计算出相差天数
            leave1 = difference % (24 * 3600 * 1000), //计算天数后剩余的毫秒数
            hours = Math.floor(leave1 / (3600 * 1000)), //计算相差分钟数
            leave2 = leave1 % (3600 * 1000), //计算小时数后剩余的毫秒数
            minutes = Math.floor(leave2 / (60 * 1000)), //计算相差秒数
            leave3 = leave2 % (60 * 1000), //计算分钟数后剩余的毫秒数
            seconds = Math.round(leave3 / 1000);
        // return `相差${days}天${hours}小时${minutes}分钟${seconds}秒`
        return days
    }

    static loginOvertime(){
        window.location.href=window.location.href.substr(0,window.location.href.lastIndexOf("#/"))+"#/login";
    }
}

