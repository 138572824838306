import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, Switch } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './sysRole.css';

const confirm = Modal.confirm;
export default class SysRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            funcList: [],
            searchModel: { pageSize: 10, page: 1, },
        }
        this.init()
    }

    init() {
        this.search();
        this.searchFunc();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "sysRole/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchFunc() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "sysFunc/searchFunc", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object
                let newList = [];
                for (let item of list) {
                    if (item.funcType === 'FUNC') {
                        item.children = [];
                        item.key = item.sysFuncId
                        item.activeInd = "Y"
                        for (let iterator of list) {
                            if (iterator.level1Id === item.sysFuncId) {
                                iterator.key = iterator.sysFuncId
                                iterator.activeInd = "Y"
                                item.children.push(iterator)
                            }
                        }
                        newList.push(item)
                    }
                }
                this.setState({
                    funcList: newList
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, sysRoleName: "", remark: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        this.searchFunc()
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { activeInd: "Y" },
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        this.setState({ isLoading: true })
        let model = this.state.selectedRows[0]
        fetch(Config.apiBackServer + "sysRole/searchRoleFunc", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list = data.object
                let newList = [];
                for (let item of list) {
                    if (item.funcType === 'FUNC') {
                        item.children = [];
                        item.key = item.sysFuncId
                        for (let iterator of list) {
                            if (iterator.level1Id === item.sysFuncId) {
                                iterator.key = iterator.sysFuncId
                                item.children.push(iterator)
                            }
                        }
                        newList.push(item)
                    }
                }
                this.setState({
                    tabIndex: "2",
                    flag: "edit",
                    model: this.state.selectedRows[0],
                    funcList: newList
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model=this.state.selectedRows[0]
        if(model.sysRoleCode==="CASHIER"||model.sysRoleCode==="HEAD_STAFF"||model.sysRoleCode==="HEAD_RESPONSIBLE"||model.sysRoleCode==="MEMBER"||model.sysRoleCode==="STORE_RESPONSIBLE"){
            Message.openMsgError("错误", "该数据无法删除");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "sysRole/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    see() {

    }

    save() {
        let url = "";
        let msg = this.check()
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "sysRole/insert";
        } else {
            url = "sysRole/update"
        }
        let model = this.state.model
        let list = []
        for (let item of this.state.funcList) {
            if (item.children != null) {
                for (let iterator of item.children) {
                    list.push(iterator)
                }
            }
            list.push(item)
        }
        model.funcList = list
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.sysRoleCode == null || model.sysRoleCode === '') {
            msg += "角色编号不能为空;"
        }
        if (model.sysRoleName == null || model.sysRoleName === '') {
            msg += "角色名称不能为空;"
        }
        if (model.activeInd == null || model.activeInd === '') {
            msg += "激活状态不能为空;"
        }
        if (model.dispSeq == null) {
            msg += "序号不能为空;"
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        if (k === "sysRoleCode" && e.length < 21) {
            newModel[k] = e.replace(/^ +| +$/g, '').replace(/[^\w\\_\\-]/ig, '').toLocaleUpperCase();
        } else {
            newModel[k] = e;
        }
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    switchChange(item, index, e) {
        if (item.activeInd === "Y") {
            item.activeInd = "N"
        } else {
            item.activeInd = "Y"
        }
        let list = this.state.funcList
        if (item.funcType === "FUNC") {
            for (let iterator of item.children) {
                iterator.activeInd = item.activeInd
            }
            list[index] = item
        }
        this.setState({
            funcList: list
        })
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '角色编号',
                dataIndex: 'sysRoleCode',
                className: "tableWidth20",
            },
            {
                title: '角色名称',
                dataIndex: 'sysRoleName',
                className: "tableWidth20",
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth20",
            },
            {
                title: '状态',
                dataIndex: 'activeInd',
                className: "tableWidth20",
                render: (text) => ContextHandler.getActiveValue(text)
            },
            {
                title: '序号',
                dataIndex: 'dispSeq',
                className: "tableWidth20 tableRightAlignment",
            },
        ];

        const funcColumns = [
            {
                title: '',
                className: "tableWidth5",
                align: 'center',
            },
            {
                title: '模块名称',
                dataIndex: 'funcName',
                className: "tableWidth40",
            },
            {
                title: '模块类型',
                dataIndex: 'funcType',
                className: "tableWidth40",
                render: (text) => ContextHandler.getMapValue('funcTypeMap', text)
            },
            {
                title: '激活',
                dataIndex: 'activeInd',
                className: "tableWidth15",
                render: (text, record, index) => {
                    return (
                        <Switch checked={text === "Y"} onChange={(e) => this.switchChange(record, index, e)} />
                    );
                }
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysRole">
                <div className="zhRoot">
                    <div className="zhTitle">角色管理</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">角色编号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="角色编号"
                                            value={this.state.searchModel.sysRoleCode || ''}
                                            onChange={(e) => this.searchChange('sysRoleCode', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">角色名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="角色名称"
                                            value={this.state.searchModel.sysRoleName || ''}
                                            onChange={(e) => this.searchChange('sysRoleName', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="sysRoleId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'sysRoleId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>角色编号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={20}
                                            placeholder="角色编号"
                                            disabled={this.state.flag === "edit"}
                                            value={this.state.model.sysRoleCode || ''}
                                            onChange={(e) => this.modelChange('sysRoleCode', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>角色名称<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="角色名称"
                                            value={this.state.model.sysRoleName || ''}
                                            onChange={(e) => this.modelChange('sysRoleName', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>状态<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.activeInd || ''}
                                            onChange={(e) => this.modelChange('activeInd', e)} >
                                            {context.activeMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                    <Col span={4} className='zhTxt'>序号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <InputNumber className="zhInputNumber" maxLength={9}
                                            min={1}
                                            precision={0}
                                            placeholder="序号"
                                            value={this.state.model.dispSeq || ''}
                                            onChange={(e) => this.modelChange('dispSeq', e)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>备注：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="备注"
                                            value={this.state.model.remark || ''}
                                            onChange={(e) => this.modelChange('remark', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="sysFuncId"
                                        // bordered={true} //边线
                                        style={{ width: "90%" }}
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        columns={funcColumns} //列名
                                        dataSource={this.state.funcList} //数据
                                        pagination={false}
                                    />
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}