import React, { Component } from 'react';
import { InputNumber, Upload, Icon, Button, Row, Col, Tabs, Table, Input, Select, Modal } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

import ImgUploadCut from '../../module/imgUploadCut/imgUploadCut';
import './homepageScrollConfigUrl.css';



const confirm = Modal.confirm;
export default class HomepageScrollConfigUrl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            sysRoleTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            sysRoleSelectedRowKeys: [],
            sysRoleSelectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            sysRoleModel: {},
            sysRoleTables: [],
            searchModel: { pageSize: 10, page: 1, },
            imageFile: [],
            ImageNum: 1,
            previewVisible: false,
            sysRoleList: [],
            oldorderNumber: "",

            /**上传图裁剪 start*/
            srcCropper: [],// cropper的图片路径
            visible: false,//裁剪弹框显示
            /**上传图裁剪 end*/
        }
        this.init()
    }

    init() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "homepageScrollConfig/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, phone: "", nickName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    onSysRoleSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            sysRoleSelectedRowKeys: selectedRowKeys,
            sysRoleSelectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { status: "Y" },
            oldorderNumber: "",
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0]
        let img = model.headPortrait;
        let file = [{ uid: '-1', }]
        if (img !== null && img !== "") {
            file[0].url = Config.homePageScroll + img;
            file[0].name = img;
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: model,
            imageFile: file,
            oldorderNumber: model.orderNumber,

        })

    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "homepageScrollConfig/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }


    save() {
        let url = "";
        let msg = this.check()
        this.obtainImgData();
        let model = this.state.model
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "homepageScrollConfig/insert";
        } else {
            url = "homepageScrollConfig/update"
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.pictureName == null || model.pictureName === '') {
            msg += "图片名称不能为空;"
        }

        if (model.orderNumber == null || model.orderNumber === '') {
            msg += "序号不能为空;"
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        if ((k === "phone") && e.length < 12) {
            newModel[k] = e.replace(/[^\d.]/g, "");
        } else if (k !== "phone") {
            newModel[k] = e;
        }
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            sysRoleTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            sysRoleSelectedRowKeys: [],
            sysRoleSelectedRows: [],
            imageFile: [],
            sysRoleTables: []
        })
        this.search()
    }

    tableChange(value, k) {
        if (k === "sysUser") {
            if (value === "2") {
                if (this.state.selectedRows.length === 1) {
                    this.edit();
                } else {
                    this.add();
                }

            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        } else {
            if (value === "2") {
                if (this.state.sysRoleSelectedRows.length === 1) {
                    this.sysRoleEdit();
                } else {
                    this.sysRoleAdd();
                }
            } else {
                this.sysRoleCancel();

            }
        }
    }

    obtainImgData() {
        let model = this.state.model;
        let imageFile = this.state.imageFile;
        if (imageFile.length !== 0) {
            for (let i in imageFile) {
                if (Common.isEmpty(imageFile[i].url)) {
                    model.headPortrait = imageFile[i].thumbUrl;
                } else {
                    model.headPortrait = imageFile[i].name;
                }
            }
        } else {
            model.headPortrait = null;
        }
        this.setState({
            model: model
        })
    }

    /**关闭图片弹窗 */
    handleCancel = () => this.setState({ previewVisible: false })

    /**查看图片 */
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    /**图片改变事件 */
    handleChange(file, flag, name) {
        // this.setState({
        //     imageFile: file.fileList
        // })
        let state = this.state;
        let model = state.model
        if (file.fileList != null && file.fileList.length == 1) {
            if (!file.file.url && !file.file.preview) {
                const reader = new FileReader();
                reader.readAsDataURL(file.file.originFileObj);
                reader.onload = (e) => {
                    let fileList = file.fileList
                    fileList[0].thumbUrl = e.target.result
                    state[flag] = fileList;
                    model[name] = e.target.result;

                    /**上传图裁剪 start */
                    state.visible = true; // cropper的弹框
                    state.srcCropper = e.target.result; // cropper的图片路径
                    /**上传图裁剪 end */

                    this.setState(state);
                }
            }
        } else {
            model[name] = "";
            state[flag] = file.fileList;
            this.setState(state);
            this.forceUpdate();
        }


    }

    /**上传图裁剪回调 start */
    closeCutModal(flag, imageFile) {
        let model = this.state.model
        if (!flag) {
            model['headPortrait'] = null;//这个要自己model里的属性（没有就不写model）
        }
        this.setState({
            model,
            imageFile: imageFile,
            srcCropper: null,
            visible: false,
        });
    }
    /**上传图裁剪回调 end */

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>轮播图像</div>
            </div>
        );
        const columns = [
            {
                title: '序号',
                dataIndex: 'orderNumber',
                className: "tableWidth15",
            },
            {
                title: '图片名称',
                dataIndex: 'pictureName',
                className: "tableWidth15",
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                className: "tableWidth15",
            },
            {
                title: '链接地址',
                dataIndex: 'linkAddress',
                className: "tableWidth30",
            },
            {
                title: '首页轮播图',
                dataIndex: 'headPortrait',
                className: "tableWidth15",
                render: (text) => {
                    return (
                        <img src={Config.homePageScroll + text} className="pictureColumns"></img>
                    );
                }
            },
            {
                title: '激活',
                dataIndex: 'status',
                className: "tableWidth10",
                render: (text) => ContextHandler.getIndValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const sysRoleRowSelection = {
            selectedRowKeys: this.state.sysRoleSelectedRowKeys,
            onChange: this.onSysRoleSelectChange,
            type: 'radio',
        };
        return (
            <div className="homepageScrollConfigUrl">
                <div className="zhRoot">
                    <div className="zhTitle">首页轮播图设置</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value, "sysUser")}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">图片名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="图片名称"
                                            value={this.state.searchModel.pictureName || ''}
                                            onChange={(e) => this.searchChange('pictureName', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        dataSource={this.state.tables} //数据
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                <Row className='row'>

                                    <Col span={4} className='zhTxt'>图片名称<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="图片名称"
                                            value={this.state.model.pictureName || ''}
                                            onChange={(e) => this.modelChange('pictureName', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>备注：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="备注"
                                            value={this.state.model.remarks || ''}
                                            onChange={(e) => this.modelChange('remarks', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.status || ''}
                                            onChange={(e) => this.modelChange('status', e)} >
                                            {context.inds.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                    <Col span={4} className='zhTxt'>序号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <InputNumber className="zhInputNumber"
                                            maxLength={9}
                                            min={1}
                                            placeholder="序号"
                                            value={this.state.model.orderNumber || ''}
                                            onChange={(e) => this.modelChange('orderNumber', e)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                <Col span={4} className='zhTxt'>链接地址：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="链接地址"
                                            value={this.state.model.linkAddress || ''}
                                            onChange={(e) => this.modelChange('linkAddress', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>首页轮播图<span className='redStar'>*</span>：(图片尺寸：355 × 150)</Col>
                                    <Col span={6} className='imgUpload zhInput'>
                                        <Upload
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            action={Config.apiBackServer + "test/test"}
                                            listType="picture-card"
                                            fileList={this.state.imageFile}
                                            onPreview={this.handlePreview}
                                            onChange={(file) => this.handleChange(file, "imageFile", "headPortrait")}>
                                            {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                        </Upload>
                                        {/* 上传图裁剪 start */}
                                        <ImgUploadCut
                                            closeCutModal={this.closeCutModal.bind(this)}
                                            width={355}
                                            height={150}
                                            model={this.state.model}
                                            files={this.state.imageFile}
                                            srcCropper={this.state.srcCropper}
                                            visible={this.state.visible}
                                        />
                                        {/* 上传图裁剪 end */}
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>

                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    sysRoleModelChange(k, e) {
        let newModel = this.state.sysRoleModel;
        if (k === "sysRoleId") {
            this.state.sysRoleList.map((item) => {
                if (e === item.sysRoleId) {
                    newModel.sysRoleId = item.sysRoleId
                    newModel.sysRoleName = item.sysRoleName
                    if (this.state.sysRoleFlag === "edit") {
                        newModel.isChange = true
                    }
                }
                return 1;
            })
        } else {
            newModel[k] = e;
        }
        this.setState({
            sysRoleModel: newModel,

        });
    }

    sysroleSearch() {
        fetch(Config.apiBackServer + "sysRole/searchAll", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    sysRoleList: data.object
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    sysRoleAdd() {
        this.sysroleSearch()
        this.setState({
            sysRoleFlag: "add",
            sysRoleTabIndex: "2",
            sysRoleModel: { activeInd: "Y" }
        })
    }

    sysRoleEdit() {
        this.sysroleSearch()
        if (this.state.sysRoleSelectedRows == null || this.state.sysRoleSelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let list = this.state.sysRoleTables
        let selecteIndex = 0;
        for (let index = 0; index < list.length; index++) {
            if (list[index].sysRoleId === this.state.sysRoleSelectedRows[0].sysRoleId) {
                selecteIndex = index
            }
        }
        this.setState({
            sysRoleTabIndex: "2",
            sysRoleFlag: "edit",
            selecteIndex: selecteIndex,
            sysRoleModel: JSON.parse(JSON.stringify(this.state.sysRoleSelectedRows[0])),
            recordModel: JSON.parse(JSON.stringify(this.state.sysRoleSelectedRows[0]))
        })
    }

    sysRoleDelete() {
        if (this.state.sysRoleSelectedRows == null || this.state.sysRoleSelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let list = this.state.sysRoleTables
        for (let index = list.length - 1; index >= 0; index--) {
            if (list[index].sysRoleId === this.state.sysRoleSelectedRows[0].sysRoleId) {
                list.splice(index, 1)
                break
            }
        }
        this.setState({
            sysRoleTables: list
        })
        this.sysRoleCancel()
    }

    sysRoleSave() {
        if (this.state.sysRoleModel.sysRoleId == null) {
            Message.openMsgError("错误", "角色不能为空")
            return
        }
        let list = this.state.sysRoleTables
        let coun = 0;
        for (let item of list) {
            if (item.sysRoleId === this.state.sysRoleModel.sysRoleId) {
                coun++
            }
        }
        if (this.state.sysRoleFlag === "add") {
            if (coun !== 0) {
                Message.openMsgError("错误", "角色重复")
                return
            }
            list.push(this.state.sysRoleModel)
        } else {
            if (this.state.sysRoleModel.isChange !== null && this.state.sysRoleModel.isChange) {
                if (this.state.sysRoleModel.sysRoleId !== this.state.recordModel.sysRoleId) {
                    if (coun !== 0) {
                        Message.openMsgError("错误", "角色重复")
                        return
                    }
                }
            }
            list[this.state.selecteIndex] = this.state.sysRoleModel
        }
        this.setState({
            sysRoleTables: list,
        })
        this.sysRoleCancel()
    }

    sysRoleCancel() {
        this.setState({
            sysRoleTabIndex: "1",
            sysRoleModel: {},
            sysRoleSelectedRowKeys: [],
            sysRoleSelectedRows: [],
            recordModel: [],
        })
    }
}