import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, DatePicker, Spin, } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './withdrawalAudit.css';
const { TextArea } = Input;

// 提现审核
export default class WithdrawalAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tables:[],
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: { pageSize: 10, page: 1, withdrawalTimeAction: new Date().setDate(new Date().getDate() - 7), withdrawalTimeEnd: new Date(),},
            total: 0,
            searchModel: {},
            notAllowEditing: false,
            saveModel:{},
            loading: false,
            isExport: false,
        }
    }

  //组件渲染之后调用
    componentDidMount() {
        this.search();
    }

    search(){
        let newmodel = this.state.model;
        fetch(Config.apiBackServer + "withdrawalAudit/search", {
            method: 'POST',
            body: JSON.stringify(newmodel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("data",data);
            if("SUCCESS" === data.msg){
                this.setState({
                    tables: data.object.list,
                    total: data.object.total,
                    isLoading: false,
                    isAuditflag: false,
                })
            }
        });
    }

    pageChange = (current, pageSize) => {
        let newModel = this.state.model;
        newModel.pageSize = pageSize;
        newModel.page = current;
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            model: newModel,
        },() => {
            this.search();
        });
        
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows,
        })
    }

    onChange(k ,e){
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({ model: newModel,})
    }

    reset(){
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            model: {
                pageSize: 10, 
                page: 1, 
                auditTimeAction:null, 
                auditTimeEnd: null,
                phone: null,
                auditStatus: null,
                cardNumber: null,
                withdrawalTimeAction: new Date().setDate(new Date().getDate() - 7),
                withdrawalTimeEnd: new Date(),
            }
        })
    }

    render(){
        const { TabPane } = Tabs;
        const { Option } = Select;

        const columns = [
            {
                title: '提现人卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth11",
            },
            {
                title: '提现人手机号',
                dataIndex: 'phone',
                className: "tableWidth11",
            },
            {
                title: '提现金额',
                dataIndex: 'withdrawalAmount',
                className: 'tableWidth11',
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '提现时间',
                dataIndex: 'withdrawalTime',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
                // sorter: (a, b) => { return Common.sortMethod(a, b, 'withdrawalTime') },
            },
            {
                title: '审核人手机号',
                dataIndex: 'auditPhone',
                className: "tableWidth11",
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth11",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: 'tableWidth11',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '审核备注',
                dataIndex: 'auditRemark',
                className: "tableWidth11",
            },
            {
                title: '提现人状态',
                dataIndex: 'status',
                className: "tableWidth11",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ] 

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            // type: 'radio',
        };

        return(
            <div className="withdrawalAudit">
                <div className="zhRoot">
                    <div className="zhTitle">提现审核</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">提现日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.withdrawalTimeAction == null? null : moment(new Date(this.state.model.withdrawalTimeAction), Config.dateFormat)}
                                                onChange={(value) => this.onChange("withdrawalTimeAction",value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.withdrawalTimeEnd == null?null : moment (new Date(this.state.model.withdrawalTimeEnd), Config.dateFormat)}
                                                onChange={(value) => this.onChange("withdrawalTimeEnd",value)}
                                                format={Config.dateFormat}  />
                                        </Col>
                                        <Col span={2} className="zhTxt">提现人卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="卡号"
                                                value={this.state.model.cardNumber}
                                                onChange={(e) => this.onChange("cardNumber",e.target.value)}
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">提现人手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="提现人手机号"
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.onChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.auditTimeAction == null? null : moment(new Date(this.state.model.auditTimeAction), Config.dateFormat)}
                                                onChange={(value) => this.onChange("auditTimeAction",value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.model.auditTimeEnd == null? null : moment(new Date(this.state.model.auditTimeEnd), Config.dateFormat)}
                                                onChange={(value) => this.onChange("auditTimeEnd",value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.model.auditStatus|| ''}
                                                onChange={(value) => this.onChange('auditStatus', value)}>
                                                <Option key="" value={""}>全部</Option>
                                                <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                                <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary"  icon="search" onClick={() => this.search()}>搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toRebateAudit()} icon="export">审核</Button>
                                        <Button disabled={this.state.loading} className="zhButtonLeft" type="primary" onClick={() => this.saveRebateAll()} icon="export">批量审核</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toRebate()} icon="eye">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft"  type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="memberWithdrawalId"
                                            scroll={{ x: 1000 }}
                                            bordered={true}                                     //边线
                                            columns={columns}                                   //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables}                      //数据
                                            pagination={{
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.total,                        //总共多少条数据
                                                current: this.state.model.page,                 //当前页
                                                pageSize: this.state.model.pageSize,            //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRow.call(this, record, 'memberWithdrawalId', 'selectedRows', 'selectedRowKeys'),// 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.cardNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.phone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>提现金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={Common.numberFormat(this.state.saveModel.withdrawalAmount|| 0, 2)}/>
                                        </Col>
                                        <Col span={4} className='zhTxt'>提现时间:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.withdrawalTime == null ? null : moment(this.state.saveModel.withdrawalTime).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核状态:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled
                                            value={ContextHandler.getMapValue('auditStatusMap', this.state.saveModel.auditStatus)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>提现人状态：</Col>
                                        <Col span={6} className='zhInput'>
                                        <Input className='disableFontColor' maxLength={30} disabled value={ContextHandler.getMapValue('statusMap', this.state.saveModel.status)} />
                                        </Col>
                                    </Row>
                                    {this.state.notAllowEditing ? 
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核人手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.auditPhone} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核时间:</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={18} disabled value={this.state.saveModel.auditDate == null ? null : moment(this.state.saveModel.auditDate).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    : null}
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核备注：</Col>
                                        <Col span={16} className='RemarkInput'>
                                            <TextArea
                                                value={this.state.saveModel.auditRemark || ""}
                                                className='disableFontColor'
                                                onChange={(e) => this.modelChange('auditRemark', e.target.value)}
                                                placeholder="备注"
                                                disabled={this.state.notAllowEditing}
                                                maxLength={500}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonLeft'>
                                        {!this.state.notAllowEditing ? <Button disabled={this.state.loading} type="primary" icon="check-circle" onClick={() => { this.saveRebate() }}>审核</Button> : null}
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
    /**确认批量审核 */
    saveRebateAll() {
        // console.log("this.state.selectedRows",this.state.selectedRows)
        let saveModel = this.state.selectedRows;
        if ( Common.isEmpty(saveModel)) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let parm=false;
        for (let index = 0; index < saveModel.length; index++) {
            const element = saveModel[index];
            if (element.auditStatus ==="CHECKED") {
                parm=true
            }
        }
        console.log("saveModel",saveModel)
        if (!parm) {
            this.setState({ loading: true });
            fetch(Config.apiBackServer + "withdrawalAudit/confirmAuditAll", {
                method: 'POST',
                body: JSON.stringify(saveModel),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                console.log("data.errorCode",data)
                if ("SUCCESS" === data.msg) {
                    this.cancel()
                    Message.openMsgSuccess("提示", data.object)
                } else {
                    Message.openMsgError("错误", data.msg, data.errorCode)
                }
            }).catch(err => { });
        } else {
            Message.openMsgError("错误", "选中数据有已审核的记录")
        }
        
    }

    tableChange(value) {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (value === "2") {
            let model = this.state.selectedRows[0];
            if(model.auditStatus === "UNCHECKED"){
                this.toRebateAudit();
            }
            if(model.auditStatus === "CHECKED"){
                this.toRebate();
            }
        } else {
            this.setState({ tab: value, })
            this.cancel();
        }
    }

    /**审核进编辑页（审核页） */
    toRebateAudit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let notAllowEditing = this.state.notAllowEditing;
        let model = this.state.selectedRows[0];
        if (model.auditStatus === "UNCHECKED") {
            notAllowEditing = false;
            this.setState({
                tabIndex: "2",
                flag: "edit",
                saveModel: model,
                notAllowEditing
            })
        }else{
            Message.openMsgError("错误", "已审核数据无法再审核")
        }
    }

    /**查看进编辑页（审核页） */
    toRebate() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let notAllowEditing = this.state.notAllowEditing;
        let model = this.state.selectedRows[0];
        if (model.auditStatus === "CHECKED") {
            notAllowEditing = true;
            this.setState({
                tabIndex: "2",
                flag: "edit",
                saveModel: model,
                notAllowEditing
            })
        }else{
            Message.openMsgError("错误", "未审核数据无法查看")
        }
    }
        
    cancel() {
        this.setState({
            loading: false,
            tabIndex: "1",
            flag: "",
            saveModel: {},
            selectedRowKeys: [],
            selectedRows: [],
            notAllowEditing: false,
        })
        this.search();
    }

    modelChange(k, e) {
        let saveModel = this.state.saveModel;
        saveModel[k] = e;
        this.setState({ saveModel });
    }

    /**确认审核 */
    saveRebate() {
        let saveModel = this.state.saveModel;
        this.setState({ loading: true });
        if(saveModel.status === "FROZEN"){
            Message.openMsgError("错误", "处于冻结状态无法审核");
            return;
        }
        saveModel.userId = Config.userInfo.userId
        fetch(Config.apiBackServer + "withdrawalAudit/confirmAudit", {
            method: 'POST',
            body: JSON.stringify(saveModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("提示", data.object)
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    // 导出报表
    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "withdrawalAudit/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        //this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "提现审核" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false })});
    }
}