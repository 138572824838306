import 'core-js/es'  
import 'react-app-polyfill/ie9'  
import 'react-app-polyfill/stable'
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {
  HashRouter as Router,
  Route, Switch
} from 'react-router-dom'//路由导包
import { ConfigProvider } from 'antd';//国际化导包
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import 'antd/dist/antd.css';//antd样式导入

import Login from './pages/login/login';
import ForgetPwd from './pages/forgetPwd/forgetPwd';
import Register from './pages/register/register';
import Main from './pages/main/main';
import Page404 from './pages/404/404';

import './index.css';
import { Common } from './public/js/common';
import { Config } from './config';
import 'core-js/es';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

// 解决低版本浏览器axios不支持finally
import 'promise.prototype.finally';
require('promise.prototype.finally').shim();

export default class Root extends Component {
  
  constructor(){
      super();
      let token = localStorage.getItem("token");
      if(Common.isNotEmpty(token)){
        Config.header.authorization = token;
      }else{
        Config.header = {
          'authorization': "",
          'Content-Type': 'application/json;charset=UTF-8'
        };
      }
      if(Common.isNotEmpty(localStorage.getItem("userInfo"))){
        Config.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }else{
        Config.userInfo = {};
      }
      if(Common.isNotEmpty(localStorage.getItem("openKeys"))){
        Config.openKeys = JSON.parse(localStorage.getItem("openKeys"));
      }else{
        Config.openKeys = [];
      }
      // 判断是移动端还是pc端
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        Config.equipmentType = 'mobile';
      } else {
        Config.equipmentType = 'pc';
      }
      console.log(Config.userInfo);
    }

    render() {
        return (
          <ConfigProvider>
            <ConfigProvider  locale={zh_CN}>
              <Router>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path="/login" component={Login} />
                  <Route path="/forgetPwd" component={ForgetPwd}/>
                  <Route path="/register" component={Register}/>
                  <Route path="/main" component={Main} />
                  <Route component={Page404} />
                </Switch>
              </Router>
            </ConfigProvider >
          </ConfigProvider>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
