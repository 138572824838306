import React, { Component } from 'react';

import { Modal } from 'antd';
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import '../../public/font/iconfont.css'; 

import './imgUploadCut.css'; 

/**
 * 上传图片裁剪功能
 */
export default class ImgUploadCut extends Component {

  constructor(props){
    super(props);
    
    this.state={
      width: props.width,
      height: props.height,
      model: props.model,
      files: props.files,
      srcCropper: props.srcCropper,// cropper的图片路径
      visible: props.visible,//裁剪弹框显示
      confirmLoading: false,//确认上传按钮加载状态
    }
    this.init();
  }

  init(){
  }

  /**
   * props或state更新之后会触发的事件
   * @param {新的props数据} nextProps 
   * @param {新的state数据} nextState 
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.visible !== nextProps.visible) {
        nextState.width = nextProps.width;
        nextState.height = nextProps.height;
        nextState.model = nextProps.model;
        nextState.files = nextProps.files;
        nextState.srcCropper = nextProps.srcCropper;
        nextState.visible = nextProps.visible;
        nextState.confirmLoading =  false;//确认上传按钮加载状态
    }
    return true;
  }

  /**
   * 更新state的状态，使页面重新渲染
   * @param {*} nextProps 
   * @param {*} nextState 
   */
  componentWillUpdate(nextProps, nextState) {
      this.state = nextState;
      this.props = nextProps;
  }

  render() {
    
    /**
     * srcCropper：cropper组件内部图片的url
     * visible：裁剪Modal的显示属性
     * confirmLoading：图片上传过程中Modal的Loading效果
     * */
    const { srcCropper, visible, confirmLoading, width, height } = this.state;
    
    return (
      <div>
        {/* 裁剪弹框 */}
        <Modal
          title="上传首页轮播图"
          width={width * 4 + 50}
          visible={visible}
          onOk={this.saveImg}
          onCancel={this.onCloseModal}
          okText="确认上传"
          confirmLoading={confirmLoading}
          maskClosable={false}//点击蒙层是否允许关闭
        >
          {/* 预览图的容器 */}
          {/* <div>
            {srcCropper ? (
              <div>
                <div className='previewContainer'/>
              </div>
            ) : (
              ''
            )}
          </div> */}
          {/* 预览图的容器 END */}

          {srcCropper ? (
            <div style={{ width: width * 4, height: height * 4 }}>
              <div>
                <Cropper
                  ref="cropper"
                  style={{ width: width * 4, height: height * 4 }}
                  // preview=".previewContainer"// 预览图的容器
                  guides={false}
                  aspectRatio={ width / height}// 固定图片裁剪比例
                  autoCropArea={1}
                  src={srcCropper}// 要裁剪的图片的路径
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </Modal>
        {/* 裁剪弹框 END */}
      </div>
    );
  }

  saveImg = () => {
    this.setState({
      confirmLoading: true,
    });
    // 通过refs读取到Cropper实例，并读取到裁剪之后的图片（base64）
    const url = this.refs.cropper.getCroppedCanvas().toDataURL();
    this.state.files[0].thumbUrl = url;
    this.props.closeCutModal(true, this.state.files);
  };

  onCloseModal = () => {
    this.setState({
      confirmLoading: false,
    });
    this.props.closeCutModal(false, []);
  }

}