import React, { Component } from 'react';
import { Upload, Icon, Button, Row, Col, Tabs, Table, Input, Select, Modal, Spin } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import SelectShop from './../selectShop/selectShop';
import { Common } from '../../public/js/common';
import './sysMemberManagement.css';
import SysRole from '../sysRole/sysRole';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';

const confirm = Modal.confirm;
const { TextArea } = Input;
export default class SysMemberManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            memberSelectedRows: [],
            memberSelectedRowKeys: [],
            model: {},
            memberModel: { pageSize: 10, page: 1, },
            tables: [],
            searchModel: { pageSize: 10, page: 1, showShop: "Y" },
            imageFile: [],
            ImageNum: 1,
            previewVisible: false,
            previewVisibleZFB: false,
            imageFileZFB: [],
            ImageNumZFB: 1,
            previewVisibleWX: false,
            imageFileWX: [],
            ImageNumWX: 1,
            searchShopList: [],
            shopFlag: false,
            memberList: [],
            isLoading: true,
            isExport: false
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list
            }, () => {
                let model = { shopId: this.state.searchShopList[0].shopId }
                this.setState({
                    model: model
                })
            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "user/searchMemberManagement", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            console.log(data.object.list)
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, phone: "", nickName: "", identity: '' },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    memberOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            memberSelectedRowKeys: selectedRowKeys,
            memberSelectedRows: selectedRows
        })
    }

    addChildren(flag) {
        var searchModel = this.state.searchModel;
        if ("shop" === flag) {
            searchModel.segment = "OPEN_STORE_CARD_NUMBER";
            searchModel.code = "CARD_NUM";

        } else if ("headquarters" === flag) {
            if (Config.userInfo.sysRoleCode.indexOf("HEAD_RESPONSIBLE")<0) {
                Message.openMsgError("错误", "只有总部管理员可以开通总部卡号")
                return
            }
            searchModel.segment = "OPENING_HEADQUARTERS_CARD_NUM";
            searchModel.code = "CARD_NUM";
        }
        this.setState({
            searchModel: searchModel,

        })
        var carnum = '';
        // if ("shop" === flag || "headquarters" === flag) {
        fetch(Config.apiBackServer + "openMembership/searchCarNum", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data.object.parmValue2);
            if ("SUCCESS" === data.msg) {
                if (data.object != null && data.object.parmValue2 != null) {
                    if (data.object.parmValue2.length === 1) {
                        carnum = data.object.parmValue1 + "00000" + data.object.parmValue2;
                    } else if (data.object.parmValue2.length === 2) {
                        carnum = data.object.parmValue1 + "0000" + data.object.parmValue2;
                    } else if (data.object.parmValue2.length === 3) {
                        carnum = data.object.parmValue1 + "000" + data.object.parmValue2;
                    } else if (data.object.parmValue2.length === 4) {
                        carnum = data.object.parmValue1 + "00" + data.object.parmValue2;
                    } else if (data.object.parmValue2.length === 5) {
                        carnum = data.object.parmValue1 + "0" + data.object.parmValue2;
                    } else {
                        carnum = data.object.parmValue1 + data.object.parmValue2;
                    }
                }

                let model = {
                    openDate: new Date(),
                    endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                    active: 'Y',
                    transactionType: 'CASH',
                    cardNumber: carnum,
                    sex: "HIDE",
                    status: "NORMAL"
                }
                let shopId = "";
                let shopFlag = true
                if ("shop" === flag) {
                    model.shopId = ""
                    shopId = ""
                    model.identity = "STORE_CHARGE"
                    shopFlag = false
                } else {
                    model.identity = "HEAD_USER"
                    model.shopId = 'S_1589272595001vCZ9'
                    shopId = 'S_1589272595001vCZ9'
                }
                this.setState({
                    tabIndex: "2",
                    flag: flag,
                    model: model,
                    code: searchModel.code,
                    segment: searchModel.segment,
                    parmValue2: parseInt(data.object.parmValue2) + 1,
                    shopId: shopId,
                    shopFlag: shopFlag,
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }

        }).catch(err => { });
        // } else {
        //     let model = {
        //         openDate: new Date(),
        //         endDate: new Date().setFullYear(new Date().getFullYear() + 1),
        //         active: 'Y',
        //         shopId: "S_1589272595001vCZ9",
        //         transactionType: 'CASH',
        //         cardNumber: carnum,
        //     }

        //     if (flag === "headquarters" || flag === "shop") {
        //         model.sex = "HIDE"
        //         model.status = "NORMAL"
        //     }
        //     this.setState({
        //         tabIndex: "2",
        //         flag: flag,
        //         model: model,
        //     })
        // }
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        // if (model.shopId !== "" && model.shopId != null) {
        //     model.shopId = this.state.selectedRows[0].shopId;
        // } else {
        //     model.shopId = this.state.searchShopList[0].shopId;
        // }

        var img = model.headPortrait;
        let file = [{ uid: '-1', }]
        if (Common.isNotEmpty(img) && img.indexOf('http') != -1) {
            file[0].url = img;
            file[0].name = img;
        } else if (Common.isNotEmpty(img) && img.indexOf('http') == -1) {
            file[0].url = Config.headPortraitImgs + img;
            file[0].name = img;
        } else {
            file = []
        }
        var weChatAddress = model.weChatAddress;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') != -1) {
            file1[0].url = weChatAddress;
            file1[0].name = weChatAddress;
        } else if (Common.isNotEmpty(weChatAddress) && weChatAddress.indexOf('http') == -1) {
            file1[0].url = Config.consumptionRebate + weChatAddress;
            file1[0].name = weChatAddress;
        } else {
            file1 = []
        }
        var alipayAddress = model.alipayAddress;
        let file2 = [{ uid: '-1', }]
        if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') != -1) {
            file2[0].url = alipayAddress;
            file2[0].name = alipayAddress;
        } else if (Common.isNotEmpty(alipayAddress) && alipayAddress.indexOf('http') == -1) {
            file2[0].url = Config.consumptionRebate + alipayAddress;
            file2[0].name = alipayAddress;
        } else {
            file2 = []
        }
        let shopFlag = true;
        if (model.identity === "CASHIER" || model.identity === "STORE_CHARGE") {
            shopFlag = false
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: model,
            imageFile: file,
            imageFileWX: file1,
            imageFileZFB: file2,
            shopFlag: shopFlag,
        })
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "user/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    save() {
        if (!Common.stopClick(1500)) {
            return
        }
        let msg = this.check()
        this.obtainImgData();
        let model = this.state.model
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        this.setState({ isLoading: true })
        let url = ""
        if (this.state.flag === "edit") {
            url = "openMembership/updateMemberManagement"
        } else {
            if (this.state.flag === "headquarters") {   //总部卡
                model.identity = "HEAD_USER"
                model.userType = "HEAD_USER"
                //总部负责人角色CODE
                model.sysRoleCode= "HEAD_RESPONSIBLE"
            } else if (this.state.flag === "shop") {    //店铺卡
                model.identity = "STORE_CHARGE"
                model.userType = "STORE_CHARGE"
                //店铺负责人角色CODE
                model.sysRoleCode= "STORE_RESPONSIBLE"
            }
            url = "openMembership/storeHeadquartersInsert"
        }
        model.userName = model.nickName;
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")
                if (this.state.flag !== "edit") {
                    this.updateparam();
                }
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));;
    }


    updateparam() {
        var model = { segment: this.state.segment, code: this.state.code, parmValue2: this.state.parmValue2 };
        fetch(Config.apiBackServer + "openMembership/updateparam", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.phone == null || model.phone === '') {
            msg += "手机号不能为空;"
        }
        if (this.state.flag != "edit") {
            if (model.password == null || model.password === '') {
                msg += "密码不能为空;"
            }
        }
        if (model.sex == null || model.sex === '') {
            msg += "性别不能为空;"
        }
        if (model.identity == null || model.identity === '') {
            msg += "身份不能为空;"
        }
        if (model.status == null || model.status === '') {
            msg += "状态不能为空;"
        }
        if (this.state.flag==="shop") {
            if (!model.shopId) {
                msg += "店铺不能为空;"
            }
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        let newshopFlag = this.state.shopFlag;
        if ((k === "phone") && e.length < 12) {
            newModel[k] = e.replace(/[^\d.]/g, "");
        } else if (k !== "phone") {
            newModel[k] = e;
        }
        if (k === "identity") {
            if ("CASHIER" === e || "STORE_CHARGE" === e) {
                newshopFlag = false;
            } else {
                newshopFlag = true;
                newModel.shopId = "";
            }
        }
        this.setState({
            model: newModel,
            shopFlag: newshopFlag,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            imageFile: [],
            imageFileWX: [],
            imageFileZFB: []
        })
        this.search()
    }

    tableChange(value, k) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                // this.add();
            }

        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    obtainImgData() {
        let model = this.state.model;
        let imageFile = this.state.imageFile;
        if (imageFile.length !== 0) {
            for (let i in imageFile) {
                if (Common.isEmpty(imageFile[i].url)) {
                    model.headPortrait = imageFile[i].thumbUrl;
                } else {
                    model.headPortrait = imageFile[i].name;
                }
            }
        } else {
            model.headPortrait = null;
        }
        let alipayAddress = this.state.imageFileZFB;
        if (alipayAddress.length !== 0) {
            for (let i in alipayAddress) {
                if (Common.isEmpty(alipayAddress[i].url)) {
                    model.alipayAddress = alipayAddress[i].thumbUrl;
                } else {
                    model.alipayAddress = alipayAddress[i].name;
                }
            }
        } else {
            model.alipayAddress = null;
        }
        let weChatAddress = this.state.imageFileWX;
        if (weChatAddress.length !== 0) {
            for (let i in weChatAddress) {
                if (Common.isEmpty(weChatAddress[i].url)) {
                    model.weChatAddress = weChatAddress[i].thumbUrl;
                } else {
                    model.weChatAddress = weChatAddress[i].name;
                }
            }
        } else {
            model.weChatAddress = null;
        }
        this.setState({
            model: model
        })
    }

    /**关闭图片弹窗 */
    handleCancel1() {
        this.setState({ previewVisible: false })
    }

    handleCancel2() {
        this.setState({ previewVisibleZFB: false })
    }
    handleCancel3() {
        this.setState({ previewVisibleWX: false })
    }


    /**查看图片 */
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handlePreviewZFB = (file) => {
        this.setState({
            previewImageZFB: file.url || file.thumbUrl,
            previewVisibleZFB: true,
        });
    }

    handlePreviewWX = (file) => {
        this.setState({
            previewImageWX: file.url || file.thumbUrl,
            previewVisibleWX: true,
        });
    }

    /**图片改变事件 */
    handleChange(file,name) {
        // this.setState({
        //     imageFile: file.fileList
        // })
        let state = this.state;
        if (file.fileList != null && file.fileList.length === 1) {
            if (!/\.(png|jpg|jpeg)$/.test(file.fileList[0].name)) {
                Message.openMsgError("错误", "请上传.jpg.png.jpeg图片文件")
                return
            }
            if (!file.file.url && !file.file.preview) {
                const reader = new FileReader();
                reader.readAsDataURL(file.file.originFileObj);
                reader.onload = (e) => {
                    let fileList = file.fileList
                    fileList[0].thumbUrl = e.target.result
                    state[name] = fileList;
                    this.setState(state);
                }
            }
        } else {
            state[name] = file.fileList;
            this.setState(state);
            this.forceUpdate();
        }
    }

    // handleChangeZFB(file,name) {
    //     // this.setState({
    //     //     imageFileZFB: file.fileList
    //     // })
    //     let state = this.state;
    //     if (file.fileList != null && file.fileList.length === 1) {
    //         if (!/\.(png|jpg|jpeg)$/.test(file.fileList[0].name)) {
    //             Message.openMsgError("错误", "请上传.jpg.png.jpeg图片文件")
    //             return
    //         }
    //         if (!file.file.url && !file.file.preview) {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file.file.originFileObj);
    //             reader.onload = (e) => {
    //                 let fileList = file.fileList
    //                 fileList[0].thumbUrl = e.target.result
    //                 state[name] = fileList;
    //                 this.setState(state);
    //             }
    //         }
    //     } else {
    //         state[name] = file.fileList;
    //         this.setState(state);
    //         this.forceUpdate();
    //     }
    // }

    // handleChangeWX(file,name) {
    //     // this.setState({
    //     //     imageFileWX: file.fileList
    //     // })
    //     let state = this.state;
    //     if (file.fileList != null && file.fileList.length === 1) {
    //         if (!/\.(png|jpg|jpeg)$/.test(file.fileList[0].name)) {
    //             Message.openMsgError("错误", "请上传.jpg.png.jpeg图片文件")
    //             return
    //         }
    //         if (!file.file.url && !file.file.preview) {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file.file.originFileObj);
    //             reader.onload = (e) => {
    //                 let fileList = file.fileList
    //                 fileList[0].thumbUrl = e.target.result
    //                 state[name] = fileList;
    //                 this.setState(state);
    //             }
    //         }
    //     } else {
    //         state[name] = file.fileList;
    //         this.setState(state);
    //         this.forceUpdate();
    //     }
    // }

    selectShopPopup = (ref) => {
        this.selectShopPopup = ref;
    }

    closePopup() {
        let shopModel = this.selectShopPopup.state.selectedRows[0]
        let model = this.state.model
        model.shopId = shopModel.shopId
        model.shopName = shopModel.shopName
        this.setState({
            model: model
        })
    }

    openPopup() {
        this.selectShopPopup.open()
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "openMembership/memberPlatformReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>设置头像</div>
            </div>
        );
        const uploadButtonZFB = (
            <div>
                <Icon type="plus" />
                <div>设置支付宝收款码</div>
            </div>
        );
        const uploadButtonWX = (
            <div>
                <Icon type="plus" />
                <div>设置微信收款码</div>
            </div>
        );
        const columns = [
            {
                title: '手机号',
                dataIndex: 'phone',
                className: 'tableWidth13',
            },
            {
                title: '卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth13',
            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                className: 'tableWidth13',
            },
            {
                title: '店铺',
                dataIndex: 'shopName',
                className: 'tableWidth10',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                className: 'tableWidth8',
                render: (text) => {
                    return (
                        <div>{text === "MALE" ? "男" : (text === "FEMALE" ? "女" : (text === "HIDE" ? "隐藏" : ""))}</div>
                    );
                }
            },
            {
                title: '角色',
                dataIndex: 'sysRoleName',
                className: 'tableWidth13',
                render: (text, item) => (Common.isNotEmpty(text)?text:"会员")
                // render: (text, item) => {
                //     return (
                //         <div>{Common.isNotEmpty(item.sysRoleCode) ? item.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部负责人" :
                //             (item.sysRoleCode.indexOf("HEAD_STAFF") > -1 ? "总部员工" :
                //                 (item.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺负责人" :
                //                     (item.sysRoleCode.indexOf("CASHIER") > -1 ? "收银员" : "会员"))) : "会员"}
                //         </div>
                //     );
                // }
            },
            // {
            //     title: '备注',
            //     dataIndex: 'remark',
            //     className: 'tableWidth20',
            // },
            {
                title: '售卡人',
                dataIndex: 'cardSellerName',
                className: 'tableWidth10',
            },
            {
                title: '售卡人手机号',
                dataIndex: 'cardSellerPhone',
                className: 'tableWidth10',
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: 'tableWidth10',
                render: (text) => {
                    return (
                        <div>{text === "NORMAL" ? "激活" : (text === "FROZEN" ? "冻结" : (text === "TOO_MANY_TIMES" ? "密码错误次数太多等待超时重试" : ""))}</div>
                    );
                }
            },
        ];
        const memberColumns = [
            {
                title: '卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth25',
            },
            {
                title: '卡类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth25',
                render: (text, record) => {
                    return (
                        <div>{text === "Y" ? "金卡" : (text === "N" && !record.whetherMasterCard ? "赠送银卡" : "银卡")}</div>
                    );
                }
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth25',
            },
            {
                title: '开卡时间',
                dataIndex: 'createdDate',
                className: 'tableWidth25',
                render: (text) => { return (Common.dateFormat(text, "yyyy/MM/dd")) }
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const memberRowSelection = {
            selectedRowKeys: this.state.memberSelectedRowKeys,
            onChange: this.memberOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysParam">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">会员管理</div>
                        <div className="content">
                            <SelectShop onRef={this.selectShopPopup} closePopup={() => this.closePopup()} />
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">昵称：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="昵称"
                                                value={this.state.searchModel.nickName || ''}
                                                onChange={(e) => this.searchChange('nickName', e.target.value)} />
                                        </Col>
                                        <Col span={2} className='zhTxt'>角色：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.sysRoleCode || ''}
                                                onChange={(e) => this.searchChange('sysRoleCode', e)} >
                                                <Option value="">全部</Option>
                                                {context.sysRoleMap.map((item) =>
                                                    <Option key={item.key} value={item.key}>{item.value}</Option>
                                                )}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.addChildren("headquarters")} icon="plus">开通总部卡号</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.addChildren("shop")} icon="plus">开通店铺卡号</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出报表</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="userId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'userId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>

                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={() => this.handleCancel1()}>
                                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                    </Modal>
                                    <Modal title="放大图" visible={this.state.previewVisibleZFB} footer={null} onCancel={() => this.handleCancel2()}>
                                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImageZFB} />
                                    </Modal>
                                    <Modal title="放大图" visible={this.state.previewVisibleWX} footer={null} onCancel={() => this.handleCancel3()}>
                                        <img alt="example" style={{ width: '100%' }} src={this.state.previewImageWX} />
                                    </Modal>
                                    {this.state.flag === "edit" ? <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>手机号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="手机号"
                                                    //企业金卡、业务员金卡只能修改 收款二维码
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    value={this.state.model.phone || ''}
                                                    onChange={(e) => this.modelChange('phone', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>昵称：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={12}
                                                    placeholder="昵称"
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    value={this.state.model.nickName || ''}
                                                    onChange={(e) => this.modelChange('nickName', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>性别<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    className="zhSelect"
                                                    value={this.state.model.sex || ''}
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    onChange={(e) => this.modelChange('sex', e)} >
                                                    <Option value={"HIDE"}>隐藏</Option>
                                                    <Option value={"MALE"}>男</Option>
                                                    <Option value={"FEMALE"}>女</Option>
                                                </Select>
                                            </Col>
                                            <Col span={4} className='zhTxt'>状态<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    className="zhSelect"
                                                    value={this.state.model.status || ''}
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    onChange={(e) => this.modelChange('status', e)} >
                                                    <Option value={"NORMAL"}>激活</Option>
                                                    <Option value={"FROZEN"}>冻结</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>身份<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                {/* <Select
                                                    className="zhSelect"
                                                    disabled
                                                    value={this.state.model.identity || ''}
                                                    onChange={(e) => this.modelChange('identity', e)} >
                                                    <Option value={"HEAD_USER"}>总部负责人</Option>
                                                    <Option value={"ADMINISTRATORS"}>总部员工</Option>
                                                    <Option value={"STORE_CHARGE"}>店铺负责人</Option>
                                                    <Option value={"CASHIER"}>收银员</Option>
                                                    <Option value={"MENBER"}>会员</Option>
                                                </Select> */}
                                                <Input disabled value={this.state.model.sysRoleName || '会员'} />

                                            </Col>
                                            <Col span={4} className='zhTxt' hidden={this.state.shopFlag}>店铺名称<span className='redStar'>*</span>：</Col>
                                            <Col span={4} className='zhInput' hidden={this.state.shopFlag}>
                                                <Input
                                                    placeholder="开卡店铺名称"
                                                    disabled
                                                    value={this.state.model.shopName || ''} />
                                            </Col>
                                            <Col span={2} style={{ marginTop: "5px" }} 
                                                hidden={this.state.shopFlag ||this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}>
                                                <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                            </Col>

                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>绑定卡号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={12}
                                                    placeholder="绑定卡号"
                                                    disabled
                                                    value={this.state.model.cardNumber || ''}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            {(this.state.model.identity == "HEAD_USER" 
                                                || this.state.model.identity == "STORE_CHARGE" 
                                                || this.state.model.rebateType=="CORPORATE" 
                                                || this.state.model.rebateType=="BUSINESS" )
                                                ? "" 
                                                : <Col span={2} className='zhTxt'> 
                                                    <Button type="primary" icon="check-circle" onClick={() => { this.showModal() }}>选择卡号</Button>
                                                </Col>
                                            }
                                        </Row>

                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>售卡人：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="售卡人"
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    value={this.state.model.cardSellerName || ''}
                                                    onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="售卡人手机号"
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    value={this.state.model.cardSellerPhone || ''}
                                                    onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>头像：</Col>
                                            <Col span={6} className='imgUpload zhInput'>
                                                <Upload
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    action={Config.apiBackServer + "test/test"}
                                                    listType="picture-card"
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    fileList={this.state.imageFile}
                                                    onPreview={this.handlePreview}
                                                    onChange={(file) => this.handleChange(file,"imageFile")}>
                                                    {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                                </Upload>
                                            </Col>
                                            <Col span={4} className='zhTxt'>备注：</Col>
                                            <Col span={6} className='zhInput'>
                                                <TextArea
                                                    value={this.state.model.remark || ""}
                                                    disabled={this.state.model.rebateType=="CORPORATE" || this.state.model.rebateType=="BUSINESS"}
                                                    onChange={(e) => this.modelChange('remark', e.target.value)}
                                                    placeholder="备注"
                                                    maxLength={100}
                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                            <Col span={6} className='imgUpload zhInput'>
                                                <Upload
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    action={Config.apiBackServer + "test/test"}
                                                    listType="picture-card"
                                                    fileList={this.state.imageFileZFB}
                                                    onPreview={this.handlePreviewZFB}
                                                    onChange={(file) => this.handleChange(file,"imageFileZFB")}>
                                                    {this.state.imageFileZFB.length >= this.state.ImageNumZFB ? null : uploadButtonZFB}
                                                </Upload>
                                            </Col>
                                            <Col span={4} className='zhTxt'>微信收款码：</Col>
                                            <Col span={6} className='imgUpload zhInput'>
                                                <Upload
                                                    key={"1"}
                                                    accept=".png, .jpg, .jpeg"
                                                    action={Config.apiBackServer + "test/test"}
                                                    listType="picture-card"
                                                    fileList={this.state.imageFileWX}
                                                    onPreview={this.handlePreviewWX}
                                                    onChange={(file) => this.handleChange(file,"imageFileWX")}>
                                                    {this.state.imageFileWX.length >= this.state.ImageNumWX ? null : uploadButtonWX}
                                                </Upload>
                                            </Col>
                                        </Row>
                                    </> : <>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>手机号<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={11}
                                                        placeholder="手机号"
                                                        value={this.state.model.phone || ''}
                                                        onChange={(e) => this.modelChange('phone', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt' hidden={this.state.flag === "edit"}>密码<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput' hidden={this.state.flag === "edit"}>
                                                    <Input.Password maxLength={16}
                                                        placeholder="密码"
                                                        value={this.state.model.password || ''}
                                                        onChange={(e) => this.modelChange('password', e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>昵称：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={12}
                                                        placeholder="昵称"
                                                        value={this.state.model.nickName || ''}
                                                        onChange={(e) => this.modelChange('nickName', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>性别<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        className="zhSelect"
                                                        value={this.state.model.sex || ''}
                                                        onChange={(e) => this.modelChange('sex', e)} >
                                                        <Option value={"HIDE"}>隐藏</Option>
                                                        <Option value={"MALE"}>男</Option>
                                                        <Option value={"FEMALE"}>女</Option>
                                                    </Select>
                                                </Col>

                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>状态<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        className="zhSelect"
                                                        value={this.state.model.status || ''}
                                                        onChange={(e) => this.modelChange('status', e)} >
                                                        <Option value={"NORMAL"}>激活</Option>
                                                        <Option value={"FROZEN"}>冻结</Option>
                                                    </Select>
                                                </Col>
                                                <Col span={4} className='zhTxt'>身份<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        className="zhSelect"
                                                        disabled
                                                        value={this.state.model.identity || ''}
                                                        onChange={(e) => this.modelChange('identity', e)} >
                                                        <Option value={"HEAD_USER"}>总部负责人</Option>
                                                        <Option value={"ADMINISTRATORS"}>总部员工</Option>
                                                        <Option value={"STORE_CHARGE"}>店铺负责人</Option>
                                                        <Option value={"CASHIER"}>收银员</Option>
                                                        <Option value={"MENBER"}>会员</Option>
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt' hidden={this.state.shopFlag}>店铺名称<span className='redStar'>*</span>：</Col>
                                                <Col span={4} className='zhInput' hidden={this.state.shopFlag}>
                                                    <Input
                                                        placeholder="开卡店铺名称"
                                                        disabled
                                                        value={this.state.model.shopName || ''} />
                                                </Col>
                                                <Col span={2} style={{ marginTop: "5px" }} hidden={this.state.shopFlag}>
                                                    <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                                </Col>
                                                <Col span={4} className='zhTxt'>绑定卡号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={12}
                                                        placeholder="绑定卡号"
                                                        disabled
                                                        value={this.state.model.cardNumber || ''}
                                                        onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                                </Col>
                                            </Row>

                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>售卡人：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        placeholder="售卡人"
                                                        value={this.state.model.cardSellerName || ''}
                                                        onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={11}
                                                        placeholder="售卡人手机号"
                                                        value={this.state.model.cardSellerPhone || ''}
                                                        onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>头像：</Col>
                                                <Col span={6} className='imgUpload zhInput'>
                                                    <Upload
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        action={Config.apiBackServer + "test/test"}
                                                        listType="picture-card"
                                                        fileList={this.state.imageFile}
                                                        onPreview={this.handlePreview}
                                                        onChange={(file) => this.handleChange(file,"imageFile")}>
                                                        {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                                    </Upload>
                                                </Col>
                                                <Col span={4} className='zhTxt'>备注：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <TextArea
                                                        value={this.state.model.remark || ""}
                                                        onChange={(e) => this.modelChange('remark', e.target.value)}
                                                        placeholder="备注"
                                                        maxLength={100}
                                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                                <Col span={6} className='imgUpload zhInput'>
                                                    <Upload
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        action={Config.apiBackServer + "test/test"}
                                                        listType="picture-card"
                                                        fileList={this.state.imageFileZFB}
                                                        onPreview={this.handlePreviewZFB}
                                                        onChange={(file) => this.handleChange(file,"imageFileZFB")}>
                                                        {this.state.imageFileZFB.length >= this.state.ImageNumZFB ? null : uploadButtonZFB}
                                                    </Upload>
                                                </Col>
                                                <Col span={4} className='zhTxt'>微信收款码：</Col>
                                                <Col span={6} className='imgUpload zhInput'>
                                                    <Upload
                                                        key={"1"}
                                                        accept=".png, .jpg, .jpeg"
                                                        action={Config.apiBackServer + "test/test"}
                                                        listType="picture-card"
                                                        fileList={this.state.imageFileWX}
                                                        onPreview={this.handlePreviewWX}
                                                        onChange={(file) => this.handleChange(file,"imageFileWX")}>
                                                        {this.state.imageFileWX.length >= this.state.ImageNumWX ? null : uploadButtonWX}
                                                    </Upload>
                                                </Col>
                                            </Row>
                                        </>}

                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button type="primary" icon="check-circle" disabled={this.state.isLoading} onClick={() => { this.save() }}>保存</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                    <Modal title="选择会员"
                                        visible={this.state.visible}
                                        onOk={this.handleOk}
                                        onCancel={this.handleCancel}
                                        width="60%"
                                    >
                                        <Row className='zhRow'>
                                            <Col span={4} className='zhTxt'>卡号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input
                                                    placeholder="卡号"
                                                    value={this.state.memberModel.cardNumber || ''}
                                                    onChange={(e) => this.meberChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt' >店铺名称：</Col>
                                            <Col span={6} className='zhInput' >
                                                <Select style={{ width: "100%" }} placeholder="开卡店铺名称"
                                                    value={this.state.memberModel.shopId || ''}
                                                    onChange={(e) => this.meberChange('shopId', e)}>
                                                    <option key="全部" value="">全部</option>
                                                    {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className="rowButton">
                                            <Col className="zhRowButtonRight">
                                                <Button type="primary" onClick={() => this.showModal()} icon="search">搜索</Button>
                                                <Button className="zhButtonRight" onClick={() => this.memberReset()} icon="redo">重置</Button>
                                            </Col>
                                        </Row>
                                        <Row className='tableStyle zhRow'>
                                            <Table rowSelection={memberRowSelection}
                                                rowKey="id"
                                                bordered={true} //边线
                                                columns={memberColumns} //列名
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                dataSource={this.state.memberList} //数据
                                                pagination={{  //分页
                                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                    onChange: this.memberPageChange,            //上页下页或者跳页方法
                                                    onShowSizeChange: this.memberPageChange,    //每页显示多少条数据方法
                                                    total: this.state.memberModel.total,             //总共多少条数据
                                                    current: this.state.memberModel.page,         //当前页
                                                    pageSize: this.state.memberModel.pageSize,       //每页显示多少条数据
                                                    defaultCurrent: 1,                               //默认当前页为1
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: event => Common.selectRadioRow.call(this, record, 'id', 'memberSelectedRows', 'memberSelectedRowKeys'), // 点击行
                                                    };
                                                }}
                                                rowClassName="tableColor"
                                            />
                                        </Row>

                                    </Modal>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }

    memberReset() {
        this.setState({
            memberModel: { page: 1, pageSize: 10, cardNumber: "" },
            memberSelectedRows: [],
            memberSelectedRowKeys: [],
        })
    }

    memberPageChange = (current, pageSize) => {
        let memberModel = this.state.memberModel;
        memberModel.pageSize = pageSize;
        memberModel.page = current;
        this.setState({
            memberModel: memberModel,
        });
        this.showModal();
    }

    showModal = () => {
        this.setState({ isLoading: true })
        let model = this.state.memberModel
        if (!this.state.visible) {
            model.shopId = this.state.model.shopId
        }
        console.log(model)
        fetch(Config.apiBackServer + "openMembership/searchUnbound", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                memberModel: model,
                memberList: data.object.list,
                visible: true,
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    };

    handleOk = e => {
        if (this.state.memberSelectedRows == null || this.state.memberSelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.memberSelectedRows[0]
        let newModel = this.state.model
        newModel.id = model.id
        newModel.cardNumber = model.cardNumber
        this.setState({
            visible: false,
            model: newModel,
        });
        this.memberReset()
    };

    handleCancel = e => {
        this.setState({
            visible: false,
            memberModel: { page: 1, pageSize: 10, cardNumber: "" },
            memberSelectedRows: [],
            memberSelectedRowKeys: [],
        });
    };

    meberChange(k, e) {
        let newModel = this.state.memberModel;
        newModel[k] = e;
        this.setState({
            memberModel: newModel
        });
    }
}