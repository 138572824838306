import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, Spin, Modal, Tooltip, AutoComplete} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './shopJoining.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
export default class ShopJoining extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            saveModel: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, },
            isLoading: true,
            isExport: false,

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
    }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "shopJoining/search", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    reset() {
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: { newsTitle: "", newsStartTime: null, newsEndTime: null, activeInd: null, page: 1, pageSize: 10, },
        }, function () {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    toAdd() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            saveModel: { activeInd: "Y", },
        })
    }

    save() {
        let saveModel = this.state.saveModel
        if (Common.isEmpty(saveModel.processingResults)) {
            Message.openMsgError("错误", "处理结果不能为空")
            return
        }
        fetch(Config.apiBackServer + "shopJoining/update", {
            method: 'POST',
            body: JSON.stringify(saveModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });

    }

    toEdit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            saveModel: this.state.selectedRows[0],
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.saveModel;
        newModel[k] = e;
        this.setState({
            saveModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            saveModel: {},
        })
        this.search();
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.toEdit();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "shopJoining/excelReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺加盟" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '手机号',
                className: 'tableWidth8',
                dataIndex: 'phone',
            },
            {
                title: '用户姓名',
                className: 'tableWidth10 ellipsis',
                dataIndex: 'userName',
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth14 ellipsis',
            },
            {
                title: '所属地区',
                dataIndex: '',
                className: 'tableWidth10',
                render: (text, item) => item.provinceName + item.cityName + item.areaName
            },
            {
                title: '详细地址',
                dataIndex: 'address',
                className: 'tableWidth25 ellipsis',
                render: (text) => <Tooltip placement="bottomRight" title={text} arrowPointAtCenter>{text} </Tooltip>
            },
            {
                title: '处理结果',
                dataIndex: 'processingResults',
                className: 'tableWidth25 ellipsis',
                render: (text) => <Tooltip placement="bottomRight" title={text} arrowPointAtCenter>{text} </Tooltip>
            },
            {
                title: '状态',
                dataIndex: 'processingStatus',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getMapValue("processingStatusMap", text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">店铺加盟</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11} placeholder="手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>

                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchModel.shopName || ''}
                                                onSelect={(e) => this.onSelect(e)}
                                                onChange={this.onChange}
                                                onSearch={this.onSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">状态：</Col>
                                        <Col span={3} className="zhInput">
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.processingStatus || ''}
                                                onChange={(e) => this.searchChange('processingStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.processingStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toEdit()} icon="edit">编辑</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            scroll={{ x: 1200 }}
                                            rowKey="shopJoiningId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'shopJoiningId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input placeholder="手机号" disabled
                                                value={this.state.saveModel.phone || ''} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>用户姓名：</Col>
                                        <Col span={6} className="zhInput">
                                            <Input placeholder="用户姓名" disabled
                                                value={this.state.saveModel.userName || ''} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>店铺名称：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input placeholder="店铺名称" disabled
                                                value={this.state.saveModel.shopName || ''} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>所属地区：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input placeholder="所属地区" disabled
                                                value={this.state.saveModel.provinceName + this.state.saveModel.cityName + this.state.saveModel.areaName || ''} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>详细地址：</Col>
                                        <Col span={6} className='zhInput'>
                                            <TextArea
                                                value={this.state.saveModel.address || ""}
                                                placeholder="详细地址"
                                                disabled
                                                autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={6} className='zhInput'>
                                            <TextArea
                                                value={this.state.saveModel.leaveMessage || ""}
                                                placeholder="备注"
                                                disabled
                                                autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.saveModel.processingStatus || ''}
                                                disabled >
                                                {context.processingStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={4} className='zhTxt'>处理结果：</Col>
                                        <Col span={6} className='zhInput'>
                                            <TextArea
                                                disabled={this.state.saveModel.processingStatus === "PROCESSED"}
                                                maxLength={100}
                                                value={this.state.saveModel.processingResults || ""}
                                                onChange={(e) => this.modelChange('processingResults', e.target.value)}
                                                placeholder="处理结果"
                                                autoSize={{ minRows: 3, maxRows: 3 }} />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button type="primary" icon="check-circle" disabled={this.state.saveModel.processingStatus === "PROCESSED"} onClick={() => { this.save() }}>保存</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}