import React, { Component } from 'react';
import { Spin, InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import SelectShop from './../selectShop/selectShop';
import { ContextHandler } from '../../public/js/contextHandler';
import './openMembership.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
var css;
export default class OpenMembership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: '',
            isAdd: false,
            isLoading: false,
            openSpecialGoldCard: false,
            isExport: false,
            topDateFlag: true,
            bottomDateFlag: true
        }
        this.init()
    }

    init() {
        // this.search();
        this.searchShop();
        this.searchPaymentAmount();
    }

    searchPaymentAmount() {
        let model = { segment: "RENEWAL_AMOUNT", code: "NUMERICAL_VALUE" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                paymentAmount: data.object[0].parmValue1
            })
        }).catch(err => { });
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShopMembershipCard", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list,
            }, () => {
                let model = { shopId: this.state.searchShopList[0].shopId }
                this.setState({
                    model: model
                })
            })
        }).catch(err => { });
    };

    // search() {
    //     this.setState({ isLoading: true })
    //     fetch(Config.apiBackServer + "openMembership/search", {
    //         method: 'POST',
    //         body: JSON.stringify(this.state.searchModel),
    //         headers: Config.header,
    //         mode: 'cors',
    //         cache: 'default'
    //     }).then(res => res.json()).then((data) => {
    //         let model = this.state.searchModel;
    //         if ("SUCCESS" === data.msg) {
    //             console.log(data.object.list)
    //             model.total = data.object.total;
    //         } else {
    //             Message.openMsgError("错误", data.msg, data.errorCode)
    //         }
    //         this.setState({
    //             searchModel: model,
    //             tables: data.object.list
    //         })
    //     }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    // }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: {
                openDate: new Date(),
                endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                active: 'Y',
                shopId: "",
                transactionType: 'CASH',
                openSpecialGoldCard: false,
                paymentAmount: this.state.paymentAmount,
            },
        }, () => {
            this.topDate()
            this.bottomDate()
        })
    }

    openEnterpriseGoldCard() {
        this.setState({
            tabIndex: "3",
            flag: "add",
            model: {
                openDate: new Date(),
                endDate: new Date().setMonth(new Date().getMonth() + 6),
                active: 'Y',
                shopId: this.state.searchShopList[0].shopId,
                transactionType: 'CASH',
                openSpecialGoldCard: true,
                paymentAmount: this.state.paymentAmount,
            },
        })
    }

    addChildren(flag) {
        var searchModel = this.state.searchModel;
        var carnum = '';
        let model = {
            openDate: new Date(),
            endDate: new Date().setFullYear(new Date().getFullYear() + 1),
            active: 'Y',
            shopId: "",
            transactionType: 'CASH',
            cardNumber: carnum,
            paymentAmount: this.state.paymentAmount,
        }
        this.setState({
            tabIndex: "2",
            flag: flag,
            model: model,
            searchModel: searchModel,
            openSpecialGoldCard: false,
        }, () => {
            this.topDate()
            this.bottomDate()
        })
    }

    save(flag) {
        let msg = this.check();
        let url = "";
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        if (this.state.flag === "add") {
            url = "openMembership/insert";
        } else if (this.state.flag === "edit") {
            url = "openMembership/update"
        } else if (this.state.flag === "silverCard") {
            url = "openMembership/giveInsert";
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                // this.cancel()
                if (this.state.flag === "silverCard") {
                    this.setState({
                        tabIndex: "1"
                    })
                } if (this.state.flag === "add") {
                    let model = this.state.model
                    model.id = data.object.substr(0, 20)
                    this.setState({
                        model: model,
                        isAdd: false
                    })
                    if (flag === 'openSpecialGoldCard') {
                        this.setState({
                            tabIndex: "1",

                        })
                       // this.search();
                    }
                }
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                })
                Message.openMsgSuccess("成功", "保存成功")
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }


    check() {
        let msg = ""
        let model = this.state.model
        let flag = this.state.flag
        console.log(flag);
        if (!model.shopId) {
            msg += "店铺不能为空;"
        }
        if (model.endDate === null) {
            msg += "结束日期不能为空;"
        } else if (model.endDate <= model.openDate) {
            msg += "结束日期要大于开通会员日期;"
        }
        if (!model.cardNumber) {
            if (flag === "silverCard") {
                msg += "银卡号不能为空;"
            } else {
                msg += "金卡卡号不能为空;"
            }
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "HU")) {
                msg += "会员卡号不能以'SC'或'HU'开头;"
            } else if (model.cardNumber.length < 7) {
                msg += "会员卡号长度至少为7;"
            } else if (model.cardNumber.length == 9) {
                msg += "会员卡号长度不能为9;"
            } else if (Common.isEmpty(model.cardSource) && model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6") {
                msg += "会员卡号为10位时，不能以'6'开头;"
            }
        }
        if (this.state.flag === "edit") {
            if (model.phone) {
                if (!model.userId) {
                    msg += "会员卡未绑定,不能修改手机号;"
                }
            }
        }
        return msg
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let modules = this.state.selectedRows[0];
        var endDate = new Date(modules.createdDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
        modules.endDate = endDate;
        fetch(Config.apiBackServer + "openMembership/editSearch", {
            method: 'POST',
            body: JSON.stringify(modules),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                this.setState({
                    tabIndex: "2",
                    model: modules,
                    flag: "edit",
                }, () => {
                    this.editSearch()
                    this.topDate()
                    this.bottomDate()
                })
            }
        }).catch(err => { });


    }

    secondarySearch() {

    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        // this.search();
    }
    secondaryPageChange = (current, pageSize) => {
        let secondaryModel = this.state.secondaryModel;
        secondaryModel.pageSize = pageSize;
        secondaryModel.page = current;
        this.setState({
            secondaryModel: secondaryModel,
        });
        this.editSearch();
    }

    modelChange(k, e) {//需求待定

        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add",
            isAdd: false
        })
        // this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                } else {
                    this.add();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            };
            if(value === '3'){
            this.setState({
                tabIndex: "3",
                flag: "add",
                model: {
                    openDate: new Date(),
                    endDate: new Date().setMonth(new Date().getMonth() + 6),
                    active: 'Y',
                    shopId: this.state.searchShopList[0].shopId,
                    transactionType: 'CASH',
                    openSpecialGoldCard: true,
                    paymentAmount: this.state.paymentAmount,
                }
                })
            }
        }
    }

    /**冻结 -N ,解冻 - Y */
    toFrozenOrThaw(state) {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (this.state.selectedRows[0].active === "Y" && state === "Y") {
            Message.openMsgError("错误", "该卡已激活，不能重复操作")
            return
        } else if (this.state.selectedRows[0].active === "N" && state === "N") {
            Message.openMsgError("错误", "该卡已冻结，不能重复操作")
            return
        }
        let that = this;
        confirm({
            title: state === 'Y' ? '您确定激活?' : '您确定冻结?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let mdoel = {
                    id: that.state.selectedRows[0].id,
                    active: state,
                    userId: that.state.selectedRows[0].userId
                }
                fetch(Config.apiBackServer + "openMembership/toFrozenOrThaw", {
                    method: 'POST',
                    body: JSON.stringify(mdoel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("成功", "保存成功");
                        // that.reset()
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "openMembership/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }
    selectShopPopup = (ref) => {
        this.selectShopPopup = ref;
    }

    closePopup() {
        let shopModel = this.selectShopPopup.state.selectedRows[0]
        let model = this.state.model
        model.shopId = shopModel.shopId
        model.shopName = shopModel.shopName
        this.setState({
            model: model
        })
    }

    openPopup() {
        this.selectShopPopup.open()
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '推荐卡号',
                dataIndex: 'whetherMasterCard',
                className: 'tableWidth10',
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth10',
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth10',
                render: (text, record, index) => (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                    (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                        (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                    ))
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: 'tableWidth10',
            },
            // {
            //     title: '备注',
            //     dataIndex: 'remark',
            //     className: 'tableWidth14',
            // },
            {
                title: '售卡人',
                dataIndex: 'cardSellerName',
                className: 'tableWidth7',
            },
            {
                title: '售卡人手机号',
                dataIndex: 'cardSellerPhone',
                className: 'tableWidth7',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getActiveValue(text)
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            }
        ];
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth15',
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: 'tableWidth15',
            },
            {
                title: '预留手机号',
                className: 'tableWidth15',
                dataIndex: 'reservedIdCard',
            },
            {
                title: '售卡人',
                className: 'tableWidth15',
                dataIndex: 'cardSellerName',
            },
            {
                title: '售卡人手机号',
                className: 'tableWidth15',
                dataIndex: 'cardSellerPhone',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: 'tableWidth20',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth5',
                render: (text) => ContextHandler.getActiveValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">会员卡管理</div>
                        <div className="content">
                            <SelectShop onRef={this.selectShopPopup} closePopup={() => this.closePopup()} />
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">开卡店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            {/* <Select style={{ width: "100%" }} placeholder="开卡店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <option key="" value="">全部</option>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select> */}
                                            <Input maxLength={20}
                                                placeholder="开卡店铺名称"
                                                value={this.state.searchModel.shopName || ''}
                                                onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">推荐卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="推荐卡号"
                                                value={this.state.searchModel.whetherMasterCard || ''}
                                                onChange={(e) => this.searchChange('whetherMasterCard', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">卡号类型：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.cardNumberType || ''}
                                                onChange={(e) => this.searchChange('cardNumberType', e)} >
                                                <Option value={""}>全部</Option>
                                                <Option value={"masterCard"}>金卡</Option>
                                                <Option value={"subCard"}>银卡</Option>
                                                <Option value={"HEAD_RESPONSIBLE"}>总部卡</Option>
                                                <Option value={"STORE_RESPONSIBLE"}>店铺卡</Option>
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row className="row">
                                        <Col span={2} className="zhTxt">开卡日期：</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">至</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="至日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">用户手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={20}
                                                placeholder="用户手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">是否绑定用户：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.binding || ''}
                                                onChange={(e) => this.searchChange('binding', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.bindingMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick="" icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">开通金卡</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.openEnterpriseGoldCard("openSpecialGoldCard")} icon="plus">开通特殊时长金卡</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.addChildren("silverCard")} icon="plus">赠送银卡</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.add()} icon="plus">开通金卡</Button>
                                    {Config.userInfo.identity === "HEAD_USER" || Config.userInfo.identity === "ADMINISTRATORS" ? <Button className="zhButtonLeft" type="primary" onClick={() => this.addChildren("headquarters")} icon="plus">开通总部卡号</Button> : ""} */}
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toFrozenOrThaw("N")} icon="edit">冻结</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toFrozenOrThaw("Y")} icon="edit">激活</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出报表</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>

                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row>
                                        <Col span={4} className='zhTxt'>开卡店铺名称<span className='redStar'>*</span>：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Input
                                                placeholder="开卡店铺名称"
                                                disabled
                                                value={this.state.model.shopName || ''} />
                                        </Col>
                                        <Col span={2} style={{ marginTop: "5px" }} >
                                            <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                        </Col>
                                        <Col span={4} hidden={this.state.topDateFlag} className='zhTxt'>会员开通日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} hidden={this.state.topDateFlag} className='zhInput'>
                                            <DatePicker placeholder="会员开通卡日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.openDate == null ? moment(new Date(this.state.model.createdDate), Config.dateFormat) : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className='row' hidden={this.state.bottomDateFlag}>
                                        <Col span={4} className='zhTxt'>会员开通日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="会员开通卡日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.openDate == null ? moment(new Date(this.state.model.createdDate), Config.dateFormat) : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员结束日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="会员结束日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        {this.state.flag === "add" || this.state.flag === "edit" ?
                                            <>
                                                <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.transactionType || ''}
                                                        disabled={this.state.flag === "edit"}
                                                        onChange={(e) => this.modelChange('transactionType', e)}>
                                                        {context.transactionTypeList.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                                                    </Select>
                                                </Col>
                                            </> :
                                            <>
                                                <Col span={4} className='zhTxt'>{this.state.flag === "silverCard" ? "银卡卡号" : ""}< span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        placeholder={this.state.flag == "silverCard" ? "银卡卡号" : ""}
                                                        value={this.state.model.cardNumber || ''}
                                                        disabled={Common.isNotEmpty(this.state.model.cardSource) && this.state.model.cardSource == "SYS"}
                                                        onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                                </Col>
                                            </>}
                                        {this.state.flag == "add" ? <>
                                            <Col span={4} className='zhTxt' >缴费金额：</Col>
                                            <Col span={6} className='zhInput' >
                                                <Input type="phone" maxLength={11} disabled
                                                    value={this.state.model.paymentAmount || ''} />
                                            </Col>
                                        </> : this.state.flag === "silverCard" ? <>
                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input placeholder="预留手机号"
                                                    maxLength={11}
                                                    value={this.state.model.reservedIdCard || ''}
                                                    onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                            </Col>

                                        </> : <>
                                                    <Col span={4} className='zhTxt'>用户手机号<span className='redStar'>*</span>：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={11}
                                                            placeholder="用户手机号"
                                                            value={this.state.model.phone || ''}
                                                            onChange={(e) => this.modelChange('phone', e.target.value)} />
                                                    </Col>
                                                </>}

                                    </Row>
                                    {this.state.flag === "add" || this.state.flag === "edit" ?
                                        <>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>{this.state.model.cardNumberType === "Y" || this.state.flag === "add" ? "金卡" :
                                                    this.state.model.cardNumber.substr(0, 2) === "HU" ? "总部" :
                                                        this.state.model.cardNumber.substr(0, 2) === "SC" ? "店铺" : "银卡"}卡号<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        placeholder={this.state.model.cardNumberType === "Y" || this.state.flag === "add" ? "金卡卡号" :
                                                            this.state.model.cardNumber.substr(0, 2) === "HU" ? "总部卡号" :
                                                                this.state.model.cardNumber.substr(0, 2) === "SC" ? "店铺卡号" : "银卡卡号"}
                                                        value={this.state.model.cardNumber || ''}
                                                        disabled={Common.isNotEmpty(this.state.model.cardSource) && this.state.model.cardSource == "SYS"}
                                                        onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input placeholder="预留手机号"
                                                        maxLength={11}
                                                        value={this.state.model.reservedIdCard || ''}
                                                        onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>售卡人：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        placeholder="售卡人"
                                                        value={this.state.model.cardSellerName || ''}
                                                        onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={11}
                                                        placeholder="售卡人手机号"
                                                        value={this.state.model.cardSellerPhone || ''}
                                                        onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>备注：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <TextArea
                                                        value={this.state.model.remark || ""}
                                                        onChange={(e) => this.modelChange('remark', e.target.value)}
                                                        placeholder="备注"
                                                        maxLength={100}
                                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                                    />
                                                </Col>
                                            </Row>
                                        </> : <>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>售卡人：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        placeholder="售卡人"
                                                        value={this.state.model.cardSellerName || ''}
                                                        onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                                </Col>
                                                <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={11}
                                                        placeholder="售卡人手机号"
                                                        value={this.state.model.cardSellerPhone || ''}
                                                        onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                                </Col>
                                            </Row>
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>备注：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <TextArea
                                                        value={this.state.model.remark || ""}
                                                        onChange={(e) => this.modelChange('remark', e.target.value)}
                                                        placeholder="备注"
                                                        maxLength={100}
                                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    }

                                    {this.state.secondaryTabIndex === "2" ? "" :
                                        <Row className='zhRow zhRowButtonRight'>
                                            <Button type="primary" icon="check-circle" disabled={this.state.isAdd} onClick={() => { this.save(this.state.flag) }}>保存</Button>
                                            <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                        </Row>}

                                    {this.state.model.cardNumberType === "N" || this.state.model.whetherMasterCardId || this.state.flag === "silverCard" ? "" : <>
                                        <Row className='zhRow'>
                                            <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                                <TabPane tab="银卡列表" key="1">
                                                    <Row className='zhRow'>
                                                        <Button type="primary" onClick={() => this.secondaryAdd()} icon="plus">添加</Button>
                                                        <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryEdit()} icon="edit">修改</Button>
                                                        <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryDelete()} icon="delete">删除</Button>
                                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                                    </Row>
                                                    <Row className='zhRow'></Row>
                                                    <Row className='row'>
                                                        <Table rowSelection={secondaryRowSelection}
                                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                            rowKey="id"
                                                            bordered={true} //边线
                                                            columns={secondaryColumns} //列名
                                                            dataSource={this.state.secondaryList} //数据
                                                            pagination={false}
                                                            pagination={{  //分页
                                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                                onChange: this.secondaryPageChange,            //上页下页或者跳页方法
                                                                onShowSizeChange: this.secondaryPageChange,    //每页显示多少条数据方法
                                                                total: this.state.secondaryModel.total,             //总共多少条数据
                                                                current: this.state.secondaryModel.page,         //当前页
                                                                pageSize: this.state.secondaryModel.pageSize,       //每页显示多少条数据
                                                                defaultCurrent: 1,                               //默认当前页为1
                                                            }}
                                                            onRow={(record) => {
                                                                return {
                                                                    onClick: event => Common.selectRadioRow.call(this, record, 'id', 'secondarySelectedRows', 'secondarySelectedRowKeys'), // 点击行
                                                                };
                                                            }}
                                                            rowClassName="tableColor"
                                                        />
                                                    </Row>
                                                </TabPane>
                                                <TabPane tab="银卡详情" key="2">
                                                    <Row className='row'>
                                                        <Col span={4} className='zhTxt'>银卡卡号<span className='redStar'>*</span>：</Col>
                                                        <Col span={6} className='zhInput'>
                                                            <Input maxLength={10}
                                                                placeholder="银卡卡号"
                                                                value={this.state.secondaryModel.cardNumber || ''}
                                                                disabled={Common.isNotEmpty(this.state.secondaryModel.cardSource) && this.state.secondaryModel.cardSource == "SYS"}
                                                                onChange={(e) => this.secondaryChange('cardNumber', e.target.value)} />
                                                        </Col>
                                                        {this.state.secondaryFlag === "add" ? <>
                                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                            <Col span={6} className='zhInput'>
                                                                <Input placeholder="预留手机号"
                                                                    maxLength={11}
                                                                    value={this.state.secondaryModel.reservedIdCard || ''}
                                                                    onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                            </Col>
                                                        </> : <>
                                                                <Col span={4} className='zhTxt' >用户手机号：</Col>
                                                                <Col span={6} className='zhInput' >
                                                                    <Input placeholder="用户手机号"
                                                                        maxLength={11}
                                                                        value={this.state.secondaryModel.phone || ''}
                                                                        onChange={(e) => this.secondaryChange('phone', e.target.value)} />
                                                                </Col>

                                                            </>}
                                                    </Row>
                                                    <Row className='row'>
                                                        <Col span={4} className='zhTxt'>售卡人：</Col>
                                                        <Col span={6} className='zhInput'>
                                                            <Input maxLength={10}
                                                                placeholder="售卡人"
                                                                value={this.state.secondaryModel.cardSellerName || ''}
                                                                onChange={(e) => this.secondaryChange('cardSellerName', e.target.value)} />
                                                        </Col>
                                                        <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                                        <Col span={6} className='zhInput'>
                                                            <Input maxLength={11}
                                                                placeholder="售卡人手机号"
                                                                value={this.state.secondaryModel.cardSellerPhone || ''}
                                                                onChange={(e) => this.secondaryChange('cardSellerPhone', e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                    {this.state.secondaryFlag === "add" ?
                                                        <Row className='row'>
                                                            <Col span={4} className='zhTxt'>备注：</Col>
                                                            <Col span={6} className='zhInput'>
                                                                <TextArea
                                                                    value={this.state.secondaryModel.remark || ""}
                                                                    onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                                    placeholder="备注"
                                                                    maxLength={100}
                                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row>
                                                            <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                            <Col span={6} className='zhInput'>
                                                                <Input placeholder="预留手机号"
                                                                    maxLength={11}
                                                                    value={this.state.secondaryModel.reservedIdCard || ''}
                                                                    onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                            </Col>
                                                            <Col span={4} className='zhTxt'>备注：</Col>
                                                            <Col span={6} className='zhInput'>
                                                                <TextArea
                                                                    value={this.state.secondaryModel.remark || ""}
                                                                    onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                                    placeholder="备注"
                                                                    maxLength={100}
                                                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row className='zhRow zhRowButtonRight'>
                                                        <Button type="primary" icon="check-circle" onClick={() => { this.secondarySave(this.state.flag) }}>保存</Button>
                                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.secondaryCancel() }}>取消</Button>
                                                    </Row>
                                                </TabPane>
                                            </Tabs>
                                        </Row>
                                    </>}
                                </TabPane>
                                <TabPane tab="开通特殊时长金卡" key="3">
                                    <Row>
                                        <Col span={4} className='zhTxt'>开卡店铺名称<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select style={{ width: "100%" }}
                                                placeholder="开卡店铺名称"
                                                value={this.state.model.shopId || ''}
                                                disabled
                                                onChange={(e) => this.modelChange('shopId', e)}>
                                                {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>开通会员日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开通会员卡日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.openDate == null ? moment(new Date(), Config.dateFormat) : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>结束日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="结束日期"
                                                className="parts-numberInput"
                                                allowClear={false}

                                                value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>金卡卡号<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input maxLength={10}
                                                placeholder="金卡卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>

                                        <Col span={4} className='zhTxt'>预留手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input placeholder="预留手机号"
                                                maxLength={11}
                                                value={this.state.model.reservedIdCard || ''}
                                                onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.transactionType || ''}
                                                disabled={this.state.flag === "edit"}
                                                onChange={(e) => this.modelChange('transactionType', e)}>
                                                {context.transactionTypeList.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                                            </Select>
                                        </Col>
                                        <Col span={4} className='zhTxt' >缴费金额<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input type="phone" maxLength={9}
                                                disabled={this.state.flag === "edit"}
                                                value={this.state.model.paymentAmount || ''}
                                                onChange={(e) => this.modelChange('paymentAmount', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>售卡人：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input maxLength={10}
                                                placeholder="售卡人"
                                                value={this.state.model.cardSellerName || ''}
                                                onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >售卡人手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input placeholder="售卡人手机号"
                                                maxLength={11}
                                                value={this.state.model.cardSellerPhone || ''}
                                                onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={6} className='zhInput'>
                                            <TextArea
                                                value={this.state.model.remark || ""}
                                                onChange={(e) => this.modelChange('remark', e.target.value)}
                                                placeholder="备注"
                                                maxLength={100}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>

                                    {this.state.secondaryTabIndex === "2" ? "" :
                                        <Row className='zhRow zhRowButtonRight'>
                                            <Button type="primary" icon="check-circle" onClick={() => { this.save('openSpecialGoldCard') }}>保存</Button>
                                            <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                        </Row>}

                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div >
        )
    }

    editSearch() {
        this.setState({ isLoading: true })
        var model = this.state.secondaryModel;
        model.whetherMasterCardId = this.state.model.id;
        fetch(Config.apiBackServer + "openMembership/openMembershipSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    secondaryList: data.object.list,
                    secondaryModel: model
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    secondaryCancel() {
        this.setState({
            secondaryFlag: "add",
            secondaryTabIndex: "1",
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
        }, () => {
            this.editSearch()
        })

    }
    secondaryAdd() {
        if (!this.state.model.id) {
            Message.openMsgError("错误", "请先保存金卡信息")
            return
        }
        this.setState({
            secondaryFlag: "add",
            secondaryTabIndex: "2",
            secondaryModel: { active: "Y" }
        })
    }

    secondaryEdit() {
        if (this.state.secondarySelectedRows == null || this.state.secondarySelectedRows.length != 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        console.log(this.state.secondarySelectedRows[0])
        this.setState({
            secondaryFlag: "edit",
            secondaryTabIndex: "2",
            secondaryModel: this.state.secondarySelectedRows[0]
        })
    }
    secondarySave() {
        let model = this.state.secondaryModel
        if (this.state.secondaryFlag === "edit") {
            if (model.phone) {
                if (!model.userId) {
                    Message.openMsgError("错误", "会员卡未绑定,不能修改手机号");
                    return
                }
            }
        }

        if (!model.cardNumber) {
            Message.openMsgError("错误", "会员卡号不能为空");
            return
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "HU")) {
                Message.openMsgError("错误", "会员卡号不能以'SC'或'HU'开头");
                return
            } else if (model.cardNumber.length < 7) {
                Message.openMsgError("错误", "会员卡号长度至少为7");
                return
            } else if (model.cardNumber.length == 9) {
                Message.openMsgError("错误", "会员卡号长度不能为9");
                return
            } else if (Common.isEmpty(model.cardSource) && model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6") {
                Message.openMsgError("错误", "会员卡号为10位时，不能以'6'开头");
                return
            }
        }

        let url = "openMembership/update"
        if (this.state.secondaryFlag === "add") {
            url = "openMembership/secondaryInsert";
            model.whetherMasterCardId = this.state.model.id
        }
        model.shopId = this.state.model.shopId
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("保存成功", "");
                this.secondaryCancel()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    }
    secondaryDelete() {
        if (this.state.secondarySelectedRows == null || this.state.secondarySelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.secondarySelectedRows[0]
        if (model.userId) {
            Message.openMsgError("错误", "会员已被绑定不能删除");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "openMembership/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.secondaryCancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    topDate() {
        let cardNumber = this.state.model.cardNumber
        let flag = false
        if (this.state.flag === "silverCard") {
            flag = false
        } else if (this.state.flag === "add") {
            flag = true
        } else if (this.state.model.cardNumberType === "Y") {
            flag = true
        } else if (this.state.model.cardNumberType === "N") {
            flag = false
        }else if (Common.isNotEmpty(cardNumber)) {
            if (cardNumber.indexOf("SC") > -1) {
                flag = false
            }
            if (cardNumber.indexOf("HU") > -1) {
                flag = false
            }

        }
        this.setState({
            topDateFlag: flag
        })
    }

    bottomDate() {
        let cardNumber = this.state.model.cardNumber
        let flag = false
        if (this.state.flag === "silverCard") {
            flag = true
        } else if (this.state.model.cardNumberType === "Y") {
            flag = false
        } else if (this.state.model.cardNumberType === "N") {
            flag = true
        } else if (Common.isNotEmpty(cardNumber)) {
            if (cardNumber.indexOf("SC") > -1) {
                flag = true
            }
            if (cardNumber.indexOf("HU") > -1) {
                flag = true
            }
        }
        this.setState({
            bottomDateFlag: flag
        })
    }

}