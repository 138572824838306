import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';

import './marketingPromotionStatistics.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class MarketingPromotionStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: true,
            saveFlag: true,//重复点击标志
            secondarySave: true,
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,
            settledSumAmount:'',
            unsettledSumAmount:'',
            settled:'0',
            unsettled:'0',
            settledSum:'0',
            unsettledSum:'0',
        }
        this.init()
    }

    init() {
        
    }

    componentWillMount(){
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "marketingPromotionStatistics/searchPromotion", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            var list = JSON.parse(JSON.stringify(data.object.list.list))
            var model =this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                var newModel=data.object.amountAll
                model.total = data.object.list.total;
                if(newModel != null && newModel != undefined){
                    newModel.cardNumber="cardNumber";
                    list.push(newModel)
                }
                this.setState({
                    searchModel: model,
                    tables: list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
           
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    edit(secondaryPageChange) {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        if("cardNumber"=== module.cardNumber){
            Message.openMsgError("错误", "请选择一条有效数据进行查看")
            return
        }
        module.settlementStatus = this.state.searchModel.settlementStatus;
        module.openDate = this.state.searchModel.openDate;
        module.endDate = this.state.searchModel.endDate;
        let secondaryModel = this.state.secondaryModel;
        if(secondaryPageChange === "secondaryPageChange"){
            module.pageSize = secondaryModel.pageSize;
            module.page = secondaryModel.page;
        }else{
            module.pageSize = 10;
            module.page = 1;
        }
       console.log(module);
        fetch(Config.apiBackServer + "marketingPromotionStatistics/searchEdit", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                var settled = '';
                var unsettled = '';
                var settledSum = '';
                var unsettledSum = '';
                let list = JSON.parse(JSON.stringify(data.object.list.list))
                secondaryModel.total = data.object.list.total;
                if(data.object.modelAll !=null){
                    let modelAll = data.object.modelAll;
                    settled = modelAll.settled;
                    unsettled = modelAll.unsettled;
                    settledSum = modelAll.settledSum;
                    unsettledSum = modelAll.unsettledSum;
                }
                if(list != null && list.length > 0){
                    let newModel=data.object.amountAll
                    if(newModel != null && newModel != undefined){
                        newModel.id="id";
                        list.push(newModel);
                    }
                }
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    settled:settled,
                    unsettled:unsettled,
                    secondaryModel,
                    settledSum:Common.numberFormat(settledSum, 2),
                    unsettledSum:Common.numberFormat(unsettledSum, 2),
                    secondaryList:list,
                })
            }
        }).catch(err => { });
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "marketingPromotionStatistics/marketingPromotionStatisticsReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推广会员统计" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }


    Mul(a, b) {
        var str1 = a.toString(),str2 = b.toString(),m=0;
        try{m += str1.split('.')[1].length;}catch(e){}
        try{m += str2.split('.')[1].length;}catch(e){}
        return Number(str1.replace(".", "")) * Number(str2.replace(".", "")) / Math.pow(10, m)
    }


    searchSecondaryReset(){
        let module = this.state.model;
        module.openDate = null;
        module.endDate = null;
        this.setState({
            model: module,
        })
        this.searchSecondary();
    }

    searchSecondary(){
        let module = this.state.model;
        module.pageSize = 10;
        module.page = 1;
        fetch(Config.apiBackServer + "marketingPromotionStatistics/searchEdit", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                var settled = '';
                var unsettled = '';
                var settledSum = '';
                var unsettledSum = '';
                if(data.modelAll != null){
                     settled = data.modelAll.settled;
                     unsettled = data.modelAll.unsettled;
                     settledSum = data.modelAll.settledSum;
                     unsettledSum = data.modelAll.unsettledSum;
                }
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    settled:settled,
                    unsettled:unsettled,
                    settledSum:settledSum,
                    unsettledSum:unsettledSum,
                    secondaryList:data.object.list,
                })
            }
        }).catch(err => { });
    }


    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize-1;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }
    secondaryPageChange = (current, pageSize) => {
        let secondaryModel = this.state.secondaryModel;
        secondaryModel.pageSize = pageSize-1;
        secondaryModel.page = current;
        this.setState({
            secondaryModel: secondaryModel,
        }, () => {
            this.edit("secondaryPageChange");
        });
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        console.log(k);
        console.log(e);
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add"
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }

    }
    downloadCurrentImage1(){
        var model =this.state.model;
        var url = Config.generalizedQRCode +this.state.model.generalizedQRcode;
        model.url = url;
        fetch(Config.apiBackServer + "marketingPromotionStatistics/download", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = this.state.model.generalizedQRcode;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

    cancelSecondary() {
        this.setState({
            tabIndex: "1",
        })
        this.search();
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '推广会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 3,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '推广会员手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth10',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return   (Common.isNotEmpty(item.sysRoleCode) && item.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                        (Common.isNotEmpty(item.sysRoleCode) && item.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                            (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                        ))
                    }
                }
                  
            },
            {
                title: '推广会员提成金额',
                dataIndex: 'newMembersCommission',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(item.settledSum+item.unsettledSum, 2)}</span>,
                            props: {
                                colSpan:1,
                            },
                        }
                    } else {
                        return Common.numberFormat((item.settledSum+item.unsettledSum), 2)
                    }
                }
            },
            {
                title: '已结算人数',
                dataIndex: 'settled',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{item.settled}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '已结算金额',
                dataIndex: 'settledSum',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(item.settledSum, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            {
                title: '未结算人数',
                dataIndex: 'unsettled',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{item.unsettled}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '未结算金额',
                dataIndex: 'unsettledSum',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.tables.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(item.unsettledSum, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2)
                    }
                }
            },
            

        ];    
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">总计</span>,
                            props: {
                                colSpan: 6,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '注册日期',
                dataIndex: 'registrationDate',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text == null ? null : moment(text).format(Config.dateFormat)
                    }
                }
            },
            {
                title: '地区',
                dataIndex: 'region',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '详细地址',
                dataIndex: 'address',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text
                    }
                }
            },
            {
                title: '结算状态',
                dataIndex: 'settlementStatus',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: "",
                            props: {
                                colSpan: 0,
                            },
                        }
                    } else {
                        return text === 'Y' ? '已结算' : '未结算'
                    }
                }
            },
            {
                title: '结算金额',
                dataIndex: 'newMembersCommission',
                className: 'tableRightAlignment',
                render: (text, item, index) => {
                    if (index === this.state.secondaryList.length - 1) {
                        return {
                            children: <span className="weight">{Common.numberFormat(item.settledSum, 2)}</span>,
                            props: {
                                colSpan: 1,
                            },
                        }
                    } else {
                        return Common.numberFormat(text, 2) 
                    }
                }
            },
           
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="marketingPromotionStatistics">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                    <div className="zhTitle">推广会员统计</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">推广会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={3} className="zhTxt">推广会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>

                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={5} className="zhInput">
                                            <DatePicker placeholder="开始日期"
                                               className="newUserStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                format={Config.dateFormat} />
                                                &nbsp;至&nbsp;
                                                <DatePicker placeholder="结束日期"
                                                className="newUserStatisticsDatePicker"
                                                allowClear={false}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        
                                    </Row>

                                    <Row className="row">
                                       
                                        <Col span={2} className="zhTxt">结算状态：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.settlementStatus || ''}
                                                onChange={(e) => this.searchChange('settlementStatus', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.settlementStatusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>


                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                pageSizeOptions: ['11', '21', '31', '41'],
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total + (
                                                    this.state.searchModel.total % this.state.searchModel.pageSize === 0 ?
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) :
                                                        parseInt(this.state.searchModel.total / this.state.searchModel.pageSize) + 1
                                                ),             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize+1,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="推广会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >推广会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广会员手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>已结算人数：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="已结算人数"
                                                value={this.state.settled || ''}
                                                onChange={(e) => this.modelChange('settled', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >未结算人数：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="未结算人数"
                                                disabled
                                                value={this.state.unsettled || ''}
                                                onChange={(e) => this.modelChange('unsettled', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>已结算金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                disabled
                                                placeholder="已结算金额"
                                                value={this.state.settledSum || ''}
                                                onChange={(e) => this.modelChange('settledSum', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' >未结算金额：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="未结算金额"
                                                disabled
                                                value={this.state.unsettledSum || ''}
                                                onChange={(e) => this.modelChange('unsettledSum', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>注册日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开始日期"
                                                className="newUserStatisticsDatePicker1"
                                                allowClear={false}
                                                // value={this.state.model.openDate}
                                                disabled
                                                value={this.state.model.openDate == null ? null : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                                format={Config.dateFormat} />
                                                &nbsp;至&nbsp;
                                                 <DatePicker placeholder="结束日期"
                                                    className="newUserStatisticsDatePicker1"
                                                    allowClear={false}
                                                    disabled
                                                    value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                    onChange={(value) => this.modelChange("endDate", value)}
                                                    format={Config.dateFormat} />
                                        </Col>
                                        
                                    </Row>

                                    <Row className='zhRow'>
                                            <Row className='zhRow'></Row>
                                            <Row className='row'>
                                                <Table 
                                                    rowKey="id"
                                                    bordered={true} //边线
                                                    loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                    columns={secondaryColumns} //列名
                                                    dataSource={this.state.secondaryList} //数据
                                                    pagination={{  //分页
                                                        pageSizeOptions: ['11', '21', '31', '41'],
                                                        showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                        onChange: this.secondaryPageChange,            //上页下页或者跳页方法
                                                        onShowSizeChange: this.secondaryPageChange,    //每页显示多少条数据方法
                                                        total: this.state.secondaryModel.total + (
                                                            this.state.secondaryModel.total % this.state.secondaryModel.pageSize === 0 ?
                                                                parseInt(this.state.secondaryModel.total / this.state.secondaryModel.pageSize) :
                                                                parseInt(this.state.secondaryModel.total / this.state.secondaryModel.pageSize) + 1
                                                        ),             //总共多少条数据
                                                        current: this.state.secondaryModel.page,         //当前页
                                                        pageSize: this.state.secondaryModel.pageSize+1,       //每页显示多少条数据
                                                        defaultCurrent: 1,                               //默认当前页为1
                                                    }}

                                                   
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                        };
                                                    }}
                                                    rowClassName="tableColor"
                                                />
                                            </Row>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancelSecondary() }}>返回</Button>
                                    </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                    </Spin>
                </div>
            </div >
        )
    }


}