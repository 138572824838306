import React, { Component } from 'react';
import { Button, Row
} from 'antd';

import './demo.css';

export default class RouteDemo extends Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
            <div>
                <Row className="zhTitle">路由使用2</Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={()=>{this.props.history.goBack();}}>回退</Button>
                </Row>
                <Row className="zhLine">
                    {this.props.location.state == null ? '' : '跳转传参：' + this.props.location.state.name}
                </Row>
            </div>
        );
    }
}