import React, { Component } from "react";
import { Spin,  Button, Row, Col, Tabs, Table, Input, Modal, DatePicker , Icon} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import SelectCardNumberModel from './selectCardNumberModel';
import { ContextHandler } from '../../public/js/contextHandler';

const confirm = Modal.confirm;
export default class HeadResponsibleManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: '1',
            isAdd: false,
            isLoading: false,
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: { pageSize: 10, page: 1},
            flag: "",
            tables: [],

            model: {},
        }
    }
    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "headResponsibleManagement/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    tables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    tableChange(value) {
        if (value === "2") {
            this.add();
        } else {
            this.setState({
                tabIndex: value
            })
            this.cancel();
        }
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: false,
            model: {},
        })
        this.search()
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, },
        })
        this.cancel();
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
        })
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;
        confirm({
            title : "您确定删除?",
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let model = {
                    id: that.state.selectedRows[0].id,
                    userId: that.state.selectedRows[0].userId
                }
                fetch(Config.apiBackServer + "headResponsibleManagement/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("成功", "保存成功");
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            }
        })
    }

    render() {
        const { TabPane } = Tabs;

        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth17',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth16',
            },
            {
                title: '推荐人卡号',
                dataIndex: 'recommendCard',
                className: 'tableWidth16',
            },
            {
                title: '推荐人手机号',
                dataIndex: 'recommendCardPhone',
                className: 'tableWidth16',
            }, 
            {
                title: '注册日期',
                dataIndex: 'registrationDate',
                className: 'tableWidth16',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: 'tableWidth16',
                render: (text) => ContextHandler.getMapValue('statusMap', text)
          
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }
        return (
            <div className="headResponsibleManagement">
                <div className="zhRoot">
                    <div className="zhTitle">总部负责人管理</div>
                    <div className="content">
                        <SelectCardNumberModel stateRef ={this.state} onRef={this.selectCardNumberPopup} closePopup={() => this.closePopup()}/>
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">注册日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">推荐人卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="推荐人卡号："
                                            value={this.state.searchModel.recommendCard || ''}
                                            onChange={(e) => this.searchChange('recommendCard', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">推荐人手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="推荐人手机号"
                                            value={this.state.searchModel.recommendCardPhone || ''}
                                            onChange={(e) => this.searchChange('recommendCardPhone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="cardNumber"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2" >
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>会员卡号<span className='redStar'>*</span>:</Col>
                                    <Col span={4} className='zhInput'>
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            disabled
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)}  />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                            <Button type="primary" onClick={() => this.openPopup()} >选择会员</Button>
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={11}
                                            placeholder="会员手机号"
                                            disabled
                                            value={this.state.model.phone || ''}
                                            onChange={(e) => this.modelChange('phone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>原密码：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password maxLength={6}
                                            placeholder="原密码"
                                            disabled
                                            value={this.state.model.oldPassword || ''}
                                            onChange={(e) => this.modelChange('oldPassword', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>新密码：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password minLength={6} type="password"
                                            placeholder="新密码"
                                            value={this.state.model.password || ''}
                                            onChange={(e) => this.modelChange('password', e.target.value)} />
                                    </Col>
                                </Row> 
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>确认密码：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password minLength={6} type="password"
                                            placeholder="确认密码"
                                            value={this.state.model.checkPassword || ''}
                                            onChange={(e) => this.modelChange('checkPassword', e.target.value)} />
                                    </Col>
                                </Row>   
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" disabled={this.state.isAdd} onClick={() => { this.save() }} >保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    check() {
        let msg = "";
        let model = this.state.model;
        if (Common.isEmpty(model.cardNumber)) {
            msg += "会员卡号不能为空;"
        }
        if (Common.isEmpty(model.oldPassword) && Common.isEmpty(model.password)) {
            msg += "用户密码不能为空;"
        } else if(Common.isNotEmpty(model.password) && model.password.length < 6) {
            msg += "新密码长度不能小于6位;"
        } else if(Common.isNotEmpty(model.password) && Common.isEmpty(model.checkPassword)) {
            msg += "确认密码不能为空"
        } else if (Common.isNotEmpty(model.password) && Common.isNotEmpty(model.checkPassword)) {
            if(model.password !== model.checkPassword) {
                msg += "新密码与确认新密码必须一致"
            }
        }
        return msg
    }

    save() {
        let msg = this.check();
        let url = "";
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        if (this.state.flag === "add") {
            url = "headResponsibleManagement/cardNumberUp";
        } else if (this.state.flag === "edit") {
            url = "";
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")
            }else {
                Message.openMsgError("错误", data.msg)
            }
        }).catch(err => { }).finally(() => this.setState({ isAdd: false}));
    }

    // 选择会员
    openPopup() {
        this.selectCardNumberPopup.open()
    }

    selectCardNumberPopup = (ref) => {
        this.selectCardNumberPopup = ref;
    }
    
    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    closePopup() {
        let selectCardNumberModel = this.selectCardNumberPopup.state.selectedRows[0];
        let model = this.state.model;
        model.userId = selectCardNumberModel.userId
        model.id = selectCardNumberModel.id
        model.cardNumber = selectCardNumberModel.cardNumber
        model.phone = selectCardNumberModel.phone
        model.oldPassword = selectCardNumberModel.password
        this.setState({
            model: model,
        })
    }
}