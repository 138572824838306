import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Input, Select, Modal, Spin } from 'antd';
import ExportJsonExcel from 'js-export-excel';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import './shopSettlement.css';

const confirm = Modal.confirm;
export default class ShopSettlement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            searchShopList: [],
            model: {},
            tables: [],
            isExport: false,
            isLoading: true,
            searchModel: { startTime: new Date().setFullYear(new Date().getFullYear() - 1), endTime: new Date(), pageSize: 10, page: 1, },
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "membershipRenewal/settlementExport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }

            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    searchShopList: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    };

    reset() {
        this.setState({
            searchModel: { startTime: new Date().setFullYear(new Date().getFullYear() - 1), endTime: new Date(), pageSize: 10, page: 1, },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    downloadTableExcel() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "membershipRenewal/settlementReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "店铺会员结算报表.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth20',
            },
            {
                title: '开卡会员人数',
                dataIndex: 'total',
                className: 'tableWidth20',
            },
            {
                title: '结算人数',
                dataIndex: 'settlementNumber',
                className: 'tableWidth20',
            },
            {
                title: '金卡数量',
                dataIndex: 'goldCardNumber',
                className: 'tableWidth20',
            },
            {
                title: '银卡数量',
                dataIndex: 'silverCardNumber',
                className: 'tableWidth20',
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="shopSettlement">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">店铺会员报表</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            {/* <Select style={{ width: "100%" }} placeholder="店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <option key="" value="">全部</option>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select> */}
                                            <Input maxLength={20}
                                                placeholder="店铺名称"
                                                value={this.state.searchModel.shopName || ''}
                                                onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">开始时间：</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="开始时间"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">结束时间：</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="结束时间"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.downloadTableExcel()} icon="download">导出</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table
                                            // rowSelection={rowSelection}
                                            rowKey="shopId"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            // onRow={(record) => {
                                            //     return {
                                            //         onClick: event => Common.selectRadioRow.call(this, record, 'shopId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            //     };
                                            // }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>

                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }
}