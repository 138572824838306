import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom'
import { Menu, Icon, Row, Col } from 'antd';
import { Message } from '../../public/js/message';

import Demo from '../../pages/demo/demo';
import RouteDemo from '../../pages/demo/routeDemo';
import RouteDemo2 from '../../pages/demo/routeDemo2';
import MessageDemo from '../../pages/demo/messageDemo';
import Page404 from '../../pages/404/404';
import SysParam from '../../pages/sysParam/sysParam';
import SysFunc from '../../pages/sysFunc/sysFunc';
import SysRole from '../../pages/sysRole/sysRole';
import SysUser from '../../pages/sysUser/sysUser';
import ShopWelcome from '../../pages/shopWelcome/shopWelcome';
import PlatformWelcome from '../../pages/platformWelcome/platformWelcome';
import Shop from '../../pages/shop/shop';
import OpenMembership from '../../pages/openMembership/openMembership';
import MembershipRenewal from '../../pages/membershipRenewal/membershipRenewal';
import NewsManagementUrl from '../../pages/newsManagementUrl/newsManagementUrl';
import HomepageScrollConfigUrl from '../../pages/homepageScrollConfigUrl/homepageScrollConfigUrl';
import ConsumptionReport from '../../pages/consumptionReport/consumptionReport';
import PlatformMembersConsumptionReport from '../../pages/platformMembersConsumptionReport/platformMembersConsumptionReport';
import MemberPromotion from '../../pages/memberPromotion/memberPromotion';
import ShopRebate from '../../pages/shopRebate/shopRebate';
import PlatformShopRebate from '../../pages/platformShopRebate/platformShopRebate';
import RegisteredMember from '../../pages/registeredMember/registeredMember';
import Cashier from '../../pages/cashier/cashier';
import PersonalStores from '../../pages/personalStores/personalStores';
import ConsumptionManagement from '../../pages/consumptionManagement/consumptionManagement';
import MyList from '../../pages/myList/myList';
import StoreReport from '../../pages/storeReport/storeReport';
import WeChatManagementUlr from '../../pages/weChatManagementUlr/weChatManagementUlr';
import MemberSettlement from '../../pages/memberSettlement/memberSettlement';
import ShopSettlement from '../../pages/shopSettlement/shopSettlement';
import CashierManagement from '../../pages/cashierManagement/cashierManagement';
import ShopRebateManagement from '../../pages/shopRebateManagement/shopRebateManagement';
import PlatformRebateManagement from '../../pages/platformRebateManagement/platformRebateManagement';
import PerformanceStatistics from '../../pages/performanceStatistics/performanceStatistics';
import ShopPerformanceStatistics from '../../pages/shopPerformanceStatistics/shopPerformanceStatistics';
import StoreRenewal from '../../pages/storeRenewal/storeRenewal';
import PlatformStoresStatistics from '../../pages/platformStoresStatistics/platformStoresStatistics';
import SysMemberManagement from '../../pages/sysMemberManagement/sysMemberManagement';
import ConsumptionManagementOfPlatform from '../../pages/consumptionManagementOfPlatform/consumptionManagementOfPlatform';
import OpenEnterpriseGoldCard from '../../pages/openEnterpriseGoldCard/openEnterpriseGoldCard';
import NewUserStatistics from '../../pages/newUserStatistics/newUserStatistics';
import RebateStatistics from '../../pages/rebateStatistics/rebateStatistics';
import ParticipateStatistics from '../../pages/participateStatistics/participateStatistics';
import UserRebateStatistics from '../../pages/userRebateStatistics/userRebateStatistics';
import ShopJoining from '../../pages/shopJoining/shopJoining';
import Trusteeship from '../../pages/trusteeship/trusteeship';
import DistrictNewUserStatistics from '../../pages/districtNewUserStatistics/districtNewUserStatistics';
import PromotionCostSetting from '../../pages/promotionCostSetting/promotionCostSetting';
import DefaultPromotionGoldCard from '../../pages/defaultPromotionGoldCard/defaultPromotionGoldCard';
import MarketingPromotionStatistics from '../../pages/marketingPromotionStatistics/marketingPromotionStatistics';
import MarketingPromotionSettlement from '../../pages/marketingPromotionSettlement/marketingPromotionSettlement';
import PromotionDetails from '../../pages/promotionDetails/promotionDetails';
import PromotionPerformanceStatistics from '../../pages/promotionPerformanceStatistics/promotionPerformanceStatistics';
import PromotionPerformanceSettlement from '../../pages/promotionPerformanceSettlement/promotionPerformanceSettlement';
import PromotionPerformanceDetails from '../../pages/promotionPerformanceDetails/promotionPerformanceDetails';
import DeleteTestAccount from '../../pages/deleteTestAccount/deleteTestAccount';
import ShopPaymentManagement from '../../pages/shopPaymentManagement/shopPaymentManagement';
import BatchRebateManagement from '../../pages/batchRebateManagement/batchRebateManagement';
import ShopBatchPayMentManagement from '../../pages/shopBatchPayMentManagement/shopBatchPayMentManagement';
import dailyProvSaleReport from '../dailyProvSaleReport/dailyProvSaleReport';
import ShopRebateStatement from '../../pages/shopRebateStatement/shopRebateStatement';
import PlatformShopRebateStatement from '../../pages/platformShopRebateStatement/platformShopRebateStatement';
import WechatPaymentFlow from '../../pages/wechatPaymentFlow/wechatPaymentFlow';
import WechatSmallPaymentFlow from '../../pages/wechatSmallPaymentFlow/wechatSmallPaymentFlow';
import PartnerManagement from '../../pages/partnerManagement/partnerManagement';
import CityAgentManagement from '../../pages/cityAgentManagement/cityAgentManagement';
import TurnoverAudit from '../../pages/turnoverAudit/turnoverAudit';
import WithdrawalAudit from '../../pages/withdrawalAudit/withdrawalAudit';
import TurnoverAuditStatement from '../../pages/turnoverAuditStatement/turnoverAuditStatement';
import MemberCostStatement from '../../pages/memberCostStatement/memberCostStatement';
import ShopFranchiseCostStatement from '../../pages/shopFranchiseCostStatement/shopFranchiseCostStatement'
import ShopPayoffStatement from '../../pages/shopPayoffStatement/shopPayoffStatement'
import UserRoyalties from '../../pages/userRoyalties/userRoyalties'
import ShopToPay from '../../pages/shopToPay/shopToPay';
import MembershipAudit from '../../pages/membershipAudit/membershipAudit';
import ShopTurnoverAudit from '../../pages/shopTurnoverAudit/shopTurnoverAudit';
import AgencyManagement from '../../pages/agencyManagement/agencyManagement';
import HeadResponsibleManagement from '../../pages/headResponsibleManagement/headResponsibleManagement';
import ShopResponsibleManagement from '../../pages/shopResponsibleManagement/shopResponsibleManagement';
import HeadStaffManagement from '../../pages/headStaffManagement/headStaffManagement';
import RegisterShopAudit from '../../pages/registerShopAudit/registerShopAudit';
import ShopCashierManagement from '../../pages/shopCashierManagement/shopCashierManagement';
import SysAdminManagement from '../../pages/sysAdminManagement/sysAdminManagement';
import ShopSalesPaymentFlow from '../../pages/shopSalesPaymentFlow/shopSalesPaymentFlow';
import WithdrawalAmountFlow from '../../pages/withdrawalAmountFlow/withdrawalAmountFlow'
import ShopFranchiseCostAudit from '../../pages/shopFranchiseCostAudit/shopFranchiseCostAudit';
import MemberRegisterFlow from '../../pages/memberRegisterFlow/memberRegisterFlow';
import UserMemberManagement from '../../pages/userMemberManagement/userMemberManagement';
import ShopTurnoverFlow from '../../pages/shopTurnoverFlow/shopTurnoverFlow';
import ChangePassword from '../../pages/changePassword/changePassword';
import OpenGoldCard from '../openGoldCard/openGoldCard';
import ShopFranchiseCostFlow from '../../pages/shopFranchiseCostFlow/shopFranchiseCostFlow';
import SuperAgencyManagement from '../../pages/superAgencyManagement/superAgencyManagement';
import UserDetailsReadyWithdrawalAmount from '../../pages/userDetailsReadyWithdrawalAmount/userDetailsReadyWithdrawalAmount';
import UserRecommendStatistics from '../../pages/userRecommendStatistics/userRecommendStatistics';
import WithdrawalBankCard from '../../pages/withdrawalBankCard/withdrawalBankCard';
import ShopTurnoverDetail from '../../pages/shopTurnoverDetail/shopTurnoverDetail';
import MemberWalletFlow from '../../pages/memberWalletFlow/memberWalletFlow';
import ShopCountStatement from '../../pages/shopCountStatement/shopCountStatement';
import ShopCountStatementPlatform from '../../pages/shopCountStatementPlatform/shopCountStatementPlatform';
import GoldCardRenewalFlow from '../../pages/goldCardRenewalFlow/goldCardRenewalFlow';
import GoldCardRenewalAudit from '../../pages/goldCardRenewalAudit/goldCardRenewalAudit';
import ShopOpenGoldCard from '../../pages/shopOpenGoldCard/shopOpenGoldCard';


import { Config } from '../../config';
import { Common } from '../../public/js/common';
import './main.css';

const SubMenu = Menu.SubMenu;

export default class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            menuList:[],
            selectList:[],
            menu: [],
            openKeys: ["SF_1587002733505v1lS"],
            rootSubmenuKeys : ["SF_1587002733505v1lS", "SF_1587005020693y1Dz", "SF_15760434312392ZX9"],
        };
        if (Config.equipmentType === 'mobile') {
            this.state.collapsed = true;
            this.state.openKeys = ["SF_1587002733505v1lS"];
        }
        this.checkLogin();
    }

    checkLogin() {
        // 校验token是否过期
        if (Common.isNotEmpty(localStorage.getItem("token"))) {
            let isLogin = false;
            fetch(Config.apiServer + "login/checkToken", {
                method: 'POST',
                body: null,
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                if ("SUCCESS" === data.msg) {
                    let menuList=JSON.parse(localStorage.getItem("menuList"));
                    var test = window.location.href.split("/");
                    let selectList=[]
                    for (let iterator of menuList) {
                        if (iterator.children != null && iterator.children.length !== 0) {
                            for (let item of iterator.children) {
                                if (item.routeUrl===test[test.length - 1]) {
                                    selectList=[item.sysFuncId]
                                }
                            }
                        }
                    }
                    this.setState({
                        menuList:menuList,
                        selectList:selectList,
                    })
                    isLogin = true;
                } else {
                }
            }).catch(err => { }).finally(() => {
                if (!isLogin) {// 未登录，跳转登录页面
                    this.props.history.push('/login');
                }
            });
        } else {
            this.props.history.push('/login');
            return;
        }
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    select(item){
        if("SF_1587698815911304f" === item.selectedKeys[0]){
            this.setState({
                selectList:item.selectedKeys,
                collapsed:true
            })
        }else{
            this.setState({
                selectList:item.selectedKeys,
                collapsed:false
            })
        }
    }

    onOpenChange(openKeys) {
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        this.setState({openKeys: [latestOpenKey],});
    };

    toChangePassword(){
        this.props.history.push({
            pathname: "/main/changePassword"
        })
    }
      
    render() {
        return (
            <div className="main">
                <Row>
                    <Row className="mainHeader">
                        <span className="systemName">国民饭卡管理系统</span>
                        {Config.equipmentType === 'mobile' ? null :
                            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} className="menuIcon" onClick={this.toggleCollapsed} />
                        }
                        <div className="right" >
                            <div className="li" onClick={() => { this.toChangePassword()}}>修改密码</div>
                            <div className="li" onClick={this.logout}>
                                <Icon type="logout" className="logoutIcon" />
                                <span>退出</span>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col span={Config.equipmentType === 'mobile' || !this.state.collapsed ? 3: 1}>
                            <Menu
                                openKeys={this.state.openKeys}
                                selectedKeys={this.state.selectList}
                                onSelect={(e)=>this.select(e)}
                                mode="inline"
                                theme="light"
                                inlineIndent={20}
                                inlineCollapsed={this.state.collapsed}
                                onOpenChange={(e)=>this.onOpenChange(e)}
                            >
                                {this.state.menuList.map((item) =>
                                    <SubMenu 
                                        key={item.sysFuncId} 
                                        title={
                                            <span>
                                                <Icon type={
                                                item.routeUrl==="shopCenter"?"shopping":
                                                item.routeUrl==="systemManagement"?"windows":
                                                item.routeUrl==="platformMemberManagement"?"shop":
                                                item.routeUrl==="platformManagement"?"layout":
                                                item.routeUrl==="platformShop"?"layout":
                                                item.routeUrl==="promotionManagement"?"notification":
                                                item.routeUrl==="rebateManagement"?"transaction":
                                                item.routeUrl==="userManagement"?"user":
                                                item.routeUrl==="statementManagement"?"audit":
                                                item.routeUrl==="wechatPay"?"wechat":
                                                item.routeUrl==="else"?"appstore":
                                                item.routeUrl==="audit"?"solution":
                                                "" } />
                                                <span>{item.funcName}</span>
                                            </span>
                                        }
                                    >
                                        {item.children.map((subItem) =>
                                            <Menu.Item key={subItem.sysFuncId}>{subItem.funcName}
                                                <Link to={"/main/" + subItem.routeUrl} replace></Link>
                                            </Menu.Item>
                                        )}
                                    </SubMenu>
                                )}
                            </Menu>
                        </Col>
                        <Col span={Config.equipmentType === 'mobile' || !this.state.collapsed ? 20 : 23}>
                            <Switch>
                                <Route path="/main" exact component={PlatformWelcome} />
                                <Route path="/main/demo" component={Demo} />
                                <Route path="/main/routeDemo" component={RouteDemo} />
                                <Route path="/main/routeDemo2" component={RouteDemo2} />
                                <Route path="/main/messageDemo" component={MessageDemo} />
                                <Route path="/main/shopWelcome" component={ShopWelcome} />
                                <Route path="/main/platformWelcome" component={PlatformWelcome} />
                                <Route path="/main/sysParam" component={SysParam} />
                                <Route path="/main/sysFunc" component={SysFunc} />
                                <Route path="/main/sysRole" component={SysRole} />
                                <Route path="/main/sysUser" component={SysUser} />
                                <Route path="/main/Shop" component={Shop} />
                                <Route path="/main/openMembership" component={OpenMembership} />
                                <Route path="/main/membershipRenewal" component={MembershipRenewal} />
                                <Route path="/main/newsManagementUrl" component={NewsManagementUrl} />
                                <Route path="/main/homepageScrollConfigUrl" component={HomepageScrollConfigUrl} />
                                <Route path="/main/platformMembersConsumptionReport" component={PlatformMembersConsumptionReport} />
                                <Route path="/main/consumptionReport" component={ConsumptionReport} />
                                <Route path="/main/memberPromotion" component={MemberPromotion} />
                                <Route path="/main/shopRebate" component={ShopRebate} />
                                <Route path="/main/platformShopRebate" component={PlatformShopRebate} />
                                <Route path="/main/registeredMember" component={RegisteredMember} />
                                <Route path="/main/cashier" component={Cashier} />
                                <Route path="/main/personalStores" component={PersonalStores} />
                                <Route path="/main/consumptionManagement" component={ConsumptionManagement} />
                                <Route path="/main/myList" component={MyList} />
                                <Route path="/main/storeReport" component={StoreReport} />
                                <Route path="/main/weChatManagementUlr" component={WeChatManagementUlr} />
                                <Route path="/main/memberSettlement" component={MemberSettlement} />
                                <Route path="/main/shopSettlement" component={ShopSettlement} />
                                <Route path="/main/cashierManagement" component={CashierManagement} />
                                <Route path="/main/shopRebateManagement" component={ShopRebateManagement} />
                                <Route path="/main/platformRebateManagement" component={PlatformRebateManagement} />
                                <Route path="/main/performanceStatistics" component={PerformanceStatistics} />
                                <Route path="/main/shopPerformanceStatistics" component={ShopPerformanceStatistics} />
                                <Route path="/main/storeRenewal" component={StoreRenewal} />
                                <Route path="/main/platformStoresStatistics" component={PlatformStoresStatistics} />
                                <Route path="/main/sysMemberManagement" component={SysMemberManagement} />
                                <Route path="/main/consumptionManagementOfPlatform" component={ConsumptionManagementOfPlatform} />
                                <Route path="/main/openEnterpriseGoldCard" component={OpenEnterpriseGoldCard} />
                                <Route path="/main/newUserStatistics" component={NewUserStatistics} />
                                <Route path="/main/rebateStatistics" component={RebateStatistics} />
                                <Route path="/main/participateStatistics" component={ParticipateStatistics} />
                                <Route path="/main/userRebateStatistics" component={UserRebateStatistics} />
                                <Route path="/main/shopJoining" component={ShopJoining} />
                                <Route path="/main/trusteeship" component={Trusteeship} />
                                <Route path="/main/districtNewUserStatistics" component={DistrictNewUserStatistics} />
                                <Route path="/main/promotionCostSetting" component={PromotionCostSetting} />
                                <Route path="/main/defaultPromotionGoldCard" component={DefaultPromotionGoldCard} />
                                <Route path="/main/MarketingPromotionStatistics" component={MarketingPromotionStatistics} />
                                <Route path="/main/MarketingPromotionSettlement" component={MarketingPromotionSettlement} />
                                <Route path="/main/PromotionDetails" component={PromotionDetails} />
                                <Route path="/main/PromotionPerformanceStatistics" component={PromotionPerformanceStatistics} />
                                <Route path="/main/PromotionPerformanceSettlement" component={PromotionPerformanceSettlement} />
                                <Route path="/main/PromotionPerformanceDetails" component={PromotionPerformanceDetails} />
                                <Route path="/main/deleteTestAccount" component={DeleteTestAccount} />
                                <Route path="/main/shopPaymentManagement" component={ShopPaymentManagement}/>
                                <Route path="/main/batchRebateManagement" component={BatchRebateManagement}/>
                                <Route path="/main/shopBatchPayMentManagement" component={ShopBatchPayMentManagement}/>
                                <Route path="/main/dailyProvSaleReport" component={dailyProvSaleReport} />
                                <Route path="/main/shopRebateStatement" component={ShopRebateStatement}/>
                                <Route path="/main/platformShopRebateStatement" component={PlatformShopRebateStatement}/>
                                <Route path="/main/wechatPaymentFlow" component={WechatPaymentFlow}/>
                                <Route path="/main/wechatSmallPaymentFlow" component={WechatSmallPaymentFlow}/>
                                <Route path="/main/partnerManagement" component={PartnerManagement}/>
                                <Route path="/main/turnoverAudit" component={TurnoverAudit}/>
                                <Route path="/main/withdrawalAudit" component={WithdrawalAudit}/>
                                <Route path="/main/withdrawalBankCard" component={WithdrawalBankCard}/>

                                <Route path="/main/turnoverAuditStatement" component={TurnoverAuditStatement}/>
                                <Route path="/main/memberCostStatement" component={MemberCostStatement}/>
                                <Route path="/main/shopFranchiseCostStatement" component={ShopFranchiseCostStatement}/>
                                <Route path="/main/shopPayoffStatement" component={ShopPayoffStatement}/>
                                <Route path="/main/userRoyalties" component={UserRoyalties}/>
                                <Route path="/main/agencyManagement" component={AgencyManagement}/>
                                <Route path="/main/cityAgentManagement" component={CityAgentManagement}/>
                                <Route path="/main/shopToPay" component={ShopToPay}/>
                                <Route path="/main/membershipAudit" component={MembershipAudit}/>
                                <Route path="/main/shopTurnoverAudit" component={ShopTurnoverAudit}/>
                                <Route path="/main/headResponsibleManagement" component={HeadResponsibleManagement}/>
                                <Route path="/main/shopResponsibleManagement" component={ShopResponsibleManagement}/>
                                <Route path="/main/headStaffManagement" component={HeadStaffManagement}/>
                                <Route path="/main/registerShopAudit" component={RegisterShopAudit}/>
                                <Route path="/main/shopCashierManagement" component={ShopCashierManagement}/>
                                <Route path="/main/sysAdminManagement" component={SysAdminManagement}/>
                                <Route path="/main/shopSalesPaymentFlow" component={ShopSalesPaymentFlow}/>
                                <Route path="/main/withdrawalAmountFlow" component={WithdrawalAmountFlow}/>
                                <Route path="/main/shopFranchiseCostAudit" component={ShopFranchiseCostAudit}/>
                                <Route path="/main/memberRegisterFlow" component={MemberRegisterFlow}/>
                                <Route path="/main/userMemberManagement" component={UserMemberManagement}/>
                                <Route path="/main/openGoldCard" component={OpenGoldCard}/>
                                <Route path="/main/shopTurnoverFlow" component={ShopTurnoverFlow}/>
                                <Route path="/main/changePassword" component={ChangePassword}/>
                                <Route path="/main/superAgencyManagement" component={SuperAgencyManagement}/>
                                <Route path="/main/shopFranchiseCostFlow" component={ShopFranchiseCostFlow}/>
                                <Route path="/main/userDetailsReadyWithdrawalAmount" component={UserDetailsReadyWithdrawalAmount}/>
                                <Route path="/main/userRecommendStatistics" component={UserRecommendStatistics}/>
                                <Route path="/main/shopTurnoverDetail" component={ShopTurnoverDetail}/>
                                <Route path="/main/memberWalletFlow" component={MemberWalletFlow}/>
                                <Route path="/main/shopCountStatement" component={ShopCountStatement}/>
                                <Route path="/main/shopCountStatementPlatform" component={ShopCountStatementPlatform}/>
                                <Route path="/main/goldCardRenewalFlow" component={GoldCardRenewalFlow}/>
                                <Route path="/main/goldCardRenewalAudit" component={GoldCardRenewalAudit}/>
                                <Route path="/main/shopOpenGoldCard" component={ShopOpenGoldCard}/>

                                <Route component={Page404} />
                            </Switch>
                        </Col>
                    </Row>
                </Row>
            </div>
        );
    }

    logout = () => {
        fetch(Config.apiServer + "login/lgout", {
            method: 'GET',
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((resp) => {
        }).catch(err => { }).finally(() => {
            Config.userInfo = {};
            Config.header.authorization = '';
            localStorage.setItem('userInfo', null);
            localStorage.setItem('token', null);
            localStorage.setItem('menuList', null);
            localStorage.setItem('openKeys', null);
            this.props.history.push('/login');
        });
    }
}