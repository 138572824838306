
/**
 * 公共方法--下拉值
 */
export default {

    transactionTypeList: [
        { key: "WECHAT", value: "微信", },
        { key: "ALIPAY", value: "支付宝", },
        { key: "CASH", value: "现金", },
        { key: "PAY_BY_CARD", value: "刷卡", },
        { key: "OTHER", value: "其他", },
    ],
    withdrawalType: [
        { key: "WECHAT", value: "微信", },
        { key: "ALIPAY", value: "支付宝", },
        { key: "bank", value: "银行卡", },
    ],
    dealingTypeList: [
        { key: "CONSUMPTION", value: "消费", },
        { key: "RECHARGE", value: "充值", },
        { key: "REBATE", value: "返利", },
    ],
    inds: [
        { key: "Y", value: "是" },
        { key: "N", value: "否" },
    ],
    activeMap: [
        { key: "Y", value: "激活" },
        { key: "N", value: "冻结" },
    ],
    bindingMap: [
        { key: "Y", value: "已绑定" },
        { key: "N", value: "未绑定" },
    ],
    funcTypeMap: [
        { key: "FUNC", value: "一级模块" },
        { key: "MENU", value: "二级模块" },
    ],
    sexMap: [
        { key: "HIDE", value: "隐藏" },
        { key: "MALE", value: "男" },
        { key: "FEMALE", value: "女" },
    ],
    userTypeMap: [
        { key: "HEAD_USER", value: "总部负责人" },
        { key: "ADMINISTRATORS", value: "总部员工" },
        { key: "STORE_CHARGE", value: "店铺负责人" },
        { key: "CASHIER", value: "收银员" },
        { key: "MENBER", value: "会员" },
    ],
    identityMap: [
        { key: "ORDINARY", value: "普通用户" },
        { key: "ADMIN", value: "管理员" },
        { key: "VIP", value: "会员" },
    ],
    statusMap: [
        { key: "NORMAL", value: "激活" },
        { key: "FROZEN", value: "冻结" },
        { key: "TOO_MANY_TIMES", value: "密码错误次数太多等待超时重试" },
    ],
    rebateTypeMap: [
        { key: "CORPORATE", value: "企业金卡" },
        { key: "BUSINESS", value: "业务员金卡" },
    ],
    processingStatusMap: [
        { key: "PROCESSED", value: "已处理" },
        { key: "UNTREATED", value: "未处理" },

    ],
    settlementStatusMap: [
        { key: "Y", value: "已结算" },
        { key: "N", value: "未结算" },
    ],
    rebateStatusMap: [
        { key: "COMPLETED", value: "已完成" },
        { key: "AINCOMPLETE", value: "未完成" },
        { key: "FROZEN", value: "已冻结" },
    ],

    auditStatusMap: [
        { key: "CHECKED", value: "已审核" },
        { key: "UNCHECKED", value: "未审核" },
    ],

    sysRoleMap: [
        { key: "HEAD_RESPONSIBLE", value: "总部负责人" },
        { key: "HEAD_STAFF", value: "总部员工" },
        { key: "STORE_RESPONSIBLE", value: "店铺负责人" },
        { key: "CASHIER", value: "收银员" },
        { key: "MEMBER", value: "会员" },
    ],
    wechatPaymentFlowMap: [
        { key: "SUCCESS", value: "已支付" },
        { key: "NOTPAY", value: "未支付" },
        { key: "CLOSED", value: "已关闭" },
    ],
    paymentTypeMap: [
        { key: "JSAPI", value: "手机支付" },
        { key: "NATIVE", value: "后台扫码支付" },
    ],
    rebateRegisterRebateTypeMap: [
        { key: "DIRECT", value: "直接推荐" },
        { key: "INDIRECT", value: "间接推荐" },
        { key: "SHOP_RECOMMEND", value: "店铺推荐" },
    ],
    rebateSalesRebateTypeMap:[
        { key: "GOLD_CARD_REWARD", value: "金卡会员奖励" },
        { key: "SILVER_CARD_REWARD", value: "银卡会员奖励" },
        { key: "GOLD_CARD_DIRECT_AWARD", value: "金卡直推奖励" },
        { key: "GOLD_CARD_INDIRECT_AWARD", value: "金卡间推奖励" },
        { key: "SILVER_CARD_DIRECT_AWARD", value: "银卡直推奖励" },
        { key: "SILVER_CARD_INDIRECT_AWARD", value: "银卡间推奖励" },
        { key: "PARTNER_DIRECT_AWARD", value: "合伙人直推奖励" },
        { key: "PARTNER_INDIRECT_AWARD", value: "合伙人间推奖励" },
        { key: "SHOP_MANAGER_DIRECT_AWARD", value: "店铺负责人直推奖励" },
        { key: "SHOP_MANAGER_INDIRECT_AWARD", value: "店铺负责人间推奖励" },
        { key: "CITY_AGENT_DIRECT_AWARD", value: "市代直推奖励" },
        { key: "CITY_AGENT_INDIRECT_AWARD", value: "市代间推奖励" },
    ],
    rebateShopJoiningRebateTypeMap:[
        { key: "SILVERCARD_DIRECT_AMOUNT", value: "银卡直推" },
        { key: "CITY_AGENT_FROM_SILVERCARD", value: "市代间推(银卡)" },
        { key: "PARTNER_INDIRECT_AMOUNT", value: "合伙人间推" },
        { key: "PARTNER_DIRECT_AMOUNT", value: "合伙人直推" },
        { key: "CITY_AGENT_FROM_PARTNER", value: "市代间推(合伙人)" },
        { key: "CITY_AGENT_DIRECT_AMOUNT", value: "市代直推" },
        { key: "SHOP_MANAGER_INDIRECT_AMOUNT", value: "店铺负责人间推" },
        { key: "SHOP_MANAGER_DIRECT_AMOUNT", value: "店铺负责人直推" },
    ],

    detailsReadyWithdrawalAmountMap: [
        {key: "SALE_WITHDRAWAL", value: "小额奖励"},
        {key: "SALE", value: "营业额奖励"},
        {key: "RECOMMEND_SHOP", value: "推荐店铺奖励"},
        {key: "RECOMMEND_MEMBER", value: "推荐会员奖励"},
        {key: "SHOP_SALE", value: "店铺营业额"},
    ],

    flowWithdrawalAmountMap: [
        { key: "SMALL_WITHDRAWAL", value: "小额奖励" },
        { key: "SALE", value: "营业额奖励" },
        { key: "RECOMMEND_SHOP", value: "推荐店铺奖励" },
        { key: "RECOMMEND_MEMBER", value: "推荐会员奖励" },
        { key: "SHOP_SALE", value: "店铺营业额奖励" },
        { key: "OPEND_GOLD_CARD", value: "开通金卡奖励" },
    ],

    membershipCardCardTypeMap:[
        { key: "REGULAR_MEMBERS", value: "会员卡" },
        { key: "CITY_AGENT", value: "市代" },
        { key: "PARTNER", value: "合伙人" },
        { key: "AGENCY", value: "代理" },
        { key: "SUPER_AGENCY", value: "超级代理" },
    ],

    feeTypeMap:[
        {key: "CNY", value: "人民币"}
    ],

    cashierStatusMap: [
        {key: "PAID", value:"已支付"},
        {key: "READY_TO_PAY", value:"待支付"},
    ],

    weChatPayMap: [
        {key:"INVALID_REQUEST", value:"参数错误"},
        {key:"NOAUTH", value:"商户无此接口权限"},
        {key:"NOTENOUGH", value:"余额不足"},
        {key:"ORDERPAID", value:"商户订单已支付"},
        {key:"ORDERCLOSED", value:"订单已关闭"},
        {key:"SYSTEMERROR", value:"系统错误"},
        {key:"APPID_NOT_EXIST", value:"APPID不存在"},
        {key:"MCHID_NOT_EXIST", value:"MCHID不存在"},
        {key:"APPID_MCHID_NOT_MATCH", value:"appid和mch_id不匹配"},
        {key:"LACK_PARAMS", value:"缺少参数"},
        {key:"OUT_TRADE_NO_USED", value:"商户订单号重复"},
        {key:"SIGNERROR", value:"签名错误"},
        {key:"XML_FORMAT_ERROR", value:"XML格式错误"},
        {key:"REQUIRE_POST_METHOD", value:"请使用post方法"},
        {key:"POST_DATA_EMPTY", value:"post数据为空"},
        {key:"NOT_UTF8", value:"编码格式错误"},
        {key:"ORDERNOTEXIST", value:"此交易订单号不存在"},
    ],

    payStatusMap:[
        {key: "PAID", value:"已支付"},
        {key: "NOTPAY", value:"待支付"},
        {key: "CLOSED", value:"已关闭"},
    ]

}