import React, { Component } from 'react';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import './shopWelcome.css';

export default class ShopWelcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            summaryModel: {},
        };
    }

    componentDidMount() {
        this.searchShopWelcomeSummary()
    }

    searchShopWelcomeSummary() {
        fetch(Config.apiBackServer + "welcome/searchShopWelcomeSummary", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({ summaryModel: data.object })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => {Message.openMsgError("错误",err)});
    }
    render() {
        return (
            <div className="shopWelcome" >
                <div className="zhRoot">
                    <div className="summary">
                        <div className="cashiertitle">
                            <div className="zhTitle"  >{Config.userInfo.shopName === undefined ?"今日营业概况" : Config.userInfo.shopName + "--今日营业概况"}</div>
                        </div>
                        <div className="totalMealAmount">
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'wuChang.png'}></img>
                                </div>

                                <div className="defaulttitleName1">饭菜总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.footAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'jiuShui.png'}></img>
                                </div>

                                <div className="defaulttitleName1">酒水总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.wineAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'sumAmount.png'}></img>
                                </div>

                                <div className="defaulttitleName1">今日业绩总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.countAmountStr||"0.00"}</div>
                            </div>
                        </div>
                        <div className="totalMealAmount ">
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'dayYeJi.png'}></img>
                                </div>
                                <div className="defaulttitleName1">今日业绩奖励总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.rebateAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'feiYonShengPi.png'}></img>
                                </div>
                                <div className="defaulttitleName1">今日审核业绩奖励总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.auditRebateAmountNum||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'memberCount.png'}></img>
                                </div>
                                <div className="defaulttitleName1">会员总数量</div>
                                <div className="defaulttitleCount1" >{this.state.summaryModel.memberNum||"0"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}