import { Row, Col, Table, Input, Modal, Button } from 'antd';
import React, { Component } from 'react';
import { Common } from '../../public/js/common';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
/**
 * 推荐会员选择处理类
 */
export default class SelectrecommendCardModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: { pageSize: 10, page: 1 },
            tables: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        if (this.props.onRef !== null) {
            this.props.onRef(this);
        }
    }
    
    open() {
        this.setState({
            visible: true,
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: { pageSize: 10, page: 1 },
            tables: [],
            isLoading: false,
        })
    }

    search = () => {
        this.setState({ isLoading: true })
        let model = this.state.searchModel
        if(this.props.stateRef.flag === "edit"){
            model.cardNumber = this.props.stateRef.selectedRows[0].cardNumber
        }
        fetch(Config.apiBackServer + "partnerManagement/searchRecommendCard", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, shopName: "" },
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    /**
     * 关闭店铺弹窗
     */
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleOk = () => {
        if (this.state.selectedRows && this.state.selectedRows.length == 1) {
            this.setState({
                visible: false
            }, () => {
                this.props.closePopup()
            })
        } else {
            Message.openMsgError("错误", "请选择一条数据")
        }
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        const columns = [
            {
                title: '推荐人卡号',
                dataIndex: 'recommendCard',
                className: "tableWidth50",
            },
            {
                title: '推荐人手机号',
                dataIndex: 'recommendCardPhone',
                className: "tableWidth50",
            },
        ];
        return (
            <Modal title="选择会员"
                className="zhRoot container"
                width='60%'
                centered={true}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >

                <Row className="row">
                    <Col span={3} className="zhTxt">推荐人卡号：</Col>
                    <Col span={3} className="zhInput">
                        <Input maxLength={20}
                            placeholder="推荐人卡号"
                            value={this.state.searchModel.recommendCard || ''}
                            onChange={(e) => this.searchChange('recommendCard', e.target.value)} />
                    </Col>
                    <Col span={3} className="zhTxt">推荐人手机号：</Col>
                    <Col span={3} className="zhInput">
                        <Input maxLength={20}
                            placeholder="推荐人手机号"
                            value={this.state.searchModel.recommendCardPhone || ''}
                            onChange={(e) => this.searchChange('recommendCardPhone', e.target.value)} />
                    </Col>
                </Row>
                <Row className="rowButton">
                    <Col className="zhRowButtonRight">
                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                    </Col>
                </Row>
                <Row className='zhRow'>
                    <Table rowSelection={rowSelection}
                        rowKey="recommendCard"
                        bordered={true} //边线
                        columns={columns} //列名
                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                        dataSource={this.state.tables} //数据
                        pagination={{  //分页
                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                            onChange: this.pageChange,            //上页下页或者跳页方法
                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                            total: this.state.searchModel.total,             //总共多少条数据
                            current: this.state.searchModel.page,         //当前页
                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                            defaultCurrent: 1,                               //默认当前页为1
                        }}
                        onRow={(record) => {
                            return {
                                onClick: event => Common.selectRadioRow.call(this, record, "recommendCard", 'selectedRows', 'selectedRowKeys'), // 点击行
                            };
                        }}
                        rowClassName="tableColor"
                    />
                </Row>
            </Modal>)
    }
}