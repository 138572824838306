import React, { Component } from 'react';

import './404.css';

export default class Page404 extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <h1>哎呀找不到页面啦！</h1>
        );
    }
}