import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './sysParam.css';

const confirm = Modal.confirm;
export default class SysParam extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            searchModel: { pageSize: 10, page: 1, },
        }
        this.init()
    }

    init() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "sysParam/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }

            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, code: "", segment: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { activeInd: "Y" },
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: this.state.selectedRows[0]
        })
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "sysParam/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    see() {

    }

    save() {
        let url = "";
        let msg = this.check()
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "sysParam/insert";
        } else {
            url = "sysParam/update"
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.segment == null || model.segment === '') {
            msg += "参数名称不能为空;"
        }
        if (model.code == null || model.code === '') {
            msg += "编码不能为空;"
        }
        if (model.shortDesc == null || model.shortDesc === '') {
            msg += "描述不能为空;"
        }
        if (model.parmValue1 == null || model.parmValue1 === '') {
            msg += "参数值1不能为空;"
        }
        if (model.activeInd == null || model.activeInd === '') {
            msg += "状态不能为空;"
        }
        if (model.dispSeq == null) {
            msg += "序号不能为空;"
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        if ((k === "code" || k === "segment") && e.length < 31) {
            newModel[k] = e.replace(/^ +| +$/g, '').replace(/[^\w\\_\\-]/ig, '').toLocaleUpperCase();//1.禁止两边输入空格 2.只能输入字母、-、_ 3.输入变大写
        } else if (k !== "code" && k !== "segment") {
            newModel[k] = e;
        }
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const { TextArea } = Input;
        const columns = [
            {
                title: '参数名称',
                dataIndex: 'segment',
                className: "tableWidth20",
            },
            {
                title: '编码',
                dataIndex: 'code',
                className: "tableWidth20",
            },
            {
                title: '描述',
                dataIndex: 'shortDesc',
                className: "tableWidth20",
            },
            {
                title: '名称',
                dataIndex: 'parmValue1',
                className: "tableWidth20",
            },
            {
                title: '激活',
                dataIndex: 'activeInd',
                className: "tableWidth10",
                render: (text) => ContextHandler.getIndValue(text)
            },
            {
                title: '序号',
                dataIndex: 'dispSeq',
                className: "tableWidth10 tableRightAlignment",
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysParam">
                <div className="zhRoot container">
                    <div className="zhTitle">系统参数</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">参数名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="参数名称"
                                            value={this.state.searchModel.segment || ''}
                                            onChange={(e) => this.searchChange('segment', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">编码：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="编码"
                                            value={this.state.searchModel.code || ''}
                                            onChange={(e) => this.searchChange('code', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="sysParamId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'sysParamId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>参数名称<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={30}
                                            disabled={this.state.flag === "edit"}
                                            placeholder="参数名称"
                                            value={this.state.model.segment || ''}
                                            onChange={(e) => this.modelChange('segment', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>编码<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={30}
                                            disabled={this.state.flag === "edit"}
                                            placeholder="编码"
                                            value={this.state.model.code || ''}
                                            onChange={(e) => this.modelChange('code', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>描述<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={20}
                                            placeholder="描述"
                                            value={this.state.model.shortDesc || ''}
                                            onChange={(e) => this.modelChange('shortDesc', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>详细描述：</Col>
                                    <Col span={6} className='zhInput'>
                                        <TextArea autoSize={{ minRows: 3, maxRows: 3 }}
                                            maxLength={100}
                                            placeholder="详细描述"
                                            value={this.state.model.longDesc || ''}
                                            onChange={(e) => this.modelChange('longDesc', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>参数值1<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="参数值1"
                                            value={this.state.model.parmValue1 || ''}
                                            onChange={(e) => this.modelChange('parmValue1', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>参数值2：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="参数值2"
                                            value={this.state.model.parmValue2 || ''}
                                            onChange={(e) => this.modelChange('parmValue2', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>参数值3：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="参数值3"
                                            value={this.state.model.parmValue3 || ''}
                                            onChange={(e) => this.modelChange('parmValue3', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>参数值4：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="参数值4"
                                            value={this.state.model.parmValue4 || ''}
                                            onChange={(e) => this.modelChange('parmValue4', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>参数值5：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="参数值5"
                                            value={this.state.model.parmValue5 || ''}
                                            onChange={(e) => this.modelChange('parmValue5', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>参数值6：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={100}
                                            placeholder="参数值6"
                                            value={this.state.model.parmValue6 || ''}
                                            onChange={(e) => this.modelChange('parmValue6', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.activeInd || ''}
                                            onChange={(e) => this.modelChange('activeInd', e)} >
                                            {context.inds.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                    <Col span={4} className='zhTxt'>序号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <InputNumber className="zhInputNumber" maxLength={9}
                                            min={1}
                                            precision={0}
                                            placeholder="序号"
                                            value={this.state.model.dispSeq || ''}
                                            onChange={(e) => this.modelChange('dispSeq', e)} />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}