import React, { Component } from 'react';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import './platformWelcome.css';

export default class PlatformWelcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            summaryModel: {},
            isPlatform:true,   //默认显示平台 欢迎页
        };
    }

    componentDidMount() {
        this.searchPlatformWelcomeSummary()
    }

    searchPlatformWelcomeSummary() {
        fetch(Config.apiBackServer + "welcome/searchPlatformWelcomeSummary", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({ summaryModel: data.object })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => {Message.openMsgError("错误", err)});
    }

    render() {
        return (
            <div className="platformWelcome" >
                <div className="zhRoot">
                    <div className="summary">
                        <div className="cashiertitle">
                            <div className="zhTitle"  >今日营业概况</div>
                        </div>
                        <div className="totalMealAmount">
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'wuChang.png'}></img>
                                </div>

                                <div className="defaulttitleName1">饭菜总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.footAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'jiuShui.png'}></img>
                                </div>

                                <div className="defaulttitleName1">酒水总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.wineAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'sumAmount.png'}></img>
                                </div>
                                <div className="defaulttitleName1">今日业绩总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.countAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'dayYeJi.png'}></img>
                                </div>
                                <div className="defaulttitleName1">今日业绩奖励总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.rebateAmountStr||"0.00"}</div>
                            </div>
                        </div>
                        <div className="totalMealAmount">
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'memberCount.png'}></img>
                                </div>
                                <div className="defaulttitleName1">会员总数量</div>
                                <div className="defaulttitleCount1" >{this.state.summaryModel.memberNum||"0"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'dianpuzhuce.png'}></img>
                                </div>
                                <div className="defaulttitleName1">店铺注册</div>
                                <div className="defaulttitleCount1" >{this.state.summaryModel.shopRegisterNum||"0"}</div>
                            </div>   
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'shopZhucCe.png'}></img>
                                </div>
                                <div className="defaulttitleName1">店铺注册总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.shopRegisterAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'shopZhuceFei.png'}></img>
                                </div>
                                <div className="defaulttitleName1">店铺注册奖励总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.shopRegisterRebateAmountStr||"0.00"}</div>
                            </div>
                        </div>
                        <div className="totalMealAmount">
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'huiYuanTianJia.png'}></img>
                                </div>
                                <div className="defaulttitleName1">今日会员注册</div>
                                <div className="defaulttitleCount1" >{this.state.summaryModel.memberRegisterNum||"0"}</div>
                            </div>  
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'feiYonZhiFu.png'}></img>
                                </div>
                                <div className="defaulttitleName1">会员注册总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.memberRegisterAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'huiYuanJianLi.png'}></img>
                                </div>
                                <div className="defaulttitleName1">会员注册奖励总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.memberRegisterRebateAmountStr||"0.00"}</div>
                            </div>
                            <div className="totalMealAmountLeft">
                                <div className="amountImg">
                                    <img alt="" className="amountImg1" src={Config.icon + 'feiYonShengPi.png'}></img>
                                </div>
                                <div className="defaulttitleName1">今日审核业绩奖励总金额</div>
                                <div className="defaulttitleCount1" >￥{this.state.summaryModel.auditRebateAmountNum||"0.00"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}