import React, { Component } from 'react';
import { Message } from '../../public/js/message';
import { Button, Row, Col, Modal
    , Input 
    , InputNumber 
    , Select 
    , Checkbox 
    , DatePicker 
} from 'antd';

import './demo.css';
import { Config } from '../../config';

const Option = Select.Option;
export default class Demo extends Component {

    constructor(props){
        super(props);
        this.state = {
            flag: false,
            qrCode: "",
            wechatPaymentOrderId: "WP_16215883647433439",
        };
    }

    pay() {
        fetch(Config.apiBackServer + "wxPay/getPayQRCode", {
            method: 'POST',
            body: JSON.stringify({
                total: 0.01
            }),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                flag: true,
                qrCode: data.object.qrCodeBase,
                wechatPaymentOrderId: data.object.wechatPaymentOrderId
            });
            // setTimeout(() => {
            //     this.orderquery();
            // }, 2000);
        }).catch(err => {Message.openMsgError("错误", err)});
    }

    orderquery() {
        fetch(Config.apiBackServer + "wxPay/orderquery", {
            method: 'POST',
            body: JSON.stringify({ wechatPaymentOrderId: this.state.wechatPaymentOrderId }),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            let flag = true;
            if("SUCCESS" === data.object) {
                Message.openMsg("success", "支付成功");
            } else if("CLOSED" === data.object) {
                Message.openMsg("info", "已取消支付");
            } else {
                // setTimeout(() => {
                //     this.orderquery();
                // }, 2000);  
                flag = false;
            }
            if(flag) {
                this.setState({
                    flag: false,
                    qrCode: "",
                    wechatPaymentOrderId: ""
                })
            }
        }).catch(err => {Message.openMsgError("错误", err)});
    }

    cancel() {
        fetch(Config.apiBackServer + "wxPay/cancelOrder", {
            method: 'POST',
            body: JSON.stringify({ wechatPaymentOrderId: this.state.wechatPaymentOrderId }),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            if("SUCCESS" === data.object.status) {
                Message.openMsg("success", "支付成功");
            } else if("CLOSED" === data.object.status) {
                Message.openMsg("info", "已取消支付");
            }
        }).catch(err => {Message.openMsgError("错误", err)}).finally(() => 
            this.setState({
                flag: false,
                qrCode: "",
                wechatPaymentOrderId: ""
            })
        );
    }

    render() {
        return (
            <div className="zhRoot">
                <div className="zhTitle">学员管理</div>
                <div className="zhLine">
                    <div className="zhTxt">姓名</div>
                    <div className="zhInput"><Input placeholder="姓名" /></div>
                    <div className="zhTxt">年龄</div>
                    <div className="zhInput">
                        <InputNumber
                            className="zhInputNumber"
                            min={1}//最小值
                            max={66666}//最大值
                            maxLength={5}//设置输入长度
                            placeholder="年龄"
                        />
                    </div>
                </div>
                <Row className="zhLine">
                    <Col span={3} className="zhTxt">姓名</Col>
                    <Col span={4}><Input placeholder="姓名" /></Col>
                    <Col span={3} className="zhTxt">年龄</Col>
                    <Col span={4}>
                        <InputNumber
                            className="zhInputNumber"
                            min={1}//最小值
                            max={66666}//最大值
                            maxLength={5}//设置输入长度
                            placeholder="年龄"
                        />
                    </Col>
                </Row>
                <Row className="zhLine">
                    <Col span={3} className="zhTxt">地址</Col>
                    <Col span={4}>
                        <Select className="zhSelect">
                            <Option value="jack">湖南</Option>
                            <Option value="lucy">北京</Option>
                            <Option value="disabled" disabled>上海</Option>
                            <Option value="Yiminghe">深圳</Option>
                        </Select>
                    </Col>
                    <Col span={3} className="zhTxt">爱好</Col>
                    <Col span={4}>
                        <Checkbox.Group className="zhCheckbox">
                            <Row>
                                <Checkbox value="A">打球</Checkbox>
                                <Checkbox value="B">音乐</Checkbox>
                                <Checkbox value="C">画画</Checkbox>
                            </Row>
                        </Checkbox.Group>
                    </Col>
                </Row>
                <Row className="zhLine">
                    <Col span={3} className="zhTxt">生日</Col>
                    <Col span={4}>
                        <DatePicker className="zhDatePicker"/>
                    </Col>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" className='zhButton'>添加</Button>
                    <Button type="primary" className='zhButton'>重置</Button>
                    <Button type="primary" className='zhButton' onClick={() => this.pay()}>支付</Button>
                    <Button type="primary" className='zhButton' onClick={() => this.orderquery()}>查询</Button>
                    <Button key='cancel' className="buttonWhiteStyle" onClick={() => this.cancel()}>取消</Button>
                </Row>
                <Modal
                    title="获取位置" 
                    maskClosable={false}
                    visible={this.state.flag}
                    onCancel={() => this.cancel()}
                    footer={[
                        <Button key='find' type="primary" className='zhButton' onClick={() => this.orderquery()}>查询</Button>,
                        <Button key='cancel' className="buttonWhiteStyle" onClick={() => this.cancel()}>取消</Button>
                    ]}
                >
                    <div>微信支付</div>
                    <img src={"data:image/png;base64," + this.state.qrCode} style={{ width: 200, height: 200}}/>
                </Modal>
            </div>
        );
    }

}