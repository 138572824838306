import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './sysFunc.css';

const confirm = Modal.confirm;
export default class SysFunc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            searchModel: { pageSize: 10, page: 1, },
            funcList: []
        }
        this.init()
    }

    init() {
        this.search();
        this.searchFunc();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "sysFunc/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchFunc() {
        let model = { funcType: "FUNC" }
        fetch(Config.apiBackServer + "sysFunc/searchFunc", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    funcList: data.object,
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { activeInd: "Y" },
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: this.state.selectedRows[0]
        })
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "sysFunc/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    see() {

    }

    save() {
        let url = "";
        let msg = this.check()
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "sysFunc/insert";
        } else {
            url = "sysFunc/update"
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.funcName == null || model.segment === '') {
            msg += "模块名称不能为空;"
        }
        if (model.funcType == null || model.funcType === '') {
            msg += "模块类型不能为空;"
        }
        if (model.funcType === "FUNC") {
            if (model.dispSeqLevel1 == null) {
                msg += "一级模块序号不能为空;"
            }
        } else if (model.funcType === "MENU") {
            if (model.level1Id == null) {
                msg += "一级模块不能为空;"
            }
            if (model.dispSeqLevel2 == null) {
                msg += "二级模块序号不能为空;"
            }
        }
        if (model.activeInd == null || model.activeInd === '') {
            msg += "状态不能为空;"
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        if ("funcType" === k) {
            if ("FUNC" === e) {
                newModel.dispSeqLevel2 = 0
                newModel.level1Id = null
                newModel.dispSeqLevel1 = null
            } else if ("MENU" === e) {
                newModel.dispSeqLevel2 = null
                this.searchFunc();
            }
        } else if ("level1Id" === k) {
            for (let item of this.state.funcList) {
                if (item.sysFuncId === e) {
                    newModel.dispSeqLevel1 = item.dispSeqLevel1
                }
            }
        }
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '模块名称',
                dataIndex: 'funcName',
                className: "tableWidth20",
            },
            {
                title: '类型',
                dataIndex: 'funcType',
                className: "tableWidth20",
                render: (text) => ContextHandler.getMapValue('funcTypeMap', text)
            },
            {
                title: '一级序号',
                dataIndex: 'dispSeqLevel1',
                className: "tableWidth15 tableRightAlignment",
            },
            {
                title: '二级序号',
                dataIndex: 'dispSeqLevel2',
                className: "tableWidth15 tableRightAlignment",
            },
            {
                title: '路由地址',
                dataIndex: 'routeUrl',
                className: "tableWidth15",
            },
            {
                title: '激活',
                dataIndex: 'activeInd',
                className: "tableWidth15",
                render: (text) => ContextHandler.getIndValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">模块管理</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">模块名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="模块名称"
                                            value={this.state.searchModel.funcName || ''}
                                            onChange={(e) => this.searchChange('funcName', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="sysFuncId"
                                        bordered={true} //边线
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        columns={columns} //列名
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'sysFuncId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>模块名称<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={30}
                                            placeholder="模块名称"
                                            value={this.state.model.funcName || ''}
                                            onChange={(e) => this.modelChange('funcName', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>类型<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.funcType || ''}
                                            onChange={(e) => this.modelChange('funcType', e)} >
                                            {context.funcTypeMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                </Row>
                                {this.state.model.funcType === "MENU" ?
                                    <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>一级模块<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    className="zhSelect"
                                                    value={this.state.model.level1Id || ''}
                                                    onChange={(e) => this.modelChange('level1Id', e)} >
                                                    <Option value=""></Option>
                                                    {this.state.funcList.map((item) => (<Option value={item.sysFuncId}>{item.funcName}</Option>))}
                                                </Select>
                                            </Col>
                                            <Col span={4} className='zhTxt'>二级序号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <InputNumber className="zhInputNumber" maxLength={9}
                                                    min={1}
                                                    precision={0}
                                                    placeholder="二级序号"
                                                    value={this.state.model.dispSeqLevel2 || ''}
                                                    onChange={(e) => this.modelChange('dispSeqLevel2', e)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    className="zhSelect"
                                                    value={this.state.model.activeInd || ''}
                                                    onChange={(e) => this.modelChange('activeInd', e)} >
                                                    <Option value={"Y"}>是</Option>
                                                    <Option value={"N"}>否</Option>
                                                </Select>
                                            </Col>
                                            <Col span={4} className='zhTxt'>路由地址：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={200}
                                                    placeholder="路由地址"
                                                    value={this.state.model.routeUrl || ''}
                                                    onChange={(e) => this.modelChange('routeUrl', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>一级序号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <InputNumber className="zhInputNumber" maxLength={9}
                                                    min={1}
                                                    precision={0}
                                                    placeholder="一级序号"
                                                    value={this.state.model.dispSeqLevel1 || ''}
                                                    onChange={(e) => this.modelChange('dispSeqLevel1', e)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select
                                                    className="zhSelect"
                                                    value={this.state.model.activeInd || ''}
                                                    onChange={(e) => this.modelChange('activeInd', e)} >
                                                    <Option value={"Y"}>是</Option>
                                                    <Option value={"N"}>否</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>路由地址：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={200}
                                                    placeholder="路由地址"
                                                    value={this.state.model.routeUrl || ''}
                                                    onChange={(e) => this.modelChange('routeUrl', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}