import React, { Component } from 'react';
import PropTypes from 'prop-types';//props传参检查库
import { Row, Col, Form, InputNumber,  Input, Button, Spin, message } from 'antd';

import {Config} from '../../config'
import { Common } from '../../public/js/common';

import './forgetPwd.css';

const FormItem = Form.Item;
message.config({top:'35%'});//设置提示消息距离顶部的位置

export default class ForgetPwd extends Component {

    constructor(props){
        super(props);
        this.state = {
             loading : false
            ,model : {
                 phone : null
                ,password : null
                ,phoneVerificationCode : null
                ,validationType : 'NOT_EXIST'//验证类型，不存在则报错
            }
            ,time : 60//获取验证码倒计时
            ,getVerifCde : false//控制获取验证码按钮disabled
        };
    }

    /**
     * 提交事件，ant插件自动校验
     */
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
          if (!err) {
          }
        });
    }

    render() {
        // ant插件自动校验处理
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="forgetPwd">
                <Spin spinning={this.state.loading}>
                    <Row className="forgetPwdRoot"
                        style={{marginTop: Config.equipmentType === 'mobile' ? '45%' : '15%'}}// 根据不同的访问设备设置margin
                    >
                        <h1>找回密码</h1>
                        <Row className="forgetPwdFormInputWidth">
                            <Form onSubmit={this.handleSubmit} >
                                <Row>
                                    <Form.Item>
                                        {getFieldDecorator('phone', {
                                            rules: [{ required: true, message: '手机号不能为空!' }],
                                            initialValue: this.state.model.phone || null,
                                        })(
                                            <InputNumber 
                                                maxLength={11}
                                                min={1} max={99999999999}
                                                className="forgetPwdPhoneInput" 
                                                placeholder="手机号" 
                                                onChange={(e)=>this.inputNbrChange(e,'phone')}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: '密码不能为空!' }],
                                            initialValue: this.state.model.password || null,
                                        })(
                                            <Input 
                                                className="forgetPwdPasswordInput"
                                                placeholder="新密码" 
                                                type="password" 
                                                onChange={(e)=>this.inputChange(e,'password')}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        {getFieldDecorator('phoneVerificationCode', {
                                            rules: [{ required: true, message: '验证码不能为空!' }],
                                            initialValue: this.state.model.phoneVerificationCode || null,
                                        })(
                                            <Input
                                                className="forgetPwdVerificationCodeInput"
                                                placeholder="验证码"
                                                maxLength={4}
                                                onChange={(e)=>this.inputChange(e,'phoneVerificationCode')}
                                            />
                                        )}
                                        <Button type="primary" className="forgetPwdVerification"
                                            onClick={() => this.getVerificationCode()}
                                            loading={this.state.time !== 60 || this.state.getVerifCde} 
                                            disabled={this.state.time !== 60 || this.state.getVerifCde}
                                        >
                                            {this.state.time === 60 ? '获取验证码' : this.state.time + "秒"}
                                        </Button>
                                    </Form.Item>
                                </Row>
                                <FormItem>
                                    <Button type="primary" htmlType="submit" className="forgetPwdButton"
                                        onClick={() => {this.forgetPwd()}} >
                                        确定修改
                                    </Button>
                                </FormItem>
                            </Form>
                            <a onClick={() => this.props.history.replace('/login')}>返回登录</a>
                        </Row>
                    </Row>
                </Spin>
            </div>
        );
    }

    /**
     * 获取验证码
     */
    getVerificationCode(){
        if(Common.isEmpty(this.state.model.phone)){
            message.error('手机号不能为空'); return;
        } else if(this.state.model.phone.toString().length !== 11){
            message.error('非法的手机号'); return;
        }
        let checkDB = 0;
        let isExistError = 0;
        fetch(Config.apiServer + "common/getPhoneVerificationCode/"+checkDB+"/"+isExistError, {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: window.headers,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                message.success('验证码发送成功');
                let interval = setInterval(() => {
                    let time = this.state.time - 1;
                    if (this.state.time === 0) {
                        time = 60;
                        clearInterval(interval);
                        this.setState({ time, getVerifCde: false });
                    }else{
                        this.setState({ time, getVerifCde: true });
                    }
                }, 1000);
            } else {
                message.error(data.msg);
                this.setState({ getVerifCde: false });
            }
        }).catch(err => ({ err })).finally(res => {
            this.setState({ getVerifCde: false });
        });
    }

    /**
     * 修改密码
     */
    forgetPwd(){
        let error = false;
        if (Common.isEmpty(this.state.model.phone)) {
            message.error('手机号不能为空');
            error = true;
        } else if (this.state.model.phone.toString().length !== 11) {
            message.error('非法的手机号');
            error = true;
        }
        if (Common.isEmpty(this.state.model.phoneVerificationCode)) {
            message.error('验证码不能为空');
            error = true;
        } else if (this.state.model.phoneVerificationCode.toString().length !== 4) {
            message.error('错误的验证码');
            error = true;
        }
        if (error) {
            return;
        }
        this.setState({ loading: true });
        fetch(Config.apiServer + "common/updatePwd", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: window.headers,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                message.success('修改成功');
                this.props.history.push('/login');
            } else {
                message.error(data.msg);
            }
        }).catch(err => ({ err })).finally(res => {
            this.setState({ loading: false });
        });
    }
    
    /**
     * input数据双向绑定
     * @param {修改之后的数据源} event 
     * @param {属性名} att 
     */
    inputChange(event,att){
        this.inputNbrChange(event.target.value,att);
    }

    /**
     * input数据双向绑定
     * @param {修改之后的数据源} event 
     * @param {属性名} att 
     */
    inputNbrChange(value,att){
        if(att === "phoneVerificationCode"){
            if(!/^[0-9]*$/.test(value)){
                return;
            }
        }
        let { model } = this.state;
        model[att] = value;
        this.setState({ model });
    }

    /**
     * 消息内容换行处理
     * @param {消息内容} content 
     */
    contentHandler(content) {
        let arr = content.split(';');
        if (arr.length > 1) {
            let index = 1;
            content = '';
            arr.map((item) => {
                if (item.trim() !== '') {
                content += index++ + '.' + item + '<br/>'
                }
            });
        }
        return content;
    }
}

ForgetPwd = Form.create({})(ForgetPwd);

/**
 * props参数检查
 */
ForgetPwd.propTypes = {
    jump : PropTypes.func
};