import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import './membershipRenewal.css';

const confirm = Modal.confirm;
var css;
export default class MembershipRenewal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            searchModel: { pageSize: 10, page: 1, },
            searchShopList: [],
            shopFlag: false,
            renewalList: []
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchByName", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list
            }, () => {
                let model = { shopId: this.state.searchShopList[0].shopId }
                this.setState({
                    model: model
                })
            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "membershipRenewal/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    add() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;


        fetch(Config.apiBackServer + "membershipRenewal/searchRenewal", {//续费查询,查询出最新一条续费记录
            method: 'POST',
            body: JSON.stringify(that.state.selectedRows[0]),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let shopFlag = true;

                if (data.object[0].endDate > new Date()) {
                    shopFlag = true
                } else {
                    shopFlag = false
                }
                var d1 = new Date(data.object[0].endDate);
                d1.setFullYear(d1.getFullYear() + 1);
                let model = data.object[0]
                model.transactionType = "CASH"
                this.setState({
                    model: model,
                    shopFlag: shopFlag,
                    tabIndex: "2",
                    cardLimit: this.state.selectedRows[0].cardLimit,
                    endDate: d1
                })
                this.editSearch()
                this.settlementSearch()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }

        }).catch(err => { });



    }

    /** 
 * 获取下一个月 
 * 
 * @date 格式为yyyy-mm-dd的日期，如：2014-01-25 
 */
    getNextMonth(date) {
        var arr = date.split('-');
        var year = arr[0]; //获取当前日期的年份  
        var month = arr[1]; //获取当前日期的月份  
        var day = arr[2]; //获取当前日期的日  
        var days = new Date(year, month, 0);
        days = days.getDate(); //获取当前日期中的月的天数  
        var year2 = year;
        var month2 = parseInt(month) + 1;
        if (month2 == 13) {
            year2 = parseInt(year2) + 1;
            month2 = 1;
        }
        var day2 = day;
        var days2 = new Date(year2, month2, 0);
        days2 = days2.getDate();
        if (day2 > days2) {
            day2 = days2;
        }
        if (month2 < 10) {
            month2 = '0' + month2;
        }

        var t2 = year2 + '-' + month2 + '-' + day2;
        return t2;
    }

    save() {
        let that = this;
        fetch(Config.apiBackServer + "membershipRenewal/renewal", {
            method: 'POST',
            body: JSON.stringify(that.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("会员卡续费成功", "");
                this.cancel()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });

    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryTabIndex: "1",
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            this.setState({
                secondaryTabIndex: value,
            })
        } else {
            if (value === "2") {
                this.add();
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '金卡卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth11',
            },
            {
                title: '金卡开始日期',
                dataIndex: 'openDate',
                className: 'tableWidth10',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '金卡结束日期',
                dataIndex: 'endDate',
                className: 'tableWidth10',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth11',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                className: 'tableWidth11',
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: 'tableWidth10',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getActiveValue(text)
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            },
            {
                title: '续卡员',
                dataIndex: 'cardRenewalOfficer',
                className: 'tableWidth10',
            },
            {
                title: '续卡员电话',
                dataIndex: 'phoneOfficer',
                className: 'tableWidth11',
            },
        ];
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth20',
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: 'tableWidth20',
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: 'tableWidth20',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: 'tableWidth30',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth20',
                render: (text) => ContextHandler.getActiveValue(text)
            },
        ];
        const renewalColumns = [
            {
                title: '金卡卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '金卡开始日期',
                dataIndex: 'openDate',
                className: 'tableWidth10',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '金卡结束日期',
                dataIndex: 'endDate',
                className: 'tableWidth10',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '缴费金额',
                dataIndex: 'paymentAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text)
            },
            {
                title: '缴费时间',
                dataIndex: 'createdDate',
                className: 'tableWidth15',
                render: (text) => text == null ? null : Common.dateFormat(text, "yyyy-MM-dd hh:mm:ss"),
            },
            {
                title: '交易方式',
                dataIndex: 'transactionType',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth15',
            },
            {
                title: '续卡员',
                dataIndex: 'cardRenewalOfficer',
                className: 'tableWidth10',
            },
            {
                title: '续卡员电话',
                dataIndex: 'phoneOfficer',
                className: 'tableWidth10',
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">金卡续费</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">开卡店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        {/* <Select style={{ width: "100%" }} placeholder="开卡店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <option key="" value="">全部</option>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select> */}
                                        <Input maxLength={20}
                                            placeholder="开卡店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">金卡卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="金卡卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            type="phone"
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">是否绑定用户：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.binding || ''}
                                            onChange={(e) => this.searchChange('binding', e)} >
                                            <Option value={""}>全部</Option>
                                            {context.bindingMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">金卡日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("openDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="至日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">续费</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>开卡店铺名称<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select style={{ width: "100%" }} placeholder="开卡店铺名称" value={this.state.model.shopId || ''}
                                            disabled={this.state.shopFlag}
                                            onChange={(e) => this.modelChange('shopId', e)}>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员开始日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="会员开始日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员结束日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="会员结束日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.endDate == null ? null : moment(new Date(this.state.endDate), Config.dateFormat)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.transactionType || ''}
                                            disabled={this.state.allowEditing}
                                            onChange={(e) => this.modelChange('transactionType', e)}>
                                            {context.transactionTypeList.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                                        </Select>
                                    </Col>
                                    <Col span={4} className='zhTxt'>缴费金额<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={8}
                                            placeholder="缴费金额"
                                            disabled
                                            value={this.state.model.paymentAmount || ''} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>金卡卡号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={10}
                                            disabled
                                            placeholder="金卡卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>预留手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={11}
                                            placeholder="预留手机号"
                                            value={this.state.model.reservedIdCard || ''}
                                            onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>续卡员：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={30}
                                            placeholder="续卡员"
                                            value={this.state.model.cardRenewalOfficer || ''}
                                            onChange={(e) => this.modelChange('cardRenewalOfficer', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>续卡员电话：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={11}
                                            placeholder="续卡员电话"
                                            value={this.state.model.phoneOfficer || ''}
                                            onChange={(e) => this.modelChange('phoneOfficer', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                        <TabPane tab="缴费记录" key="1">
                                            <Row className='zhRow'></Row>
                                            <Table rowKey="id"
                                                bordered={true} //边线
                                                columns={renewalColumns} //列名
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                dataSource={this.state.renewalList} //数据
                                                pagination={false}
                                                rowClassName="tableColor"
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Row>
                            </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    settlementSearch() {
        this.setState({ isLoading: true })
        let model = { membershipCardId: this.state.model.membershipCardId, pageSize: 50, page: 1, }
        fetch(Config.apiBackServer + "membershipRenewal/settlementSearchByCardId", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    renewalList: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));

    }
}