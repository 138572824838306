import React, { Component } from 'react';
import { Upload, Icon, Button, Row, Col, Tabs, Table, Input, Select, Modal } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import SelectShop from './../selectShop/selectShop';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import './sysUser.css';

const confirm = Modal.confirm;
export default class SysUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            sysRoleTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            sysRoleSelectedRowKeys: [],
            sysRoleSelectedRows: [],
            model: {},
            tables: [],
            sysRoleModel: {},
            sysRoleTables: [],
            searchModel: { pageSize: 10, page: 1, },
            imageFile: [],
            ImageNum: 1,
            previewVisible: false,
            sysRoleList: [],
            searchShopList: [],
            shopFlag: false,
            isLoading: false,
            isShop: false,
            passWordModel: {},
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list
            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "user/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, phone: '', nickName: '', identity: '' },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    onSysRoleSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            sysRoleSelectedRowKeys: selectedRowKeys,
            sysRoleSelectedRows: selectedRows
        })
    }

    add() {

        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { status: "NORMAL", identity: "HEAD_USER", sex: "HIDE" },
            shopFlag: true,
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        var img = model.headPortrait;
        let file = [{ uid: '-1', }]
        if (Common.isNotEmpty(img) && img.indexOf('http') != -1) {
            file[0].url = img;
            file[0].name = img;
        } else if (Common.isNotEmpty(img) && img.indexOf('http') == -1) {
            file[0].url = Config.headPortraitImgs + img;
            file[0].name = img;
        } else {
            file = []
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "user/searchSysUserRole", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let isShop = false
                for (let item of data.object) {
                    if (item.sysRoleCode === "STORE_RESPONSIBLE" || item.sysRoleCode === "CASHIER") {
                        isShop = true
                    }
                }
                this.setState({
                    tabIndex: "2",
                    flag: "edit",
                    model: model,
                    imageFile: file,
                    isShop: isShop,
                    sysRoleTables: data.object,
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));

    }

    editPassword() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let passWordModel = this.state.selectedRows[0];
        fetch(Config.apiBackServer + "user/searchSysUserRole", {
            method: 'POST',
            body: JSON.stringify(passWordModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (passWordModel.identity === "CASHIER") {
                    this.setState({
                        tabIndex: "3",
                        flag: "editPassword",
                        passWordModel: passWordModel,
                        sysRoleTables: data.object,
                        shopFlag: false,
                    })
                } else {
                    this.setState({
                        tabIndex: "3",
                        flag: "editPassword",
                        passWordModel: passWordModel,
                        sysRoleTables: data.object,
                        shopFlag: true,
                    })
                }

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "user/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    see() {

    }

    save() {
        let url = "";
        let msg = this.check()
        this.obtainImgData();
        let model = this.state.model
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "user/insert";
        } else {
            url = "user/update"
        }
        model.sysUserRoleList = this.state.sysRoleTables
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.phone == null || model.phone === '') {
            msg += "手机号不能为空;"
        }
        if (this.state.flag === "add") {
            if (model.password == null || model.password === '') {
                msg += "密码不能为空;"
            }
        }
        if (model.sex == null || model.sex === '') {
            msg += "性别不能为空;"
        }
        // if (model.identity == null || model.identity === '') {
        //     msg += "身份不能为空;"
        // }
        if (model.status == null || model.status === '') {
            msg += "状态不能为空;"
        }
        if (Common.isNotEmpty(this.state.sysRoleTables)) {
            for (let item of this.state.sysRoleTables) {
                if (item.sysRoleCode === "STORE_RESPONSIBLE" || item.sysRoleCode === "CASHIER") {
                    if (!model.shopId) {
                        msg += "店铺不能为空;"
                    }
                }
            }
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        let newshopFlag = this.state.shopFlag;
        if ((k === "phone") && e.length < 12) {
            newModel[k] = e.replace(/[^\d.]/g, "");
        } else if (k !== "phone") {
            newModel[k] = e;
        }
        if (k === "identity") {
            if ("CASHIER" === e || "STORE_CHARGE" === e) {
                newshopFlag = false;
            } else {
                newshopFlag = true;
                newModel.shopId = "";
            }
        }
        this.setState({
            model: newModel,
            shopFlag: newshopFlag,
        });
    }

    passWordModelChange(k, e) {
        let newModel = this.state.passWordModel;
        newModel[k] = e;
        this.setState({
            passWordModel: newModel
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            sysRoleTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            sysRoleSelectedRowKeys: [],
            sysRoleSelectedRows: [],
            imageFile: [],
            sysRoleTables: []
        })
        this.search()
    }

    tableChange(value, k) {
        if (k === "sysUser") {
            if (value === "2") {
                if (this.state.selectedRows.length === 1) {
                    this.edit();
                } else {
                    this.add();
                }

            } else if (value === "3") {
                this.editPassword();
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        } else {
            if (value === "2") {
                if (this.state.sysRoleSelectedRows.length === 1) {
                    this.sysRoleEdit();
                } else {
                    this.sysRoleAdd();
                }
            } else {
                this.sysRoleCancel();

            }
        }
    }

    obtainImgData() {
        let model = this.state.model;
        let imageFile = this.state.imageFile;
        if (imageFile.length !== 0) {
            for (let i in imageFile) {
                if (Common.isEmpty(imageFile[i].url)) {
                    model.headPortrait = imageFile[i].thumbUrl;
                } else {
                    model.headPortrait = imageFile[i].name;
                }
            }
        } else {
            model.headPortrait = null;
        }
        this.setState({
            model: model
        })
    }

    /**关闭图片弹窗 */
    handleCancel = () => this.setState({ previewVisible: false })

    /**查看图片 */
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    /**图片改变事件 */
    handleChange(file) {
        this.setState({
            imageFile: file.fileList
        })
    }

    savePassword() {
        let msg = this.passwordChack()
        if (msg) {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.passWordModel
        model.sysUserRoleList = this.state.sysRoleTables
        fetch(Config.apiBackServer + "user/update", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    passwordChack() {
        let model = this.state.passWordModel
        let msg = ""
        if (!model.newPassWord) {
            msg += "新密码不能为空;"
        }
        if (!model.password) {
            msg += "确认密码不能为空;"
        }
        if (model.password && model.newPassWord) {
            if (model.password !== model.newPassWord) {
                msg += "新密码和确认密码不一致;"
            }
        }
        return msg
    }

    selectShopPopup = (ref) => {
        this.selectShopPopup = ref;
    }

    closePopup() {
        let shopModel = this.selectShopPopup.state.selectedRows[0]
        let model = this.state.model
        model.shopId = shopModel.shopId
        model.shopName = shopModel.shopName
        this.setState({
            model: model
        })
    }

    openPopup() {
        this.selectShopPopup.open()
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>设置头像</div>
            </div>
        );
        const columns = [
            {
                title: '手机号',
                dataIndex: 'phone',
                className: "tableWidth20",
            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                className: "tableWidth20",
            },
            {
                title: '性别',
                dataIndex: 'sex',
                className: "tableWidth20",
                render: (text) => ContextHandler.getMapValue('sexMap', text)
            },
            {
                title: '角色',
                dataIndex: 'identity',
                className: "tableWidth20",
                render: (text, item) =>
                    Common.isNotEmpty(item.sysRoleCode) ? item.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部负责人" :
                        (item.sysRoleCode.indexOf("HEAD_STAFF") > -1 ? "总部员工" :
                            (item.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺负责人" :
                                (item.sysRoleCode.indexOf("CASHIER") > -1 ? "收银员" : "会员"))) : "会员"

                // ContextHandler.getMapValue('userTypeMap', text)
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: "tableWidth20",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ];
        const sysRoleColumns = [
            {
                title: '角色名称',
                dataIndex: 'sysRoleName',
                className: "tableWidth34",
            },
            {
                title: '角色编号',
                dataIndex: 'sysRoleId',
                className: "tableWidth33",
            },
            {
                title: '激活',
                dataIndex: 'activeInd',
                className: "tableWidth33",
                render: (text) => ContextHandler.getIndValue(text)
            },
        ]
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const sysRoleRowSelection = {
            selectedRowKeys: this.state.sysRoleSelectedRowKeys,
            onChange: this.onSysRoleSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysParam">
                <div className="zhRoot">
                    <div className="zhTitle">用户管理</div>
                    <div className="content">
                        <SelectShop onRef={this.selectShopPopup} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value, "sysUser")}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">昵称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="昵称"
                                            value={this.state.searchModel.nickName || ''}
                                            onChange={(e) => this.searchChange('nickName', e.target.value)} />
                                    </Col>
                                    <Col span={2} className='zhTxt'>角色：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.sysRoleCode || ''}
                                            onChange={(e) => this.searchChange('sysRoleCode', e)} >
                                            {context.sysRoleMap.map((item) =>
                                                <Option value={item.key}>{item.value}</Option>
                                            )}
                                            {/* <Option value={"HEAD_USER"}>总部负责人</Option>
                                            <Option value={"ADMINISTRATORS"}>总部员工</Option>
                                            <Option value={"STORE_CHARGE"}>店铺负责人</Option>
                                            <Option value={"CASHIER"}>收银员</Option>
                                            <Option value={"MENBER"}>会员</Option> */}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.editPassword()} icon="edit">修改密码</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="userId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'userId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>手机号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={11}
                                            placeholder="手机号"
                                            value={this.state.model.phone || ''}
                                            onChange={(e) => this.modelChange('phone', e.target.value)} />
                                    </Col>
                                    {this.state.flag === "add" ? <>
                                        <Col span={4} className='zhTxt'>密码<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input.Password maxLength={16}
                                                placeholder="密码"
                                                value={this.state.model.password || ''}
                                                onChange={(e) => this.modelChange('password', e.target.value)} />
                                        </Col>
                                    </> : null}
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>昵称：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={12}
                                            placeholder="昵称"
                                            value={this.state.model.nickName || ''}
                                            onChange={(e) => this.modelChange('nickName', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>性别<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.sex || ''}
                                            onChange={(e) => this.modelChange('sex', e)} >
                                            {context.sexMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col>

                                </Row>
                                <Row className='row'>
                                    {/* <Col span={4} className='zhTxt'>身份<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.identity || ''}
                                            onChange={(e) => this.modelChange('identity', e)} >
                                            {context.userTypeMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>
                                    </Col> */}
                                    <Col span={4} className='zhTxt'>店铺名称{this.state.isShop ? <span className='redStar'>*</span> : ""}：</Col>
                                    <Col span={4} className='zhInput'>
                                        <Input
                                            placeholder="店铺名称"
                                            disabled
                                            value={this.state.model.shopName || ''} />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                        <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                    </Col>
                                    <Col span={4} className='zhTxt'>状态<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.status || ''}
                                            onChange={(e) => this.modelChange('status', e)} >
                                            <Option value={"NORMAL"}>激活</Option>
                                            <Option value={"FROZEN"}>冻结</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                {/* <Row className='row' hidden={this.state.shopFlag}>
                                    <Col hidden={this.state.shopFlag} span={4} className='zhTxt'>店铺名称<span className='redStar'>*</span>：</Col>
                                    <Col hidden={this.state.shopFlag} span={4} className='zhInput'>
                                        <Input
                                            placeholder="店铺名称"
                                            disabled
                                            value={this.state.model.shopName || ''} />
                                    </Col>
                                    <Col span={2} hidden={this.state.shopFlag} style={{ marginTop: "5px" }} >
                                        <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                    </Col>
                                </Row> */}
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>头像：</Col>
                                    <Col span={6} className='imgUpload zhInput'>
                                        <Upload
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            action={Config.apiBackServer + "test/test"}
                                            listType="picture-card"
                                            fileList={this.state.imageFile}
                                            onPreview={this.handlePreview}
                                            onChange={(file) => this.handleChange(file)}>
                                            {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>

                                <Row className='zhRow'>
                                    <Tabs defaultActiveKey='1' activeKey={this.state.sysRoleTabIndex} onChange={(value) => this.tableChange(value, "sysRole")}>
                                        <TabPane tab="角色列表" key="1">
                                            <Row className='zhRow'>
                                                <Button type="primary" onClick={() => this.sysRoleAdd()} icon="plus">添加</Button>
                                                {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.sysRoleEdit()} icon="edit">修改</Button> */}
                                                <Button className="zhButtonLeft" type="primary" onClick={() => this.sysRoleDelete()} icon="delete">删除</Button>
                                            </Row>
                                            <Row className='zhRow'>
                                                <Table rowSelection={sysRoleRowSelection}
                                                    rowKey="sysRoleId"
                                                    bordered={true} //边线
                                                    columns={sysRoleColumns} //列名
                                                    loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                    dataSource={this.state.sysRoleTables} //数据
                                                    pagination={false}
                                                    rowClassName="tableColor"
                                                    onRow={(record) => {
                                                        return {
                                                            onClick: event => Common.selectRadioRow.call(this, record, 'sysRoleId', 'sysRoleSelectedRows', 'sysRoleSelectedRowKeys'), // 点击行
                                                        };
                                                    }}
                                                />
                                            </Row>
                                        </TabPane>
                                        <TabPane tab="角色详情" key="2">
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>角色<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        className="zhSelect"
                                                        value={this.state.sysRoleModel.sysRoleId || ''}
                                                        onChange={(e) => this.sysRoleModelChange('sysRoleId', e)} >
                                                        {this.state.sysRoleList.map((item) => <Option key={item.sysRoleId} value={item.sysRoleId}>{item.sysRoleName}</Option>)}
                                                    </Select>
                                                </Col>
                                                <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select
                                                        className="zhSelect"
                                                        value={this.state.sysRoleModel.activeInd || ''}
                                                        onChange={(e) => this.sysRoleModelChange('activeInd', e)} >
                                                        {context.inds.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                                    </Select>
                                                </Col>
                                            </Row>
                                            <Row className='zhRow zhRowButtonRight'>
                                                <Button type="primary" icon={"check-circle"} onClick={() => { this.sysRoleSave() }}>保存</Button>
                                                <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.sysRoleCancel() }}>取消</Button>
                                            </Row>
                                        </TabPane>
                                    </Tabs>
                                </Row>
                                {this.state.tabIndex === "2" && this.state.sysRoleTabIndex === "2" ? null :
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                }
                            </TabPane>
                            <TabPane tab="修改密码" key="3">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>手机号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={11}
                                            placeholder="手机号"
                                            disabled
                                            value={this.state.passWordModel.phone || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>昵称：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={12}
                                            placeholder="昵称"
                                            disabled
                                            value={this.state.passWordModel.nickName || ''} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>新密码<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password maxLength={16}
                                            placeholder="密码"
                                            value={this.state.passWordModel.newPassWord || ''}
                                            onChange={(e) => this.passWordModelChange('newPassWord', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>确认密码<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password maxLength={16}
                                            placeholder="密码"
                                            value={this.state.passWordModel.password || ''}
                                            onChange={(e) => this.passWordModelChange('password', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.savePassword() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    sysRoleModelChange(k, e) {
        let newModel = this.state.sysRoleModel;
        if (k === "sysRoleId") {
            this.state.sysRoleList.map((item) => {
                if (e === item.sysRoleId) {
                    newModel.sysRoleId = item.sysRoleId
                    newModel.sysRoleName = item.sysRoleName
                    newModel.sysRoleCode = item.sysRoleCode
                    if (this.state.sysRoleFlag === "edit") {
                        newModel.isChange = true
                    }
                }
                return 1;
            })
        } else {
            newModel[k] = e;
        }
        this.setState({
            sysRoleModel: newModel,

        });
    }

    sysroleSearch() {
        fetch(Config.apiBackServer + "sysRole/searchAll", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    sysRoleList: data.object
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }

    sysRoleAdd() {
        this.sysroleSearch()
        for (let item of this.state.sysRoleTables) {
            if (item.sysRoleCode === "CASHIER" || item.sysRoleCode === "STORE_RESPONSIBLE") {
                Message.openMsgError("错误", "店铺负责人和收银员只能拥有一个角色")
                return
            }
        }
        this.setState({
            sysRoleFlag: "add",
            sysRoleTabIndex: "2",
            sysRoleModel: { activeInd: "Y" }
        })
    }

    sysRoleEdit() {
        this.sysroleSearch()
        if (this.state.sysRoleSelectedRows == null || this.state.sysRoleSelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let list = this.state.sysRoleTables
        let selecteIndex = 0;
        for (let index = 0; index < list.length; index++) {
            if (list[index].sysRoleId === this.state.sysRoleSelectedRows[0].sysRoleId) {
                selecteIndex = index
            }
        }
        this.setState({
            sysRoleTabIndex: "2",
            sysRoleFlag: "edit",
            selecteIndex: selecteIndex,
            sysRoleModel: JSON.parse(JSON.stringify(this.state.sysRoleSelectedRows[0])),
            recordModel: JSON.parse(JSON.stringify(this.state.sysRoleSelectedRows[0]))
        })
    }

    sysRoleDelete() {
        if (this.state.sysRoleSelectedRows == null || this.state.sysRoleSelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let list = this.state.sysRoleTables
        for (let index = list.length - 1; index >= 0; index--) {
            if (list[index].sysRoleId === this.state.sysRoleSelectedRows[0].sysRoleId) {
                list.splice(index, 1)
                break
            }
        }
        this.setState({
            sysRoleTables: list
        })
        this.sysRoleCancel()
    }

    sysRoleSave() {
        if (this.state.sysRoleModel.sysRoleId == null) {
            Message.openMsgError("错误", "角色不能为空")
            return
        }
        let list = this.state.sysRoleTables
        let coun = 0;
        for (let item of list) {
            if (item.sysRoleId === this.state.sysRoleModel.sysRoleId) {
                coun++
            }
        }
        if (this.state.sysRoleFlag === "add") {
            if (coun !== 0) {
                Message.openMsgError("错误", "角色重复")
                return
            }
            if (this.state.sysRoleModel.sysRoleCode === "CASHIER" || this.state.sysRoleModel.sysRoleCode === "STORE_RESPONSIBLE") {
                if (Common.isNotEmpty(this.state.sysRoleTables)) {
                    Message.openMsgError("错误", "店铺负责人和收银员只能拥有一个角色")
                    return
                }
                let model = this.state.model
                model.shopName = ""
                model.shopId = ""
                this.setState({
                    isShop: true,
                    model: model,
                })
            }
            if (this.state.sysRoleModel.sysRoleCode === "MEMBER") {
                this.setState({
                    isShop: false,
                })
            }
            list.push(this.state.sysRoleModel)
            if (this.state.sysRoleModel.sysRoleCode === "HEAD_RESPONSIBLE" || this.state.sysRoleModel.sysRoleCode === "HEAD_STAFF") {
                let shopModel = { }
                fetch(Config.apiBackServer + "shop/searchByName", {
                    method: 'POST',
                    body: JSON.stringify(shopModel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                    let model = this.state.model
                    if (Common.isNotEmpty(data.object.list)) {
                        model.shopName = data.object.list[0].shopName
                        model.shopId = data.object.list[0].shopId
                    }
                    this.setState({
                        model: model,
                        isShop: false,
                    })
                }).catch(err => { });
            }
        } else {
            if (this.state.sysRoleModel.isChange !== null && this.state.sysRoleModel.isChange) {
                if (this.state.sysRoleModel.sysRoleId !== this.state.recordModel.sysRoleId) {
                    if (coun !== 0) {
                        Message.openMsgError("错误", "角色重复")
                        return
                    }
                }
            }
            list[this.state.selecteIndex] = this.state.sysRoleModel
        }
        this.setState({
            sysRoleTables: list,
        })
        this.sysRoleCancel()
    }

    sysRoleCancel() {
        this.setState({
            sysRoleTabIndex: "1",
            sysRoleModel: {},
            sysRoleSelectedRowKeys: [],
            sysRoleSelectedRows: [],
            recordModel: [],
        })
    }
}