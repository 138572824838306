import { Row, Col, Table, Input, Modal, Button, AutoComplete} from 'antd';
import React, { Component } from 'react';
import { Common } from '../../public/js/common';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { ContextHandler } from '../../public/js/contextHandler';
/**
 * 店铺选择处理类
 */
export default class SelectShop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: { pageSize: 10, page: 1, code: 'Y' },
            tables: [],
            isLoading: false,

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount() {
        if (this.props.onRef !== null) {
            this.props.onRef(this);
            this.searchShop();
        }
    }
    open() {
        this.setState({
            visible: true,
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: { pageSize: 10, page: 1, code: 'Y' },
            tables: [],
            isLoading: false,
        })
    }
    search = () => {
        this.setState({ isLoading: true })
        let model = this.state.searchModel
        fetch(Config.apiBackServer + "shop/searchByName", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, shopName: "" },
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    /**
     * 关闭店铺弹窗
     */
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    handleOk = () => {
        if (this.state.selectedRows && this.state.selectedRows.length == 1) {
            this.setState({
                visible: false
            }, () => {
                this.props.closePopup()
            })
        } else {
            Message.openMsgError("错误", "请选择一条数据")
        }
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth20",
            },
            {
                title: '店铺类型',
                dataIndex: 'characteristicName',
                className: "tableWidth20",
            },
            {
                title: '店铺电话',
                dataIndex: 'shopPhone',
                className: "tableWidth20",
            },
            {
                title: '序号',
                dataIndex: 'dispSeq',
                className: "tableWidth20 tableRightAlignment",
            },
            {
                title: '激活',
                dataIndex: 'activeInd',
                className: "tableWidth20",
                render: (text) => ContextHandler.getIndValue(text)
            },
        ];
        return (
            <Modal title="店铺弹窗"
                className="zhRoot container"
                width='60%'
                centered={true}
                visible={this.state.visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >

                <Row className="row">
                    <Col span={2} className="zhTxt">店铺名称：</Col>
                    <Col span={3} className="zhInput">
                        <AutoComplete
                            dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                            style={{ width: '100%' }}
                            dataSource={this.state.dataSource}
                            value={this.state.searchModel.shopName || ''}
                            onSelect={(e) => this.onSelect(e)}
                            onChange={this.onChange}
                            onSearch={this.onSearch}
                            filterOption={true}     //是否根据输入项进行筛选。
                            placeholder="店铺名称"
                        />
                    </Col>
                </Row>
                <Row className="rowButton">
                    <Col className="zhRowButtonRight">
                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                    </Col>
                </Row>
                <Row className='zhRow'>
                    <Table rowSelection={rowSelection}
                        rowKey="shopId"
                        bordered={true} //边线
                        columns={columns} //列名
                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                        dataSource={this.state.tables} //数据
                        pagination={{  //分页
                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                            onChange: this.pageChange,            //上页下页或者跳页方法
                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                            total: this.state.searchModel.total,             //总共多少条数据
                            current: this.state.searchModel.page,         //当前页
                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                            defaultCurrent: 1,                               //默认当前页为1
                        }}
                        onRow={(record) => {
                            return {
                                onClick: event => Common.selectRadioRow.call(this, record, 'shopId', 'selectedRows', 'selectedRowKeys'), // 点击行
                            };
                        }}
                        rowClassName="tableColor"
                    />
                </Row>
            </Modal>)
    }
}