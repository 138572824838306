import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './myList.css';
import context from '../../public/js/context';

export default class MyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, },
            funcList: [],
            isLoading: true,
            allowEditing: false,
            value: 'CASH',
        }
        this.init()
    }

    init() {
        this.search();
    }


    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashier/searchMyList", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    save() {
        let msg = this.check();
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        fetch(Config.apiBackServer + "cashier/handleMyLsit", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });

    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.cardNumber == null || model.cardNumber === '') {
            msg += "会员卡号不能为空;"
        }
        if (model.footAmountStr == null || model.footAmountStr === '') {
            msg += "饭菜金额不能为空;"
        }
        return msg
    }
    //自动加上三天
    getNextDay(d, t) {
        console.log(d, t)//格式为---2019-02-13 3
        d = new Date(d);
        console.log(d)//格式为---Wed Feb 13 2019 08:00:00 GMT+0800 (中国标准时间)
        d = +d + (1000 * 60 * 60 * 24) * t;
        console.log(d)//格式为--时间戳1550275200000
        // d = new Date(d);
        // console.log(d)//格式为---Sat Feb 16 2019 08:00:00 GMT+0800 (中国标准时间)
        return new Date(d)   //格式为"2019-02-16 00:00:00"
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        model.cashierType = 'WECHAT';
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: model
        })


    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e.target.value;
        this.setState({
            model: newModel,
        });
    }

    //定义一个加法函数
    countSum(name, value) {
        let d = 3,//定义小数位的初始长度，默认为整数，即小数位为0
            sum = 0,//定义sum来接收所有数据的和
            //循环所有的参数

            m = Math.pow(10, d);
        if (name === "footAmount") {
            if (value != null && value !== "" && value !== undefined) {
                sum += value * m;
            }
            if (this.state.model.wineAmount != null && this.state.model.wineAmount !== "" && this.state.model.wineAmount !== undefined) {
                sum += this.state.model.wineAmount * m;
            }
        }

        if (name === "wineAmount") {
            if (value != null && value !== "" && value !== undefined) {
                sum += value * m;
            }
            if (this.state.model.footAmount != null && this.state.model.footAmount !== "" && this.state.model.footAmount !== undefined) {
                sum += this.state.model.footAmount * m;
            }
        }

        //返回结果
        return sum / m;

    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (Common.isNotEmpty(this.state.selectedRows) && this.state.selectedRows.length === 1) {
                this.edit();
            } 
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    onChangePitrue(e) {
        // this.setState({
        //     value: e,
        //   });
    }

    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth15",
            },
            {
                title: '挂单日期',
                dataIndex: 'cashierTime',
                className: "tableWidth20",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '店铺名称',
                className: "tableWidth15 tableRightAlignment",
                dataIndex: 'shopName',
            },
            {
                title: '饭菜金额',
                className: "tableWidth10 tableRightAlignment",
                dataIndex: 'footAmountStr',
            },
            {
                title: '酒水金额',
                className: "tableWidth10 tableRightAlignment",
                dataIndex: 'wineAmountStr',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth30",
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };

        const { TextArea } = Input;
        return (
            <div className="myList">
                <div className="cashier">
                    <div className="zhRoot">
                        <div className="zhTitle">挂单管理</div>
                        <div className="content">
                            <Tabs activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">挂单日期：</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.openCashierTime == null ? null : moment(new Date(this.state.searchModel.openCashierTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openCashierTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">至</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="至日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.endCashierTime == null ? null : moment(new Date(this.state.searchModel.endCashierTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endCashierTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            key="id"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            dataSource={this.state.tables} //数据
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={2} className='zhTxt'>会员卡号<span className='redStar'>*</span>：</Col>
                                        <Col span={5} className='zhInput'>
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                disabled
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={2} className='zhTxt'>饭菜金额<span className='redStar'>*</span>：</Col>
                                        <Col span={5} className='zhInput'>
                                            <InputNumber className="zhInputNumber" maxLength={9}
                                                min={0}
                                                precision={2}
                                                placeholder="饭菜金额"
                                                value={this.state.model.footAmountStr || ''}
                                                onChange={(e) => this.modelChange('footAmountStr', e)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={2} className='zhTxt'>酒水金额：</Col>
                                        <Col span={5} className='zhInput'>
                                            <InputNumber className="zhInputNumber" maxLength={9}
                                                min={0}
                                                precision={2}
                                                placeholder="酒水金额"
                                                value={this.state.model.wineAmountStr || ''}
                                                onChange={(e) => this.modelChange('wineAmountStr', e)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={2} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                        <Col span={5} className='zhInput'>
                                            <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.cashierType || ''}
                                                onChange={(e) => this.modelChange('cashierType', e)}>
                                                {context.transactionTypeList.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={2} className='zhTxt'>备注：</Col>
                                        <Col span={5} className='zhInput'>
                                            <TextArea className="zhInputNumber"
                                                maxLength={500}
                                                autoSize={{ minRows: 5, maxRows: 5 }}
                                                placeholder="备注"
                                                value={this.state.model.remark || ''}
                                                onChange={(e) => this.modelChange('remark', e)} />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button disabled={this.state.allowEditing} type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}