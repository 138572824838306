import React, { Component } from 'react';
import { Spin,  Button, Row, Col, Tabs, Table, Input, Modal, DatePicker , Icon} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import SelectRecommendCardModel from './selectRecommendCardModel';
import SelectCardNumberModel from './selectCardNumberModel'
import { ContextHandler } from '../../public/js/contextHandler';

const confirm = Modal.confirm;
const { TextArea } = Input;
export default class SuperAgencyManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isExport: false,
            searchModel: { pageSize: 10, page: 1},
            tabIndex: '1',
            isLoading: false,
            isAdd: false,
            model: {},
            selectedRowKeys: [],
            selectedRows: [],

            isMap: false,       // 控制获取位置弹窗
            mapModel: {},
            mapFlag: true,
            searchMapInput: "",
            previewImage: '',//选中图片的文件信息（Base64信息）
            previewImageName: '',//选择图片的名字
            model: {},
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "superAgencyManagement/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    tables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    add() {
        fetch(Config.apiBackServer + "superAgencyManagement/searchCardNumber", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let cardNumber = "";
            let cardTotal = "";
            if ("SUCCESS" === data.msg) {
                if(Common.isNotEmpty(data.object.cardNumber) && Common.isNotEmpty(data.object.cardTotal)){
                    cardNumber = data.object.cardNumber;
                    cardTotal = data.object.cardTotal;
                }
                this.setState({
                    tabIndex: "2",
                    flag: "add",
                    model: {
                        registrationDate: new Date(),
                        cardNumber : cardNumber,
                        cardTotal : cardTotal,
                    }
                })
            }
        }).catch(err => { });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length == 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tabIndex: value
            })
            this.cancel();
        }
    }

    editUpType() {
        this.setState({
            tabIndex: '2',
            flag: 'editUpType'   
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        this.setState({
            flag: "edit",
            tabIndex: '2',
            model: model,
        })
    }

    updateAgencyDemotion() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;
        confirm({
            title: "您确定删除?",
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let mdoel = {
                    id: that.state.selectedRows[0].id,
                    userId: that.state.selectedRows[0].userId,
                }
                fetch(Config.apiBackServer + "superAgencyManagement/updateAgencyDemotion", {
                    method: 'POST',
                    body: JSON.stringify(mdoel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("成功", "保存成功");
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
            onCancel() {},
        })
    }

    /**冻结 -N ,解冻 - Y */
    toFrozenOrThaw() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;
        confirm({
            title: this.state.selectedRows[0].status === "NORMAL" ? '您确定冻结?' : '您确定激活?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let status = "";
                if(that.state.selectedRows[0].status === "NORMAL"){
                    status = "FROZEN";
                }
                if(that.state.selectedRows[0].status === "FROZEN"){
                    status = "NORMAL";
                }
                if(Common.isEmpty(that.state.selectedRows[0].status)){
                    status = "FROZEN";
                }
                let mdoel = {
                    id: that.state.selectedRows[0].id,
                    userId: that.state.selectedRows[0].userId,
                    status: status,
                }
                fetch(Config.apiBackServer + "superAgencyManagement/toFrozenOrThaw", {
                    method: 'POST',
                    body: JSON.stringify(mdoel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("成功", "保存成功");
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
        });
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "superAgencyManagement/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "超级代理信息表" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, },
        })
        this.cancel();
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: false,
            model: {},
        })
        this.search()
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (!model.cardNumber) {
            msg += "超级代理卡号不能为空;"
        }
        if (!model.phone) {
            msg += "超级代理手机号不能为空;"
        }
        return msg
    }

    render() {
        const { TabPane } = Tabs;

        const columns = [
            {
                title: '超级代理卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth17',
            },
            {
                title: '超级代理手机号',
                dataIndex: 'phone',
                className: 'tableWidth17',
            },
            {
                title: '推荐人卡号',
                dataIndex: 'recommendCard',
                className: 'tableWidth17',
            },
            {
                title: '推荐人手机号',
                dataIndex: 'recommendCardPhone',
                className: 'tableWidth17',
            },
            {
                title: '注册日期',
                dataIndex: 'registrationDate',
                className: 'tableWidth17',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: 'tableWidth17',
                render: (text) => ContextHandler.getMapValue('statusMap', text)
          
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }

        return(
            <div className="superAgencyManagement">
               <div className="zhRoot">
                <Spin spinning={this.state.isExport} tip="下载中...">
                    <div className="zhTitle">超级代理管理</div>
                    <div className="content">
                        <SelectCardNumberModel stateRef ={this.state} onRef={this.selectCardNumberPopup} closePopup={() => this.closeCardNumbePopup()}/>
                        <SelectRecommendCardModel stateRef={this.state} onRef={this.selectRecommendCardPopup} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">超级代理卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="超级代理卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">超级代理手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="超级代理手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">注册日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="userRoyaltiesDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="userRoyaltiesDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">推荐人卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="推荐人卡号："
                                            value={this.state.searchModel.recommendCard || ''}
                                            onChange={(e) => this.searchChange('recommendCard', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">推荐人手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="推荐人手机号"
                                            value={this.state.searchModel.recommendCardPhone || ''}
                                            onChange={(e) => this.searchChange('recommendCardPhone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">注册超级代理</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.editUpType()} icon="plus">升级超级代理</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.updateAgencyDemotion()} icon="delete">删除超级代理</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toFrozenOrThaw()} icon="edit">冻结/激活</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出报表</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2" >
                                <Modal title="获取位置" maskClosable={false}
                                    width="50%" visible={this.state.isMap}
                                    onCancel={() => this.cancelMap(false)}
                                    onOk={() => this.cancelMap(true)}
                                >
                                    <div id="pickerBox">
                                        <input id="pickerInput" placeholder="输入关键字选取地点" onChange={(e) => { this.setState({ searchMapInput: e.target.value }) }} value={this.state.searchMapInput} />
                                            <div id="poiInfo"></div>
                                    </div>
                                    <div style={{ height: "500px", width: "100%" }} id="container"></div>
                                    <button style={{ display: "none" }} id="getadress" onClick={() => this.getadress()}></button>
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                {this.state.flag !== "editUpType" ?
                                    <>
                                        <Row className="row">
                                            <Col span={4} className='zhTxt'>超级代理卡号<span className='redStar'>*</span>：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="代理卡号"
                                                    disabled
                                                    value={this.state.model.cardNumber || ''}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>超级代理手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input placeholder="代理手机号"
                                                    maxLength={11}
                                                    value={this.state.model.phone || ''}
                                                    onChange={(e) => this.modelChange('phone', e.target.value)}/>
                                            </Col>
                                        </Row>
                                        <Row className="row">
                                            <Col span={4} className='zhTxt'>推荐人卡号：</Col>
                                            <Col span={4} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="推荐人卡号"
                                                    disabled
                                                    value={this.state.model.recommendCard || ''}
                                                    onChange={(e) => this.modelChange('recommendCard', e.target.value)}  />
                                            </Col>
                                            <Col span={2} style={{ marginTop: "5px" }} >
                                                    <Button type="primary" onClick={() => this.openPopup()} >选择会员</Button>
                                            </Col>
                                            <Col span={4} className='zhTxt'>推荐人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="推荐人手机号"
                                                    disabled
                                                    value={this.state.model.recommendCardPhone || ''}
                                                    onChange={(e) => this.modelChange('recommendCardPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </> :
                                    <>
                                        <Row className="row">
                                            <Col span={4} className='zhTxt'>会员卡号<span className='redStar'>*</span>:</Col>
                                            <Col span={4} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="会员卡号"
                                                    disabled
                                                    value={this.state.model.cardNumber || ''}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)}  />
                                            </Col>
                                            <Col span={2} style={{ marginTop: "5px" }} >
                                                    <Button type="primary" onClick={() => this.openMemberPopup()} >选择会员</Button>
                                            </Col>
                                            <Col span={4} className='zhTxt'>会员手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="会员手机号"
                                                    disabled
                                                    value={this.state.model.phone || ''}
                                                    onChange={(e) => this.modelChange('phone', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>推荐人卡号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="推荐人卡号"
                                                    disabled
                                                    value={this.state.model.recommendCard || ''}
                                                    onChange={(e) => this.modelChange('recommendCard', e.target.value)}  />
                                            </Col>
                                            <Col span={4} className='zhTxt'>推荐人手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={11}
                                                    placeholder="推荐人手机号"
                                                    disabled
                                                    value={this.state.model.recommendCardPhone || ''}
                                                    onChange={(e) => this.modelChange('recommendCardPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>省市区：</Col>
                                    <Col span={4} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="省市区"
                                            disabled
                                            value={this.state.model.area || ''}
                                            onChange={(e) => this.modelChange('area', e.target.value)} />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }}>
                                        <Button type="primary" onClick={() => { this.openMap() }}>获取位置</Button>
                                    </Col>
                                    <Col span={4} className='zhTxt'>详细地址：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="详细地址"
                                            value={this.state.model.address || ''}
                                            onChange={(e) => this.modelChange('address', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>经度：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="经度"
                                            disabled
                                            value={this.state.model.longitude || ''}
                                            onChange={(e) => this.modelChange('longitude', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>纬度：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="纬度"
                                            disabled
                                            value={this.state.model.latitude || ''}
                                            onChange={(e) => this.modelChange('latitude', e.target.value)} />
                                    </Col>
                                </Row>
                                {this.state.flag !== "editUpType" ? 
                                    <Row className="row">
                                        <Col span={4}  className='zhTxt'>注册日期：</Col>
                                        <Col span={6}  className='zhInput'>
                                            <Input maxLength={200}
                                                placeholder="注册日期"
                                                disabled
                                                value={this.state.model.registrationDate == null ? moment(new Date()).format(Config.dateFormat) : moment(new Date(this.state.model.registrationDate)).format(Config.dateFormat)}
                                            />
                                        </Col>
                                    </Row>
                                : null}
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>备注：</Col>
                                    <Col span={16} className='zhInput'>
                                        <TextArea
                                            value={this.state.model.remark || ""}
                                            onChange={(e) => this.modelChange('remark', e.target.value)}
                                            placeholder="备注"
                                            maxLength={100}
                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                        />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonLeft'>
                                    <Button type="primary" icon="check-circle" disabled={this.state.isAdd} onClick={() => { this.save() }} >保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </Spin>
               </div>
            </div>
        )
    }

    getadress() {
        this.setState({ mapModel: window.mapModel })
    }

    // 获取位置
    openMap() {
        if (this.state.mapFlag) {
            window.container()
        } else {
            window.defaultPositioning()
        }
        this.setState({
            isMap: true,
            searchMapInput: "",
            mapFlag: false
        })
    }

    cancelMap(e) {
        let model = this.state.model;
        if (e) {
            if (this.state.mapModel != null) {
                let mapModel = this.state.mapModel;
                model.area = mapModel.area;
                model.address = mapModel.address;
                model.province = mapModel.province;
                model.city = mapModel.city;
                model.district = mapModel.district;
                model.longitude = mapModel.longitude;
                model.latitude = mapModel.latitude;
            } else {
                Message.openMsgError("错误", "请选择店铺位置")
                return
            }
        }
        this.setState({ isMap: false, model: model }, () => {
            if (window.marker != null) {
                window.map.remove(window.marker);
            }
        })
    }

    
    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    // 选择推荐会员
    openPopup() {
        this.selectRecommendCardPopup.open()
    }

    selectRecommendCardPopup = (ref) => {
        this.selectRecommendCardPopup = ref;
    }

    closePopup() {
        let recommendCardModel = this.selectRecommendCardPopup.state.selectedRows[0];
        let model = this.state.model;
        model.recommendCard = recommendCardModel.recommendCard;
        model.recommendCardPhone = recommendCardModel.recommendCardPhone;
        model.recommendCardId = recommendCardModel.recommendCardId;
        this.setState({
            model: model,
        })
    }

    // 选择会员
    openMemberPopup() {
        this.selectCardNumberPopup.open()
    }

    selectCardNumberPopup = (ref) => {
        this.selectCardNumberPopup = ref;
    }

    closeCardNumbePopup() {
        let cardNumbedModel = this.selectCardNumberPopup.state.selectedRows[0];
        let model = this.state.model;
            model.id = cardNumbedModel.id;
            model.userId = cardNumbedModel.userId;
            model.cardNumber = cardNumbedModel.cardNumber;
            model.phone = cardNumbedModel.phone;
            model.recommendCard = cardNumbedModel.recommendCard;
            model.recommendCardPhone = cardNumbedModel.recommendCardPhone;
            model.area = cardNumbedModel.area;
            model.address = cardNumbedModel.address;
            model.longitude = cardNumbedModel.longitude;
            model.latitude = cardNumbedModel.latitude;
            model.provinceId = cardNumbedModel.provinceId;
            model.cityId = cardNumbedModel.cityId;
            model.areaId = cardNumbedModel.areaId;
        this.setState({
            model: model,
        })
    }

    /**关闭图片弹窗 */
    handleCancel = () => this.setState({ previewVisible: false, isMap: false })

    save() {
        let msg = this.check();
        let url = "";
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        if (this.state.flag === "add") {
            url = "superAgencyManagement/insert";
        } else if (this.state.flag === "editUpType") {
            url = "superAgencyManagement/updateUpType"
        } else if (this.state.flag === "edit") {
            url = "superAgencyManagement/update"
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                if(this.state.flag === "add"){
                    Message.openMsgSuccess("成功", "您分配的卡号为："+ data.object.cardNumber)
                }else{
                    Message.openMsgSuccess("成功", "修改成功")
                }
            } else {
                Message.openMsgError("错误", data.msg)
            }
        }).catch(err => { });
    }
} 