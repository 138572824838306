import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Spin, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";

export default class UserRecommendStatistics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchModel: {pageSize: 30, page: 1,startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(),},
            isExport: false,
            tabIndex: '1',
            charthData: {},  
            chartList: [],
        }
    }

    componentDidMount() {
       this.searchChart()
    }

    searchChart() {
        let model = this.state.searchModel;
        this.setState({ isLoading:true })
        fetch(Config.apiBackServer + "userRecommendStatistics/searchChart", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                console.log(data)
                let list = data.object.list;
                let xAxisContentList = [];
                let recommendList = [];
                let directlyRecommendedList = [];
                let indirectRecommendedList = [];
                list.forEach(element => {
                    xAxisContentList.push(element.phone);
                    recommendList.push( (Common.isEmpty(element.directlyRecommended) ? 0 : element.directlyRecommended) + (Common.isEmpty(element.indirectRecommended) ? 0 : element.indirectRecommended) || 0)
                    directlyRecommendedList.push(element.directlyRecommended || 0);
                    indirectRecommendedList.push(element.indirectRecommended || 0)
                });
                

                let datesetsItem = {
                    label: "推荐数量",
                    data: recommendList,
                    backgroundColor: "#37a2da",
                    borderColor: "#37a2da",
                    borderWidth: 1,
                    hoverBackgroundColor: "#37a2da",
                    hoverBorderColor: "#37a2da",
                    barThickness: "flex",
                    fill: false
                }

                let directlyRecommendedItem = {
                    label: "直接推荐",
                    data: directlyRecommendedList,
                    backgroundColor: "#ee3f4d",
                    borderColor: "#ee3f4d",
                    borderWidth: 1,
                    hoverBackgroundColor: "#ee3f4d",
                    hoverBorderColor: "#ee3f4d",
                    barThickness: "flex",
                    fill: false
                }

                let indirectRecommendedItem = {
                    label: "间接推荐",
                    data: indirectRecommendedList,
                    backgroundColor: "#FF9933",
                    borderColor: "#FF9933",
                    borderWidth: 1,
                    hoverBackgroundColor: "#FF9933",
                    hoverBorderColor: "#FF9933",
                    barThickness: "flex",
                    fill: false
                }

                let chartData = {
                    labels: xAxisContentList,
                    datasets: [datesetsItem,directlyRecommendedItem,indirectRecommendedItem,]
                }
                this.setState({
                    chartList: data.object.list,
                    charthData: chartData,
                })
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => {Message.openMsgError("错误", err)}).finally(() => this.setState({ isLoading: false }))
    }

    searchChange(k,e) {
        let newModel = this.state.searchModel;
        if (k === "startTime") {
            newModel.endTime = null;
        }
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    handleEndDisabledDate(current) {
        let {startTime} = this.state.searchModel
        if (!current || !startTime) {
            return true;
        }
        return current > moment(startTime).add(1, 'month') || current < moment(startTime);
    }

    reset() {
        this.setState({
            searchModel: {pageSize: 30, page: 1,startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date(),},
        }, () => {
           this.searchChart()
        })
    }

    exportChartData() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "userRecommendStatistics/exportChartData", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推荐统计报表" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {    // for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        })
    }

    render() {
        const { TabPane } = Tabs;
        return(
            <div className="userRecommendStatistics">
                 <div className="zhRoot">
                    <div className="zhTitle">推荐统计</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                                <TabPane tab="统计" key="1">
                                    <Row className="row">
                                        {/* <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col> */}
                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="memberCostStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd") }
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="memberCostStatementDatePicker"
                                                disabledDate={(e) => this.handleEndDisabledDate(e)}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" icon="search" onClick = {() => this.searchChart()}>搜索</Button>
                                            <Button className="zhButtonRight" icon="redo" onClick = {() => this.reset()}>重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" icon="download" onClick = {() => this.exportChartData()}>导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                    <Bar
                                        width={1200}
                                        data={this.state.charthData}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "会员推荐统计",
                                                    position: "top",
                                                },
                                                tooltips: {
                                                    enabled: true,
                                                    intersect: false
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: [{

                                                    }],
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            callback: function (value) { if (value % 1 === 0) { return value } }
                                                        }
                                                    }]
                                                },
                                                legend: {
                                                    position: "bottom",
                                                    align: "center",
                                                    labels: {
                                                        boxWidth: 20
                                                    }
                                                },
                                                hover: {
                                                    animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                },
                                                animation: {
                                                    onComplete: function() {
                                                        var chartInstance = this.chart,
                                                        ctx = chartInstance.ctx;
                                                        // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                        ctx.fillStyle = "black";
                                                        ctx.textAlign = 'center';
                                                        ctx.textBaseline = 'bottom';
                                                        this.data.datasets.forEach(function (dataset, i) {
                                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                                            meta.data.forEach(function (bar, index) {
                                                                var data = dataset.data[index];
                                                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                            });
                                                        });
                                                    }
                                                }
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                 </div>
            </div>
        ) 
    }
}