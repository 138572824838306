import React, { Component } from 'react';
import { Button, Row
} from 'antd';

import './demo.css';

export default class RouteDemo extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <div>
                <Row className="zhTitle">路由使用</Row>
                <Row className="zhLine">
                    <a href="/main/routeDemo2">a标签跳转到routeDemo2页</a>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={()=>{this.props.history.push('/main/routeDemo2')}}>
                        使用代码跳转
                    </Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={()=>{this.props.history.push(
                        {
                            pathname: '/main/routeDemo2',
                            state: {
                                name: '小明'
                            }
                        }
                    )}}>带参跳转</Button>
                </Row>
            </div>
        );
    }
}