import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import SelectShop from './../selectShop/selectShop';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import './memberPromotion.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
var css;
export default class MemberPromotion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, },
            funcList: [],
            searchShopList: [],
            allowEditing: false,
            allowEditing1: false,
            isLoading: true,
            paymentAmount: [{}],
            cardLimit: 5
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
        this.searchPaymentAmount();
    }

    searchPaymentAmount() {
        let model = { segment: "RENEWAL_AMOUNT", code: "NUMERICAL_VALUE" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                paymentAmount: data.object
            })
        }).catch(err => { });
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list
            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "memberPromotion/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }



    save() {
        let msg = this.check();
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        fetch(Config.apiBackServer + "memberPromotion/insert", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });

    }

    check() {
        let msg = ""
        let model = this.state.model
        if (Common.isEmpty(model.cardNumber)) {
            msg += "会员金卡卡号不能为空;"
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "HU")) {
                msg += "会员卡号不能以'SC'或'HU'开头;"
            } else if (model.cardNumber.length < 7) {
                msg += "会员卡号长度至少为7;"
            } else if (model.cardNumber.length == 9) {
                msg += "会员卡号长度不能为9;"
            } else if (model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6"){
                msg += "会员卡号为10位时，不能以'6'开头;"
            }
        }
        return msg
    }

    add() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0]
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: {
                openDate: new Date(),
                endDate: new Date().setFullYear(new Date().getFullYear() + 1),
                active: 'Y',
                id: model.id,
                shopId: model.shopId,
                shopName: model.shopName,
                oldCardNumber: model.cardNumber,
                userId: model.userId,
                transactionType: 'CASH',
            },
            cardLimit: this.state.selectedRows[0].cardLimit
        })

    }



    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        // if(newModel.flag === "add"){

        // }
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            this.add();
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    selectShopPopup = (ref) => {
        this.selectShopPopup = ref;
    }

    closePopup() {
        let shopModel = this.selectShopPopup.state.selectedRows[0]
        let model = this.state.model
        model.shopId = shopModel.shopId
        model.shopName = shopModel.shopName
        this.setState({
            model: model
        })
    }

    openPopup() {
        this.selectShopPopup.open()
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth11',
            },
            {
                title: '推荐卡号',
                dataIndex: 'whetherMasterCard',
                className: 'tableWidth11',
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                className: 'tableWidth8',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth11',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                className: 'tableWidth11',
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: 'tableWidth11',
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: 'tableWidth15',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: 'tableWidth11',
                render: (text) => ContextHandler.getActiveValue(text)
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                className: 'tableWidth11',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            }
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">银卡升级</div>
                    <div className="content">
                        <SelectShop onRef={this.selectShopPopup} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">开卡店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        {/* <Select style={{ width: "100%" }} placeholder="开卡店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <option key="" value="">全部</option>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select> */}
                                        <Input
                                            maxLength={20}
                                            placeholder="开卡店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input
                                            maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">推荐卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="推荐卡号"
                                            value={this.state.searchModel.whetherMasterCard || ''}
                                            onChange={(e) => this.searchChange('whetherMasterCard', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2} className="zhTxt">手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            type="phone"
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员卡日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("openDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="至日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">升级</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="cardNumber"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        dataSource={this.state.tables} //数据
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>开卡店铺名称<span className='redStar'>*</span>：</Col>
                                    <Col span={4} className='zhInput'>
                                        {/* <Select style={{ width: "100%" }} placeholder="开卡店铺名称" value={this.state.model.shopId || ''}
                                            onChange={(e) => this.modelChange('shopId', e)}>
                                            {this.state.searchShopList.map((item) => <option key={item.shopId} value={item.shopId}>{item.shopName}</option>)}
                                        </Select> */}
                                        <Input
                                            placeholder="开卡店铺名称"
                                            disabled
                                            value={this.state.model.shopName || ''} />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                        <Button type="primary" onClick={() => this.openPopup()} >选择店铺</Button>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>银卡升级日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="银卡升级日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.openDate == null ? null : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("openDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>银卡结束日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="银卡结束日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("openDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>

                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.transactionType || ''}
                                            disabled={this.state.allowEditing}
                                            onChange={(e) => this.modelChange('transactionType', e)}>
                                            {context.transactionTypeList.map((item) => <option key={item.key} value={item.key}>{item.value}</option>)}
                                        </Select>
                                    </Col>
                                    <Col span={4} className='zhTxt' >缴费金额：</Col>
                                    <Col span={6} className='zhInput' >
                                        <Input type="phone" maxLength={11} disabled
                                            value={this.state.paymentAmount[0].parmValue1 || ''} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员金卡卡号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={10}
                                            placeholder="会员金卡卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>预留手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            disabled={this.state.allowEditing}
                                            maxLength={11}
                                            placeholder="预留手机号"
                                            value={this.state.model.reservedIdCard || ''}
                                            onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>售卡人：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={10}
                                            placeholder="售卡人"
                                            value={this.state.model.cardSellerName || ''}
                                            onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>售卡人手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={11}
                                            placeholder="售卡人手机号"
                                            value={this.state.model.cardSellerPhone || ''}
                                            onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>备注：</Col>
                                    <Col span={6} className='zhInput'>
                                        <TextArea
                                            value={this.state.model.remark || ""}
                                            onChange={(e) => this.modelChange('remark', e.target.value)}
                                            placeholder="备注"
                                            maxLength={100}
                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                        />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button disabled={this.state.allowEditing} type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}