import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, Upload, Icon } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import SelectCardNumberModel from './selectCardNumberModel'
import './cashierManagement.css';

const confirm = Modal.confirm;
export default class CashierManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            imageFile: [],
            isLoading: true,
            ImageNum: 1,
            previewVisible: false,
            searchModel: { shopId: Config.userInfo.shopId, pageSize: 10, page: 1, },
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "cashierManagement/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                console.log(data)
                model.total = data.object.total;
                model.cashierLimit = data.object.cashierLimit;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { shopId: Config.userInfo.shopId, pageSize: 10, page: 1, },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        let model = this.state.searchModel
        fetch(Config.apiBackServer + "cashierManagement/cashierTotal", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (data.object < this.state.searchModel.cashierLimit) {
                    this.setState({
                        tabIndex: "2",
                        flag: "add",
                    })
                } else {
                    Message.openMsgError("错误", "店铺收银员已达到上限")
                }
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });

    }


    save() {
        let url = "";
        let msg = this.check()
        // this.obtainImgData();
        let model = this.state.model
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        if (this.state.flag === "add") {
            url = "cashierManagement/insert";
        }
        model.shopId = Config.userInfo.shopId
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (Common.isEmpty(model.cardNumber)) {
            msg += "会员卡号不能为空;"
        }
        if (Common.isEmpty(model.phone)) {
            msg += "会员手机号不能为空;"
        }
        if (Common.isEmpty(model.oldPassword) && Common.isEmpty(model.password)) {
            msg += "用户密码不能为空;"
        } 
        if(Common.isNotEmpty(model.password) && model.password.length < 6) {
            msg += "新密码长度不能小于6位;"
        }
        return msg
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        var model = this.state.selectedRows[0];
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "cashierManagement/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });

    }
    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            imageFile: [],
            model: {},
        },() => {
            this.search()
        })
        
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '用户名',
                dataIndex: 'nickName',
                className: "tableWidth20",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth20",
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                className: "tableWidth20",
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth20",
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: "tableWidth20",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="cashierManagement">
                <div className="zhRoot">
                    <div className="zhTitle">店铺员工管理</div>
                    <div className="content">
                        <SelectCardNumberModel stateRef = {this.state} onRef={this.selectCardNumberPopup} closePopup={() => this.closePopup()}/>
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="userId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'userId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>会员卡号<span className='redStar'>*</span>:</Col>
                                    <Col span={4} className='zhInput'>
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            disabled
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)}  />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                            <Button type="primary" onClick={() => this.openPopup()} >选择会员</Button>
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={11}
                                            placeholder="会员手机号"
                                            disabled
                                            value={this.state.model.phone || ''}
                                            onChange={(e) => this.modelChange('phone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>原密码：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password maxLength={6}
                                        disabled
                                            placeholder="原密码"
                                            value={this.state.model.oldPassword || ''}
                                            onChange={(e) => this.modelChange('oldPassword', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>新密码：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input.Password minLength={6} type="password"
                                            placeholder="新密码"
                                            value={this.state.model.password || ''}
                                            onChange={(e) => this.modelChange('password', e.target.value)} />
                                    </Col>
                                </Row>  
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

    // 选择会员
    openPopup() {
        this.selectCardNumberPopup.open()
    }

    selectCardNumberPopup = (ref) => {
        this.selectCardNumberPopup = ref;
    }

    closePopup() {
        let selectCardNumberModel = this.selectCardNumberPopup.state.selectedRows[0];
        let model = this.state.model;
        model.userId = selectCardNumberModel.userId
        model.id = selectCardNumberModel.id;
        model.cardNumber = selectCardNumberModel.cardNumber
        model.phone = selectCardNumberModel.phone
        model.status = selectCardNumberModel.status
        model.headPortrait = selectCardNumberModel.headPortrait
        model.oldPassword = selectCardNumberModel.password
        this.setState({
            model:model,
        })
    }
}