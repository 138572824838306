import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Spin, AutoComplete } from 'antd';
import ExportJsonExcel from 'js-export-excel';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import { Tab } from 'react-bootstrap';

export default class PlatformShopRebateStatement extends Component {

    constructor(props){
        super(props)                                                       
        this.state = {
            tabIndex: "1", // 页面切换
            searchModel:{type: "PlatformShopRebateStatement", pageSize:10, page:1},    // 查询保存的数据
            model:{list:[]},
            isExport: false,    // 表格加载状态
            tables:[],  // 表格数据
            rebateModel:{pageSize:10, page:1,},
            rebateModelList:{},
            rebateTables:[],    // 子表表格数据
            RebateModelTables:[], // 子表表格全部数据
            selectedRowKeys: [],
            selectedRows: [],
            isLoading:true, // 表格加载状态

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount(){
        this.searchShopRebate();
        this.searchShop();
    }

    searchShopRebate(){
        fetch(Config.apiBackServer + "platformShopRebateStatement/searchAll",{
            method: "POST",
            body:JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if("SUCCESS" === data.msg){
                model.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
            })           
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    goSearchTables(){
        this.setState({
            isLoading:true,
        });
        this.searchShopRebate();
    }

    // 重置
    reset(){
        this.setState({
            searchModel:{type: "PlatformShopRebateStatement", pageSize:10, page:1},  
            tables:[]
        }, () => {
            this.searchShopRebate()
        })
    }

    tableChange(value){
        if(value == 2){
            this.eye();
        } else {
            this.setState({tab:value,})
            this.cancel();
        }
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };
    
    searchChange(k, e) {
        let { searchModel} = this.state;
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    // 查看
    eye(){
        if(this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误","请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        let newRebateModel = this.state.rebateModel;
        newRebateModel.shopId = model.shopId;
        newRebateModel.cashierTime = model.cashierTime;
        this.setState({
            tabIndex: "2",
            flag:"Eye",
            model:model,
            rebateModel:newRebateModel,
        }, () => {
            this.searchDetailedDayStatement();
        })
    }

     // 奖励
     rebateye(){
        if(this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        let newRebateModel = this.state.rebateModel;
        newRebateModel.shopId = model.shopId;
        newRebateModel.cashierTime = model.cashierTime;

        this.setState({
            tabIndex: "2",
            flag:"Rebateye",
            model:model,
            rebateModel:newRebateModel,
            rebateModelList:model,
        }, () => {
            this.searchDetailedDayStatement();
        })
    }

    downloadTableExcel() {
        this.setState({
            isExport: true,
        })
        fetch(Config.apiBackServer + "platformShopRebateStatement/statementReport",{
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if(blob.type === "application/json"){
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    Message.openMsgError("错误",data.msg)
                }
                reader.readAsText(blob);
            }else{
                var filename = "店铺奖励日报表.xlsx";
                if(window.navigator && window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob,filename);
                }else{
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = "display:none";
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    pageChange = (current, pageSize) =>{
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel:searchModel,
            isLoading: true,
        }, function() {
            this.searchShopRebate();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys)
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.shopId +record.batchId + record.cashierTime];
            checkboxState = [record];
        }
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    render(){
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth12',
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth12 tableLeftAlignment",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '酒水金额',
                dataIndex: 'wineAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费总金额',
                dataIndex: 'tradeAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            }, 
            {
                title: '店铺到账',
                dataIndex: 'paymentAmount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            }, 
            {
                title: '消费笔数',
                dataIndex: 'cashierCount',
                className: "tableWidth12 tableRightAlignment",
                render: (text) => Common.numberFormat(text)
            },                 
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return(
            <div className="shopRebateStatement">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">                    
                        <div className="zhTitle">店铺奖励日报表</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            <AutoComplete
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchModel.shopName || ''}
                                                onSelect={(e) => this.onSelect(e)}
                                                onChange={this.onChange}
                                                onSearch={this.onSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">消费日期：</Col>
                                        <Col span={7} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="shopRebateStatement"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat} />
                                             &nbsp;&nbsp;至&nbsp;&nbsp;
                                             <DatePicker placeholder="至日期"
                                                className="shopRebateStatement"
                                                allowClear={true}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>                                       
                                    </Row>    
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" icon="search" disabled={this.state.isLoading} onClick={() => this.goSearchTables()}>搜索</Button>
                                            <Button className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                        </Col>    
                                    </Row> 
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" icon="download" onClick={() => this.downloadTableExcel()}>导出</Button>
                                    </Row>
                                    <Row className="zhRow">
                                        <Table  rowSelection={rowSelection}
                                            rowKey={record => record.shopId + record.batchId + record.cashierTime}
                                            bordered={true} // 边线
                                            columns={columns}   // 列名
                                            loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                            dataSource={this.state.tables}  // 数据
                                            pagination={{   // 分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => this.selectRadioRow(record), // 点击行
                                                };
                                            }}
                                       />
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }

    cancel(){
        this.setState({
            tabIndex:"1",
            flag:"",
            selectedRowKeys: [],
            selectedRows: [],
            RebateTables:[],
            rebateModel:{pageSize:10, page:1},
            model:{}
        },() => {
            this.searchShopRebate();
        })
    }

    /* 查询详细页每天的详细数据(分页) */
    searchDetailedDayStatement(){
        this.setState({
            isLoading:true,
        })
        fetch(Config.apiBackServer + "platformShopRebateStatement/searchDetailedDayStatement",{
            method: "POST",
            body:JSON.stringify(this.state.rebateModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.model;
            if("SUCCESS" === data.msg){
                model.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                rebateModel: model,
                RebateTables:data.object.list,
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }
   
    rebateChange = (current, pageSize) => {
        let rebateModel = this.state.rebateModel;
        rebateModel.pageSize = pageSize;
        rebateModel.page = current;
        this.setState({
            rebateModel:rebateModel,
        },function() {
            this.searchDetailedDayStatement();
        });        
    }
}

