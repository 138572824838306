import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './memberSettlement.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
export default class MemberSettlement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            searchShopList: [],
            searchModel: { settlementStatus: "N", pageSize: 10, page: 1, },
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.setState({
                    searchShopList: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "membershipRenewal/settlementSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }

            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, code: "", segment: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { activeInd: "Y" },
        })
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model: this.state.selectedRows[0]
        })
    }



    save() {
        fetch(Config.apiBackServer + "membershipRenewal/settlementUpdate", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const { TextArea } = Input;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth14",
            },
            {
                title: '会员昵称',
                dataIndex: 'nickName',
                className: "tableWidth14",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth14",
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth16",
            },
            {
                title: '开通日期',
                dataIndex: 'openDate',
                className: "tableWidth14",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '缴费金额',
                dataIndex: 'paymentAmount',
                className: "tableWidth14 tableRightAlignment",
                render: (text) => Common.numberFormat(text)
            },
            {
                title: '结算状态',
                dataIndex: 'settlementStatus',
                className: "tableWidth14",
                render: (text) => {
                    return (
                        <div>{text === "Y" ? "已结算" : (text === "N" ? "未结算" : "")}</div>
                    );
                }
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="memberSettlement">
                <div className="zhRoot">
                    <div className="zhTitle">金卡会员结算</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        {/* <Select style={{ width: "100%" }} placeholder="店铺名称" value={this.state.searchModel.shopId || ''}
                                            onChange={(e) => this.searchChange('shopId', e)}>
                                            <Select.Option key="" value="">全部</Select.Option>
                                            {this.state.searchShopList.map((item) => <Select.Option key={item.shopId} value={item.shopId}>{item.shopName}</Select.Option>)}
                                        </Select> */}
                                        <Input maxLength={20}
                                            placeholder="店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>

                                    <Col span={2} className="zhTxt">结算状态：</Col>
                                    <Col span={3} className="zhInput">
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.settlementStatus || ''}
                                            onChange={(e) => this.searchChange('settlementStatus', e)} >
                                            <Option key="Y" value={"Y"}>已结算</Option>
                                            <Option key="N" value={"N"}>未结算</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">开通日期：</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("openDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">至</Col>
                                    <Col span={3} className="zhInput">
                                        <DatePicker placeholder="至日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.edit()} icon="check-circle">结算</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员卡号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.model.cardNumber || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>店铺名称：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="店铺名称"
                                            value={this.state.model.shopName || ''} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员昵称：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="会员昵称"
                                            value={this.state.model.nickName || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="会员手机号"
                                            value={this.state.model.phone || ''} />
                                    </Col>

                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>开始时间：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="开始时间"
                                            value={moment(this.state.model.openDate).format(Config.dateFormat) || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>结束时间：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="结束时间"
                                            value={moment(this.state.model.endDate).format(Config.dateFormat) || ''} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>缴费金额：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="缴费金额"
                                            value={this.state.model.paymentAmount || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>缴费时间：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="缴费时间"
                                            value={moment(this.state.model.createdDate).format(Config.dateFormatALL) || ''} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>收银员：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="收银员"
                                            value={this.state.model.operatorName || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>结算状态：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            disabled
                                            value={this.state.model.settlementStatus || ''} >
                                            <Option value={"Y"}>已结算</Option>
                                            <Option value={"N"}>未结算</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>结算备注：</Col>
                                    <Col span={6} className='zhInput'>
                                        <TextArea
                                            value={this.state.model.settlementNote || ""}
                                            onChange={(e) => this.modelChange('settlementNote', e.target.value)}
                                            placeholder="结算备注"
                                            maxLength={100}
                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                        />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}