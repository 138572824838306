import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import SelectCardNumberByColdCard from './../selectCardNumberByColdCard/selectCardNumberByColdCard';

import './defaultPromotionGoldCard.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class DefaultPromotionGoldCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            model: {},
            tables: [],
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: false,
            saveFlag: true,//重复点击标志
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,
            newMembersCommission:'',//推广新会员提成参数设置
            memberConsumptionCommission:'',//推广会员消费额提成
            selectedRowKeys: [],
            selectedRows: [],
        }
        this.init()
    }

    init() {
        // this.search();
    }
    componentWillMount(){
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "defaultPromotionGold/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
            console.log(this.state.tables);
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

 


    reset() {
        this.setState({
            searchModel: { page: 1,
                           pageSize: 10, 
                           cardNumber: '',
                           phone:''},
        }, () => {
            this.cancel();
        })
        
    }


    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { 
                    memberConsumptionCommission:this.state.memberConsumptionCommission,
                    newMembersCommission:this.state.newMembersCommission,

                     },
        })
    }

    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "defaultPromotionGold/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "默认推广金卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }


    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        var model = this.state.selectedRows[0];
        model.cardNumberType ='金卡';
       
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model:model,
        })
    }

 



    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }
 

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
       
        this.setState({
            model: newModel,
        });
    }


    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    save(){
        fetch(Config.apiBackServer + "defaultPromotionGold/update", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" === data.msg) {
                this.cancel();
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
           
            
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));

    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value, flag) {
        
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }

    }

    selectCardNumberPopByColdCard = (ref) => {
        this.selectCardNumberPopByColdCard = ref;
    }

    selectCardNumberByGoldCard() {
        this.selectCardNumberPopByColdCard.open()
    }

    closePopup() {
        let cardNumbermodel = this.selectCardNumberPopByColdCard.state.selectedRows[0]
        console.log(cardNumbermodel);
        let model = this.state.model
        console.log(model);
        model.cardNumber = cardNumbermodel.cardNumber;
        model.phone = cardNumbermodel.phone;
        model.promotionCardNumber = cardNumbermodel.promotionCardNumber;
        model.createdDate = cardNumbermodel.createdDate;
        model.userId = cardNumbermodel.userId;
        model.cardNumberType ='金卡';
        this.setState({
            model: model
        })
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.selectedRows[0]
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "defaultPromotionGold/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                render: (text, record, index) =>
                (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                    (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                ))
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            }

        ];    
      

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
    
        return (
            <div className="defaultPromotionGold">
                <div className="zhRoot">
                    <div className="zhTitle">默认推广金卡</div>
                    <div className="content">
                        <SelectCardNumberByColdCard onRef={this.selectCardNumberPopByColdCard} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                       
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                    <Row>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input disabled
                                                maxLength={20}
                                                placeholder="会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} style={{ marginTop: "5px" }} >
                                            <Button hidden={this.state.flag === 'edit'} type="primary" onClick={() => this.selectCardNumberByGoldCard()} >选择会员</Button>
                                        </Col>
                                    </Row>
                                   
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>卡号类型：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="卡号类型"
                                                disabled
                                                value={this.state.model.cardNumberType || ''}
                                                onChange={(e) => this.modelChange('cardNumberType', e.target.value)} />
                                        </Col>

                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>开通日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开通日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.createdDate == null ? null : moment(new Date(this.state.model.createdDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("createdDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button type="primary" hidden ={this.state.flag==='edit' } icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                    
                                   
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div >
        )
    }




 
   

}