import { Row, Col, Table, Input, Modal, Button } from 'antd';
import React, { Component } from 'react';
import { Common } from '../../public/js/common';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { ContextHandler } from '../../public/js/contextHandler';
/**
 * 店铺选择处理类
 */
export default class SelectCard extends Component {
    constructor(prpos){
        super(prpos);
        this.state = {
        visible: false,
        selectedRowKeys: [],
        selectedRows: [],
        searchModel: { pageSize: 10, page: 1 },
        tables: [],
        isLoading: false,
      }
    }
     
    componentDidMount() {
      if (this.props.onRef !== null) {
      this.props.onRef(this);
      }
    }
  
    open() {
      this.setState({
      visible: true,
      selectedRowKeys: [],
      selectedRows: [],
      searchModel: { pageSize: 10, page: 1 },
      tables: [],
      isLoading: false,
      })
    }
  
    search(){
      this.setState({ isLoading: true })
      let model = this.state.searchModel
      fetch(Config.apiBackServer + "openGoldCard/searchCardNumber", {
        method: 'POST',
        body: JSON.stringify(model),
        headers: Config.header,
        mode: 'cors',
        cache: 'default'
      }).then(res => res.json()).then((data) => {
        let model = this.state.searchModel;
        if ("SUCCESS" === data.msg) {
          model.total = data.object.total;
        } else {
          Message.openMsgError("错误", data.msg, data.errorCode)
        }
        this.setState({
          searchModel: model,
          tables: data.object.list,
        });
      }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }
  
    reset() {
      this.setState({
          searchModel: { page: 1, pageSize: 10, },
      })
    }
  
    searchChange(k, e) {
      let newModel = this.state.searchModel;
      newModel[k] = e;
      this.setState({
          searchModel: newModel
      });
    }
  
    onSelectChange = (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows
      })
    }
  
    pageChange = (current, pageSize) => {
      let searchModel = this.state.searchModel;
      searchModel.pageSize = pageSize;
      searchModel.page = current;
      this.setState({
          searchModel: searchModel,
      });
      this.search();
    }
  
    handleOk = () => {
      if (this.state.selectedRows && this.state.selectedRows.length == 1) {
        this.setState({
          visible: false
        }, () => {
            this.props.closePopup()
        })
      } else {
          Message.openMsgError("错误", "请选择一条数据")
      }
    }
  
    handleCancel = () => {
      this.setState({
        visible: false
      })
    }
  
    render() {
      const columns = [
        {
          title: '会员卡号',
          dataIndex: 'cardNumber',
          className: "tableWidth50",
        },
        {
          title: '会员手机号',
          dataIndex: 'phone',
          className: "tableWidth50",
        },
      ];
  
      const rowSelection = {
        selectedRowKeys: this.state.selectedRowKeys,
        onChange: this.onSelectChange,
        type: 'radio',
      }
  
      return(
        <Modal title="选择会员"
          className="zhRoot container"
          width='60%'
          centered={true}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row className="row">
            <Col span={3} className="zhTxt">会员卡号：</Col>
            <Col span={3} className="zhInput">
                <Input maxLength={20}
                    placeholder="会员卡号"
                    value={this.state.searchModel.cardNumber || ''}
                    onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
            </Col>
            <Col span={3} className="zhTxt">会员手机号：</Col>
            <Col span={3} className="zhInput">
                <Input maxLength={20}
                    placeholder="会员手机号"
                    value={this.state.searchModel.phone || ''}
                    onChange={(e) => this.searchChange('phone', e.target.value)} />
            </Col>
          </Row>
          <Row className="rowButton">
            <Col className="zhRowButtonRight">
                <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
            </Col>
          </Row>
          <Row className="zhRow">
            <Table rowSelection={rowSelection}
              rowKey="id"
              bordered={true} //边线
              columns={columns} //列名
              loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
              dataSource={this.state.tables} //数据
              pagination={{  //分页
                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                onChange: this.pageChange,            //上页下页或者跳页方法
                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                total: this.state.searchModel.total,             //总共多少条数据
                current: this.state.searchModel.page,         //当前页
                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                defaultCurrent: 1,                               //默认当前页为1
              }}
              onRow={(record) => {
                  return {
                      onClick: event => Common.selectRadioRow.call(this, record, "id", 'selectedRows', 'selectedRowKeys'), // 点击行
                  };
              }}
              rowClassName="tableColor"
            />
          </Row>
        </Modal>
      )
    }
  }