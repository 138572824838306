import React, { Component } from 'react';
import { Button, Row} from 'antd';

import './demo.css';
import { Message } from '../../public/js/message';

export default class MessageDemo extends Component {

    constructor(props){
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <div className="zhRoot">
                <Row className="zhTitle">提示信息</Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsgSuccess('添加成功')}>添加成功</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsgSuccess('添加成功','班级创建成功')}>成功详细信息</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsgError('添加失败')}>添加失败</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsgError('添加失败','请检查服务器是否正常运行')}>失败详细信息</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsg('warning','warning提示信息')}>警告提示信息</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsg('info','info提示信息')}>info</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.openMsg('open','open提示信息')}>open</Button>
                </Row>
                <Row className="zhLine">
                    <Button type="primary" onClick={() => Message.closeMsg()}>清空提示信息</Button>
                </Row>
            </div>
        );
    }
}