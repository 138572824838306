import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Modal } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import './weChatManagementUlr.css';

const confirm = Modal.confirm;
export default class WeChatManagementUlr extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: false,
            searchModel: { pageSize: 10, page: 1, },
            funcList: []
        }
        this.init()
    }

    init() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "user/searchCardUser", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }
    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }
    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "user/deleteOneKey", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }
    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10 },
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    render() {
        const { TabPane } = Tabs;
        const columns = [
            {
                title: '卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '手机号',
                dataIndex: 'phone',
            },
            {
                title: '用户姓名',
                dataIndex: 'nickName',
            },
            {
                title: '性别',
                dataIndex: 'sex',
                render: (text) => ContextHandler.getMapValue('sexMap', text)
            },
            {
                title: '身份',
                dataIndex: 'identity',
                render: (text) => ContextHandler.getMapValue('identityMap', text)
            },

            {
                title: '创建日期',
                dataIndex: 'createdDate',
                render: (text) => <span>{Common.isEmpty(text) ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },

            {
                title: '用户头像',
                dataIndex: 'headPortrait',
                render: (text) => (Common.isNotEmpty(text) ?
                    <img alt="" className="img" src={(text.search('http') == -1) && (text.search('https') == -1) ? Config.headPortraitImgs + text : text} /> : null),
            },

            {
                title: '状态',
                dataIndex: 'status',
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">微信公众号管理</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">手机号</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>

                                    <Col span={2} className="zhTxt">用户姓名</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="昵称"
                                            value={this.state.searchModel.nickName || ''}
                                            onChange={(e) => this.searchChange('nickName', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button className="primary" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        dataSource={this.state.tables} //数据
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}