import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Input, Select, Modal, Spin, message } from 'antd';
import ExportJsonExcel from 'js-export-excel';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import { Tab } from 'react-bootstrap';

export default class shopPaymentManagement extends Component {

    constructor(props){
        super(props)                                                       
        this.state = {
            tabIndex: "1", // 页面切换
            searchModel:{ batchRebateState:'PAYMENT',pageSize:10, page:1,startTime: new Date().setHours(new Date().getHours() - 24*7) , endTime: new Date()},    // 查询保存的数据
            searchShopList:[],
            selectedRowKeys: [],
            selectedRows: [],
            model:{},
            isExport: false,    // 表格加载状态
            tables:[],  // 表格数据
            rebateModel:{pageSize:10, page:1,},
            rebateTables:[],    // 子表表格数据
            RebateModelTables:[], // 子表表格全部数据
            isLoading:false, // 表格加载状态
            rebateStateTabe:false, // 显示打款按钮
            isPaying:false,//支付中
            openPaymentInd:'',
        }
    }

    componentDidMount(){
        this.searchBatchRebateList();
        this.searchOpenWxPayment();
    }

    searchOpenWxPayment(){
        let model = { segment:"OPEN_WECHAT_PAYMENT", code:"OPEN_IND" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let openPaymentInd = "N";
            if(data.object !=null && data.object.length > 0){
                openPaymentInd = data.object[0].parmValue1;
            }
            this.setState({ openPaymentInd: openPaymentInd });
        }).catch(err => { });
    }

    // 查询 
    searchBatchRebateList(){
        this.setState({isLoading:true});
        fetch(Config.apiBackServer + "shopPaymentManagement/shopPaymentSearch",{
            method: "POST",
            body:JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if("SUCCESS" === data.msg){
                model.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
            })
        }).catch(err => { }).finally(() => 
            this.setState({ isLoading: false })
        );
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    // 重置
    reset(){
        this.setState({
            searchModel: {
                batchRebateState:'PAYMENT',
                page: 1,
                pageSize: 10,
                shopId: '',
                shopName:'',
                startTime: new Date().setHours(new Date().getHours() - 24*7) ,
                endTime: new Date(),
            },
            selectedRowKeys: [],
            selectedRows: [],
        })
    }

    tableChange(value){
        if(value == 2){
            this.see();
        } else {
            this.setState({tab:value,})
            this.cancel();
        }
    }

    // 查看
    see(){
        if(this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误","请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        let newRebateModel = this.state.rebateModel;
        newRebateModel.batchId = model.batchId;
        this.setState({
            tabIndex: "2",
            flag:"eye",
            model:model,
            rebateModel:newRebateModel,
        }, () => {
            this.searchRebateListByBatchId();
        })
    }

    searchRebateListByBatchId(){
        this.setState({isLoading:true});
        fetch(Config.apiBackServer + "shopPaymentManagement/searchRebateListByBatchId",{
            method: "POST",
            body:JSON.stringify(this.state.rebateModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            let rebateModel = this.state.rebateModel;
            if("SUCCESS" === data.msg){
                rebateModel.total = data.object.total;
            }else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                rebateModel: rebateModel,
                rebateTables: data.object.list,
            })
        }).catch(err => { }).finally(() => 
            this.setState({ isLoading: false })
        );
    }

    pageChange = (current, pageSize) =>{
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel:searchModel,
        }, function() {
            this.searchBatchRebateList();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log(selectedRowKeys)
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    cancel(){
        this.setState({
            tabIndex:"1",
            flag:"",
            selectedRowKeys: [],
            selectedRows: [],
            rebateTables:[],
            rebateModel:{pageSize:10, page:1},
            model:{}
        },() => {
            this.searchBatchRebateList();
        })
    }

    /**前往去 小额打款 */
    toPayment(){
        if(this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误","请选择一条数据");
            return
        }
        let model = this.state.selectedRows[0];
        let newRebateModel = this.state.rebateModel;
        newRebateModel.batchId = model.batchId;
        this.setState({
            tabIndex: "2",
            flag:"payment",
            model:model,
            rebateModel:newRebateModel,
        }, () => {
            this.searchRebateListByBatchId();
        })
    }

    /** 打款 需调支付方法*/
    payment(){
        if(this.state.openPaymentInd !="Y"){
            Message.openMsgError("错误","微信支付暂未开通，请联系平台");
            return;
        }
        // this.setState({isPaying:true});
        // fetch(Config.apiBackServer + "shopPaymentManagement/batchPayment",{
        //     method: "POST",
        //     body:JSON.stringify(this.state.rebateModel),
        //     headers: Config.header,
        //     mode: 'cors',
        //     cache: 'default'
        // }).then(res => res.json()).then((data) => {
        //     console.log(data);
        //     let rebateModel = this.state.rebateModel;
        //     if("SUCCESS" === data.msg){
        //         rebateModel.total = data.object.total;
        //     }else {
        //         Message.openMsgError("错误", data.msg, data.errorCode)
        //     }
        //     this.setState({
        //         rebateModel: rebateModel,
        //         rebateTables: data.object.list,
        //     })
        // }).catch(err => { }).finally(() => 
        //     this.setState({ isPaying: false })
        // );

    }
   
    rebateChange = (current, pageSize) => {
        let rebateModel = this.state.rebateModel;
        rebateModel.pageSize = pageSize;
        rebateModel.page = current;
        this.setState({
            rebateModel:rebateModel,
        },function() {
            this.searchRebateListByBatchId();
        });        
    }

    selectRadioRow(record) {
        let selectedRowKeys = this.state.selectedRowKeys;
        let checkboxState = this.state.selectedRows;
        let i = checkboxState.indexOf(record);
        if (i > -1) {
            selectedRowKeys = [];
            checkboxState = [];
        } else {
            selectedRowKeys = [record.batchId];
            checkboxState = [record];
        }
        console.log(selectedRowKeys)
        this.setState({ selectedRowKeys : selectedRowKeys, selectedRows: checkboxState });
    }

    render(){
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth11',
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '酒水金额',
                dataIndex: 'wineAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '消费金额',
                dataIndex: 'consumeAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返利总金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            }, 
            {
                title: '已打款金额',
                dataIndex: 'rebateAmountCompleted',
                className: "tableWidth10 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            }, 
            {
                title: '消费笔数',
                dataIndex: 'cashierCount',
                className: "tableWidth9 tableRightAlignment",
                render: (text) => Common.numberFormat(text)
            },                 
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: "tableWidth10 tableLeftAlignment",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '打款时间',
                dataIndex: 'batchRebateTime',
                className: "tableWidth10 tableLeftAlignment",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返利状态',
                dataIndex: 'batchRebateState',
                className: "tableWidth10 tableLeftAlignment",
                render: (text) => Common.isNotEmpty(text) ? (text === 'PAYMENT' ? '已支付' : (text === 'REBATE' ? '已打款' : '')) : ""
            }           
        ]

        
        // 子表列名
        const rebateColumns = [
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth11',
            },
            {
                title: '消费日期',
                dataIndex: 'cashierTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '消费金额',
                dataIndex: 'consumeAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '酒水金额',
                dataIndex: 'wineAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返利金额',
                dataIndex:"rebateAmount",
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '返利时间',
                dataIndex:"rebateTime",
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '返利状态',
                dataIndex:"rebateState",
                className: 'tableWidth10',
                render: (text) => Common.isNotEmpty(text) ? (text ==='AINCOMPLETE'?'未完成': (text ==='COMPLETED'?'已完成': '')) : ""
            },
            {
                title: '返利类型',
                dataIndex:"rebateType",
                className: 'tableWidth8',
                render: (text) => Common.isNotEmpty(text) ? (text === 'MASTER_CARD' ? '金卡' : '银卡') : ""
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return(
            <div className="shopRebateStatement">
                <div className="zhRoot">
                    <div className="zhTitle">店铺批量返利管理</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName',e.target.value)}
                                        />
                                    </Col>

                                    <Col span={2} className="zhTxt">消费日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="shopRebateStatement"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat} />
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                            className="shopRebateStatement"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className="zhTxt">返利状态：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.batchRebateState || ''}
                                            onChange={(e) => this.searchChange('batchRebateState', e)} >
                                            <Option key="PAYMENT"       value={"PAYMENT"}>已支付</Option>
                                            <Option key="REBATE"        value={"REBATE"}>已返利</Option>
                                        </Select>
                                    </Col>
                                </Row>    
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" icon="search" disabled={this.state.isLoading} onClick={() => this.searchBatchRebateList()}>搜索</Button>
                                        <Button className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                    </Col>    
                                </Row> 
                                <Row>
                                    <Button disabled={this.state.isLoading} type="primary" icon="export" onClick={() => this.toPayment()} >前往返利</Button>
                                    <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" icon="eye" onClick={() => this.see()}>查看</Button>
                                </Row>
                                <Row className="zhRow">
                                    <Table  rowSelection={rowSelection}
                                        rowKey={record => record.batchId}
                                        bordered={true} // 边线
                                        columns={columns}   // 列名
                                        loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                        dataSource={this.state.tables}  // 数据
                                        pagination={{   // 分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => this.selectRadioRow(record), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详细" key="2">
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>店铺名称</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="店铺名称"
                                                className='shopName'
                                                value={this.state.model.shopName || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>消费日期</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="消费日期"
                                                className='cashierTime'
                                                value={this.state.model.cashierTime == null ? null : moment(this.state.model.cashierTime).format(Config.dateFormat)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>消费金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="消费金额"
                                                className='tradeAmount'
                                                value={this.state.model.consumeAmount || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>消费笔数</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="消费笔数"
                                                className='cashierCount'
                                                value={this.state.model.cashierCount || ''}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>饭菜金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="饭菜金额"
                                                className='foodAmount'
                                                value={this.state.model.foodAmount || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>酒水金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="酒水金额"
                                                className='wineAmount'
                                                value={this.state.model.wineAmount || ''}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={4} className='zhTxt'>返利总金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="返利总金额"
                                                className='rebateAmount'
                                                value={this.state.model.rebateAmount || ''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>返利状态</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                            disabled={true}
                                            placeholder="返利状态"
                                            className='batchRebateState'
                                            value={Common.isNotEmpty(this.state.model.batchRebateState) ?
                                                (this.state.model.batchRebateState === 'PAYMENT' ? '已支付' : (this.state.model.batchRebateState === 'REBATE' ? '已打款' : '')) 
                                                : ""
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4} className='zhTxt'>已打款金额</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="已打款金额"
                                                className='batchRebateTime'
                                                value={this.state.model.rebateAmountCompleted ||''}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>打款时间</Col>
                                    <Col span={6} className="zhInput">
                                        <Input
                                                disabled={true}
                                                placeholder="打款时间"
                                                className='batchRebateTime'
                                                value={this.state.model.batchRebateTime == null ? null :  moment(this.state.model.batchRebateTime).format(Config.dateFormatALL)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="zhRow"> 
                                    <Table
                                        rowKey="rebateId"
                                        bordered={true} // 边线
                                        columns={rebateColumns}   // 列名
                                        dataSource={this.state.rebateTables}  // 数据
                                        loading={{spinning:this.state.isLoading, tip:"加载中..."}}
                                        pagination={{   // 分页
                                            showSizeChanger:true, //是否显示可以设置几条一页的选项
                                            onChange:this.rebateChange,  //上页下页或者跳页方法
                                            onShowSizeChange:this.rebateChange, //每页显示多少条数据方法
                                            total: this.state.rebateModel.total,             //总共多少条数据
                                            current: this.state.rebateModel.page,         //当前页
                                            pageSize: this.state.rebateModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                              //默认当前页为
                                        }}
                                    >                                            
                                    </Table>
                                </Row>
                                <Row className='zhRow'>   
                                    <div style={{width:"50%",textAlign:"left",float:"left"}}>
                                        <Button hidden={this.state.flag === "eye"} disabled={this.state.isPaying} type="primary" icon="check-circle" onClick={() => { this.payment()}} >去返利</Button>
                                    </div>
                                    <div style={this.state.flag === "eye"?{width:"100%",textAlign:"right"}:{width:"50%",textAlign:"right",float:"left"}}>
                                        <Button className="zhButtonRight" icon="rollback" onClick={() => { this.cancel() }}>返回</Button>
                                    </div>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

