import React, { Component } from 'react';
import { DatePicker, Button, Row, Col, Tabs, Table, Input, Select, Modal } from 'antd';
import ExportJsonExcel from 'js-export-excel';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import moment from 'moment';//日期格式化导包
import context from '../../public/js/context';
import SelectCardNumber from './selectCardNumber/selectCardNumber';
import './trusteeship.css';

const confirm = Modal.confirm;
export default class Trusteeship extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            cardNumberList: [],
            searchModel: { pageSize: 10, page: 1, },
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "trusteeship/trusteeshipSearch", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }

            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    add() {
        this.setState({
            flag: "add",
            tabIndex: "2",
            model: {}
        })
    }



    save() {
        let model = this.state.model
        if (Common.isEmpty(model.id)) {
            Message.openMsgError("错误", "请选择会员")
            return
        }
        this.setState({ isLoading: true })
        model.trusteeshipState = 'Y'
        fetch(Config.apiBackServer + "trusteeship/trusteeshipUpdate", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("成功", "保存成功")
                this.cancel()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }

            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let model = that.state.selectedRows[0]
                model.trusteeshipState = 'N'
                fetch(Config.apiBackServer + "trusteeship/trusteeshipUpdate", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    reset() {
        this.setState({
            searchModel: { pageSize: 10, page: 1, },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        if (k === "id") {
            this.state.cardNumberList.forEach((item) => {
                if (item.id === e) {
                    newModel = item
                }
            })
        }
        this.setState({
            model: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            cardNumberList: []
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            this.add();
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    selectCardNumberPop = (ref) => {
        this.selectCardNumberPop = ref;
    }

    selectCardNumber() {
        this.selectCardNumberPop.open()
    }

    closePopup() {
        let cardNumbermodel = this.selectCardNumberPop.state.selectedRows[0]
        let model = this.state.model
        model.id = cardNumbermodel.id
        model.cardNumber = cardNumbermodel.cardNumber
        model.phone = cardNumbermodel.phone
        model.userType = cardNumbermodel.userType
        model.cardType = (Common.isNotEmpty(cardNumbermodel.sysRoleCode) && cardNumbermodel.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
        (Common.isNotEmpty(cardNumbermodel.sysRoleCode) && cardNumbermodel.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
            (cardNumbermodel.cardNumberType === "Y" ? "金卡" : (cardNumbermodel.cardNumberType === "N" ? "银卡" : ""))
        ))
        model.sysRoleCode = Common.isNotEmpty(cardNumbermodel.sysRoleCode) ?
            cardNumbermodel.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "HEAD_RESPONSIBLE" :
                cardNumbermodel.sysRoleCode.indexOf("HEAD_STAFF") > -1 ? "HEAD_STAFF" :
                    cardNumbermodel.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "STORE_RESPONSIBLE" :
                        cardNumbermodel.sysRoleCode.indexOf("CASHIER") > -1 ? "CASHIER" :
                            cardNumbermodel.sysRoleCode.indexOf("MEMBER") > -1 ? "MEMBER" :""
            : "";
        model.whetherMasterCard = cardNumbermodel.masterCard
        model.createdDate = cardNumbermodel.createdDate
        this.setState({
            model: model
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
            },
            {
                title: '推荐卡号',
                dataIndex: 'whetherMasterCard',
            },
            {
                title: '开通日期',
                dataIndex: 'createdDate',
                render: (text) => Common.dateFormat(text)
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
            },
            {
                title: '会员卡类型',
                dataIndex: 'cardNumberType',
                render: (text, record, index) => (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                    (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                        (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                    ))
                // Common.isNotEmpty(record.userType) && record.userType === "HEAD_USER" ? "总部卡" :
                //     (Common.isNotEmpty(record.userType) && record.userType === "STORE_CHARGE" ? '店铺卡' :
                //         (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                //     )

            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="shopSettlement">
                <div className="zhRoot">
                    <div className="zhTitle">总部托管</div>
                    <div className="content">
                        <SelectCardNumber onRef={this.selectCardNumberPop} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.see()} icon="eye">查看</Button> */}
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员卡号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        {/* <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} /> */}
                                        {/* <Select
                                            showSearch
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            value={this.state.model.cardNumber || ''}
                                            onSearch={(e) => this.modelChange('cardNumber', e)}
                                            onChange={(e) => this.modelChange('id', e)}
                                        >
                                            {this.state.cardNumberList.map((item) => <Option value={item.id}>{item.cardNumber}</Option>)}
                                        </Select> */}
                                        <Input disabled
                                            maxLength={20}
                                            placeholder="会员卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                        <Button type="primary" onClick={() => this.selectCardNumber()} >选择会员</Button>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>手机号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="手机号"
                                            value={this.state.model.phone || ''} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员卡类型：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="会员卡类型"
                                            value={this.state.model.cardType || ''} />
                                        {/* <Select disabled
                                            className="zhSelect"
                                            value={this.state.model.sysRoleCode || ''}>
                                            {context.sysRoleMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select> */}
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>开通日期：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="开通日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.createdDate == null ? null : moment(new Date(this.state.model.createdDate), Config.dateFormat)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>推荐卡号：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input disabled
                                            placeholder="推荐卡号"
                                            value={this.state.model.whetherMasterCard || ''} />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}