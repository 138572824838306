import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';

import './openEnterpriseGoldCard.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class OpenEnterpriseGoldCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondarySelectedRowKeys: [],
            secondarySelectedRows: [],
            model: {},
            tables: [],
            secondaryList: [],
            secondaryFlag: "add",
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            secondaryModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: true,
            saveFlag: true,//重复点击标志
            secondarySave: true,
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
        }
        this.init()
    }

    init() {
        this.search();
        this.searchShop();
        this.searchPaymentAmount();
        this.searchCountcard();
        this.getArea(0, 'provinceList');
    }

    getArea(data, type) {
        let parentId = data;
        fetch(Config.apiBackServer + "area/getArea", {
            method: 'POST',
            body: JSON.stringify(parentId),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            } else {
                let list = data.object;
                if (type === "provinceList") {
                    this.setState({
                        provinceList: list,
                    })
                } else if (type === "cityList") {
                    this.setState({
                        cityList: list,
                    })
                } else {
                    this.setState({
                        areaList: list,
                    })
                }
            }
        }).catch(err => { });
    }

    searchCountcard() {
        fetch(Config.apiBackServer + "openMembership/searchCountcard", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            } else {
                this.setState({
                    goldCardCount: data.object.goldCardCount,
                    silverCardCount: data.object.silverCardCount,
                })
            }
            console.log(this.state.model);
        }).catch(err => { });
    }

    searchPaymentAmount() {
        let model = { segment: "RENEWAL_AMOUNT", code: "NUMERICAL_VALUE" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                paymentAmount: data.object[0].parmValue1,
            })
            console.log(this.state.model);
        }).catch(err => { });
    }

    searchShop() {
        fetch(Config.apiBackServer + "openMembership/searchShop", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchShopList: data.object.list,
            }, () => {
                let model = { shopId: this.state.searchShopList[0].shopId }
                this.setState({
                    model: model
                })
            })
        }).catch(err => { });
    };

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "openMembership/searchGoldCrad", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
            console.log(this.state.tables);
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }
    secondaryOnSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            secondarySelectedRowKeys: selectedRowKeys,
            secondarySelectedRows: selectedRows
        })
    }

    openEnterpriseGoldCard(statu) {
        var endDate = null;
        var shopFlag = false;
        var openSpecialGoldCard = false;
        if (statu === "openEnterpriseGoldCard") {
            endDate = new Date().setFullYear(new Date().getFullYear() + 1);
            shopFlag = true;

        } else if (statu === "openSpecialGoldCard") {
            endDate = new Date().setMonth(new Date().getMonth() + 6);
            shopFlag = true;
            openSpecialGoldCard = true;
        }

        this.setState({
            tabIndex: "2",
            flag: "add",
            shopFlag: shopFlag,
            preservationFlag: false,
            openSpecialGoldCard: openSpecialGoldCard,
            statu: statu,
            saveFlag: false,
            secondarySave: false,
            secondaryList: [],
            secondaryModel: { total: 0 },
            model: {
                openDate: new Date(),
                endDate: endDate,
                rebateRatio: 0.05,
                rebateType: 'CORPORATE',
                active: 'Y',
                shopId: 'S_1589272595001vCZ9',
                transactionType: 'CASH',
                paymentAmount: this.state.paymentAmount,

            },
        }, () => {
            console.log(statu);
            console.log(this.state.statu);

        })
    }

    addChildren(flag) {
        var searchModel = this.state.searchModel;
        var carnum = '';
        let model = {
            openDate: new Date(),
            endDate: new Date().setFullYear(new Date().getFullYear() + 1),
            active: 'Y',
            shopId: "S_1589272595001vCZ9",
            transactionType: 'CASH',
            cardNumber: carnum,
        }
        this.setState({
            tabIndex: "2",
            flag: flag,
            model: model,
            searchModel: searchModel,
        })
    }

    save(flag) {
        this.setState({
            saveFlag: true,
        })
        let msg = this.check();
        let url = "";
        if (msg !== "") {
            this.setState({
                saveFlag: false,
            })
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model
        if (this.state.flag === "add") {
            url = "openMembership/corporateCardInsert";
        } else if (this.state.flag === "edit") {
            url = "openMembership/update"
        }
        console.log(url);
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                if (this.state.flag === "add") {
                    let model = this.state.model
                    model.id = data.object.substr(0, 20)
                    this.setState({
                        model: model,
                        preservationFlag: true,
                    })
                }
                this.setState({
                    selectedRowKeys: [],
                    selectedRows: [],
                    flag: 'edit',
                    saveFlag: false,
                })
                this.searchCountcard();
                Message.openMsgSuccess("成功", "保存成功")
            } else {
                this.setState({
                    saveFlag: false,
                })
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });
    }


    check() {
        let msg = ""
        let model = this.state.model
        let flag = this.state.flag
        let rebateRatio = Number(model.rebateRatio);
        let paymentAmount = Number(model.paymentAmount || '');
        if (!model.cardNumber) {
            msg += "金卡卡号不能为空;"
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "HU")) {
                msg += "会员卡号不能以'SC'或'HU'开头;"
            } else if (model.cardNumber.length < 7) {
                msg += "金卡卡号长度至少为7位;"
            } else if (model.cardNumber.length == 9) {
                msg += "金卡卡号长度不能为9位;"
            } else if (Common.isEmpty(model.cardSource) && model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6") {
                msg += "会员卡号为10位时，不能以'6'开头;"
            }
        }
        if (model.endDate === null) {
            msg += "结束日期不能为空;"
        } else if (model.endDate <= model.openDate) {
            msg += "结束日期要大于开通会员日期;"
        }
        if (this.state.statu === "openEnterpriseGoldCard") {
            if (!model.rebateRatio) {
                msg += "返利比例不能为空;"
            } else if (model.rebateRatio <= 0) {
                msg += "返利比例为正数数值;"
            }
        }
        if (Common.isEmpty(model.rebateType)) {
            msg += "类型不能为空;";
        }

        if (this.state.flag === 'add' && model.rebateType === "CORPORATE") {
            if (Common.isEmpty(model.paymentAmount)) {
                msg += "缴费金额不能为空;"
            } else if (model.paymentAmount <= 0) {
                msg += "缴费金额为正数数值;"
            }
        }
        if (this.state.flag === "edit") {
            if (model.phone) {
                if (!model.userId) {
                    msg += "会员卡未绑定,不能修改手机号;"
                }
            }
        }
        return msg
    }

    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let module = this.state.selectedRows[0];
        var statu = "openEnterpriseGoldCard";
        if (module.rebateRatio === null || module.rebateRatio === "" || module.rebateRatio === undefined) {
            statu = "openSpecialGoldCard"
        }
        module.page = 1;
        module.pageSize = 10;
        fetch(Config.apiBackServer + "openMembership/editSearch", {
            method: 'POST',
            body: JSON.stringify(module),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let secondaryModel = this.state.secondaryModel;
            if ("SUCCESS" === data.msg) {
                module.paymentAmount = data.object.list[0].paymentAmount
                secondaryModel.page = 1;
                secondaryModel.pageSize = 10;
                this.setState({
                    tabIndex: "2",
                    model: module,
                    flag: "edit",
                    statu: statu,
                    saveFlag: false,
                    secondarySave: false,
                    secondaryModel
                }, () => {
                    if (Common.isNotEmpty(module.cityId)) {
                        this.getArea(module.provinceId, 'cityList');
                    }
                    if (Common.isNotEmpty(module.areaId)) {
                        this.getArea(module.cityId, 'areaList');
                    }
                    this.editSearch()
                })
            }
            console.log(data.object.list[0]);
        }).catch(err => { });
    }

    goldenCardExport() {
        this.setState({ isDown: true })
        fetch(Config.apiBackServer + "openMembership/goldenCardExport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "企业金卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + ".xlsx"
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

    silverCardExport() {
        this.setState({ isDown: true })
        let model = this.state.model
        model.whetherMasterCardId = model.id
        model.showShop = "Y"
        fetch(Config.apiBackServer + "openMembership/silverCardExport", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "企业银卡" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + ".xlsx"
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }
    secondaryPageChange = (current, pageSize) => {
        let secondaryModel = this.state.secondaryModel;
        secondaryModel.pageSize = pageSize;
        secondaryModel.page = current;
        this.setState({
            secondaryModel: secondaryModel,
        });
        this.editSearch();
    }

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        newModel[k] = e;
        if (k === "rebateType") {
            if (e === "CORPORATE") {
                newModel.provinceId = null;
                newModel.cityId = null;
                newModel.areaId = null;
                newModel.rebateRatio = 0.05
                newModel.endDate = new Date().setFullYear(new Date().getFullYear() + 1)
                newModel.paymentAmount = this.state.paymentAmount;
            } else {
                newModel.rebateRatio = 0.05
                newModel.paymentAmount = 0
                newModel.endDate = new Date().setFullYear(new Date().getFullYear() + 5)
            }
        }
        if (k === "provinceId") {
            newModel.cityId = null;
            newModel.areaId = null;
            this.state.cityList = [];
            this.state.areaList = [];
            this.getArea(e, 'cityList');
        } else if (k === "cityId") {
            newModel.areaId = null;
            this.state.areaList = [];
            this.getArea(e, 'areaList');
        }
        this.setState({
            model: newModel,
        });
    }
    secondaryChange(k, e) {//需求待定
        let newModel = this.state.secondaryModel;
        newModel[k] = e;
        this.setState({
            secondaryModel: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],
            secondaryFlag: "add"
        })
        this.search()
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            if (value === "2") {
                if (this.state.secondarySelectedRows.length == 1) {
                    this.secondaryEdit();
                } else {
                    this.secondaryAdd();
                }
            } else {
                this.setState({
                    secondaryTabIndex: value,
                })
                this.secondaryCancel();
            }
        } else {
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
                // else {
                //     this.add();
                // }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }

    }

    /**冻结 -N ,解冻 - Y */
    toFrozenOrThaw(state) {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (this.state.selectedRows[0].active === "Y" && state === "Y") {
            Message.openMsgError("错误", "该卡已激活，不能重复操作")
            return
        } else if (this.state.selectedRows[0].active === "N" && state === "N") {
            Message.openMsgError("错误", "该卡已冻结，不能重复操作")
            return
        }
        let that = this;
        confirm({
            title: state === 'Y' ? '您确定激活?' : '您确定冻结?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let mdoel = {
                    id: that.state.selectedRows[0].id,
                    active: state,
                    userId: that.state.selectedRows[0].userId
                }
                fetch(Config.apiBackServer + "openMembership/toFrozenOrThaw", {
                    method: 'POST',
                    body: JSON.stringify(mdoel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("成功", "保存成功");
                        // that.reset()
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });

    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth8",
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth12",
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                className: "tableWidth8",
                render: (text, record, index) =>
                    Common.isNotEmpty(record.userType) && record.userType === "HEAD_USER" ? "总部卡" :
                        (Common.isNotEmpty(record.userType) && record.userType === "STORE_CHARGE" ? '店铺卡' :
                            (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                        )
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: "tableWidth8",
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: "tableWidth8",
            },
            {
                title: '类型',
                dataIndex: 'rebateType',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getMapValue('rebateTypeMap', text)
            },
            {
                title: '所属地区',
                dataIndex: 'provinceName',
                className: 'tableWidth8',
                render: (text, item) => (Common.isEmpty(item.provinceName) ? "" : item.provinceName) + (Common.isEmpty(item.cityName) ? "" : item.cityName) + (Common.isEmpty(item.areaName) ? "" : item.areaName)
            },
            {
                title: '售卡人',
                dataIndex: 'cardSellerName',
                className: 'tableWidth8',
            },
            {
                title: '售卡人电话',
                dataIndex: 'cardSellerPhone',
                className: 'tableWidth8',
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: "tableWidth7",
                render: (text) => ContextHandler.getActiveValue(text)
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            },

        ];
        const secondaryColumns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth20",
            },
            {
                title: '用户手机号',
                dataIndex: 'phone',
                className: "tableWidth10",
            },
            {
                title: '预留手机号',
                dataIndex: 'reservedIdCard',
                className: "tableWidth10",
            },
            {
                title: '售卡人',
                dataIndex: 'cardSellerName',
                className: "tableWidth20",
            },
            {
                title: '售卡人电话',
                dataIndex: 'cardSellerPhone',
                className: "tableWidth10",
            },
            {
                title: '备注',
                dataIndex: 'remark',
                className: "tableWidth20",
            },
            {
                title: '状态',
                dataIndex: 'active',
                className: "tableWidth10",
                render: (text) => ContextHandler.getActiveValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const secondaryRowSelection = {
            selectedRowKeys: this.state.secondarySelectedRowKeys,
            onChange: this.secondaryOnSelectChange,
            type: 'radio',
        };
        return (
            <div className="openEnterpriseGoldCard">
                <div className="zhRoot">
                    <div className="zhTitle">企业金卡管理</div>
                    <Row>
                        <div className="" style={{ float: "left" }}>企业金卡总数：{this.state.goldCardCount} </div>
                        <div style={{ width: "50px", height: "1px", float: "left" }}></div>
                        <div style={{ float: "left" }}>企业银卡总数：{this.state.silverCardCount}</div>
                    </Row>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input
                                                maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">开卡日期：</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={2} className="zhTxt">至</Col>
                                        <Col span={3} className="zhInput">
                                            <DatePicker placeholder="至日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                value={this.state.searchModel.endDate == null ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>

                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">是否绑定用户：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.binding || ''}
                                                onChange={(e) => this.searchChange('binding', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.bindingMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={2} className="zhTxt">类型：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.rebateType || ''}
                                                onChange={(e) => this.searchChange('rebateType', e)} >
                                                <Option value={""}>全部</Option>
                                                {context.rebateTypeMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.openEnterpriseGoldCard("openEnterpriseGoldCard")} icon="plus">开通企业金卡</Button>
                                        {/* <Button className="zhButtonLeft" type="primary" onClick={() => this.openEnterpriseGoldCard("openSpecialGoldCard")} icon="plus">开通特殊时长金卡</Button> */}
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">修改</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.goldenCardExport()} icon="download">导出excel</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                    <Row>
                                        <Col span={4} className='zhTxt'>开卡店铺名称<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select style={{ width: "100%" }}
                                                placeholder="开卡店铺名称"
                                                value={this.state.model.shopId || ''}
                                                disabled
                                                onChange={(e) => this.modelChange('shopId', e)}>
                                                {this.state.searchShopList.map((item) => <Option key={item.shopId} value={item.shopId}>{item.shopName}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={4} className='zhTxt'>类型<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select style={{ width: "100%" }}
                                                disabled={this.state.flag === "edit"}
                                                value={this.state.model.rebateType || ''}
                                                onChange={(e) => this.modelChange('rebateType', e)}>
                                                {context.rebateTypeMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>开通会员日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开通会员卡日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.openDate == null ? moment(new Date(), Config.dateFormat) : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("openDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>结束日期<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="结束日期"
                                                className="parts-numberInput"
                                                allowClear={false}

                                                value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                                onChange={(value) => this.modelChange("endDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>


                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>金卡卡号<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={10}
                                                placeholder="金卡卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>

                                        <Col span={4} className='zhTxt' hidden={this.state.statu === "openSpecialGoldCard"}>返利比例<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput' hidden={this.state.statu === "openSpecialGoldCard"}>
                                            <Input type="phone" maxLength={8}
                                                value={this.state.model.rebateRatio || ''}
                                                onChange={(e) => this.modelChange('rebateRatio', e.target.value)} />
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Select style={{ width: "100%" }} placeholder="交易方式" value={this.state.model.transactionType || ''}
                                                disabled={this.state.flag === "edit"}
                                                onChange={(e) => this.modelChange('transactionType', e)}>
                                                {context.transactionTypeList.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                        </Col>
                                        <Col span={4} className='zhTxt' >缴费金额<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input type="phone" maxLength={9}
                                                disabled={this.state.flag === "edit"}
                                                value={this.state.model.paymentAmount || ''}
                                                onChange={(e) => this.modelChange('paymentAmount', e.target.value)} />
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>预留手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input placeholder="预留手机号"
                                                maxLength={11}
                                                value={this.state.model.reservedIdCard || ''}
                                                onChange={(e) => this.modelChange('reservedIdCard', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt' hidden={this.state.flag === "add"}>用户手机号<span className='redStar'>*</span>：</Col>
                                        <Col span={6} className='zhInput' hidden={this.state.flag === "add"}>
                                            <Input maxLength={11}
                                                placeholder="用户手机号"
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>

                                        {Common.isNotEmpty(this.state.model.rebateType) && this.state.model.rebateType == "BUSINESS" ?
                                            <>
                                                <Col span={4} className='zhTxt'>省：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Select style={{ width: "100%" }}
                                                        placeholder="省"
                                                        value={this.state.model.provinceId || ''}
                                                        onChange={(e) => this.modelChange('provinceId', e)}>
                                                        <Option value=""></Option>
                                                        {this.state.provinceList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                                    </Select>
                                                </Col>
                                            </>
                                            : null}
                                    </Row>
                                    {Common.isNotEmpty(this.state.model.rebateType) && this.state.model.rebateType == "BUSINESS" ?
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>市：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select style={{ width: "100%" }}
                                                    placeholder="市"
                                                    value={this.state.model.cityId || ''}
                                                    disabled={Common.isEmpty(this.state.model.provinceId)}
                                                    onChange={(e) => this.modelChange('cityId', e)}>
                                                    <Option value=""></Option>
                                                    {this.state.cityList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                                </Select>
                                            </Col>
                                            <Col span={4} className='zhTxt'>区：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Select style={{ width: "100%" }}
                                                    placeholder="区"
                                                    value={this.state.model.areaId || ''}
                                                    disabled={Common.isEmpty(this.state.model.cityId)}
                                                    onChange={(e) => this.modelChange('areaId', e)}>
                                                    <Option value=""></Option>
                                                    {this.state.areaList.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                                </Select>
                                            </Col>
                                        </Row>
                                        : null}
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>售卡人：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input
                                                maxLength={50}
                                                placeholder="售卡人"
                                                value={this.state.model.cardSellerName || ''}
                                                onChange={(e) => this.modelChange('cardSellerName', e.target.value)} />
                                        </Col>

                                        <Col span={4} className='zhTxt' >售卡人电话：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input type="phone" maxLength={11}
                                                placeholder="售卡人电话"
                                                value={this.state.model.cardSellerPhone || ''}
                                                onChange={(e) => this.modelChange('cardSellerPhone', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={6} className='zhInput'>
                                            <TextArea
                                                value={this.state.model.remark || ""}
                                                onChange={(e) => this.modelChange('remark', e.target.value)}
                                                placeholder="备注"
                                                maxLength={100}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                            <TabPane tab="银卡列表" key="1">
                                                <Row className='zhRow'>
                                                    <Button type="primary" onClick={() => this.secondaryAdd()} icon="plus">添加</Button>
                                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryEdit()} icon="edit">修改</Button>
                                                    <Button className="zhButtonLeft" type="primary" onClick={() => this.secondaryDelete()} icon="delete">删除</Button>
                                                    <Button className="zhButtonLeft" hidden={Common.isEmpty(this.state.model.id)} type="primary" onClick={() => this.silverCardExport()} icon="download">导出excel</Button>
                                                </Row>
                                                <Row className='zhRow'></Row>
                                                <Row className='row'>
                                                    <Table rowSelection={secondaryRowSelection}
                                                        rowKey="id"
                                                        bordered={true} //边线
                                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                        columns={secondaryColumns} //列名
                                                        dataSource={this.state.secondaryList} //数据
                                                        pagination={{  //分页
                                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                            onChange: this.secondaryPageChange,            //上页下页或者跳页方法
                                                            onShowSizeChange: this.secondaryPageChange,    //每页显示多少条数据方法
                                                            total: this.state.secondaryModel.total,             //总共多少条数据
                                                            current: this.state.secondaryModel.page,         //当前页
                                                            pageSize: this.state.secondaryModel.pageSize,       //每页显示多少条数据
                                                            defaultCurrent: 1,                               //默认当前页为1
                                                        }}

                                                        onRow={(record) => {
                                                            return {
                                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'secondarySelectedRows', 'secondarySelectedRowKeys'), // 点击行
                                                            };
                                                        }}
                                                        rowClassName="tableColor"
                                                    />
                                                </Row>
                                            </TabPane>
                                            <TabPane tab="银卡详情" key="2">
                                                <Row className='row'>
                                                    <Col span={4} className='zhTxt'>银卡卡号<span className='redStar'>*</span>：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={10}
                                                            placeholder="银卡卡号"
                                                            value={this.state.secondaryModel.cardNumber || ''}
                                                            disabled={Common.isNotEmpty(this.state.secondaryModel.cardSource) && this.state.secondaryModel.cardSource == "SYS"}
                                                            onChange={(e) => this.secondaryChange('cardNumber', e.target.value)} />
                                                    </Col>
                                                    {this.state.secondaryFlag === "add" ? <>
                                                        <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                        <Col span={6} className='zhInput'>
                                                            <Input placeholder="预留手机号"
                                                                maxLength={11}
                                                                value={this.state.secondaryModel.reservedIdCard || ''}
                                                                onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                        </Col>
                                                    </> : <>
                                                            <Col span={4} className='zhTxt' >用户手机号：</Col>
                                                            <Col span={6} className='zhInput' >
                                                                <Input placeholder="用户手机号"
                                                                    maxLength={11}
                                                                    value={this.state.secondaryModel.phone || ''}
                                                                    onChange={(e) => this.secondaryChange('phone', e.target.value)} />
                                                            </Col>

                                                        </>}
                                                </Row>
                                                <Row className='row'>
                                                    <Col span={4} className='zhTxt'>售卡人：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input
                                                            maxLength={50}
                                                            placeholder="售卡人"
                                                            value={this.state.secondaryModel.cardSellerName || ''}
                                                            onChange={(e) => this.secondaryChange('cardSellerName', e.target.value)} />
                                                    </Col>

                                                    <Col span={4} className='zhTxt' >售卡人电话：</Col>
                                                    <Col span={6} className='zhInput' >
                                                        <Input type="phone" maxLength={11}
                                                            placeholder="售卡人电话"
                                                            value={this.state.secondaryModel.cardSellerPhone || ''}
                                                            onChange={(e) => this.secondaryChange('cardSellerPhone', e.target.value)} />
                                                    </Col>
                                                </Row>
                                                <Row className='row' hidden={this.state.secondaryFlag === "edit"}>
                                                    <Col span={4} className='zhTxt'>备注：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <TextArea
                                                            value={this.state.secondaryModel.remark || ""}
                                                            onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                            placeholder="备注"
                                                            maxLength={100}
                                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='row' hidden={this.state.secondaryFlag === "add"}>
                                                    <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input placeholder="预留手机号"
                                                            maxLength={11}
                                                            value={this.state.secondaryModel.reservedIdCard || ''}
                                                            onChange={(e) => this.secondaryChange('reservedIdCard', e.target.value)} />
                                                    </Col>
                                                    <Col span={4} className='zhTxt'>备注：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <TextArea
                                                            value={this.state.secondaryModel.remark || ""}
                                                            onChange={(e) => this.secondaryChange('remark', e.target.value)}
                                                            placeholder="备注"
                                                            maxLength={100}
                                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className='zhRow zhRowButtonRight'>
                                                    <Button type="primary" disabled={this.state.secondarySave} icon="check-circle" onClick={() => { this.secondarySave(this.state.flag) }}>保存</Button>
                                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.secondaryCancel() }}>取消</Button>
                                                </Row>
                                            </TabPane>
                                        </Tabs>
                                    </Row>

                                    {this.state.secondaryTabIndex === "2" ? "" :
                                        <Row className='zhRow zhRowButtonRight'>
                                            <Button disabled={this.state.saveFlag} type="primary" icon="check-circle" onClick={() => { this.save(this.state.flag) }}>保存</Button>
                                            <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                        </Row>}
                                </Spin>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div >
        )
    }

    editSearch(flag) {
        var model = this.state.secondaryModel;
        model.whetherMasterCardId = this.state.model.id;
        if (flag === 'secondaryCancel') {
            model.pageSize = 10;
            model.page = 1;
        }
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "openMembership/searchGoldCardSecond", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    secondaryList: data.object.list,
                    secondaryModel: model
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    secondaryCancel() {
        this.setState({
            secondaryFlag: "add",
            secondaryTabIndex: "1",
            secondaryModel: {},
            secondarySelectedRows: [],
            secondarySelectedRowKeys: [],

        }, () => {
            this.editSearch("secondaryCancel");
        })

    }
    secondaryAdd() {
        if (!this.state.model.id) {
            Message.openMsgError("错误", "请先保存金卡信息")
            return
        }
        this.setState({
            secondaryFlag: "add",
            secondaryTabIndex: "2",
            secondaryModel: { active: "Y" },
            secondarySave: false,
        })
    }

    secondaryEdit() {
        if (this.state.secondarySelectedRows == null || this.state.secondarySelectedRows.length != 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        this.setState({
            secondaryFlag: "edit",
            secondaryTabIndex: "2",
            secondaryModel: this.state.secondarySelectedRows[0],
            secondarySave: false,
        })
    }
    secondarySave() {
        let model = this.state.secondaryModel
        if (this.state.secondaryFlag === "edit") {
            if (model.phone) {
                if (!model.userId) {
                    Message.openMsgError("错误", "会员卡未绑定,不能修改手机号");
                    return
                }
            }
        }
        if (!model.cardNumber) {
            Message.openMsgError("错误", "会员卡号不能为空");
            return
        } else {
            if (model.cardNumber.length >= 2 && (model.cardNumber.substr(0, 2) === "SC" || model.cardNumber.substr(0, 2) === "HU")) {
                Message.openMsgError("错误", "会员卡号不能以'SC'或'HU'开头");
                return
            } else if (model.cardNumber.length < 7) {
                Message.openMsgError("错误", "会员卡号长度至少为7位");
                return
            } else if (model.cardNumber.length == 9) {
                Message.openMsgError("错误", "会员卡号长度不能为9位");
                return
            } else if (Common.isEmpty(model.cardSource) && model.cardNumber.length == 10 && model.cardNumber.substr(0, 1) === "6") {
                Message.openMsgError("错误", "会员卡号为10位时，不能以'6'开头");
                return
            }
        }
        let url = "openMembership/update"
        if (this.state.secondaryFlag === "add") {
            url = "openMembership/secondaryInsert";
            model.whetherMasterCardId = this.state.model.id
        }
        console.log(url);
        model.shopId = this.state.model.shopId
        this.setState({ secondarySave: true })
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("保存成功", "");
                this.secondaryCancel()
                this.searchCountcard()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
            this.setState({ secondarySave: false, })
        }).catch(err => { });
    }
    secondaryDelete() {
        if (this.state.secondarySelectedRows == null || this.state.secondarySelectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.secondarySelectedRows[0]
        if (model.userId) {
            Message.openMsgError("错误", "会员已被绑定不能删除");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "openMembership/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.secondaryCancel()
                        that.searchCountcard();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

}