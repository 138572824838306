// import $ from 'jquery';
export class Config {
    // // 本地地址
    //  static apiServer = "http://192.168.0.154:9971/";
    //  static imgServer = "https://restaurant-img-1301092565.cos.ap-guangzhou.myqcloud.com/test/";
    //  static imgServer = "http://192.168.0.245:8080/imgs/image/restaurant_member_imgs/";//废弃

    //新客户线网地址
    static apiServer = "https://api.jinhuixitong.com/";
    static imgServer = "https://restaurant-img-1301092565.cos.ap-guangzhou.myqcloud.com/";
    // static imgServer = "https://www.jinhuixitong.com/image/restaurant_member_imgs/";//废弃,转为腾讯云
    
    // //测试线网地址
    // static apiServer = "https://jinhui.58xv.com:9972/";
    // static imgServer = "https://jinhui.58xv.com/image/restaurant_member_imgs/";
    
    static apiBackServer = Config.apiServer + "back/";

    static headPortraitImgs = Config.imgServer + "headPortraitImgs/";//用户头像

    static shopLogo = Config.imgServer + "classify/";//申请店铺图片
    
    static homePageScroll = Config.imgServer + "homePageScroll/";//轮播图

    static consumptionRebate = Config.imgServer + "consumptionRebate/";//轮播图

    static generalizedQRCode = Config.imgServer + "generalizedQRCode/";//临时推广二维码

    static permanentGeneralizedQRCode = Config.imgServer + "permanentGeneralizedQRCode/";//永久推广二维码

    static img3DCompressionDiagram = Config.imgServer +"3D/compressionDiagram/";//店铺全景图片
    
    static icon = Config.imgServer + "icon/";//收银台支付图

    static dateFormat = 'YYYY-MM-DD';//日期格式化样式
    static dateFormatHm = 'YYYY-MM-DD HH:mm';//日期格式化样式
    static dateFormatALL = 'YYYY-MM-DD HH:mm:ss';//日期格式化样式
    static monthFormat = 'YYYY-MM'; //日期格式化样式

    static header = {
        'authorization': "",
        'Content-Type': 'application/json;charset=UTF-8'
    };

    static userInfo = {
        token: "abc",
        userId: "SU_1547774716740SMR8",//SU_1546927709678bPU4
    };

    static equipmentType = 'pc';
    static openKeys = [];

}