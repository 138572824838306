import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import SelectCard from './selectCard';
import { ContextHandler } from '../../public/js/contextHandler';
import './openGoldCard.css';

const confirm = Modal.confirm;
var css;
export default class OpenGoldCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            model: {},
            tables: [],
            isLoading: true,
            searchModel: { pageSize: 10, page: 1, },
            // searchRecordModel: { pageSize: 10, page: 1, },
            // searchShopList: [],
            shopFlag: false,
            renewalList: []
        }
    }

    //组件渲染之后调用
    componentDidMount() {
    this.search();
    this.searchPaymentAmount();
    }

    //获取系统参数金卡费用
    searchPaymentAmount() {
        let model = { segment: "RENEWAL_AMOUNT", code: "NUMERICAL_VALUE" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("RENEWAL_AMOUNT",data)
            let model=this.state.model;
            model.paymentAmount= data.object[0].parmValue1;
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                model:model,
            })
            console.log(model)
        }).catch(err => { });
    }
    


    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "openGoldCard/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                console.log(data.object.list)
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    searchRecord(cardNumber) {
        console.log("cardNumber",this.state.model)

        this.setState({
            isLoading: true ,
            // searchRecordModel:{cardNumber:cardNumber},
            renewalList:[],
        })
        // console.log("searchRecordModel",this.state.searchRecordModel)
        fetch(Config.apiBackServer + "openGoldCard/searchRecord", {
            method: 'POST',
            body: JSON.stringify(cardNumber),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("data",data)
            let model = this.state.model;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                let finish ;
                //判断有无开通金卡记录
                if (Common.isNotEmpty(data.object.list)) {
                     finish = data.object.list[0].endDate; //取金卡到期时间
                }else{
                    finish =null
                }
                const newDate = Date.parse(new Date()); 
                // console.log("newDate",newDate);
                let openDate=null;
                let endDate=null;
                if (Common.isEmpty(finish) || finish<=newDate) {
                    //开始时间
                    openDate=new Date();
                     //结束时间
                     let date = new Date();
                     endDate = new Date(date.setFullYear(date.getFullYear() + 1));
                }else if (finish>newDate) {
                    //开始时间
                    openDate=new Date(finish)
                    //结束时间
                    let date = new Date(finish);
                    endDate = new Date(date.setFullYear(date.getFullYear() + 1));
                }
                model.openDate = openDate;
                model.endDate = endDate;
                console.log("startDate2",openDate);
                console.log("endDate",endDate);
            }
            this.setState({
                model: model,
                renewalList: data.object.list
            })
            console.log("model",model)
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
            this.setState({
            flag: "edit",
            tabIndex: "2",
            // model: model,
        })
    }

    save() {
        let that = this;
        fetch(Config.apiBackServer + "openGoldCard/insertGoldCard", {
            method: 'POST',
            body: JSON.stringify(that.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("会员卡续费成功", "");
                this.cancel()
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });

    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryTabIndex: "1",
            renewalList: [],
            model:{},
            // searchRecordModel: { pageSize: 10, page: 1, },
            searchModel:{ pageSize: 10, page: 1, },
        })
        this.search()
        this.searchPaymentAmount();
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            this.setState({
                secondaryTabIndex: value,
            })
        } else {
            if (value === "2") {
                this.add();
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }
    }

    openPopup() {
        this.selectShopPopup.open()
    }

    closePopup() {
        let shopModel = this.selectShopPopup.state.selectedRows[0]
        let model = this.state.model
        model.cardNumber = shopModel.cardNumber
        model.phone = shopModel.phone
        model.membershipCardId = shopModel.id
        model.status = shopModel.status
        this.searchRecord(shopModel.cardNumber);
        this.setState({
            model: model
        })
    }

    selectShopPopup = (ref) => {
        this.selectShopPopup = ref;
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '金卡卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth12',
            },
            {
                title: '金卡开始日期',
                dataIndex: 'openDate',
                className: 'tableWidth12',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '金卡结束日期',
                dataIndex: 'endDate',
                className: 'tableWidth12',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '缴费金额',
                dataIndex: 'paymentAmount',
                className: 'tableWidth8',
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '交易方式',
                dataIndex: 'transactionType',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '缴费时间',
                dataIndex: 'createdDate',
                className: 'tableWidth12',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                className: 'tableWidth12',
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
            {
                title: '缴费店铺',
                dataIndex: 'shopName',
                className: 'tableWidth12',
            },
        ];
        const renewalColumns = [
            {
                title: '金卡卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '金卡开始日期',
                dataIndex: 'openDate',
                className: 'tableWidth10',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '金卡结束日期',
                dataIndex: 'endDate',
                className: 'tableWidth10',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormat),
            },
            {
                title: '缴费金额',
                dataIndex: 'paymentAmount',
                className: 'tableWidth10',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '缴费时间',
                dataIndex: 'createdDate',
                className: 'tableWidth15',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '交易方式',
                dataIndex: 'transactionType',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="openGoldCard">
                <div className="zhRoot">
                    <div className="zhTitle">金卡缴费</div>
                    <div className="content">
                        <SelectCard onRef={this.selectShopPopup} closePopup={() => this.closePopup()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">金卡卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="金卡卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            type="phone"
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">缴费时间：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="userRebateStatisticsDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.createdStartDate == null ? null : moment(new Date(this.state.searchModel.createdStartDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("createdStartDate", value)}
                                            format={Config.dateFormat} />
                                    &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            className="userRebateStatisticsDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.createdEndDate == null ? null : moment(new Date(this.state.searchModel.createdEndDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("createdEndDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    {/* <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">线上开通金卡</Button> */}
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">金卡管理</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号<span className='redStar'>*</span>：</Col>
                                        <Col span={4} className='zhInput'>
                                            <Input
                                                placeholder="会员卡号"
                                                disabled
                                                value={this.state.model.cardNumber || ''} />
                                        </Col>
                                        <Col span={2} style={{ marginTop: "5px" }} >
                                            <Button type="primary" onClick={() => this.openPopup()} >选择会员</Button>
                                        </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员开始日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="会员开始日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.openDate == null ? null : moment(new Date(this.state.model.openDate), Config.dateFormat)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员结束日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="会员结束日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.endDate == null ? null : moment(new Date(this.state.model.endDate), Config.dateFormat)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                            <Input
                                            maxLength={8}
                                            placeholder="交易方式"
                                            disabled
                                            value="现金" />
                                    </Col>
                                    <Col span={4} className='zhTxt'>缴费金额<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={8}
                                            placeholder="缴费金额"
                                            disabled
                                            value={this.state.model.paymentAmount || ''} />
                                    </Col>
                                </Row>
                                
                                <Row className='zhRow'>
                                    <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                        <TabPane tab="缴费记录" key="1">
                                            <Row className='zhRow'></Row>
                                            <Table rowKey="id"
                                                bordered={true} //边线
                                                columns={renewalColumns} //列名
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                dataSource={this.state.renewalList} //数据
                                                pagination={false}
                                                rowClassName="tableColor"
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

}