import { Common } from "./common";

import Context from "./context";
/**
 * 处理Context.js中数据的方法
 */
export class ContextHandler {
    
    static getTransactionTypeValue = (key)=>{
        if(Common.isEmpty(key)){
            return;
        }
        let index = Context.transactionTypeList.findIndex( item => item.key === key);
        if(index !== -1){
            return Context.transactionTypeList[index].value;
        }
    }

    static getDealingTypeValue = (key)=>{
        if(Common.isEmpty(key)){
            return;
        }
        let index = Context.dealingTypeList.findIndex( item => item.key === key);
        if(index !== -1){
            return Context.dealingTypeList[index].value;
        }
    }

    static getIndValue = (key)=>{
        if(Common.isEmpty(key)){
            return;
        }
        let index = Context.inds.findIndex( item => item.key === key);
        if(index !== -1){
            return Context.inds[index].value;
        }
    }

    static getActiveValue = (key)=>{
        if(Common.isEmpty(key)){
            return;
        }
        let index = Context.activeMap.findIndex( item => item.key === key);
        if(index !== -1){
            return Context.activeMap[index].value;
        }
    }

    // 根據集合名字和指定key獲取對應value
    static getMapValue = (mapName, key)=>{
        if(Common.isEmpty(key) || Common.isEmpty(mapName) || Common.isEmpty(Context[mapName])){
            return;
        }
        let index = Context[mapName].findIndex( item => item.key === key);
        if(index !== -1){
            return Context[mapName][index].value;
        }
    }
}