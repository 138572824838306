import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import './shopOpenGoldCard.css';

const confirm = Modal.confirm;
var css;
export default class ShopOpenGoldCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            secondaryTabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryList: [],
            model: {page: 1, pageSize: 10,},
            tables: [],
            isLoading: false,
            searchModel: { pageSize: 10, page: 1, },
            shopFlag: false,
            renewalList: [],
            shopName:'',

            showModal:false,   //展示支付二维码
            openPaymentInd:'',
            showModal:false,   //展示支付二维码
            payQrCode:"",   // 支付二维码
            flowGoldCardRenewalId:"",//订单ID
            timer:null,
            countDownTimer:null,
            paymentTotalAmount:0,
            timeDown:300,   //300秒倒计时
            isShowModalLoading:false,

            cardNumber:'',
            phone:'',
            inputFlag: '', //输入的是手机号码还是卡号

            paymentAmount:'',//开通金卡需要缴费的金额
        }
    }

    //组件渲染之后调用
    componentDidMount() {
    this.search();
    this.searchPaymentAmount();
    this.searchOpenWxPayment();//查询是否开通微信支付
    }

    searchOpenWxPayment(){
        let model = { segment:"OPEN_WECHAT_PAYMENT", code:"OPEN_IND" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let openPaymentInd = "N";
            if(data.object !=null && data.object.length > 0){
                openPaymentInd = data.object[0].parmValue1;
            }
            this.setState({ openPaymentInd: openPaymentInd });
        }).catch(err => { });
    }
    //获取系统参数金卡费用
    searchPaymentAmount() {
        let model = { segment: "RENEWAL_AMOUNT", code: "NUMERICAL_VALUE" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("RENEWAL_AMOUNT",data)
            let {paymentAmount}=this.state;
            paymentAmount= data.object[0].parmValue1;
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                paymentAmount
            })
            console.log(model)
        }).catch(err => { });
    }
    
    search() {
        let model = this.state.searchModel;
        model.shopId = Config.userInfo.shopId;
        console.log(model)
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "shopOpenGoldCard/search", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                console.log(data)
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list,
                shopName : data.object.list[0].shopName
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        let model = this.state.selectedRows[0];
        if(Common.isNotEmpty(model) && Common.isNotEmpty(model.cardNumber)){
            let newModle = this.state.model;
            newModle.cardNumber=model.cardNumber
            console.log(model)
            this.setState({
                cardNumber : model.cardNumber,
                model: newModle,
            },()=>{
                this.changeSearch("cardNumber");
            });
        }
            this.setState({
                flag: "edit",
                tabIndex: "2",
            })
    }
    /** 调 获取收款二维码 方法--*/
    payment(model){
        console.log("获取收款二维码");
        model.shopId = Config.userInfo.shopId;
        if(Common.isEmpty(model.cardNumber)){
            Message.openMsgError("错误", "电话号码不能为空");
            return;
        }
        if(Common.isEmpty(model.phone)){
            Message.openMsgError("错误", "电话号码不能为空");
            return;
        }
        if(this.state.openPaymentInd !=="Y"){
            Message.openMsgError("错误","微信支付暂未开通，请联系平台");
            return;
        }
        model.paymentAmount = this.state.paymentAmount;
        console.log(model);
        this.setState({isShowModalLoading:true,});
        fetch(Config.apiBackServer + "shopOpenGoldCard/getPayQRCode", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            console.log(data);
            this.setState({
                showModal: true,
                payQrCode: data.object.qrCodeBase,
                flowGoldCardRenewalId: data.object.flowGoldCardRenewalId,
                paymentTotalAmount: data.object.receiveAmount,
                timeDown:300,
            },()=>{
                this.countDownFunc();//300秒开始倒计时
                this.orderQuery();
            });
        }).catch(err => { }).finally(() => 
            this.setState({isShowModalLoading:false})
        ); 
    }

    countDownFunc(){
        let countDownTimer = setTimeout(() => {
            this.setState({timeDown:this.state.timeDown-1},()=>{
                if(this.state.timeDown <= 0){
                    clearTimeout(this.state.countDownTimer); 
                    this.payCancel("timeOut");//取消订单
                }else{
                    this.countDownFunc();
                }
            })
        }, 1000);
        this.setState({countDownTimer})
    }

    orderQuery() {
        console.log("orderQuery");
        let parmModel = this.state.model;
        parmModel.flowGoldCardRenewalId = this.state.flowGoldCardRenewalId;
        console.log(parmModel)
        fetch(Config.apiBackServer + "shopOpenGoldCard/orderQuery", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("orderQuery data");
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            let flag = true;
            if(Common.isNotEmpty(data.object) && data.object.indexOf("SUCCESS") >= 0) {
                console.log("SUCCESS---------");
                clearTimeout(this.state.countDownTimer); 
                this.reSearch();
                Message.openMsg("success","支付成功");
            } else if("CLOSED" === data.object) {
                console.log("CLOSED---------");
                this.reSearch();
                Message.openMsg("info", "已取消支付");
            } else {
                console.log("继续---------");
                let {timer} = this.state;
                if(this.state.showModal){
                    timer = setTimeout(() => {
                        this.orderQuery();
                    }, 6000);
                    flag = false;
                    this.setState({timer})
                }
            }
            if(flag) {
                this.setState({
                    showModal: false,
                    payQrCode: "",
                })
            }
        }).catch(err => { });
    }

    /**支付取消 */
    payCancel(parm) {
        console.log("cancelOrder");
        let parmModel = this.state.model;
        parmModel.flowGoldCardRenewalId = this.state.flowGoldCardRenewalId;
        let {timer,countDownTimer} = this.state;
            console.log("clearTimeout timer");
            clearTimeout(timer);
            if("timeOut" !== parm){
                console.log("clearTimeout countDownTimer");
                clearTimeout(countDownTimer);
            }
        this.setState({
            showModal: false,
            payQrCode: "",
            flowGoldCardRenewalId: "",
        })
        fetch(Config.apiBackServer + "shopOpenGoldCard/cancelOrder", {
            method: 'POST',
            body: JSON.stringify(parmModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" !== data.msg) {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            if("SUCCESS" === data.object || data.object.indexOf("SUCCESS") >=0) {
                // clearTimeout(this.state.countDownTimer);
                // this.handlRebateLogic(parmModel);//处理提成逻辑
                this.reSearch();
                Message.openMsg("success", "支付成功");
            } else if("CLOSED" === data.object || data.object.indexOf("CLOSED") >=0) {
                if("timeOut" === parm){
                    Message.openMsg("error", "二维码已过期,请重新获取二维码");
                }else{
                    Message.openMsg("info", "已取消支付");
                }
                this.reSearch();
            }
        }).catch(err => { }).finally(() => {
            let {timer,countDownTimer} = this.state;
            console.log("clearTimeout timer");
            clearTimeout(timer);
            if("timeOut" !== parm){
                console.log("clearTimeout countDownTimer");
                clearTimeout(countDownTimer);
            }
            this.setState({
                showModal: false,
                payQrCode: "",
                flowGoldCardRenewalId: "",
            })
        });
    }

    reSearch(){
        this.setState({
            model:{pageSize: 10, page: 1,},
            payQrCode:"",
            paymentTotalAmount:0,
            timeDown:300,
        },()=>{
            this.changeSearch("cardNumber");
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    recordsPageChange = (current, pageSize) => {
        let model = this.state.model;
        model.pageSize = pageSize;
        model.page = current;
        this.setState({
            model: model,
        });
        this.searchUserPaymentRecord(model);
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    modelChange(k, e) {
        console.log(k,e)
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            cardNumber:"",
            phone:"",
            renewalList:[],
        });
        if(k==="cardNumber"){
            newModel.phone = '';
            newModel.headPortrait = "";
            newModel.nickName="";
            newModel.cardType = "";
            if(e.length >= 6){
                this.setState({
                    model: newModel,
                    cardNumber:e,
                    amountFlag: 'cardNumber', 
                },()=>{
                    this.changeSearch(k);
                });
            }
        }
        if(k === 'phone' ){
            newModel.cardNumber='';
            newModel.headPortrait = "";
            newModel.nickName="";
            newModel.cardType = "";
            if( e.length === 11 ){
                this.setState({
                    amountFlag: 'phone', 
                    model: newModel,
                    phone:e,
                },()=>{
                    this.changeSearch(k);
                });
            }
        }
        this.setState({
            model: newModel,
        });
    }

    changeSearch(k) {
        let newModel = this.state.model;
        newModel.searchType = k;
        newModel.cardNumber = this.state.cardNumber;
        newModel.phone = this.state.phone;
        console.log(newModel);
        fetch(Config.apiBackServer + "shopOpenGoldCard/searchGoldCardPayBySearchType", {
            method: 'POST',
            body: JSON.stringify(newModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" === data.msg) {
                if(Common.isNotEmpty(data.object)){
                    let model = data.object;
                    console.log(data)
                    if(k==="cardNumber"){
                        newModel.phone = model.phone;
                    }
                    if(k==="phone"){
                        newModel.cardNumber = model.cardNumber;
                    }
                    let stDate = new Date();
                    if(Common.isNotEmpty(model.startDate) && new Date(model.startDate).getTime() >  stDate.getTime()) {
                        stDate = model.startDate;
                    }
                    newModel.openDate = new Date(stDate);
                    var date=new Date(newModel.openDate);
                    date.setFullYear(date.getFullYear()+1);
                    newModel.endDate = date;
                    newModel.membershipCardId =model.membershipCardId;
                    newModel.headPortrait = model.headPortrait;
                    newModel.nickName = model.nickName;
                    newModel.cardType = model.cardType;
                    console.log(newModel)
                    this.searchUserPaymentRecord(newModel);
                }
            } else {
                console.log(data.msg);
                Common.error("暂无数据", 2.5)
                //清空数据
                newModel.phone = "";
                newModel.cardNumber = "";
                newModel.openDate = null;
                newModel.endDate = null;
            }
            this.setState({
                model: newModel,
            });
        }).catch(err => {});
    }

    searchUserPaymentRecord(model){
        console.log(model)
        fetch(Config.apiBackServer + "shopOpenGoldCard/searchUserPaymentRecord", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            console.log(data)
            let totalModel = this.state.model;
            if ("SUCCESS" === data.msg) {
                if(Common.isNotEmpty(data.object.list)){
                    list = data.object.list;
                    console.log(list)
                }
                totalModel.recordsTotal = data.object.total;
            } else {
                console.log(data.msg);
                Common.error("暂无数据", 2.5)
            }
            this.setState({
                renewalList: list,
                model: totalModel,
            });
        }).catch(err => {});
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            secondaryTabIndex: "1",
            renewalList: [],
            model:{ pageSize: 10, page: 1,},
            // searchRecordModel: { pageSize: 10, page: 1, },
            searchModel:{ pageSize: 10, page: 1, },
            model:{ pageSize: 10, page: 1, },
            cardNumber:'',
            phone:'',
        })
        this.search()
        this.searchPaymentAmount();
    }

    // 重置
    reset(){
    this.setState({
        searchModel: {
            page: 1,
            pageSize: 10,
        },
        model:{pageSize: 10, page: 1,},
        payQrCode:"",
        paymentTotalAmount:0,
        timeDown:300,
    },()=>{
        this.search();
    })
    }

    tableChange(value, flag) {
        if (flag === "secondary") {
            this.setState({
                secondaryTabIndex: value,
            })
        } else {
            if (value === "2") {
                this.add();
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }
        }
    }

   /*  //键盘监听事件
    handleKeyDown(e) {
        let model = this.state.model;
        console.log(e.keyCode)
        if (e.keyCode === 13 || e.keyCode === 9) {//按下了Enter键 或者Tab切换键

            if (this.state.amountFlag === "phone") {
                if (model.phone == null || model.phone === undefined || model.phone === "") {
                    this.clear();
                    Message.openMsgError('请输入会员手机号.');
                } else {
                    this.changeSearch('phone');
                }
            } else if (this.state.amountFlag === "cardNumber") {
                if (model.cardNumber == null || model.cardNumber === "" || model.cardNumber === undefined) {
                    this.clear();
                    Message.openMsgError('请输入会员卡号.');
                } else {
                    this.changeSearch('cardNumber',true);
                }
            }
        }
    } */

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '金卡卡号',
                dataIndex: 'cardNumber',
               /*  className: 'tableWidth12', */
            },
            {
                title: '金卡开始日期',
                dataIndex: 'openDate',
                /* className: 'tableWidth15', */
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '金卡结束日期',
                dataIndex: 'endDate',
                /* className: 'tableWidth15', */
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '缴费金额',
                dataIndex: 'paymentAmount',
                /* className: 'tableWidth12', */
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '交易方式',
                dataIndex: 'transactionType',
                /* className: 'tableWidth12', */
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '缴费时间',
                dataIndex: 'goldPaymentTime',
                /* className: 'tableWidth12', */
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                /* className: 'tableWidth12', */
            },
        ];
        const renewalColumns = [
            {
                title: '金卡卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '金卡开始日期',
                dataIndex: 'openDate',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '金卡结束日期',
                dataIndex: 'endDate',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '缴费金额',
                dataIndex: 'paymentAmount',
                /* render: (text) => Common.numberFormat(text) */
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '缴费时间',
                dataIndex: 'goldPaymentTime',
                render: (text) => text == null ? null : moment(text).format(Config.dateFormatALL),
            },
            {
                title: '交易方式',
                dataIndex: 'transactionType',
                render: (text) => ContextHandler.getTransactionTypeValue(text)
            },
            {
                title: '支付状态',
                dataIndex: 'payStatus',
                render: (text) => ContextHandler.getMapValue("payStatusMap",text)
            },
        ];
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="shopOpenGoldCard">
                <div className="zhRoot">
                    <div className="zhTitle">开通金卡</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">金卡卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="金卡卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            type="phone"
                                            placeholder="手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">缴费时间：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="userRebateStatisticsDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.paymentTimeStartDate == null ? null : moment(new Date(this.state.searchModel.paymentTimeStartDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("paymentTimeStartDate", value)}
                                            format={Config.dateFormat} />
                                    &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            className="userRebateStatisticsDatePicker"
                                            allowClear={true}
                                            value={this.state.searchModel.paymentTimeEndDate == null ? null : moment(new Date(this.state.searchModel.paymentTimeEndDate), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("paymentTimeEndDate", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    {/* <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">线上开通金卡</Button> */}
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">开通金卡</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                        rowClassName="tableColor"
                                    />
                                </Row>

                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Row className='row'>
                                    <div className="head" hidden={!(Common.isNotEmpty(this.state.model.headPortrait)|| Common.isNotEmpty(this.state.model.nickName) || Common.isNotEmpty(this.state.model.cardType))}>
                                        <div className="headPortrait" hidden={Common.isEmpty(this.state.model.phone)  || Common.isEmpty(this.state.model.cardNumber) }>
                                            <img alt=""  src={Common.isEmpty(this.state.model.headPortrait)
                                                ? Config.headPortraitImgs + "defaultHead.png"
                                                : (this.state.model.headPortrait.indexOf('http') == -1 && (this.state.model.headPortrait.indexOf('https') == -1)
                                                    ? Config.headPortraitImgs + this.state.model.headPortrait
                                                    : this.state.model.headPortrait
                                                )
                                                } 
                                                />
                                        </div>
                                        <div className="right" hidden={Common.isEmpty(this.state.model.phone)  || Common.isEmpty(this.state.model.cardNumber) }>
                                            <div className="nickName">{this.state.model.nickName}</div>
                                            <div className="cardType">{this.state.model.cardType === "GOLDEN_CARD" ? "金卡会员" : "银卡会员"}</div>
                                        </div>
                                        {/* <div className="cardType" hidden={Common.isEmpty(this.state.model.phone)  || Common.isEmpty(this.state.model.cardNumber) }>
                                            <img alt=""  src={Config.icon + (this.state.model.cardType === "GOLDEN_CARD" ? "goldMember.png" : "silverMember.png")}></img>
                                        </div> */}
                                    </div>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className="zhTxt">手机号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className="zhInput">
                                        <Input maxLength={11}
                                            type="phone"
                                            placeholder="手机号"
                                            value={this.state.model.phone || ''}
                                            onChange={(e) => this.modelChange('phone', e.target.value)}
                                            /* onKeyDown={(e) => this.handleKeyDown(e)} *//>
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员卡号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            placeholder="会员卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} 
                                            /* onKeyDown={(e) => this.handleKeyDown(e)} *//>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>会员开始日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="会员开始日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.openDate == null ? null : moment(this.state.model.openDate, Config.dateFormatALL)}
                                            format={Config.dateFormatALL} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>会员结束日期<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <DatePicker placeholder="会员结束日期"
                                            className="parts-numberInput"
                                            allowClear={false}
                                            disabled
                                            value={this.state.model.endDate == null ? null : moment(this.state.model.endDate, Config.dateFormatALL)}
                                            format={Config.dateFormatALL} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>交易方式<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                            <Input
                                            maxLength={8}
                                            placeholder="交易方式"
                                            disabled
                                            value="微信" />
                                    </Col>
                                    <Col span={4} className='zhTxt'>缴费金额<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input
                                            maxLength={8}
                                            placeholder="缴费金额"
                                            disabled
                                            value={this.state.paymentAmount || ''} />
                                    </Col>
                                </Row>
                                
                                <Row className='zhRow'>
                                    <Tabs defaultActiveKey='1' activeKey={this.state.secondaryTabIndex} onChange={(value) => this.tableChange(value, "secondary")}>
                                        <TabPane tab="缴费记录" key="1">
                                            <Row className='zhRow'></Row>
                                            <Table rowKey="id"
                                                bordered={true} //边线
                                                columns={renewalColumns} //列名
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                dataSource={this.state.renewalList} //数据
                                                pagination={{
                                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                    onChange: this.recordsPageChange,            //上页下页或者跳页方法
                                                    onShowSizeChange: this.recordsPageChange,    //每页显示多少条数据方法
                                                    total: this.state.model.recordsTotal,             //总共多少条数据
                                                    current: this.state.model.page,         //当前页
                                                    pageSize: this.state.model.pageSize,       //每页显示多少条数据
                                                    defaultCurrent: 1, 
                                                }}
                                                rowClassName="tableColor"
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button disabled={this.state.isShowModalLoading} type="primary" icon="check-circle" onClick={() => { this.payment(this.state.model) }}>支付</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                            <Modal
                                title={<div className="modalTitle">微信支付</div>}
                                key={"openModal"}
                                className="shopToPayModal"
                                maskClosable={false}
                                width={700}
                                visible={this.state.showModal}
                                onCancel={() => this.payCancel()}
                                footer={[
                                    <Button key='cancel' className="buttonWhiteStyle" onClick={() => this.payCancel()}>取消</Button>
                                ]}
                            >
                                <div className="topInfo">
                                    <div className="order">店铺名称：<span className="infoValue">{this.state.shopName}</span></div>
                                    <div className="amount">消费日期：<span className="infoValue">{moment(this.state.model.cashierTime).format(Config.dateFormat)}</span></div>
                                </div>
                                <div className="topInfo marginBttom">
                                    <div className="order">订单编号：<span className="infoValue">{this.state.flowGoldCardRenewalId}</span></div>
                                    <div className="amount">应付金额 {<span className="amountFont">{this.state.paymentTotalAmount}</span>} 元</div>
                                </div>
                                <div className="topInfo textLeft">
                                    距离二维码过期还有 <span className="redFont">{this.state.timeDown}</span> 秒，请尽快付款！过期后请重新获取二维码。
                                </div>
                                <div className="leftImg">
                                    <img alt="" src={"data:image/png;base64," + this.state.payQrCode} className="payQrCode" />
                                    <img alt="" src={Config.icon+"tishi.png"} className="tishi" />
                                </div>
                                <div className="rightInfo">
                                    <img alt="" src={Config.icon+"phoneBtn.png"}  className="phoneBtn" />
                                </div>
                            </Modal>                   
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}

