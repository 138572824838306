import React, { Component } from "react";
import { Spin,  Button, Row, Col, Tabs, Table, Input, Modal, DatePicker , Icon} from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';


export default class MemberWalletFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            searchModel:{page: 1, pageSize: 10,},
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "memberWalletFlow/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    tables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
       this.search();
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }


    reset() {
        this.setState({
            searchModel: {page: 1, pageSize: 10,},
            selectedRowKeys: [],
            selectedRows: [],
        }, () => {
            this.search()
        })
    }

    render() {

        const { TabPane } = Tabs;

        const columns = [
            {
                title: '会员钱包流水ID',
                dataIndex: 'detailsReadyWithdrawalAmountId',
                className: 'tableWidth14',
            },
            {
                title: '会员头像',
                dataIndex: 'headPortrait',
                className: 'tableWidth10',
                render: (text) => (Common.isNotEmpty(text) ?
                <img alt="" className="img" src={(text.search('http') == -1) && (text.search('https') == -1) ? Config.headPortraitImgs + text : text} /> : null),
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '变动前金额',
                dataIndex: 'changeBeforeAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '变动金额',
                dataIndex: 'changeAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '变动后金额',
                dataIndex: 'changeAfterAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '变动时间',
                dataIndex: 'changeTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '交易类型',
                dataIndex: 'transactionType',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getMapValue('flowWithdrawalAmountMap', text)
            },
            {
                title: '备注',
                dataIndex: 'withdrawalRemark',
                className: 'tableWidth8',
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }

        return(
            <div className="memberWalletFlow">
                <div className="zhRoot">
                    <div className="zhTitle">会员钱包流水</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                            <TabPane tab="列表" key='1'>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">变动日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="memberWalletFlowDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="memberWalletFlowDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="detailsReadyWithdrawalAmountId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'detailsReadyWithdrawalAmountId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}