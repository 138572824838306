

import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, DatePicker, Spin, AutoComplete} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './shopTurnoverAudit.css';
import { ContextHandler } from '../../public/js/contextHandler';
const { TextArea } = Input;

export default class shopTurnoverAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            searchModel: { pageSize: 10, page: 1, auditStatus: "",paymentStartTime: new Date().setDate(new Date().getDate() - 7), paymentEndTime:new Date() },
            saveModel: {},
            notAllowEditing: true,
            loading: false,
            isExport: false,

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

      //组件渲染之后调用
    componentDidMount() {
        this.search();
        this.searchShop();
    }

    /**查询平台返利数据 */
    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "shopTurnoverAudit/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("data",data)
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    // 查询全部店铺
    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    }

    reset() {
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            searchModel: {
                page: 1,
                pageSize: 10,
                auditStatus: "",
                shopId: null,
                auditStartDate: null,
                auditEndDate: null,
                cardNumber: '',
                paymentStartTime: new Date().setDate(new Date().getDate() - 7),
                paymentEndTime:new Date() ,
            },
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let { searchModel} = this.state;
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    modelChange(k, e) {
        let saveModel = this.state.saveModel;
        saveModel[k] = e;
        this.setState({ saveModel });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: searchModel,
        });
        this.search();
    }

    tableChange(value) {
        if (value === "2") {
            this.toRebate();
        } else {
            this.setState({ tab: value, })
            this.cancel();
        }
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth6",
            },
            {
                title: '负责人卡号',
                dataIndex: 'shopNumber',
                className: 'tableWidth7',
            },
            {
                title: '负责人手机号',
                dataIndex: 'shopPhone',
                className: "tableWidth7",
            },
            {
                title: '会员卡号',
                dataIndex: 'vipNumber',
                className: "tableWidth6",
            },
            {
                title: '会员手机号',
                dataIndex: 'vipPhone',
                className: "tableWidth7",
            },
            {
                title: '收银卡号',
                dataIndex: 'operatorNumber',
                className: 'tableWidth6',
            },
            {
                title: '收银员手机号',
                dataIndex: 'operatorPhone',
                className: "tableWidth7",
            },
            {
                title: '营业额日期',
                dataIndex: 'paymentTime',
                className: "tableWidth7",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: "tableRightAlignment tableWidth6",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '酒水金额',
                dataIndex: 'wineAmount',
                className: "tableRightAlignment tableWidth6",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'paymentAmount',
                className: "tableRightAlignment tableWidth6",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth6",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核人手机号',
                dataIndex: 'auditByPhone',
                className: "tableWidth7",
                render: (text,record) => record.auditStatus === 'CHECKED' ? record.auditByPhone  : ""
            },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: "tableWidth7",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '负责人状态',
                dataIndex: 'activeStatus',
                className: "tableWidth7",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
            
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            // type: 'radio',
        };

        return (
            <div className="shopTurnoverAudit">
                <div className="zhRoot">
                    <div className="zhTitle">营业额审核</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.vipNumber || ''}
                                                onChange={(e) => this.searchChange('vipNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.vipPhone || ''}
                                                onChange={(e) => this.searchChange('vipPhone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">营业额日期：</Col>
                                        <Col span={8} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.paymentStartTime == null ? null : moment(new Date(this.state.searchModel.paymentStartTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("paymentStartTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.paymentEndTime == null ? null : moment(new Date(this.state.searchModel.paymentEndTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("paymentEndTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">负责人卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="负责人卡号："
                                                value={this.state.searchModel.shopNumber || ''}
                                                onChange={(e) => this.searchChange('shopNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">负责人手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="负责人手机号："
                                                value={this.state.searchModel.shopPhone || ''}
                                                onChange={(e) => this.searchChange('shopPhone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={8} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditStartDate == null ? null : moment(new Date(this.state.searchModel.auditStartDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditStartDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="userRebateStatisticsDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditEndDate == null ? null : moment(new Date(this.state.searchModel.auditEndDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">店铺名称：</Col>
                                        <Col span={3} className="zhInput">
                                            {/* <Input maxLength={20}
                                                placeholder="店铺名称"
                                                value={this.state.searchModel.shopName || ''}
                                                onChange={(e) => this.searchChange('shopName', e.target.value)} /> */}
                                            <AutoComplete
                                                // key={this.state.searchModel.shopId || ''}
                                                dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                                style={{ width: '100%' }}
                                                dataSource={this.state.dataSource}
                                                value={this.state.searchModel.shopName || ''}
                                                onSelect={(e) => this.onSelect(e)}
                                                onChange={this.onChange}
                                                onSearch={this.onSearch}
                                                filterOption={true}     //是否根据输入项进行筛选。
                                                placeholder="店铺名称"
                                            />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.auditStatus || ''}
                                                onChange={(e) => this.searchChange('auditStatus', e)} >
                                                <Option key="" value={""}>全部</Option>
                                                <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                                <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toRebate()} icon="export">审核</Button>
                                        <Button disabled={this.state.loading} className="zhButtonLeft" type="primary" onClick={() => this.saveRebateAll()} icon="export">批量审核</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toSearchRebate()} icon="eye">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="detailsShopSalesPaymentId"
                                            scroll={{ x: 1000 }}
                                            bordered={true}                                     //边线
                                            columns={columns}                                   //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables}                      //数据
                                            pagination={{                                       //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.searchModel.total,            //总共多少条数据
                                                current: this.state.searchModel.page,           //当前页
                                                pageSize: this.state.searchModel.pageSize,      //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRow.call(this, record, 'detailsShopSalesPaymentId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                        />
                                    </Row>

                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>店铺名称：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.shopName} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>负责人卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.shopNumber} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                    <Col span={4} className='zhTxt'>负责人手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                        <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.shopPhone} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.vipNumber} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.vipPhone} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>收银员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.operatorNumber} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>收银员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                        <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.operatorPhone} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>营业额日期：</Col>
                                        <Col span={6} className='zhInput'>
                                        <Input className='disableFontColor' disabled value={this.state.saveModel.paymentTime == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.saveModel.paymentTime).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>饭菜金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.saveModel.foodAmount || 0, 2)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>酒水金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.saveModel.wineAmount || 0, 2)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>奖励金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.saveModel.paymentAmount || 0, 2)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled
                                                value={ContextHandler.getMapValue('auditStatusMap', this.state.saveModel.auditStatus)} />
                                        </Col>
                                    </Row>
                                    {this.state.notAllowEditing ? 
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核人手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.saveModel.auditByPhone} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核时间：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' disabled value={this.state.saveModel.auditDate == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.saveModel.auditDate).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    : null}
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={16} className='RemarkInput'>
                                            <TextArea
                                                value={this.state.saveModel.auditRemark || ""}
                                                className='disableFontColor'
                                                onChange={(e) => this.modelChange('auditRemark', e.target.value)}
                                                placeholder="备注"
                                                disabled={this.state.notAllowEditing}
                                                maxLength={500}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonLeft'>
                                        {!this.state.notAllowEditing ? <Button disabled={this.state.loading} type="primary" icon="check-circle" onClick={() => { this.saveRebate() }}>审核</Button> : null}
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }

    /**点击审核进编辑页 */
    toRebate() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let notAllowEditing = this.state.notAllowEditing;
        let model = this.state.selectedRows[0];
        console.log("model",model);
        if (model.auditStatus === "UNCHECKED") {
            notAllowEditing = false;
            this.setState({
                tabIndex: "2",
                flag: "edit",
                saveModel: model,
                notAllowEditing
            })
        }else{
            Message.openMsgError("错误", "已审核数据无法再审核")
        }
    }

    /**点击查看进编辑页 */
    toSearchRebate() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let notAllowEditing = this.state.notAllowEditing;
        let model = this.state.selectedRows[0];
        console.log("model",model);
        if (model.auditStatus === "CHECKED") {
            notAllowEditing = true;
            this.setState({
                tabIndex: "2",
                flag: "edit",
                saveModel: model,
                notAllowEditing
            })
        }else{
            Message.openMsgError("错误", "未审核数据无法查看")
        }
    }


    /**确认审核 */
    saveRebate() {
        let { saveModel} = this.state;
        this.setState({ loading: true });
        fetch(Config.apiBackServer + "shopTurnoverAudit/updateAudit", {
            method: 'POST',
            body: JSON.stringify(saveModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("提示", data.object)
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
        }).catch(err => { });


    }

     /**确认批量审核 */
     saveRebateAll() {
        // console.log("this.state.selectedRows",this.state.selectedRows)
        let saveModel = this.state.selectedRows;
        if ( Common.isEmpty(saveModel)) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let parm=false;
        for (let index = 0; index < saveModel.length; index++) {
            const element = saveModel[index];
            if (element.auditStatus ==="CHECKED") {
                parm=true
            }
        }
        if (!parm) {
            this.setState({ loading: true });
            fetch(Config.apiBackServer + "shopTurnoverAudit/updateAuditAll", {
                method: 'POST',
                body: JSON.stringify(saveModel),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                console.log("data.errorCode",data)
                if ("SUCCESS" === data.msg) {
                    this.cancel()
                    Message.openMsgSuccess("提示", data.object)
                } else {
                    Message.openMsgError("错误", data.msg, data.errorCode)
                }
            }).catch(err => { });
        } else {
            Message.openMsgError("错误", "选中数据有已审核的记录")
        }
        
    }

    cancel() {
        this.setState({
            loading: false,
            tabIndex: "1",
            flag: "",
            saveModel: {},
            selectedRowKeys: [],
            selectedRows: [],
            notAllowEditing: false,
        })
        this.search();
    }

    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopTurnoverAudit/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "营业额审核" + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false })});
    }
}
