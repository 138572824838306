import React, { Component } from 'react';
import { Spin,  Button, Row, Col, Tabs, Table, Input, Modal, DatePicker, Upload, Select, Icon} from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';
import SelectRecommendCardModel from './selectRecommendCardModel';


const confirm = Modal.confirm;
export default class UserMemberManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            flag: '',
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],
            searchModel: { pageSize: 10, page: 1},
            isExport: false,
            isLoading: false,
            model: {},

            imageFile: [],
            ImageNum: 1,
            previewVisible:false,

            previewVisiblePermanent:false,
            imageFilePermanent: [],
            ImageNumPermanent: 1,

            previewImageTwo: '',//选中图片的文件信息（Base64信息）
            imageFileTwo: [],//已经上传的文件列表（受控）
            previewImageNameTwo: '',//选择图片的名字
            imageFileOne: [],
            previewImageOne: '',
            previewImageNameOne: '',
        }
    }
    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "userMemberManagement/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            console.log(data.object.list)
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    
    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, },
            selectedRowKeys: [],
            selectedRows: [],
        },() => {
            this.search()
        })
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            flag:'',
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: false,
            model: {},
            searchModel: { page: 1, pageSize: 10, },
        }, () => {
            this.search()
        })
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
                Message.openMsgError("错误", "请选择一条数据")
                return
            }
            if (this.state.selectedRows.length == 1) {
                this.editRecommendCard();
            } 
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    editRecommendCard() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0]
        this.setState({
            flag: "editRecommendCard",
            tabIndex: "2",
            model: model,
        })
    }

    editPassword() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0]
        this.setState({
            flag: "editPassword",
            tabIndex: "2",
            model: model,
        })
    }

    toFrozenOrThaw() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let that = this;
        confirm({
            title: this.state.selectedRows[0].status === "NORMAL" ? '您确定冻结?' : '您确定激活?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                let status = "";
                if(that.state.selectedRows[0].status === "NORMAL"){
                    status = "FROZEN";
                }
                if(that.state.selectedRows[0].status === "FROZEN"){
                    status = "NORMAL";
                }
                if(Common.isEmpty(that.state.selectedRows[0].status)){
                    status = "FROZEN";
                }
                let mdoel = {
                    id: that.state.selectedRows[0].id,
                    userId: that.state.selectedRows[0].userId,
                    status: status,
                }
                fetch(Config.apiBackServer + "userMemberManagement/toFrozenOrThaw", {
                    method: 'POST',
                    body: JSON.stringify(mdoel),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("成功", "保存成功");
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }).catch(err => { });
            },
            onCancel() {},
        });
    }

    qrcode() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0]

        // 临时二维码
        var generalizedQRcode = model.userId;
        var generalizedQRCodeFlag = model.generalizedQRCodeFlag;
        let file2 = [{ uid: '-1', }]
        if (generalizedQRCodeFlag) {
            file2[0].url = Config.generalizedQRCode + generalizedQRcode+".jpg"+ '?' + Math.random();
            file2[0].name = generalizedQRcode;
        } else {
            file2 = []
        }
        // 永久二维码
        var permanentGeneralizedQRCode = model.permanentGeneralizedQRCode;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(permanentGeneralizedQRCode) && permanentGeneralizedQRCode.indexOf('http') != -1) { 
            file1[0].url = permanentGeneralizedQRCode+ '?' + Math.random();
            file1[0].name = permanentGeneralizedQRCode;
        } else if(Common.isNotEmpty(permanentGeneralizedQRCode) && permanentGeneralizedQRCode.indexOf('http') == -1) {
            file1[0].url = Config.permanentGeneralizedQRCode + permanentGeneralizedQRCode+ '?' + Math.random();
            file1[0].name = permanentGeneralizedQRCode;
        } else {
            file1 = []
        }
        this.setState({
            flag: "qrcode",
            tabIndex: "2",
            model: model,
            imageFile: file2,
            imageFilePermanent: file1,
        })
        if (!generalizedQRCodeFlag) {
            this.addQRCodeTemporary(model)
        }
    }

    editReceivingRewards() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0]
        let img = model.weChatAddress;
        let imageFileOne = [{ uid: '-1', }]
        if (img !== "" && img !== null && img !== undefined) {
            imageFileOne[0].url = Config.consumptionRebate + img;
            imageFileOne[0].name = img;
        } else {
            imageFileOne = [];
        }
        let img1 = model.alipayAddress;
        let imageFileTwo = [{ uid: '-1', }]
        if (img1 !== "" && img1 !== null && img1 !== undefined) {
            imageFileTwo[0].url = Config.consumptionRebate + img1;
            imageFileTwo[0].name = img1;
        } else {
            imageFileTwo = [];
        }
        this.setState({
            flag: "editReceivingRewards",
            tabIndex: "2",
            model: model,
            imageFileOne: imageFileOne,
            imageFileTwo: imageFileTwo,
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    save() {
        let msg = this.check();
        let url = "";
        if(msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        let model = this.state.model;
        if (this.state.flag === "editRecommendCard") {
            url = "userMemberManagement/editRecommendCard";
        } else if (this.state.flag === "editPassword") {
            url = "userMemberManagement/editPassword";
        } else if (this.state.flag === "editReceivingRewards") {
            this.obtainImgData();
            url = "userMemberManagement/editReceivingRewards";
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "修改成功")
            } else {
                Message.openMsgError("错误", data.msg)
            }
        }).catch(err => { });
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (!model.cardNumber) {
            msg += "会员卡号不能为空;"
        }
        if (this.state.flag === "editPassword") {
            if (Common.isEmpty(model.password)) {
                msg += "密码不能为空;"
            }
            if (Common.isEmpty(model.checPassword)) {
                msg += "确认密码不能为空"
            } else if (model.checPassword.length < 6){
                msg += "新密码长度不能小于6位;";
            }
            if ((Common.isNotEmpty(model.checPassword) && Common.isNotEmpty(model.password)) && (model.password !== model.checPassword)) {
                msg += "新密码和确认密码不一致;"
            }      
        }
        if (this.state.flag === "editReceivingRewards") {     
            if ( Common.isNotEmpty(model.bankCardnumberName) || Common.isNotEmpty(model.bankCardnumber) 
            || Common.isNotEmpty(model.subBranch)  || Common.isNotEmpty(model.bankCardnumberPhone)) {
              if (Common.isEmpty(model.bankCardnumberName)) {
                msg += '预留手用户名不能为空;'           
              }
              if (Common.isEmpty(model.bankCardnumber)) {
                msg += '银行卡号不能为空;'
              }
              if (Common.isEmpty(model.subBranch)) {
                msg += '银行支行不能为空;';
              }
              if (Common.isEmpty(model.bankCardnumberPhone)) {
                msg += '预留手机号不能为空;';
              } else {
                if (model.bankCardnumberPhone.length < 11) {
                    msg += "手机号格式不正确;"
                }
              }
            } 
        }   
        return msg
    }
    
    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "userMemberManagement/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员信息表" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>临时二维码</div>
            </div>
        );
        const uploadButtonPermanent = (
            <div>
                <Icon type="plus" />
                <div>永久二维码</div>
            </div>
        );

        const { imageFileOne, imageFileTwo } = this.state;
        const uploadButton1 = (
          <div>
            <Icon type="plus" />
            <div>支付宝收款码</div>
          </div>
        );
        const uploadButton2 = (
            <div>
            <Icon type="plus" />
            <div>微信收款码</div>
          </div>
        );

        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth17',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth17',
            },
            {
                title: '推荐人卡号',
                dataIndex: 'recommendCard',
                className: 'tableWidth17',
            },
            {
                title: '推荐人手机号',
                dataIndex: 'recommendCardPhone',
                className: 'tableWidth17',
            },
            {
                title: '注册日期',
                dataIndex: 'registrationDate',
                className: 'tableWidth17',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '状态',
                dataIndex: 'status',
                className: 'tableWidth17',
                render: (text) => ContextHandler.getMapValue('statusMap', text)
          
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return(
            <div className="memberManagement">
                 <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">会员管理</div>
                            <div className="content">
                            <SelectRecommendCardModel stateRef={this.state} onRef={this.selectRecommendCardPopup} closePopup={() => this.closePopup()} />
                                <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                    <TabPane tab="列表" key="1">
                                        <Row className="row">
                                            <Col span={2} className="zhTxt">会员卡号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={10}
                                                    placeholder="会员卡号"
                                                    value={this.state.searchModel.cardNumber || ''}
                                                    onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">会员手机号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={20}
                                                    placeholder="会员手机号"
                                                    value={this.state.searchModel.phone || ''}
                                                    onChange={(e) => this.searchChange('phone', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">注册日期：</Col>
                                            <Col span={6} className="zhInput">
                                                <DatePicker placeholder="由日期"
                                                    className="tableWidth445"
                                                    allowClear={true}
                                                    disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                    value={this.state.searchModel.openDate == null ? null : moment(new Date(this.state.searchModel.openDate), Config.dateFormat)}
                                                    onChange={(value) => this.searchChange("openDate", value)} 
                                                    format={Config.dateFormat} />
                                                    &nbsp;&nbsp;至&nbsp;&nbsp;
                                                <DatePicker placeholder="至日期"
                                                    className="tableWidth445"
                                                    allowClear={true}
                                                    disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                                    value={this.state.searchModel.endDate == null  ? null : moment(new Date(this.state.searchModel.endDate), Config.dateFormat)}
                                                    onChange={(value) => this.searchChange("endDate", value)} 
                                                    format={Config.dateFormat} />
                                            </Col>
                                        </Row>
                                        <Row className="row">
                                            <Col span={2} className="zhTxt">推荐人卡号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={20}
                                                    placeholder="推荐人卡号"
                                                    value={this.state.searchModel.recommendCard || ''}
                                                    onChange={(e) => this.searchChange('recommendCard', e.target.value)} />
                                            </Col>
                                            <Col span={2} className="zhTxt">推荐人手机号：</Col>
                                            <Col span={3} className="zhInput">
                                                <Input maxLength={20}
                                                    placeholder="推荐人手机号"
                                                    value={this.state.searchModel.recommendCardPhone || ''}
                                                    onChange={(e) => this.searchChange('recommendCardPhone', e.target.value)} />
                                            </Col>
                                        </Row>
                                        <Row className="rowButton">
                                            <Col className="zhRowButtonRight">
                                                <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                                <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.editRecommendCard()} icon="edit">修改推荐人</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.editPassword()} icon="edit">修改密码</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toFrozenOrThaw()} icon="edit">冻结/激活</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.qrcode()} icon="qrcode">推荐二维码</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.editReceivingRewards()} icon="plus-square">大额收款设置</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportReport()} icon="download">导出报表</Button>
                                        </Row>
                                        <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                                rowKey="id"
                                                bordered={true} //边线
                                                columns={columns} //列名
                                                loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                                dataSource={this.state.tables} //数据
                                                pagination={{  //分页
                                                    showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                    onChange: this.pageChange,            //上页下页或者跳页方法
                                                    onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                    total: this.state.searchModel.total,             //总共多少条数据
                                                    current: this.state.searchModel.page,         //当前页
                                                    pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                    defaultCurrent: 1,                               //默认当前页为1
                                                }}
                                                onRow={(record) => {
                                                    return {
                                                        onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                    };
                                                }}
                                                rowClassName="tableColor"
                                            />
                                        </Row>
                                    </TabPane>
                                    <TabPane tab="详情" key="2" >
                                        <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={()=>this.handleCancel2()}>
                                            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                        </Modal>
                                        <Modal title="放大图" visible={this.state.previewVisiblePermanent} footer={null} onCancel={()=>this.handleCancel3()}>
                                            <img alt="example" style={{ width: '100%' }} src={this.state.previewImagePermanent} />
                                        </Modal>
                                        <Row className="row">
                                            <Col span={4} className='zhTxt'>会员卡号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="会员卡号"
                                                    disabled
                                                    value={this.state.model.cardNumber || ''}
                                                    onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>会员手机号：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input placeholder="会员手机号"
                                                    disabled
                                                    maxLength={11}
                                                    value={this.state.model.phone || ''}
                                                    onChange={(e) => this.modelChange('phone', e.target.value)}
                                                    />
                                            </Col>
                                        </Row>
                                        {this.state.flag === "editRecommendCard" ?
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>推荐人卡号:</Col>
                                                <Col span={4} className='zhInput'>
                                                    <Input maxLength={10}
                                                        disabled
                                                        placeholder="推荐人卡号"
                                                        value={this.state.model.recommendCard || ''}
                                                        onChange={(e) => this.modelChange('recommendCard', e.target.value)}  />
                                                </Col>
                                                <Col span={2} style={{ marginTop: "5px" }} >
                                                    <Button type="primary" onClick={() => this.openPopup()} >选择会员</Button>
                                                </Col>
                                                <Col span={4} className='zhTxt'>推荐人手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={11}
                                                        disabled
                                                        placeholder="推荐人手机号"
                                                        value={this.state.model.recommendCardPhone || ''}
                                                        onChange={(e) => this.modelChange('recommendCardPhone', e.target.value)} />
                                                </Col>
                                            </Row>
                                        :
                                            <Row className='row'>
                                                <Col span={4} className='zhTxt'>推荐人卡号:</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={10}
                                                        disabled
                                                        placeholder="推荐人卡号"
                                                        value={this.state.model.recommendCard || ''}
                                                        onChange={(e) => this.modelChange('recommendCard', e.target.value)}  />
                                                </Col>
                                                <Col span={4} className='zhTxt'>推荐人手机号：</Col>
                                                <Col span={6} className='zhInput'>
                                                    <Input maxLength={11}
                                                        disabled
                                                        placeholder="推荐人手机号"
                                                        value={this.state.model.recommendCardPhone || ''}
                                                        onChange={(e) => this.modelChange('recommendCardPhone', e.target.value)} />
                                                </Col>
                                            </Row>
                                        }
                                         
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>注册日期:</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input maxLength={10}
                                                    placeholder="注册日期"
                                                    disabled
                                                    value={moment(this.state.model.registrationDate).format(Config.dateFormat) || ""}
                                                    onChange={(e) => this.modelChange('registrationDate', e.target.value)}  />
                                            </Col>
                                            <Col span={4} className='zhTxt'>状态：</Col>
                                            <Col span={6} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                disabled
                                                value={this.state.model.status || ''}
                                                onChange={(e) => this.modelChange('status', e)} >
                                                {context.statusMap.map((item) => <Option key={item.key} value={item.key}>{item.value}</Option>)}
                                            </Select>
                                            </Col>
                                        </Row>
                                        {this.state.flag === "editPassword" ? 
                                            <>
                                                <Row className="row">
                                                    <Col span={4} className='zhTxt'>新密码<span className='redStar'>*</span>:</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input.Password maxLength={11}
                                                            placeholder="新密码"
                                                            value={this.state.model.password || ''}
                                                            onChange={(e) => this.modelChange('password', e.target.value)}  />
                                                    </Col>
                                                    <Col span={4} className='zhTxt'>确认密码<span className='redStar'>*</span>:</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input.Password maxLength={11}
                                                            placeholder="确认密码"
                                                            value={this.state.model.checPassword || ''}
                                                            onChange={(e) => this.modelChange('checPassword', e.target.value)}  />
                                                    </Col>
                                                </Row>
                                            </>
                                        : null}
                                        {this.state.flag === "qrcode" ?
                                            <>
                                                <Row className="row">
                                                    <Col span={4} className='zhTxt'>临时推广二维码：</Col>
                                                    <Col span={6}  className='imgUpload zhInput' hidden ={this.state.imageFile != 0}></Col>
                                                    <Col span={6} className='imgUpload zhInput' hidden ={this.state.imageFile == 0}>
                                                        <Upload
                                                            key={"1"}
                                                            disabled
                                                            accept=".png, .jpg, .jpeg"
                                                            action={Config.apiBackServer + "test/test"}
                                                            listType="picture-card"
                                                            fileList={this.state.imageFile}
                                                            onPreview={this.handlePreview}
                                                        >
                                                            {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                                        </Upload>
                                                        <Button hidden={Common.isEmpty(this.state.model.userId)} type="primary" onClick={() => this.downloadCurrentImage1("generalizedQRcode")}>下载临时推广二维码</Button>
                                                    </Col>

                                                    <Col span={4} className='zhTxt'>永久推广二维码：</Col>
                                                    <Col span={6} className='imgUpload zhInput' hidden={Common.isEmpty(this.state.model.permanentGeneralizedQRCode)}>
                                                        <Upload
                                                            key={"1"}
                                                            disabled
                                                            accept=".png, .jpg, .jpeg"
                                                            action={Config.apiBackServer + "test/test"}
                                                            listType="picture-card"
                                                            fileList={this.state.imageFilePermanent}
                                                            onPreview={this.handlePreviewPermanent}
                                                        >
                                                        {this.state.imageFilePermanent.length >= this.state.ImageNum ? null : uploadButtonPermanent}
                                                        </Upload>
                                                        <Button type="primary" hidden={Common.isEmpty(this.state.model.permanentGeneralizedQRCode)} onClick={() => this.downloadCurrentImage1("permanentGeneralizedQRCode")}>下载永久推广二维码</Button>
                                                    </Col>
                                                    <Col span={6} className='imgUpload zhInput' hidden={!Common.isEmpty(this.state.model.permanentGeneralizedQRCode)}>
                                                        <Button type="primary" hidden={!Common.isEmpty(this.state.model.permanentGeneralizedQRCode)} onClick={() => this.addQRCode()} >生成永久推广二维码</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        : null}
                                        {this.state.flag === 'editReceivingRewards' ?
                                            <>
                                                <Row className="row">
                                                    <Col span={4} className='zhTxt'>银行卡号：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={19}
                                                            placeholder="银行卡号"
                                                            value={this.state.model.bankCardnumber || ''}
                                                            onChange={(e) => this.modelChange('bankCardnumber', e.target.value)}  />
                                                    </Col>
                                                    <Col span={4} className='zhTxt'>银行支行：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={20}
                                                            placeholder="银行支行"
                                                            value={this.state.model.subBranch || ''}
                                                            onChange={(e) => this.modelChange('subBranch', e.target.value)}  />
                                                    </Col>
                                                </Row>
                                                <Row className="row">
                                                    <Col span={4} className='zhTxt'>预留用户名：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={19}
                                                            placeholder="预留用户名"
                                                            value={this.state.model.bankCardnumberName || ''}
                                                            onChange={(e) => this.modelChange('bankCardnumberName', e.target.value)}  />
                                                    </Col>
                                                    <Col span={4} className='zhTxt'>预留手机号：</Col>
                                                    <Col span={6} className='zhInput'>
                                                        <Input maxLength={11}
                                                            placeholder="预留手机号"
                                                            value={this.state.model.bankCardnumberPhone || ''}
                                                            onChange={(e) => this.modelChange('bankCardnumberPhone', e.target.value)}  />
                                                    </Col>
                                                </Row>
                                                <Row className="row">
                                                    <Col span={4} className='zhTxt'>微信收款码：</Col>
                                                    <Col span={6} className='imgUpload zhInput'>
                                                        <Upload
                                                            // action={Config.apiBackServer + "test/test"}
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            accept=".png, .jpg, .jpeg"
                                                            listType="picture-card"
                                                            fileList={imageFileOne}
                                                            onRemove={this.handleRemoveOne}
                                                            onPreview={this.handlePreview}
                                                            onChange={(file) => this.handleChange(file, "imageFileOne")}>
                                                            {imageFileOne.length >= 1 ? null : uploadButton2}
                                                        </Upload>
                                                    </Col>
                                                    <Col span={4} className='zhTxt'>支付宝收款码：</Col>
                                                    <Col span={6} className='imgUpload zhInput'>
                                                        <Upload
                                                            // action={Config.apiBackServer + "test/test"}
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            accept=".png, .jpg, .jpeg"
                                                            listType="picture-card"
                                                            fileList={imageFileTwo}
                                                            onRemove={(file)=>this.handleRemoveTwo}
                                                            onPreview={this.handlePreview}
                                                            onChange={(file) => this.handleChange(file, "imageFileTwo")}>
                                                            {imageFileTwo.length >= 1 ? null : uploadButton1}
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                            </>
                                        : null}
                                        <Row className='zhRow zhRowButtonRight'>
                                        {this.state.flag !== "qrcode" ?
                                            <>
                                            <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                            </>
                                        : null}
                                            <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </div>
                    </Spin>
                 </div>
            </div>
        )
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    selectRecommendCardPopup = (ref) => {
        this.selectRecommendCardPopup = ref;
    }

    closePopup() {
        let recommendCardModel = this.selectRecommendCardPopup.state.selectedRows[0];
        let model = this.state.model;
            model.recommendCard = recommendCardModel.recommendCard;
            model.recommendCardPhone = recommendCardModel.recommendCardPhone;
            model.recommendCardId = recommendCardModel.recommendCardId;
        this.setState({
            model: model,
        })
    }
    
    openPopup() {
        this.selectRecommendCardPopup.open()
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleCancel2 (){
        this.setState({ previewVisible: false })
    } 

    // 生成临时推广二维码
    addQRCodeTemporary(model) {
        if (Common.isEmpty(model.userId)) {
            Message.openMsgError("错误", "请先选择会员");
            return;
        } else {
            fetch(Config.apiBackServer + "userMemberManagement/permanentGeneralizedqRCodeTemporary", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                var generalizedQRCode = model.userId;
                let file2 = [{uid: '-1',}]
                file2[0].url = Config.generalizedQRCode + generalizedQRCode + ".jpg" + '?' + Math.random();
                file2[0].name = generalizedQRCode+".jpg";
                if ("SUCCESS" === data.msg) {
                    this.setState({
                        model: model,
                        imageFile: file2,
                    })
                }
            }).catch(err => { });
        }
    } 

    downloadCurrentImage1(qRcode) {
        var model = this.state.model;
        var url = "";
        if ("permanentGeneralizedQRCode" === qRcode) {
            url = Config.permanentGeneralizedQRCode + this.state.model.permanentGeneralizedQRCode;
        } else if ("generalizedQRcode" === qRcode) {
            url = Config.generalizedQRCode +this.state.model.userId+".jpg";
        }
        model.url = url;
        fetch(Config.apiBackServer + "promotionCostSetting/download", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "";
                if("permanentGeneralizedQRCode" === qRcode) {
                    filename = this.state.model.permanentGeneralizedQRCode;
                } else if("generalizedQRcode" === qRcode) {
                    filename = this.state.model.userId+".jpg";
                }
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

    handlePreviewPermanent = (file) => {
        this.setState({
            previewImagePermanent: file.url || file.thumbUrl,
            previewVisiblePermanent: true,
        });
    }

    handleCancel3 () {
        this.setState({ previewVisiblePermanent: false })
    }

    // 生成永久推广二维码
    addQRCode() {
        var model =this.state.model;
        if(Common.isEmpty(model.userId)){
            Message.openMsgError("错误", "请先选择会员");
            return;
        }else if(Common.isEmpty(model.permanentGeneralizedQRcode)){
            fetch(Config.apiBackServer + "userMemberManagement/permanentGeneralizedqRCode", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                var permanentGeneralizedQRCode = data.object.permanentGeneralizedQRCode;
                model.permanentGeneralizedQRCode = permanentGeneralizedQRCode;
                let filel = [{uid: '1',}]
                filel[0].url = Config.permanentGeneralizedQRCode +  model.userId+".jpg"+ '?' + Math.random();
                filel[0].name = model.userId+".jpg";
                if ("SUCCESS" === data.msg) {
                    this.setState({
                        model: model,
                        imageFilePermanent: filel,
                    })
                    Message.openMsgSuccess("生成永久推广二维码成功", "");
                }
            }).catch(err => { });
        }else{
            Message.openMsgError("错误", "该用户已经生成永久推广二维码");
            return;
        }
    }

    handleRemoveOne = (file) => {
        this.setState({
            previewImageOne: '',
            imageFileOne: [],
            previewImageNameOne: '',
        });
    }

    handleRemoveTwo = (file) =>{
        this.setState({
            previewImageTwo: '',
            imageFileTwo: [],
            previewImageNameTwo: '',
        });
    }

    handleChange(file,name) {
        let state = this.state;
        if (file.fileList != null && file.fileList.length === 1) {
            if (!/\.(png|jpg|jpeg)$/.test(file.fileList[0].name)) {
              Common.error("请上传.jpg.png.jpeg图片文件", 1.5);
              return
            }
            if (!file.file.url && !file.file.preview) {
                const reader = new FileReader();
                reader.readAsDataURL(file.file.originFileObj);
                reader.onload = (e) => {
                    let fileList = file.fileList
                    fileList[0].thumbUrl = e.target.result
                    state[name] = fileList;
                    this.setState(state);
                }
            }
        } else {
            state[name] = file.fileList;
            this.setState(state);
            this.forceUpdate();
        }
      }

      obtainImgData() {
        let model = this.state.model;
        let imageFileOne = this.state.imageFileOne;
        let imageFileTwo = this.state.imageFileTwo;
        if (imageFileOne.length !== 0) {
          for (let i in imageFileOne) {
            if (Common.isEmpty(imageFileOne[i].url)) {
              model.weChatAddress = imageFileOne[i].thumbUrl;
            } else {
              model.weChatAddress = imageFileOne[i].name;
            }
          }
        } else {
          model.weChatAddress = null;
        }
        if (imageFileTwo.length !== 0) {
          for (let i in imageFileTwo) {
            if (Common.isEmpty(imageFileTwo[i].url)) {
              model.alipayAddress = imageFileTwo[i].thumbUrl;
            } else {
              model.alipayAddress = imageFileTwo[i].name;
            }
          }
        } else {
          model.alipayAddress = null;
        }
        this.setState({
          model: model
        })
      }
}