import React, { Component } from 'react';
import PropTypes from 'prop-types';//props传参检查库
import { Row,Form, Icon, Input, Button, message } from 'antd';

import './login.css';
import {Config} from '../../config'
import { Common } from '../../public/js/common';
import { Message } from '../../public/js/message';

export default class Login extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLogin : false
            ,model : {
                phone : ''
                ,password : ''
            }
        };
    }

    componentDidMount() {
        this.checkLogin();
    }

    checkLogin(){
        fetch(Config.apiServer + "login/checkToken", {
            method: 'POST',
            body: null,
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.props.history.push('/main');
            }
        }).catch(err => {Message.openMsgError("错误",err)}).finally(() => {});
    }

    render() {
        // ant插件自动校验处理
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Row className="loginFormRoot">
                    <h1>金惠国民饭卡管理系统</h1>
                    <Row className="loginFormInputWidth">
                        <Form onSubmit={(e)=>this.handleSubmit(e)} >
                            <Form.Item className='loginLeftAlign'>
                                {getFieldDecorator('phone', {
                                    rules: [{ required: true, message: '手机号不能为空' }],
                                    initialValue : this.state.model.phone
                                })(
                                    <Input 
                                        maxLength={11}
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                                        placeholder="用户名"
                                        onChange={(e)=>this.inputChange(e,'phone')}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item className='loginLeftAlign'>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: '密码不能为空' }],
                                    initialValue : this.state.model.password
                                })(
                                    <Input 
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                                        type="password" 
                                        placeholder="密码" 
                                        onChange={(e)=>this.inputChange(e,'password')}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="loginFormButton" disabled={this.state.isLogin}>登录</Button>
                            </Form.Item>
                            {/* <div className="loginRegisterBut"><a onClick={()=>this.props.history.push('/register')}>注册</a></div> */}
                            <div className="loginRegisterBut"><a>注册</a></div>
                            <div className="loginRightAlign"><a onClick={()=>this.props.history.push('/forgetPwd')}>找回密码</a></div>
                        </Form>
                    </Row>
                </Row>
                <Row className="bottomUrl"><a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2022007424号-2</a></Row>
            </div>
        );
    }
    
    /**
     * 提交事件，ant插件自动校验
     */
    handleSubmit(e){
        e.preventDefault();
        this.props.form.validateFields((err) => {
          if (!err) {// 验证通过
            this.login();
          }
        });
    }

    /**
     * input数据双向绑定
     * @param {修改之后的数据源} event 
     * @param {属性名} att 
     */
    inputChange(event,att){
        let {model} = this.state;
        model[att] = event.target.value;
        this.setState({ model });
    }

    /**
     * 登录方法，调用登录接口获取用户数据
     */
    login(){
        //登入前检查
        this.setState({isLogin : true});
        fetch(Config.apiServer + "login/checkIsAvailable",{
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode:'cors',
            cache:'default'
        }).then(res =>res.json()).then((resp) => {
            if(resp != null && resp.msg === "SUCCESS"){
                if(resp.object > 0){
                    fetch(Config.apiServer + "login/ajaxLoginByPhone",{
                        method: 'POST',
                        body: JSON.stringify(this.state.model),
                        headers: Config.header,
                        mode:'cors',
                        cache:'default'
                    }).then(res =>res.json()).then((resp) => {
                        if(resp != null && resp.msg === "SUCCESS"){
                            // message.success('登录成功',2);
                            // 登录成功，保存用户信息到Config中
                            let {userInfo , token} = resp.object
                            userInfo.token = token;
                            Config.userInfo = userInfo;
                            Config.header.authorization = token;
                            localStorage.setItem('userInfo',JSON.stringify(userInfo));
                            localStorage.setItem('token',token);
                            this.searchMenu()
                        }else{
                            message.error(resp.msg);
                        }
                    }).catch(err=>{
                        Message.openMsgError("错误", err)
                        // message.error('接口调用失败，请检查服务器是否启动');
                    }).finally(()=>{
                        this.setState({isLogin : false});
                    });
                }else if(resp.object == 0){
                    message.error('该账号已经被冻结');
                }else if(resp.object < 0){
                    message.error('该账号不存在');
                }
            }else{
                message.error(resp.msg);
            }
        }).catch(err=>{
            Message.openMsgError("错误", err)
            // message.error('接口调用失败，请检查服务器是否启动');
        }).finally(()=>{
            this.setState({isLogin : false});
        });




       
    }

    searchMenu() {
        let model = { userId: Config.userInfo.userId }
        fetch(Config.apiBackServer + "sysFunc/leftMenuSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                let list=data.object;
                let newList=[];
                let openKeys=[];
                for (let item of list) {
                    item.children=[]
                    for (let iterator of list) {
                        if (item.sysFuncId===iterator.level1Id) {
                            item.children.push(iterator)
                        }
                    }
                    if (item.funcType==="FUNC") {
                        newList.push(item)
                        openKeys.push(item.sysFuncId)
                    }
                }
                Config.openKeys=openKeys
                localStorage.setItem('menuList',JSON.stringify(newList));
                console.log(newList)
                localStorage.setItem('openKeys',JSON.stringify(openKeys));
                if(Common.isEmpty(Config.userInfo.shopId)){
                    this.props.history.push('/main/platformWelcome');
                }else{
                    this.props.history.push('/main/shopWelcome');
                }
                message.success('登录成功',2);
            } else {
                Message.openMsgError("错误", data.msg )
            }
        }).catch(err=>{
            Message.openMsgError("错误", err)
            // message.error(err);
        }).finally(()=>{
            this.setState({isLogin : false});
        });
    }
}

Login = Form.create({})(Login);

/**
 * props参数检查
 */
Login.propTypes = {
    jump : PropTypes.func
};