import React, { Component } from 'react';
import { Row, Col, Table, Input, Modal, Button } from 'antd';
import { Common } from '../../public/js/common';
import { Config } from '../../config';
import { Message } from '../../public/js/message';

import PanoramaViewer from '../shopView/PanoramaViewer';

export default class ShopViewModal extends Component {
    constructor(prpos){
        super(prpos);
        this.state = {
            visible: false,
            imageUrl: "",
            imageList: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        if (this.props.onRef !== null) {
            this.props.onRef(this);
        }
    }

    open() {
        this.setState({
            visible: true,
        })
        console.log(this.props.shopId);
        this.search();
    }

    search() {
        console.log("search");
        fetch(Config.apiBackServer + "shop/editSearchByShopId", {
            method: 'POST',
            body: JSON.stringify({ shopId: this.props.shopId}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg && data.object != null) {
                console.log(data.object);
                let model = data.object;
                let imagePath = Config.img3DCompressionDiagram + model.shopId + "/";
                let list = [];
                if (model.shop3DImage1 != "" && model.shop3DImage1 != null) {
                    list.push({ id: 1, imageUrl: imagePath + model.shop3DImage1, imgName: model.shop3DImageName1 });
                }
                if (model.shop3DImage2 != "" && model.shop3DImage2 != null) {
                    list.push({ id: 2, imageUrl: imagePath + model.shop3DImage2, imageName: model.shop3DImageName2 });
                }
                if (model.shop3DImage3 != "" && model.shop3DImage3 != null) {
                    list.push({ id: 3, imageUrl: imagePath + model.shop3DImage3, imageName: model.shop3DImageName3 });
                }
                if (model.shop3DImage4 != "" && model.shop3DImage4 != null) {
                    list.push({ id: 4, imageUrl: imagePath + model.shop3DImage4, imageName: model.shop3DImageName4 });
                }
                if (model.shop3DImage5 != "" && model.shop3DImage5 != null) {
                    list.push({ id: 5, imageUrl: imagePath + model.shop3DImage5, imageName: model.shop3DImageName5 });
                }
                if (model.shop3DImage6 != "" && model.shop3DImage6 != null) {
                    list.push({ id: 6, imageUrl: imagePath + model.shop3DImage6, imageName: model.shop3DImageName6 });
                }
                if (model.shop3DImage7 != "" && model.shop3DImage7 != null) {
                    list.push({ id: 7, imageUrl: imagePath + model.shop3DImage7, imageName: model.shop3DImageName7 });
                }
                if (model.shop3DImage8 != "" && model.shop3DImage8 != null) {
                    list.push({ id: 8, imageUrl: imagePath + model.shop3DImage8, imageName: model.shop3DImageName8 });
                }
                if (model.shop3DImage9 != "" && model.shop3DImage9 != null) {
                    list.push({ id: 9, imageUrl: imagePath + model.shop3DImage9, imageName: model.shop3DImageName9 });
                }
                console.log(list);
                this.setState({
                    imageList: list,
                    imageUrl: list[0].imageUrl || ""
                });
            }
        }).catch(err => { });
    }

    modelChange(k, e) {
        console.log(this.props.shopId);
        let newModel = this.state;
        newModel[k] = e;
        this.setState(
            newModel
        , () => {
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        return(
            <Modal
                className="zhRoot container shopViewModal"
                width='90%'
                centered={true}
                visible={this.state.visible}
                // closable={false}
                onCancel={this.handleCancel}
                footer={null}
            >
                <div className="testBody">
                    {/* <div className=''><CloseOutlined /></div> */}
                    <PanoramaViewer imageUrl={this.state.imageUrl} />
                    <div className="carouselBox">
                        {
                            this.state.imageList?.map((item, index) =>
                                <div key={index} className={'imgBox ' + (this.state.imageUrl === item.imageUrl ? 'selectImgBox' : '')} onClick={() => this.modelChange('imageUrl', item.imageUrl)}>
                                    <img key={item.id} alt={item.imageName} src={item.imageUrl} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}