import React, { Component } from "react";
import { Spin,  Button, Row, Col, Tabs, Table, Input, Modal, Select, DatePicker , Icon} from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

export default class WithdrawalAmountFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            isLoading: false,
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],
            searchModel: {page: 1, pageSize: 10,},

            selectedRowKeysFmwp:[],
            selectedRowsFmwp:[],
            model: {page: 1, pageSize: 10,},
            flowMemberWithdrawalPaymentTables: [],
        }
    }

    componentDidMount() {
        this.searchMemberWithdrawalPaymentFlow()
        // this.search()
    }


    searchMemberWithdrawalPaymentFlow() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "withdrawalAmountFlow/searchMemberWithdrawalPaymentFlow", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.model;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    flowMemberWithdrawalPaymentTables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    search() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "withdrawalAmountFlow/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    tables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    tableChange(value) {
        if (value === "2") {
            this.reset();
        }
        this.resetFmwp();
        this.setState({
            tabIndex: value
        })
    }


    reset() {
        this.setState({
            searchModel: {page: 1, pageSize: 10,},
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    onSelectChangeFmwp = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeysFmwp: selectedRowKeys,
            selectedRowsFmwp: selectedRows
        })
    }

    cancel() {
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
        }, () => {
            this.search()
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    
    pageChangeFmwp = (current, pageSize) =>{
        let searchModel = this.state.model;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            model: searchModel,
        });
        this.searchMemberWithdrawalPaymentFlow();
    }


    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    resetFmwp() {
        this.setState({
            model: {page: 1, pageSize: 10,},
            selectedRowKeysFrp: [],
            selectedRowsFrp: [],
        }, () => {
            this.searchMemberWithdrawalPaymentFlow()
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        
        const columns = [
            {
                title: '会员已到账流水ID',
                dataIndex: 'flowWithdrawalAmountId',
                className: 'tableWidth12',
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth12.5',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '变动前金额',
                dataIndex: 'withdrawalBeforeAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '变动金额',
                dataIndex: 'withdrawalAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '变动后金额',
                dataIndex: 'withdrawalAfterAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '变动时间',
                dataIndex: 'withdrawalTime',
                className: 'tableWidth12.5',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '交易类型',
                dataIndex: 'transactionType',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getMapValue('flowWithdrawalAmountMap', text)
            },
            {
                title: '备注',
                dataIndex: 'withdrawalRemark',
                className: 'tableWidth10',
            },
        ]


        const columnsFmwp = [
            {
                title: '会员提现流水ID',
                dataIndex: 'flowMemberWithdrawalPaymentId',
                className: 'tableWidth14',
            },
            {
                title: '会员头像',
                dataIndex: 'headPortrait',
                render: (text) => (Common.isNotEmpty(text) ?
                <img alt="" className="img" src={(text.search('http') == -1) && (text.search('https') == -1) ? Config.headPortraitImgs + text : text} /> : null),
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth10',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth10',
            },
            {
                title: '收款人',
                dataIndex: 'nickName',
                className: 'tableWidth10',
            },
            {
                title: '打款金额',
                dataIndex: 'paymentAmount',
                className: 'tableWidth10 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '打款状态',
                dataIndex: 'paymentStatus',
                className: 'tableWidth10',
                render: (text) => ContextHandler.getMapValue('wechatPaymentFlowMap', text)
            },
            {
                title: '打款时间',
                dataIndex: 'paymentTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '打款备注',
                dataIndex: 'paymentRemark',
                className: 'tableWidth8',
            },
            {
                title: '付款信息',
                dataIndex: 'errMessage',
                className: 'tableWidth8',
                render: (text) =><span>{text === 'ERR' ? "打款失败" : null }</span>,
            },
            
        ]
        
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }

        const rowSelectionFmwp = {
            selectedRowKeys: this.state.selectedRowKeysFmwp,
            onChange: this.onSelectChangeFmwp,
            type: 'radio',
        }

        return(
            <div className="withdrawalAmountFlow">
                <div className="zhRoot">
                    <div className="zhTitle">会员提现金额流水</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="会员提现流水" key='1'>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.model.cardNumber || ''}
                                            onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="会员手机号"
                                            value={this.state.model.phone || ''}
                                            onChange={(e) => this.modelChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">打款日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.dateFormat)}
                                            onChange={(value) => this.modelChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.dateFormat)}
                                            onChange={(value) => this.modelChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">打款状态：</Col>
                                    <Col span={3} className="zhInput">
                                        <Select style={{ width: "100%" }} placeholder="打款状态" 
                                            value={this.state.model.paymentStatus || ''}
                                            onChange={(e) => this.modelChange('paymentStatus', e)}>
                                            <Option  key="" value="">全部</Option >
                                            {context.wechatPaymentFlowMap.map((item) => <Option  key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>   
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.searchMemberWithdrawalPaymentFlow()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.resetFmwp()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="flowMemberWithdrawalPaymentId"
                                        bordered={true} //边线
                                        columns={columnsFmwp} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.flowMemberWithdrawalPaymentTables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChangeFmwp,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChangeFmwp,    //每页显示多少条数据方法
                                            total: this.state.model.total,             //总共多少条数据
                                            current: this.state.model.page,         //当前页
                                            pageSize: this.state.model.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'flowMemberWithdrawalPaymentId', 'selectedRowsFmwp', 'selectedRowKeysFmwp'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                            <TabPane tab="会员已到账金额流水" key="2">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">变动日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="headResponsibleDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button type="primary" onClick = {() => this.search()} icon="search">搜索</Button>
                                        <Button className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="flowWithdrawalAmountId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'flowWithdrawalAmountId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}