import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'; // 使用户能够旋转和缩放 3D 场景

const PanoramaViewer = ({ imageUrl }) => {
    const mountRef = useRef(null);

    useEffect(() => {
        const mount = mountRef.current;

        const scene = new THREE.Scene(); // 场景
        // 相机 参数：1.视场角，单位为度，表示相机视野的垂直角度 ，2.宽度与高度的比例，3.相机能够看到的最近距离，4.相机能够看到的最远距离
        const camera = new THREE.PerspectiveCamera(90, mount.clientWidth / mount.clientHeight, 0.1, 1000); // 相机
        const renderer = new THREE.WebGLRenderer(); // 渲染器
        renderer.setSize(mount.clientWidth, mount.clientHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        mount.appendChild(renderer.domElement);

        // 加载图像，并创建一个翻转的球体几何体来显示全景图像
        const textureLoader = new THREE.TextureLoader(); 
        textureLoader.load(imageUrl, (texture) => {
            // 设置纹理过滤器
            texture.magFilter = THREE.LinearFilter;
            texture.minFilter = THREE.LinearMipMapLinearFilter;
            // 启用 Mipmapping
            texture.generateMipmaps = true;
            // 使用 equirectangular refractive mapping
            texture.mapping = THREE.EquirectangularRefractionMapping;
            // SphereGeometry参数: 1.球体半径，增加 2.widthSegments 和 3.heightSegments 会使球体表面更平滑，但也会增加计算负担
            const geometry = new THREE.SphereGeometry(100, 512, 512);
            geometry.scale(-1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ map: texture });
            const sphere = new THREE.Mesh(geometry, material);
            scene.add(sphere);
        });

        // 配置用户交互
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.enableZoom = false;

        camera.position.set(0, 0, 1);

        // 函数在每帧更新控制器并渲染场景
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };
        animate();


        // 创建监听器，在窗口大小变化时，调整渲染器大小并更新相机的投影矩阵
        const handleResize = () => {
            renderer.setSize(mount.clientWidth, mount.clientHeight);
            renderer.setPixelRatio(window.devicePixelRatio);
            camera.aspect = mount.clientWidth / mount.clientHeight;
            camera.updateProjectionMatrix();
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        // 组件卸载时移除渲染器并清除事件监听器
        return () => {
            mount.removeChild(renderer.domElement);
            window.removeEventListener('resize', handleResize)
        };
    }, [imageUrl]);

    return <div ref={mountRef}  style={{ width: '100%', height: '100%' }} />;
};

export default PanoramaViewer;
