import React, { Component } from 'react';
import { Spin, Button, Row, Col, Tabs, Table, Input, Select, Modal, DatePicker,Checkbox,Upload,Icon} from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import SelectCardNumber from './../selectCardNumber/selectCardNumber';
import SelectCardNumberBatch from './../selectCardNumberBatch/selectCardNumberBatch';

import './promotionCostSetting.css';

const confirm = Modal.confirm;
const { TextArea } = Input;
const { Option } = Select;
var css;
export default class PromotionCostSetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            model: {},
            tables: [],
            tablesBatch:[],
            searchModel: { pageSize: 10, page: 1, showShop: 'Y' },
            searchShopList: [],
            paymentAmount: [{}],
            shopFlag: false,
            preservationFlag: false,
            openSpecialGoldCard: false,
            paymentAmount: '',
            statu: '',
            goldCardCount: 0,
            silverCardCount: 0,
            isLoading: false,
            saveFlag: true,//重复点击标志
            isDown: false,
            provinceList: [],//省
            cityList: [],//市
            areaList: [],//区
            isExport: false,
            newMembersCommission:'',//推广新会员提成参数设置
            memberConsumptionCommission:'',//推广会员消费额提成
            selectedRowKeys: [],
            selectedRows: [],
            checked:false,

            previewVisible:false,
            imageFile: [],
            ImageNum: 1,

            previewVisiblePermanent:false,
            imageFilePermanent: [],
            ImageNumPermanent: 1,
        }
        this.init()
    }

    init() {
        // this.search();
        // this.searchParameter();
    }

    componentWillMount = () => {
        this.search();
        this.searchParameter();
    }

    search() {
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "promotionCostSetting/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
            console.log(this.state.tables);
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchParameter() {
        fetch(Config.apiBackServer + "promotionCostSetting/searchParameter", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            let model = data.object;
            if ("SUCCESS" === data.msg) {
                this.setState({
                    newMembersCommission: model.newMembersCommission,
                    memberConsumptionCommission: model.memberConsumptionCommission
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
           
            
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }


    reset() {
        this.setState({
            checked:false,
            searchModel: { page: 1,
                           pageSize: 10, 
                           cardNumber: '',
                           phone:'',
                          },
        }, () => {
            this.cancel();
        })
       
    }


    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    batch() {
        this.setState({
            tabIndex: "3",
            flag: "batch",
            tablesBatch:[],
            model: { 
                    memberConsumptionCommission:this.state.memberConsumptionCommission,
                    newMembersCommission:this.state.newMembersCommission,

                     },
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            imageFile:'',
            imageFilePermanent:'',
            model: { 
                    memberConsumptionCommission:this.state.memberConsumptionCommission,
                    newMembersCommission:'',

                     },
        })
    }


    edit() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        var model = this.state.selectedRows[0];
        if(Common.isNotEmpty(model.userType) && model.userType === "HEAD_USER"){
            model.cardNumberType ='总部卡';
        }else if(Common.isNotEmpty(model.userType) && model.userType === "STORE_CHARGE"){
            model.cardNumberType ='店铺卡';
        }else if(model.cardNumberType === 'Y'){
            model.cardNumberType ='金卡';
        }else if(model.cardNumberType === 'N'){
            model.cardNumberType ='银卡';
        }else{
            model.cardNumberType ='';
        }

        var generalizedQRcode = model.userId;
        var generalizedQRCodeFlag = model.generalizedQRCodeFlag;
        let file2 = [{ uid: '-1', }]
        if (generalizedQRCodeFlag) {
            file2[0].url = Config.generalizedQRCode + generalizedQRcode+".jpg"+ '?' + Math.random();
            file2[0].name = generalizedQRcode;
        } else {
            file2 = []
        }
        var permanentGeneralizedQRCode = model.permanentGeneralizedQRCode;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(permanentGeneralizedQRCode) && permanentGeneralizedQRCode.indexOf('http') != -1) {
            file1[0].url = permanentGeneralizedQRCode+ '?' + Math.random();
            file1[0].name = permanentGeneralizedQRCode;
        } else if (Common.isNotEmpty(permanentGeneralizedQRCode) && permanentGeneralizedQRCode.indexOf('http') == -1) {
            file1[0].url = Config.permanentGeneralizedQRCode + permanentGeneralizedQRCode+ '?' + Math.random();
            file1[0].name = permanentGeneralizedQRCode;
        } else {
            file1 = []
        }
        this.setState({
            tabIndex: "2",
            flag: "edit",
            model:model,
            imageFile: file2,
            imageFilePermanent: file1,
        })
        this.addQRCodeTemporary(model);
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handlePreviewPermanent = (file) => {
        this.setState({
            previewImagePermanent: file.url || file.thumbUrl,
            previewVisiblePermanent: true,
        });
    }

    handleCancel2 (){
        this.setState({ previewVisible: false })
    } 

    handleCancel3 (){
        this.setState({ previewVisiblePermanent: false })
    } 

    handleChange(file) {
        this.setState({
            imageFile: file.fileList
        })
    }

    handleChangePermanent(file) {
        this.setState({
            imageFilePermanent: file.fileList
        })
    }

 

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }
 

    modelChange(k, e) {//需求待定
        let newModel = this.state.model;
        console.log("memberConsumptionCommission");
        console.log(e);
       if('newMembersCommission' === k || 'memberConsumptionCommission' === k){
            this.addSUm(e,0);
       }
       newModel[k] = e;
       console.log("newModel");
       console.log(newModel);
        this.setState({
            model: newModel,
        });
    }

    addSUm(a, b) {
        var str1 = a.toString(),str2 = b.toString(),r1,r2,m;
        try{r1= str1.split('.')[1].length;}catch(e){ r1 = 0; }
        try{r2= str2.split('.')[1].length;}catch(e){ r2 = 0; }
        m = Math.pow(10,Math.max(r1,r2));
        return (a*m + b*m)/m
    }


    searchChange(k, e) {
        let newModel = this.state.searchModel;
        
        if("checked" === k){
            this.setState({
                checked: e
            });
        }
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    save(){
         var model = this.state.model
         if(Common.isEmpty(model.newMembersCommission)){
         }else {
             if(model.newMembersCommission.length >=2){
                var  len1 = model.newMembersCommission.slice(0,1);
                var  len2 = model.newMembersCommission.slice(1,2);
                if(len1 === '0' && len2 != '.'){
                    Message.openMsgError("错误", "推广会员提成请输入小于1000且最多包含2位小数的数字;")
                    return
                }
             }
         }
         
        fetch(Config.apiBackServer + "promotionCostSetting/update", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log(data);
            if ("SUCCESS" === data.msg) {
                this.cancel();
                Message.openMsgSuccess("成功", "保存成功")
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
           
            
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));

    }

    saveBatch(){
        var model = this.state.model
        var list = this.state.tablesBatch;
        if(list == null || list.length === 0){
            Message.openMsgError("错误", "请至少选中一条记录进行推广费用设置;")
            return
        }
        if(Common.isEmpty(model.newMembersCommission)){
           Message.openMsgError("错误", "推广新会员提成不能为空;")
           return
        }else {
            if(model.newMembersCommission.length >=2){
               var  len1 = model.newMembersCommission.slice(0,1);
               var  len2 = model.newMembersCommission.slice(1,2);
               if(len1 === '0' && len2 != '.'){
                   Message.openMsgError("错误", "推广新会员提成请输入小于1000且最多包含2位小数的数字;")
                   return
               }
            }
        }
        model.list = list;
       fetch(Config.apiBackServer + "promotionCostSetting/updateBatch", {
           method: 'POST',
           body: JSON.stringify(model),
           headers: Config.header,
           mode: 'cors',
           cache: 'default'
       }).then(res => res.json()).then((data) => {
           console.log(data);
           if ("SUCCESS" === data.msg) {
               this.cancel();
               Message.openMsgSuccess("成功", "保存成功")
           } else {
               Message.openMsgError("错误", data.msg, data.errorCode)
           }
          
           
       }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
        })
        this.search()
    }

    tableChange(value, flag) {
        
            if (value === "2") {
                if (this.state.selectedRows.length == 1) {
                    this.edit();
                }
            } else {
                this.setState({
                    tab: value,
                })
                this.cancel();
            }

    }

    selectCardNumberPop = (ref) => {
        this.selectCardNumberPop = ref;
    }

    selectCardNumber() {
        this.selectCardNumberPop.open()
    }

    selectCardNumberPopBatch = (ref) => {
        this.selectCardNumberPopBatch = ref;
    }

    selectCardNumberBatch () {
        this.selectCardNumberPopBatch.open()
    }

    closePopupBatch() {
        let cardNumbermodel = this.selectCardNumberPopBatch.state.selectedRows;
        var tablesBatch = this.state.tablesBatch;
        if(cardNumbermodel != null ){
            for(var i=0;i<cardNumbermodel.length;i++){
                for(var j=0;j<tablesBatch.length;j++){
                    if(cardNumbermodel[i].cardNumber === tablesBatch[j].cardNumber){
                        Message.openMsgError("错误", "不能选择重复卡号");
                        return;
                    }
                }
                tablesBatch.push(cardNumbermodel[i]);
            }
        }
        
        this.setState({
            tablesBatch: tablesBatch
        })
    }

    closePopup() {
        let cardNumbermodel = this.selectCardNumberPop.state.selectedRows[0]
        console.log(cardNumbermodel);
        let model = this.state.model
        console.log(model);
        model.cardNumber = cardNumbermodel.cardNumber;
        model.phone = cardNumbermodel.phone;
        model.promotionCardNumber = cardNumbermodel.promotionCardNumber;
        model.createdDate = cardNumbermodel.createdDate;
        model.userId = cardNumbermodel.userId;
        model.masterCard = cardNumbermodel.masterCard;
        model.permanentGeneralizedQRCode = cardNumbermodel.permanentGeneralizedQRCode;
        if(Common.isNotEmpty(cardNumbermodel.userType) && cardNumbermodel.userType === "HEAD_USER"){
            model.cardNumberType ='总部卡';
        }else if(Common.isNotEmpty(cardNumbermodel.userType) && cardNumbermodel.userType === "STORE_CHARGE"){
            model.cardNumberType ='店铺卡';
        }else if(cardNumbermodel.cardNumberType === 'Y'){
            model.cardNumberType ='金卡';
        }else if(cardNumbermodel.cardNumberType === 'N'){
            model.cardNumberType ='银卡';
        }else{
            model.cardNumberType ='';
        }

        var generalizedQRcode = cardNumbermodel.userId;
        var generalizedQRCodeFlag = cardNumbermodel.generalizedQRCodeFlag;
        let file2 = [{ uid: '-1', }]
        if (generalizedQRCodeFlag) {
            file2[0].url = Config.generalizedQRCode + generalizedQRcode+".jpg"+ '?' + Math.random();
            file2[0].name = generalizedQRcode;
        } else {
            file2 = []
        }

        var permanentGeneralizedQRCode = cardNumbermodel.permanentGeneralizedQRCode;
        let file1 = [{ uid: '-1', }]
        if (Common.isNotEmpty(permanentGeneralizedQRCode) && permanentGeneralizedQRCode.indexOf('http') != -1) {
            file1[0].url = permanentGeneralizedQRCode+ '?' + Math.random();
            file1[0].name = permanentGeneralizedQRCode;
        } else if (Common.isNotEmpty(permanentGeneralizedQRCode) && permanentGeneralizedQRCode.indexOf('http') == -1) {
            file1[0].url = Config.permanentGeneralizedQRCode + permanentGeneralizedQRCode+ '?' + Math.random();
            file1[0].name = permanentGeneralizedQRCode;
        } else {
            file1 = []
        }
        this.setState({
            model: model,
            imageFile: file2,
            imageFilePermanent: file1,
        })
        this.addQRCodeTemporary(model);
    }

    
    exportReport() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "promotionCostSetting/exportReport", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推广设置" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        });
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let model = this.state.selectedRows[0]
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "promotionCostSetting/delete", {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel();
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    downloadCurrentImage1(qRcode){
        var model =this.state.model;
        console.log(model);
        var url = "";
        if("permanentGeneralizedQRCode" === qRcode){
            url = Config.permanentGeneralizedQRCode +this.state.model.permanentGeneralizedQRCode;
        }else if("generalizedQRcode" === qRcode){
            url = Config.generalizedQRCode +this.state.model.userId+".jpg";
        }
        model.url = url;
        fetch(Config.apiBackServer + "promotionCostSetting/download", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob().then(blob => {
            if (blob.type == "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        Message.openMsgError("错误", data.msg, data.errorCode)
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "";
                if("permanentGeneralizedQRCode" === qRcode){
                    filename = this.state.model.permanentGeneralizedQRCode;
                }else if("generalizedQRcode" === qRcode){
                    filename = this.state.model.userId+".jpg";
                }
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        })).catch(err => ({ err })).finally(() => {
            this.setState({ isDown: false })
        });
    }

     //生成临时推广二维码
     addQRCodeTemporary(model){
        // var model =this.state.model;
        console.log(model);
        if(Common.isEmpty(model.userId)){
            Message.openMsgError("错误", "请先选择会员");
            return;
        }else{
            fetch(Config.apiBackServer + "promotionCostSetting/permanentGeneralizedqRCodeTemporary", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                var generalizedQRcode = model.userId;
                let file2 = [{ uid: '-1', }]
                file2[0].url = Config.generalizedQRCode + generalizedQRcode+".jpg"+ '?' + Math.random();
                file2[0].name = generalizedQRcode+".jpg";
                if ("SUCCESS" === data.msg) {
                    this.setState({
                        model: model,
                        imageFile: file2,
                    })
                }
                
            }).catch(err => { });
        }
    }

    //生成永久推广二维码
    addQRCode(){
        var model =this.state.model;
        console.log(model);
        if(Common.isEmpty(model.userId)){
            Message.openMsgError("错误", "请先选择会员");
            return;
        }else if(Common.isEmpty(model.permanentGeneralizedQRcode)){
            fetch(Config.apiBackServer + "promotionCostSetting/permanentGeneralizedqRCode", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                console.log(data);
                var permanentGeneralizedQRCode = data.object.permanentGeneralizedQRCode;
                model.permanentGeneralizedQRCode = permanentGeneralizedQRCode;

                let file1 = [{ uid: '-1', }]
                file1[0].url = Config.permanentGeneralizedQRCode + model.userId+".jpg"+ '?' + Math.random();
                file1[0].name = model.userId+".jpg";

                if ("SUCCESS" === data.msg) {
                    this.setState({
                        model: model,
                        imageFilePermanent: file1,
                    })
                    Message.openMsgSuccess("生成永久推广二维码成功", "");
                }
                
            }).catch(err => { });
        }else{
            Message.openMsgError("错误", "该用户已经生成永久推广二维码");
            return;
        }

        
    }


    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>临时二维码</div>
            </div>
        );
        const uploadButtonPermanent = (
            <div>
                <Icon type="plus" />
                <div>永久二维码</div>
            </div>
        );
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
            },
            {
                title: '推荐卡号',
                dataIndex: 'masterCard',
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '开卡店铺名称',
                dataIndex: 'shopName',
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth10',
                render: (text, record, index) =>
                        (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("HEAD_RESPONSIBLE") > -1 ? "总部卡" :
                        (Common.isNotEmpty(record.sysRoleCode) && record.sysRoleCode.indexOf("STORE_RESPONSIBLE") > -1 ? "店铺卡" :
                            (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                        ))
            },
            {
                title: '是否绑定用户',
                dataIndex: 'binding',
                className: 'tableWidth8',
                render: (text) => ContextHandler.getMapValue('bindingMap', text)
            }, 
            {
                title: '推广会员提成',
                dataIndex: 'newMembersCommission',
                className: 'tableRightAlignment',
            },
            {
                title: '推广会员消费额提成比例',
                dataIndex: 'memberConsumptionCommission',
                className: 'tableRightAlignment',
            }

        ];    

        const columnsBatch = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
            },
            {
                title: '推荐卡号',
                dataIndex: 'masterCard',
            },
            {
                title: '开通会员卡日期',
                dataIndex: 'createdDate',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormat)}</span>,
            },
            {
                title: '卡号类型',
                dataIndex: 'cardNumberType',
                className: 'tableWidth10',
                render: (text, record, index) =>
                    Common.isNotEmpty(record.userType) && record.userType === "HEAD_USER" ? "总部卡" :
                        (Common.isNotEmpty(record.userType) && record.userType === "STORE_CHARGE" ? '店铺卡' :
                            (text === "Y" ? "金卡" : (text === "N" ? "银卡" : ""))
                        )
            },
        ];    
      

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
    
        return (
            <div className="promotionCostSetting">
                <div className="zhRoot">
                    <div className="zhTitle">推广设置</div>
                    <div className="content">
                        <SelectCardNumber onRef={this.selectCardNumberPop} closePopup={() => this.closePopup()} />
                        <SelectCardNumberBatch  onRef={this.selectCardNumberPopBatch} closePopupBatch={() => this.closePopupBatch()} />
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="列表" key="1">
                                <Spin spinning={this.state.isDown} tip="下载中...">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={4} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>

                                        <Col span={3} className="zhTxt">非默认推广费用会员：</Col>
                                        <Col span={3} className="zhInput">
                                                <Checkbox
                                                    checked={this.state.checked}
                                                    onChange={(e) => this.searchChange('checked', e.target.checked)} 
                                                >
                                                </Checkbox>
                                        </Col>
                                       
                                    </Row>

                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.add()} icon="plus">添加</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft"  type="primary" onClick={() => this.batch()} icon="plus">批量设定</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.edit()} icon="edit">编辑</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportReport()} icon="download">导出excel</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.delete()} icon="delete">删除</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columns} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables} //数据
                                            pagination={{  //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,            //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                                total: this.state.searchModel.total,             //总共多少条数据
                                                current: this.state.searchModel.page,         //当前页
                                                pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                                defaultCurrent: 1,                               //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'cardNumber', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                            rowClassName="tableColor"
                                        />
                                    </Row>
                                </Spin>
                            </TabPane>
                            <TabPane tab="详情" key="2">
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={()=>this.handleCancel2()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisiblePermanent} footer={null} onCancel={()=>this.handleCancel3()}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImagePermanent} />
                                </Modal>
                                    <Row>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input disabled
                                                maxLength={20}
                                                placeholder="会员卡号"
                                                value={this.state.model.cardNumber || ''}
                                                onChange={(e) => this.modelChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} style={{ marginTop: "5px" }} >
                                            <Button disabled={this.state.flag === 'edit'} type="primary" onClick={() => this.selectCardNumber()} >选择会员</Button>
                                        </Col>
                                    </Row>
                                   
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                disabled
                                                value={this.state.model.phone || ''}
                                                onChange={(e) => this.modelChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>卡号类型：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="卡号类型"
                                                disabled
                                                value={this.state.model.cardNumberType || ''}
                                                onChange={(e) => this.modelChange('cardNumberType', e.target.value)} />
                                        </Col>

                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>开通日期：</Col>
                                        <Col span={6} className='zhInput'>
                                            <DatePicker placeholder="开通日期"
                                                className="parts-numberInput"
                                                allowClear={false}
                                                disabled
                                                value={this.state.model.createdDate == null ? null : moment(new Date(this.state.model.createdDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("createdDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>推荐卡号：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推荐卡号"
                                                disabled
                                                value={this.state.model.masterCard || ''}
                                                onChange={(e) => this.modelChange('masterCard', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推广会员提成：</Col>
                                        <Col span={6} className='zhInput' >
                                            <Input maxLength={11}
                                                placeholder="推广会员提成"
                                                value={this.state.model.newMembersCommission || ''}
                                                onChange={(e) => this.modelChange('newMembersCommission', e.target.value)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>推广会员消费额提成比例：</Col>
                                        <Col span={6} className='zhInput' >
                                            <div className="inputLeft">
                                                <Input maxLength={11}
                                                    placeholder="推广会员消费额提成比例"
                                                    value={this.state.model.memberConsumptionCommission || ''}
                                                    onChange={(e) => this.modelChange('memberConsumptionCommission', e.target.value)} />
                                            </div>
                                            <div className="inputRight">
                                                例：提成 1% 应设置为 0.01 
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>临时推广二维码：</Col>
                                        <Col span={6}  className='imgUpload zhInput' hidden ={this.state.imageFile != 0}></Col>
                                        <Col span={6} className='imgUpload zhInput' hidden ={this.state.imageFile == 0}>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFile}
                                                onPreview={this.handlePreview}
                                                >
                                                {this.state.imageFile.length >= this.state.ImageNum ? null : uploadButton}
                                            </Upload>
                                            {/* <a  type="primary" hidden={Common.isEmpty(this.state.model.userId)} onClick={() => this.downloadCurrentImage1("generalizedQRcode")} >下载临时推广二维码</a> */}
                                            <Button hidden={Common.isEmpty(this.state.model.userId)} type="primary" onClick={() => this.downloadCurrentImage1("generalizedQRcode")}>下载临时推广二维码</Button>
                                        </Col>
                                        {/* <Col span={6} className='zhInput' >
                                            <img width={120} src={(Common.isEmpty(this.state.model.generalizedQRcode) ? (Config.generalizedQRCode +"defaultQRcode.jpg") : (Config.generalizedQRCode +this.state.model.generalizedQRcode) )}/>
                                            <a  type="primary" hidden={Common.isEmpty(this.state.model.generalizedQRcode)} onClick={() => this.downloadCurrentImage1("generalizedQRCode")} >下载临时推广二维码</a>
                                        </Col> */}
                                        <Col span={4} className='zhTxt'>永久推广二维码：</Col>
                                        <Col span={6} className='imgUpload zhInput' hidden={Common.isEmpty(this.state.model.permanentGeneralizedQRCode)}>
                                            <Upload
                                                key={"1"}
                                                disabled
                                                accept=".png, .jpg, .jpeg"
                                                action={Config.apiBackServer + "test/test"}
                                                listType="picture-card"
                                                fileList={this.state.imageFilePermanent}
                                                onPreview={this.handlePreviewPermanent}
                                                >
                                                {this.state.imageFilePermanent.length >= this.state.ImageNum ? null : uploadButtonPermanent}
                                            </Upload>
                                            <Button type="primary" hidden={Common.isEmpty(this.state.model.permanentGeneralizedQRCode)} onClick={() => this.downloadCurrentImage1("permanentGeneralizedQRCode")}>下载永久推广二维码</Button>
                                        </Col>
                                        <Col span={6} className='imgUpload zhInput' hidden={!Common.isEmpty(this.state.model.permanentGeneralizedQRCode)}>
                                            <Button type="primary" hidden={!Common.isEmpty(this.state.model.permanentGeneralizedQRCode)} onClick={() => this.addQRCode()} >生成永久推广二维码</Button>
                                        </Col>
                                    </Row>

                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                   
                            </TabPane>
                            <TabPane tab="批量设定" key="3">
                                <Row className='row'>
                                    <Col span={2} style={{ marginTop: "5px" }} >
                                        <Button  type="primary" onClick={() => this.selectCardNumberBatch()} >选择会员</Button>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Table 
                                            rowKey="cardNumber"
                                            bordered={true} //边线
                                            columns={columnsBatch} //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tablesBatch} //数据
                                            rowClassName="tableColor"
                                        />
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>推广新会员提成<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput' >
                                        <Input maxLength={11}
                                            placeholder="推广新会员提成"
                                            value={this.state.model.newMembersCommission || ''}
                                            onChange={(e) => this.modelChange('newMembersCommission', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>推广会员消费额提成比例：</Col>
                                    <Col span={6} className='zhInput' >
                                        <Input maxLength={11}
                                            placeholder="推广会员消费额提成比例"
                                            value={this.state.model.memberConsumptionCommission || ''}
                                            onChange={(e) => this.modelChange('memberConsumptionCommission', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button type="primary" icon="check-circle" onClick={() => { this.saveBatch() }}>保存</Button>
                                    <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div >
        )
    }




 
   

}