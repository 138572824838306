import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Select, DatePicker, Input } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import './wechatPaymentFlow.css';


export default class wechatPaymentFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            isLoading: true,
            searchModel: { type: "微信支付流水", pageSize: 10, page: 1, },
            funcList: [],
            searchShopList: []
        }
        this.init()
    }

    init() {
        this.search();
    }

    search() {
        let model = this.state.searchModel
        let startTime = model.startTime != null ? moment(model.startTime).format("YYYYMMDD") : null;
        let endTime = model.endTime != null ? moment(model.endTime).format("YYYYMMDD") : null;
        if (startTime!=null&& endTime != null ) {
            if(endTime < startTime){
                Message.openMsgError("错误", "支付由日期不能大于支付至日期;");
                return;
            }
        } 
        console.log("model");
        console.log(model);
        this.setState({ isLoading: true })
        fetch(Config.apiBackServer + "wechatPaymentFlow/wechatPaymentFlowSearch", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                console.log("data.object.list");
                console.log(data.object.list);
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: { type: "微信支付流水", page: 1, pageSize: 10, startTime: null, endTime: null, },
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '微信支付编号',
                dataIndex: 'wechatPaymentOrderId',
                className: "tableWidth10",
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: "tableWidth8",
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth7",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth7",
            },
            {
                title: '支付金额',
                dataIndex: 'total',
                className: "tableWidth10 tableRightAlignment",
                className: 'tableRightAlignment',
            },
            {
                title: '货币类型',
                dataIndex: 'feeType',
                className: "tableWidth7",
                render: (text) => ContextHandler.getMapValue('feeTypeMap', text)
            },
            {
                title: '支付日期',
                dataIndex: 'paymentTime',
                className: "tableWidth10",
                render: (text) => text != null ? moment(text).format(Config.dateFormatALL) : null
            },
            {
                title: '支付状态',
                dataIndex: 'status',
                className: "tableWidth7",
                render: (text) => ContextHandler.getMapValue('wechatPaymentFlowMap', text)
            },{
                title: '支付类型',
                dataIndex: 'paymentType',
                className: "tableWidth8",
                render: (text) => ContextHandler.getMapValue('paymentTypeMap', text)
            },
            {
                title: '公众号ID',
                dataIndex: 'appId',
                className: "tableWidth13",
            },
            {
                title: '商户号',
                dataIndex: 'mchId',
                className: "tableWidth10",
            }
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="sysFunc">
                <div className="zhRoot">
                    <div className="zhTitle">微信支付流水</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex}>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">支付编号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="支付编号"
                                            value={this.state.searchModel.wechatPaymentOrderId || ''}
                                            onChange={(e) => this.searchChange('wechatPaymentOrderId', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">支付日期：</Col>
                                    <Col span={7} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            className="wcFlowDatePicker"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormatALL)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat} />
                                         &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            className="wcFlowDatePicker"
                                            allowClear={true}
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormatALL)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat} />
                                    </Col>
                                    <Col span={2} className='zhTxt'>支付状态：</Col>
                                    <Col span={3} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.searchModel.status || ''}
                                            onChange={(e) => this.searchChange('status', e)} >
                                            <Option value="">      </Option>
                                            {context.wechatPaymentFlowMap.map((item) =>
                                                <Option value={item.key}>{item.value}</Option>
                                            )}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="店铺名称"
                                            value={this.state.searchModel.shopName || ''}
                                            onChange={(e) => this.searchChange('shopName', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={11}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row >
                                    <Button disabled={this.state.isLoading} type="primary" onClick={() => this.exportConsumptionExcel()} icon="download">导出Excel表格</Button>
                                </Row>
                                <Row className='zhRow'>
                                    <Table rowSelection={rowSelection}
                                        rowKey="id"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'id', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>

                            </TabPane>

                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
    exportConsumptionExcel() {
        fetch(Config.apiBackServer + "wechatPaymentFlow/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "微信支付流水"+ Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => {
        });
    }
}