import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, DatePicker, Spin, AutoComplete} from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Common } from '../../public/js/common';
import { Message } from '../../public/js/message';
import { ContextHandler } from '../../public/js/contextHandler';
import './goldCardRenewalAudit.css';

const { TextArea } = Input;
export default class GoldCardRenewalAudit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],
            searchModel: { pageSize: 10, page: 1, auditStatus: "",rebateStartTime: new Date().setDate(new Date().getDate() - 7), rebateEndTime:new Date() },
            isLoading: false,

            model: {},
            isExport: false,
        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        fetch(Config.apiBackServer + "goldCardRenewalAudit/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            console.log("data",data)
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            this.setState({
                searchModel: model,
                tables: data.object
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchChange(k, e) {
        let { searchModel} = this.state;
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: searchModel,
        });
        this.search();
    }

    tableChange(value) {
        if (value === "2") {
            this.toRebate();
        } else {
            this.setState({ tab: value, })
            this.cancel();
        }
    }

    /**点击审核进编辑页 */
    toRebate() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        if (model.auditStatus === "UNCHECKED") {
            this.setState({
                tabIndex: "2",
                flag: "edit",
                model: model,
            })
        } else {
            Message.openMsgError("错误", "已审核数据无法再审核")
        }
    }

    /**点击查看进编辑页 */
    toSearchRebate() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        if (model.auditStatus === "CHECKED") {
            this.setState({
                tabIndex: "2",
                flag: "eye",
                model: model,
            })
        } else {
            Message.openMsgError("错误", "未审核数据无法查看")
        }
    }

    /**确认批量审核 */
    saveRebateAll() {
        let model = this.state.selectedRows;
        if ( Common.isEmpty(model)) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        if (Common.isNotEmpty(model.activeStatus) &&　model.activeStatus === "FROZEN") {
            Message.openMsgError("错误", "冻结状态无法审核")
            return
        }
        let parm=false;
        for (let index = 0; index < model.length; index++) {
            const element = model[index];
            if (element.auditStatus ==="CHECKED") {
                parm=true
            }
        }
        if (!parm) {
            this.setState({ loading: true });
            fetch(Config.apiBackServer + "goldCardRenewalAudit/updateAuditAll", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                console.log("data.errorCode",data)
                if ("SUCCESS" === data.msg) {
                    this.cancel()
                    Message.openMsgSuccess("成功", "审核成功")
                } else {
                    Message.openMsgError("错误", data.msg, data.errorCode)
                }
            }).catch(err => { });
        } else {
            Message.openMsgError("错误", "选中数据有已审核的记录")
        }
    }

    render() {

        const { TabPane } = Tabs;
        const { Option } = Select;

        const columns = [
            {
                title: '负责人卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth8',
            },
            {
                title: '负责人手机号',
                dataIndex: 'phone',
                className: "tableWidth8",
            },
            {
                title: '会员卡号',
                dataIndex: 'goldPayNumber',
                className: 'tableWidth8',
            },
            {
                title: '会员手机号',
                dataIndex: 'goldPayPhone',
                className: "tableWidth8",
            },
            {
                title: '奖励类型',
                dataIndex: 'rebateType',
                className: "tableWidth8",
                render: (text) => ContextHandler.getMapValue('rebateRegisterRebateTypeMap', text)
            },
            {
                title: '奖励时间',
                dataIndex: 'rebateTime',
                className: "tableWidth10",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: "tableRightAlignment tableWidth8",
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth8",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核人电话',
                dataIndex: 'auditByPhone',
                className: "tableWidth8",
            },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: "tableWidth10",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '审核备注',
                dataIndex: 'auditRemark',
                className: "tableWidth8",
            },
            {
                title: '会员状态',
                dataIndex: 'activeStatus',
                className: "tableWidth8",
                render: (text) => ContextHandler.getMapValue('statusMap', text)
            },
        ]

        
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            // type: 'radio',
        };

        return (
            <div className="goldCardRenewalAudit">
                <div className="zhRoot">
                    <div className="zhTitle">推荐金卡奖励审核</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.goldPayNumber || ''}
                                                onChange={(e) => this.searchChange('goldPayNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.goldPayPhone || ''}
                                                onChange={(e) => this.searchChange('goldPayPhone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">奖励时间：</Col>
                                        <Col span={8} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="goldCardRenewalAuditDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.rebateStartTime == null ? null : moment(new Date(this.state.searchModel.rebateStartTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("rebateStartTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="goldCardRenewalAuditDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.rebateEndTime == null ? null : moment(new Date(this.state.searchModel.rebateEndTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("rebateEndTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">负责人卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="负责人卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">负责人手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="负责人手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={8} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="goldCardRenewalAuditDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditStartTime == null ? null : moment(new Date(this.state.searchModel.auditStartTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditStartTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="goldCardRenewalAuditDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditEndTime == null ? null : moment(new Date(this.state.searchModel.auditEndTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditEndTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.auditStatus || ''}
                                                onChange={(e) => this.searchChange('auditStatus', e)} >
                                                <Option key="" value={""}>全部</Option>
                                                <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                                <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toRebate()} icon="export">审核</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.saveRebateAll()} icon="export">批量审核</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.toSearchRebate()} icon="eye">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="rebateGoldCardId"
                                            scroll={{ x: 1000 }}
                                            bordered={true}                                     //边线
                                            columns={columns}                                   //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables}                      //数据
                                            pagination={{                                       //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.searchModel.total,            //总共多少条数据
                                                current: this.state.searchModel.page,           //当前页
                                                pageSize: this.state.searchModel.pageSize,      //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRow.call(this, record, 'rebateGoldCardId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                        
                                        />

                                    </Row>
                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>负责人卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.cardNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>负责人手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.phone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.goldPayNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.goldPayPhone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>奖励类型：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={ContextHandler.getMapValue('rebateRegisterRebateTypeMap', this.state.model.rebateType)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>奖励时间：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' disabled value={this.state.model.rebateTime == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.model.rebateTime).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>奖励金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} 
                                            disabled value={Common.numberFormat(this.state.model.rebateAmount || 0, 2)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled
                                            value={ContextHandler.getMapValue('auditStatusMap', this.state.model.auditStatus)} />
                                        </Col>
                                    </Row>
                                    {this.state.flag === "eye" ?  
                                        <Row className='row'>
                                            <Col span={4} className='zhTxt'>审核人电话：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.auditByPhone} />
                                            </Col>
                                            <Col span={4} className='zhTxt'>审核时间：</Col>
                                            <Col span={6} className='zhInput'>
                                                <Input className='disableFontColor' disabled value={this.state.model.auditDate == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.model.auditDate).format(Config.dateFormatALL)} />
                                            </Col>
                                        </Row>
                                    : null}
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={16} className='RemarkInput'>
                                            <TextArea
                                                value={this.state.model.auditRemark || ""}
                                                className='disableFontColor'
                                                onChange={(e) => this.modelChange('auditRemark', e.target.value)}
                                                placeholder="备注"
                                                disabled={this.state.flag === "eye"}
                                                maxLength={500}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonLeft'>
                                        {this.state.flag !== "eye" ? <Button disabled={this.state.loading} type="primary" icon="check-circle" onClick={() => { this.saveRebate() }}>审核</Button> : null}
                                        <Button className="zhButtonRight" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }

    modelChange(k, e) {
        let model = this.state.model;
        model[k] = e;
        this.setState({ model });
    }

    /**确认审核 */
    saveRebate() {
        let { model } = this.state;
        if (Common.isNotEmpty(model.activeStatus) &&　model.activeStatus === "FROZEN") {
            Message.openMsgSuccess("错误", "冻结状态无法审核")
        } else {
            this.setState({loading: true })
            fetch(Config.apiBackServer + "goldCardRenewalAudit/updateAudit", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                if ("SUCCESS" === data.msg) {
                    this.cancel()
                    Message.openMsgSuccess("成功", "审核通过")
                } else {
                    Message.openMsgError("错误", data.msg, data.errorCode)
                }
            }).catch(err => { });
        }
    }

    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "goldCardRenewalAudit/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                       // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "推荐金卡奖励审核.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({ isExport: false })
        });
    }

    reset() {
        this.setState({
            selectedRows: [],
            selectedRowKeys: [],
            searchModel: { pageSize: 10, page: 1, auditStatus: "",rebateStartTime: new Date().setDate(new Date().getDate() - 7), rebateEndTime:new Date() },
        })
    }
    
    cancel() {
        this.setState({
            loading: false,
            tabIndex: "1",
            flag: "",
            model: {},
            selectedRowKeys: [],
            selectedRows: [],
        }, () => {
            this.search();
        })
    }
}