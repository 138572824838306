import React, { Component } from "react";
import { Spin,  Button, Row, Col, Tabs, Table, Input, DatePicker, Select, AutoComplete} from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import context from '../../public/js/context';

export default class ShopSalesPaymentFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            selectedRows: [],
            tables: [],
            searchModel: {page: 1, pageSize: 10,},

            shopNameList: [],
            dataSource: [], // 自动填充数据
        }
    }

    componentDidMount() {
        this.search();
        this.searchShop();
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    search() {
        this.setState({ isLoading: true });
        fetch(Config.apiBackServer + "shopSalesPaymentFlow/search", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
                this.setState({
                    searchModel: model,
                    tables: data.object.list
                })
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)   
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    searchShop() {
        fetch(Config.apiBackServer + "shop/searchShop", {
            method: 'POST',
            body: JSON.stringify({}),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let list = [];
            if ("SUCCESS" === data.msg) {
                data.object.list.forEach(element => {
                    list.push(element.shopName)
                });
                this.setState({
                    shopNameList:list
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode);
            }
        }).catch(err => { });
    }

    
    reset() {
        this.setState({
            searchModel: {page: 1, pageSize: 10,},
        })
        this.cancel();
    }

    cancel() {
        this.setState({
            selectedRowKeys: [],
            selectedRows: [],
        }, () => {
            this.search()
        })
    }

    
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    onSelect(value) {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({
            searchModel: model,
            dataSource: []
        })
    }

    onChange = (value) => {
        let model = this.state.searchModel
        model.shopName = value
        this.setState({ 
            searchModel: model
        });
    }

    onSearch = searchText => {
        let model = this.state.searchModel
        model.shopName = searchText
        let dataSource = [];
        if (searchText) {
            let list = this.state.shopNameList
            for(let i=0; i < list.length; i++) {
                if (list[i].split(searchText).length > 1) {
                    dataSource.push(list[i]);
                }
            }
        }
        this.setState({
            dataSource: dataSource
        })
    };

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;

        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'flowShopSalesPaymentId',
                className: 'tableWidth14',
            },
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                className: 'tableWidth8',
            },
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: 'tableWidth8',
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: 'tableWidth8',
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: 'tableWidth8',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '饭菜金额',
                dataIndex: 'wineAmount',
                className: 'tableWidth8',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '收款人',
                dataIndex: 'nickName',
                className: 'tableWidth7',
            },
            {
                title: '打款金额',
                dataIndex: 'paymentAmount',
                className: 'tableWidth8 tableRightAlignment',
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '打款状态',
                dataIndex: 'paymentStatus',
                className: 'tableWidth7',
                render: (text) => ContextHandler.getMapValue('wechatPaymentFlowMap', text)
            },
            {
                title: '打款时间',
                dataIndex: 'paymentTime',
                className: 'tableWidth10',
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '打款备注',
                dataIndex: 'paymentRemark',
                className: 'tableWidth7',
            },
            {
                title: '付款信息',
                dataIndex: 'errMessage',
                className: 'tableWidth7',
                render: (text) =><span>{text === 'ERR' ? "打款失败" : null }</span>,
            },
        ]

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        }
        return (
            <div className="shopSalesPaymentFlow">
                <div className="zhRoot">
                    <div className="zhTitle">营业额店铺打款流水</div>
                    <div className="content">
                        <Tabs defaultActiveKey='1'>
                            <TabPane tab="列表" key="1">
                                <Row className="row">
                                    <Col span={2} className="zhTxt">会员卡号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={10}
                                            placeholder="会员卡号"
                                            value={this.state.searchModel.cardNumber || ''}
                                            onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">会员手机号：</Col>
                                    <Col span={3} className="zhInput">
                                        <Input maxLength={20}
                                            placeholder="会员手机号"
                                            value={this.state.searchModel.phone || ''}
                                            onChange={(e) => this.searchChange('phone', e.target.value)} />
                                    </Col>
                                    <Col span={2} className="zhTxt">打款日期：</Col>
                                    <Col span={6} className="zhInput">
                                        <DatePicker placeholder="由日期"
                                            allowClear={true}
                                            className="shopSalesPaymentFlowDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("startTime", value)}
                                            format={Config.dateFormat}/>
                                        &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                            allowClear={true}
                                            className="shopSalesPaymentFlowDatePicker"
                                            disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd")}
                                            value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                            onChange={(value) => this.searchChange("endTime", value)}
                                            format={Config.dateFormat}/>
                                    </Col>
                                </Row>
                                <Row className="row">
                                    <Col span={2} className="zhTxt">店铺名称：</Col>
                                    <Col span={3} className="zhInput">
                                        <AutoComplete
                                            dropdownMatchSelectWidth={false}//下拉菜单与选择器同宽
                                            style={{ width: '100%' }}
                                            dataSource={this.state.dataSource}
                                            value={this.state.searchModel.shopName || ''}
                                            onSelect={(e) => this.onSelect(e)}
                                            onChange={this.onChange}
                                            onSearch={this.onSearch}
                                            filterOption={true}     //是否根据输入项进行筛选。
                                            placeholder="店铺名称"
                                        />
                                    </Col>
                                    <Col span={2} className="zhTxt">打款状态：</Col>
                                    <Col span={3} className="zhInput">
                                        <Select style={{ width: "100%" }} placeholder="打款状态" 
                                            value={this.state.searchModel.paymentStatus || ''}
                                            onChange={(e) => this.searchChange('paymentStatus', e)}>
                                            <Option  key="" value="">全部</Option >
                                            {context.wechatPaymentFlowMap.map((item) => <Option  key={item.key} value={item.key}>{item.value}</Option>)}
                                        </Select>   
                                    </Col>
                                </Row>
                                <Row className="rowButton">
                                    <Col className="zhRowButtonRight">
                                        <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                    </Col>
                                </Row>
                                <Row className='zhRow'>
                                    <Table
                                        rowKey="flowShopSalesPaymentId"
                                        bordered={true} //边线
                                        columns={columns} //列名
                                        loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                        dataSource={this.state.tables} //数据
                                        pagination={{  //分页
                                            showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                            onChange: this.pageChange,            //上页下页或者跳页方法
                                            onShowSizeChange: this.pageChange,    //每页显示多少条数据方法
                                            total: this.state.searchModel.total,             //总共多少条数据
                                            current: this.state.searchModel.page,         //当前页
                                            pageSize: this.state.searchModel.pageSize,       //每页显示多少条数据
                                            defaultCurrent: 1,                               //默认当前页为1
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: event => Common.selectRadioRow.call(this, record, 'flowShopSalesPaymentId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                            };
                                        }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }
}