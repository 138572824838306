import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Table, Input, Select, DatePicker, Spin } from 'antd';
import moment from 'moment';//日期格式化导包

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import Context from '../../public/js/context';
import { ContextHandler } from '../../public/js/contextHandler';
import './shopRebateManagement.css';
const { TextArea } = Input;

export default class ShopRebateManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            searchModel: {shopId: Config.userInfo.shopId, pageSize: 10, page: 1, auditStatus: "", type: "会员奖励", cashierStartTime: new Date().setDate(new Date().getDate() - 7), cashierEndTime: new Date() },
            isLoading: true,
            isExport: false,

        }
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ isLoading: true })
        let model = this.state.searchModel;
        console.log(Config.userInfo)
        fetch(Config.apiBackServer + "shopRebateManagement/shopRebateSearchByShopId", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                model.total = data.object.total;
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode)
            }
            console.log(data)
            this.setState({
                searchModel: model,
                tables: data.object.list
            })
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    reset() {
        this.setState({
            searchModel: {shopId: Config.userInfo.shopId, pageSize: 10, page: 1, auditStatus: "", type: "会员奖励", cashierStartTime: new Date().setDate(new Date().getDate() - 7), cashierEndTime: new Date() },
        },() => {
            this.search();
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    searchChange(k, e) {
        let { searchModel} = this.state;
        searchModel[k] = e;
        this.setState({ searchModel });
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    
    tableChange(value) {
        if (value === "2") {
            this.eye();
        } else {
            this.setState({ tabIndex: value, })
            this.cancel();
        }
    }

    eye() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据")
            return
        }
        let model = this.state.selectedRows[0];
        if (model.auditStatus === "CHECKED") {
            this.setState({
                tabIndex: "2",
                model: model,
            })
        }else{
            Message.openMsgError("错误", "未审核数据无法查看")
        }
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            model: {},
            selectedRowKeys: [],
            selectedRows: [],
            searchModel: {shopId: Config.userInfo.shopId, pageSize: 10, page: 1, auditStatus: "", type: "会员奖励", cashierStartTime: new Date().setDate(new Date().getDate() - 7), cashierEndTime: new Date() },
        }, () => {
            this.search()
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '会员卡号',
                dataIndex: 'cardNumber',
                className: "tableWidth9",
            },
            {
                title: '会员手机号',
                dataIndex: 'phone',
                className: "tableWidth9",
            },
            {
                title: '推荐人卡号',
                dataIndex: 'recommendNumber',
                className: 'tableWidth9',
            },
            {
                title: '推荐人手机号',
                dataIndex: 'recommendPhone',
                className: "tableWidth9",
            },
            {
                title: '推荐类型',
                dataIndex: 'rebateType',
                className: "tableWidth10",
                render: (text) => ContextHandler.getMapValue('rebateSalesRebateTypeMap', text)
            },
            {
                title: '奖励时间',
                dataIndex: 'rebateTime',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
            {
                title: '饭菜金额',
                dataIndex: 'foodAmount',
                className: "tableWidth8 tableRightAlignment",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '酒水金额',
                dataIndex: 'wineAmount',
                className: "tableWidth8 tableRightAlignment",
                render: (text) => Common.numberFormat(text || 0, 2)
            },
            {
                title: '奖励金额',
                dataIndex: 'rebateAmount',
                className: "tableWidth8 tableRightAlignment",
                render: (text) => Common.numberFormat(text, 2)
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                className: "tableWidth7",
                render: (text) => ContextHandler.getMapValue('auditStatusMap', text)
            },
            {
                title: '审核时间',
                dataIndex: 'auditDate',
                className: "tableWidth11",
                render: (text) => <span>{text == null ? null : moment(text).format(Config.dateFormatALL)}</span>,
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        return (
            <div className="shopRebateManagement">
                <div className="zhRoot">
                    <Spin spinning={this.state.isExport} tip="下载中...">
                        <div className="zhTitle">会员奖励</div>
                        <div className="content">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="列表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">会员卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={10}
                                                placeholder="会员卡号"
                                                value={this.state.searchModel.cardNumber || ''}
                                                onChange={(e) => this.searchChange('cardNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">会员手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="会员手机号"
                                                value={this.state.searchModel.phone || ''}
                                                onChange={(e) => this.searchChange('phone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">奖励日期：</Col>
                                        <Col span={8} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="shopRebateManagementDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.cashierStartTime == null ? null : moment(new Date(this.state.searchModel.cashierStartTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("cashierStartTime", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                        <DatePicker placeholder="至日期"
                                                className="shopRebateManagementDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.cashierEndTime == null ? null : moment(new Date(this.state.searchModel.cashierEndTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("cashierEndTime", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">推荐人卡号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推荐人卡号"
                                                value={this.state.searchModel.recommendNumber || ''}
                                                onChange={(e) => this.searchChange('recommendNumber', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">推荐人手机号：</Col>
                                        <Col span={3} className="zhInput">
                                            <Input maxLength={11}
                                                placeholder="推荐人手机号"
                                                value={this.state.searchModel.recommendPhone || ''}
                                                onChange={(e) => this.searchChange('recommendPhone', e.target.value)} />
                                        </Col>
                                        <Col span={2} className="zhTxt">审核日期：</Col>
                                        <Col span={8} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                className="shopRebateManagementDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditStartDate == null ? null : moment(new Date(this.state.searchModel.auditStartDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditStartDate", value)}
                                                format={Config.dateFormat} />
                                                &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                className="shopRebateManagementDatePicker"
                                                allowClear={true}
                                                value={this.state.searchModel.auditEndDate == null ? null : moment(new Date(this.state.searchModel.auditEndDate), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("auditEndDate", value)}
                                                format={Config.dateFormat} />
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">审核状态：</Col>
                                        <Col span={3} className='zhInput'>
                                            <Select
                                                className="zhSelect"
                                                value={this.state.searchModel.auditStatus || ''}
                                                onChange={(e) => this.searchChange('auditStatus', e)} >
                                                <Option key="" value={""}>全部</Option>
                                                <Option key="CHECKED" value={"CHECKED"}>已审核</Option>
                                                <Option key="UNCHECKED" value={"UNCHECKED"}>未审核</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button disabled={this.state.isLoading} type="primary" onClick={() => this.search()} icon="search">搜索</Button>
                                            <Button disabled={this.state.isLoading} className="zhButtonRight" onClick={() => this.reset()} icon="redo">重置</Button>
                                        </Col>
                                    </Row>
                                    <Row >
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" onClick={() => this.eye()} icon="eye">查看</Button>
                                        <Button disabled={this.state.isLoading} className="zhButtonRight" type="primary" onClick={() => this.exportExcel()} icon="download">导出</Button>
                                    </Row>
                                    <Row className='zhRow'>
                                        <Table rowSelection={rowSelection}
                                            rowKey="rebateSalesId"
                                            bordered={true}                                     //边线
                                            columns={columns}                                   //列名
                                            loading={{ spinning: this.state.isLoading, tip: "加载中..." }}
                                            dataSource={this.state.tables}                      //数据
                                            pagination={{                                       //分页
                                                showSizeChanger: true,                          //是否显示可以设置几条一页的选项
                                                onChange: this.pageChange,                      //上页下页或者跳页方法
                                                onShowSizeChange: this.pageChange,              //每页显示多少条数据方法
                                                total: this.state.searchModel.total,            //总共多少条数据
                                                current: this.state.searchModel.page,           //当前页
                                                pageSize: this.state.searchModel.pageSize,      //每页显示多少条数据
                                                defaultCurrent: 1,                              //默认当前页为1
                                            }}
                                            onRow={(record) => {
                                                return {
                                                    onClick: event => Common.selectRadioRow.call(this, record, 'rebateSalesId', 'selectedRows', 'selectedRowKeys'), // 点击行
                                                };
                                            }}
                                        />
                                    </Row>
                                </TabPane>
                                <TabPane tab="详情" key="2">
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>会员卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.cardNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>会员手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.phone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推荐人卡号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.recommendNumber} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>推荐人手机号：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled value={this.state.model.recommendPhone} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>推荐类型：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={ContextHandler.getMapValue('rebateSalesRebateTypeMap', this.state.model.rebateType)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>饭菜金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.model.foodAmount || 0, 2)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>酒水金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.model.wineAmount || 0, 2)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>奖励金额：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled 
                                            value={Common.numberFormat(this.state.model.rebateAmount || 0, 2)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>审核状态：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' maxLength={30} disabled
                                                value={ContextHandler.getMapValue('auditStatusMap', this.state.model.auditStatus)} />
                                        </Col>
                                        <Col span={4} className='zhTxt'>审核时间：</Col>
                                        <Col span={6} className='zhInput'>
                                            <Input className='disableFontColor' disabled value={this.state.model.auditDate == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.model.auditDate).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>奖励时间：</Col>
                                        <Col span={6} className='zhInput'>
                                        <Input className='disableFontColor' disabled value={this.state.model.rebateTime == null ? moment(new Date()).format(Config.dateFormatALL) : moment(this.state.model.rebateTime).format(Config.dateFormatALL)} />
                                        </Col>
                                    </Row>
                                    <Row className='row'>
                                        <Col span={4} className='zhTxt'>备注：</Col>
                                        <Col span={16} className='RemarkInput'>
                                            <TextArea
                                                value={this.state.model.auditRemark || ""}
                                                className='disableFontColor'
                                                onChange={(e) => this.modelChange('auditRemark', e.target.value)}
                                                placeholder="备注"
                                                disabled
                                                maxLength={500}
                                                autoSize={{ minRows: 3, maxRows: 3 }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='zhRow zhRowButtonRight'>
                                        <Button className="zhButtonRight" icon="rollback" onClick={() => { this.cancel() }}>返回</Button>
                                    </Row>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Spin>
                </div>
            </div>
        )
    }

    exportExcel() {
        this.setState({ isExport: true });
        fetch(Config.apiBackServer + "shopRebateManagement/exportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" === data.msg) {
                    } else {
                        // this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员奖励.xlsx";
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {// for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// for Non-IE (chrome, firefox etc.)
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                    this.cancel();
                }
            }
        }).catch(err => ({ err })).finally(() => { this.setState({ isExport: false }) });
    }
}