import React, { Component } from 'react';
import { InputNumber, Button, Row, Col, Tabs, Table, Input, Select, Modal, Upload, Icon, Spin } from 'antd';

import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { ContextHandler } from '../../public/js/contextHandler';
import $ from 'jquery';
import './personalStores.css';
import E from 'wangeditor';
import ShopViewModal from '../shop/shopViewModal';

const confirm = Modal.confirm;
const { TextArea } = Input;

export default class PersonalStores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            model: {},
            tables: [],
            imageFileOne: [],
            imageFileTwo: [],
            imageFileThree: [],
            imageFileFour: [],
            classificationList: [],
            ImageNum: 1,
            previewVisible: false,
            isMap: false,
            searchModel: { pageSize: 10, page: 1, },
            mapFlag: true,
            searchMapInput: "",
            fileList1: [],   //全景图片在插件中存放的位置
            fileList2: [],
            fileList3: [],
            fileList4: [],
            fileList5: [],
            fileList6: [],
            fileList7: [],
            fileList8: [],
            fileList9: [],
            isLoading: false,
        }
        this.init()
    }

    init() {
        this.search();
    }

    search() {
        fetch(Config.apiBackServer + "shop/searchById", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg && data.object.list != null && data.object.list.length > 0) {
                // model.total = data.object.total;
                console.log(data.object.list[0]);
                this.edit(data.object.list[0]);
                this.imageAssignment(data.object.list[0]);
            }

        }).catch(err => { });
    }

    searchClassification() {
        let model = { segment: "STORE_CLASSIFICATIONS" }
        fetch(Config.apiBackServer + "sysParam/searchAll", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            let model = this.state.searchModel;
            if ("SUCCESS" === data.msg) {
                this.setState({
                    classificationList: data.object,
                })
            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { });
    }

    reset() {
        this.setState({
            searchModel: { page: 1, pageSize: 10, funcName: "" },
        })
        this.cancel();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows
        })
    }

    add() {
        this.setState({
            tabIndex: "2",
            flag: "add",
            model: { activeInd: "Y" },
        })
        this.searchClassification()
    }

    edit(modelNew) {
        // if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
        //     Message.openMsgError("错误", "请选择一条数据")
        //     return
        // }

        let model = modelNew
        let imgOne = model.shopLogo;
        let imgTwo = model.shopIntroduceImgOne;
        let imgThree = model.shopIntroduceImgTwo;
        let imgFour = model.shopIntroduceImgThree;
        let imageFileOne = [{ uid: '-1', }]
        let imageFileTwo = [{ uid: '-1', }]
        let imageFileThree = [{ uid: '-1', }]
        let imageFileFour = [{ uid: '-1', }]
        if (imgOne != null && imgOne !== "") {
            imageFileOne[0].url = Config.imgServer + "classify/" + imgOne;
            imageFileOne[0].name = imgOne;
        } else {
            imageFileOne = []
        }
        if (imgTwo != null && imgTwo !== "") {
            imageFileTwo[0].url = Config.imgServer + "classify/" + imgTwo;
            imageFileTwo[0].name = imgTwo;
        } else {
            imageFileTwo = []
        }
        if (imgThree != null && imgThree !== "") {
            imageFileThree[0].url = Config.imgServer + "classify/" + imgThree;
            imageFileThree[0].name = imgThree;
        } else {
            imageFileThree = []
        }
        if (imgFour != null && imgFour !== "") {
            imageFileFour[0].url = Config.imgServer + "classify/" + imgFour;
            imageFileFour[0].name = imgFour;
        } else {
            imageFileFour = []
        }
        this.searchClassification()
        this.setState({
            tabIndex: "1",
            flag: "edit",
            model: modelNew,
            imageFileOne: imageFileOne,
            imageFileTwo: imageFileTwo,
            imageFileThree: imageFileThree,
            imageFileFour: imageFileFour
        }, () => { this.creatEditor() })
    }

    //图片赋值
    imageAssignment(model) {
        if (model.shop3DImage1 != "" && model.shop3DImage1 != null) {
            this.assignment("fileList1", model.shop3DImage1, model.shopId)
        } else {
            this.state.fileList1 = [];
        }
        if (model.shop3DImage2 != "" && model.shop3DImage2 != null) {
            this.assignment("fileList2", model.shop3DImage2, model.shopId)
        } else {
            this.state.fileList2 = [];
        }
        if (model.shop3DImage3 != "" && model.shop3DImage3 != null) {
            this.assignment("fileList3", model.shop3DImage3, model.shopId)
        } else {
            this.state.fileList3 = [];
        }
        if (model.shop3DImage4 != "" && model.shop3DImage4 != null) {
            this.assignment("fileList4", model.shop3DImage4, model.shopId)
        } else {
            this.state.fileList4 = [];
        }
        if (model.shop3DImage5 != "" && model.shop3DImage5 != null) {
            this.assignment("fileList5", model.shop3DImage5, model.shopId)
        } else {
            this.state.fileList5 = [];
        }
        if (model.shop3DImage6 != "" && model.shop3DImage6 != null) {
            this.assignment("fileList6", model.shop3DImage6, model.shopId)
        } else {
            this.state.fileList6 = [];
        }
        if (model.shop3DImage7 != "" && model.shop3DImage7 != null) {
            this.assignment("fileList7", model.shop3DImage7, model.shopId)
        } else {
            this.state.fileList7 = [];
        }
        if (model.shop3DImage8 != "" && model.shop3DImage8 != null) {
            this.assignment("fileList8", model.shop3DImage8, model.shopId)
        } else {
            this.state.fileList8 = [];
        }
        if (model.shop3DImage9 != "" && model.shop3DImage9 != null) {
            this.assignment("fileList9", model.shop3DImage9, model.shopId)
        } else {
            this.state.fileList9 = [];
        }
        this.setState(this.state);
    }

    assignment(k, image, shopId) {
        let file = [{}];
        if(k == "imageFileOne" || k == "imageFileTwo" || k == "imageFileThree" || k == "imageFileFour") {
            file[0].url = Config.shopLogo + "/" + image;
        }else{
            file[0].url = Config.img3DCompressionDiagram + shopId + "/" + image;
        }
        file[0].uid = "-1";
        this.state[k] = file;
    }

    delete() {
        if (this.state.selectedRows == null || this.state.selectedRows.length !== 1) {
            Message.openMsgError("错误", "请选择一条数据");
            return;
        }
        let that = this;
        confirm({
            title: '您确定删除?',
            content: '',
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                fetch(Config.apiBackServer + "shop/delete", {
                    method: 'POST',
                    body: JSON.stringify(that.state.selectedRows[0]),
                    headers: Config.header,
                    mode: 'cors',
                    cache: 'default'
                }).then(res => res.json()).then((data) => {
                    if ("SUCCESS" === data.msg) {
                        Message.openMsgSuccess("删除成功", "");
                        that.cancel()
                    } else {
                        Message.openMsgError("错误", data.msg,data.errorCode);
                    }
                }).catch(err => { });
            },
            onCancel() {
            },
        });
    }

    see() {

    }

    save() {
        this.obtainImgData()
        let url = "";
        let msg = this.check()
        if (msg !== "") {
            Message.openMsgError("错误", msg)
            return
        }
        this.setState({ isLoading: true })
        if (this.state.flag === "add") {
            url = "shop/insert";
        } else {
            url = "shop/update"
        }
        fetch(Config.apiBackServer + url, {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                this.cancel()
                Message.openMsgSuccess("成功", "保存成功")

            } else {
                Message.openMsgError("错误", data.msg,data.errorCode)
            }
        }).catch(err => { }).finally(() => this.setState({ isLoading: false }));
    }

    check() {
        let msg = ""
        let model = this.state.model
        if (model.shopName == null || model.shopName === '') {
            msg += "店铺名称不能为空;"
        }
        if (model.characteristicId == null || model.characteristicId === '') {
            msg += "店铺类型不能为空;"
        }
        if (model.shopPhone == null || model.shopPhone === '') {
            msg += "店铺电话不能为空;"
        }
        if (model.businessDate == null || model.businessDate === '') {
            msg += "营业时间不能为空;"
        }
        if (model.area == null || model.area === '' || model.address == null || model.address === "") {
            msg += "地址不能为空;"
        }

        if (model.dispSeq == null) {
            msg += "序号不能为空;"
        }
        if (model.activeInd == null || model.activeInd === '') {
            msg += "状态不能为空;"
        }
        if (model.shopLogo == null || model.shopLogo === '') {
            msg += "店铺logo不能为空;"
        }
        if (model.shopIntroduceImgOne == null || model.shopIntroduceImgOne === '') {
            msg += "店铺图一不能为空;"
        }
        return msg
    }

    pageChange = (current, pageSize) => {
        let searchModel = this.state.searchModel;
        searchModel.pageSize = pageSize;
        searchModel.page = current;
        this.setState({
            searchModel: searchModel,
        });
        this.search();
    }

    modelChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({
            model: newModel,
        });
    }

    searchChange(k, e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        this.setState({
            searchModel: newModel
        });
    }

    cancel() {
        this.setState({
            tabIndex: "1",
            selectedRowKeys: [],
            selectedRows: [],
            imageFileOne: [],
            imageFileTwo: [],
            imageFileThree: [],
            imageFileFour: [],
            fileList1: [],//全景图片在插件中存放的位置
            fileList2: [],
            fileList3: [],
            fileList4: [],
            fileList5: [],
            fileList6: [],
            fileList7: [],
            fileList8: [],
            fileList9: [],
        })
        this.search()
    }

    tableChange(value) {
        if (value === "2") {
            if (this.state.selectedRows.length === 1) {
                this.edit();
            } else {
                this.add();
            }
        } else {
            this.setState({
                tab: value,
            })
            this.cancel();
        }
    }

    obtainImgData() {
        let state = this.state;
        let model = this.state.model;
        if (state.imageFileOne.length !== 0) {
            if (Common.isEmpty(state.imageFileOne[0].url)) {
                model.shopLogo = state.imageFileOne[0].thumbUrl;
            } else {
                model.shopLogo = state.imageFileOne[0].name;
            }
        } else {
            model.shopLogo = null;
        }

        if (state.imageFileTwo.length !== 0) {
            if (Common.isEmpty(state.imageFileTwo[0].url)) {
                model.shopIntroduceImgOne = state.imageFileTwo[0].thumbUrl;
            } else {
                model.shopIntroduceImgOne = state.imageFileTwo[0].name;
            }
        } else {
            model.shopIntroduceImgOne = null;
        }

        if (state.imageFileThree.length !== 0) {
            if (Common.isEmpty(state.imageFileThree[0].url)) {
                model.shopIntroduceImgTwo = state.imageFileThree[0].thumbUrl;
            } else {
                model.shopIntroduceImgTwo = state.imageFileThree[0].name;
            }
        } else {
            model.shopIntroduceImgTwo = null;
        }

        if (state.imageFileFour.length !== 0) {
            if (Common.isEmpty(state.imageFileFour[0].url)) {
                model.shopIntroduceImgThree = state.imageFileFour[0].thumbUrl;
            } else {
                model.shopIntroduceImgThree = state.imageFileFour[0].name;
            }
        } else {
            model.shopIntroduceImgThree = null;
        }

        if (state.fileList1.length == 1) {
            model.shop3DImage1 = state.fileList1[0].url || state.fileList1[0].thumbUrl;
            if (model.shop3DImageName1 == null || model.shop3DImageName1 == "") {
                model.shop3DImageName1 = "全景图片一";
            }
        } else {
            model.shop3DImage1 = null;
            model.shop3DImageName1 = null;
        }

        if (state.fileList2.length == 1) {
            model.shop3DImage2 = state.fileList2[0].url || state.fileList2[0].thumbUrl;
            if (model.shop3DImageName2 == null || model.shop3DImageName2 == "") {
                model.shop3DImageName2 = "全景图片二";
            }
        } else {
            model.shop3DImage2 = null;
            model.shop3DImageName2 = null;
        }

        if (state.fileList3.length == 1) {
            model.shop3DImage3 = state.fileList3[0].url || state.fileList3[0].thumbUrl;
            if (model.shop3DImageName3 == null || model.shop3DImageName3 == "") {
                model.shop3DImageName3 = "全景图片三";
            }
        } else {
            model.shop3DImage3 = null;
            model.shop3DImageName3 = null;
        }

        if (state.fileList4.length == 1) {
            model.shop3DImage4 = state.fileList4[0].url || state.fileList4[0].thumbUrl;
            if (model.shop3DImageName4 == null || model.shop3DImageName4 == "") {
                model.shop3DImageName4 = "全景图片四";
            }
        } else {
            model.shop3DImage4 = null;
            model.shop3DImageName4 = null;
        }

        if (state.fileList5.length == 1) {
            model.shop3DImage5 = state.fileList5[0].url || state.fileList5[0].thumbUrl;
            if (model.shop3DImageName5 == null || model.shop3DImageName5 == "") {
                model.shop3DImageName5 = "全景图片五";
            }
        } else {
            model.shop3DImage5 = null;
            model.shop3DImageName5 = null;
        }

        if (state.fileList6.length == 1) {
            model.shop3DImage6 = state.fileList6[0].url || state.fileList6[0].thumbUrl;
            if (model.shop3DImageName6 == null || model.shop3DImageName6 == "") {
                model.shop3DImageName6 = "全景图片六";
            }
        } else {
            model.shop3DImage6 = null;
            model.shop3DImageName6 = null;
        }

        if (state.fileList7.length == 1) {
            model.shop3DImage7 = state.fileList7[0].url || state.fileList7[0].thumbUrl;
            if (model.shop3DImageName7 == null || model.shop3DImageName7 == "") {
                model.shop3DImageName7 = "全景图片七";
            }
        } else {
            model.shop3DImage7 = null;
            model.shop3DImageName7 = null;
        }

        if (state.fileList8.length == 1) {
            model.shop3DImage8 = state.fileList8[0].url || state.fileList8[0].thumbUrl;
            if (model.shop3DImageName8 == null || model.shop3DImageName8 == "") {
                model.shop3DImageName8 = "全景图片八";
            }
        } else {
            model.shop3DImage8 = null;
            model.shop3DImageName8 = null;
        }

        if (state.fileList9.length == 1) {
            model.shop3DImage9 = state.fileList9[0].url || state.fileList9[0].thumbUrl;
            if (model.shop3DImageName9 == null || model.shop3DImageName9 == "") {
                model.shop3DImageName9 = "全景图片九";
            }
        } else {
            model.shop3DImage9 = null;
            model.shop3DImageName9 = null;
        }

        this.setState({
            model: model
        })
    }

    /**关闭图片弹窗 */
    handleCancel = () => this.setState({ previewVisible: false, isMap: false })

    /**查看图片 */
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    /**图片改变事件 */
    handleChange(file, flag) {
        let state = this.state
        state[flag] = file.fileList
        this.setState(state)
    }

    handlePreview3DImg = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleChange3DImg(k, file, name) {
        let newState = this.state;
        let fileList = file.fileList;
        file = file.file;
        if (fileList != null && fileList.length == 1) {
            if (!file.url && !file.preview) {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = (e) => {
                    fileList[fileList.length - 1].thumbUrl = e.target.result;
                    newState[k] = fileList;
                    this.setState(newState);
                }
            }
        }else{
            newState.model[name] = "";
        }
        newState[k] = fileList
        this.setState(newState)
    }

    openMap() {
        if (this.state.mapFlag) {
            window.container()
        } else {
            window.defaultPositioning()
        }
        // window.container()
        this.setState({
            isMap: true,
            searchMapInput: "",
            mapFlag: false
        })
    }

    cancelMap(e) {
        let model = this.state.model;
        if (e) {
            if (this.state.mapModel != null) {
                let mapModel = this.state.mapModel;
                model.area = mapModel.area;
                model.address = mapModel.address;
                model.province = mapModel.province;
                model.city = mapModel.city;
                model.district = mapModel.district;
                model.longitude = mapModel.longitude;
                model.latitude = mapModel.latitude;
            } else {
                Message.openMsgError("错误", "请选择店铺位置")
                return
            }
        }
        this.setState({ isMap: false, model: model }, () => {
            if (window.marker != null) {
                window.map.remove(window.marker);
            }
        })
    }

    getadress() {
        this.setState({ mapModel: window.mapModel })
    }

    creatEditor() {
        const elemMenu = this.refs.editorElemMenu;
        const editor = new E(elemMenu);
        editor.customConfig.onchange = html => { //使用 onchange 函数监听内容的变化，并实时更新到 state 中
            let model = this.state.model;
            model.shopGraphics = editor.txt.html();
            this.setState({ model });
        }
        editor.customConfig.menus = [
            'head',  // 标题
            'bold',  // 粗体
            'fontSize',  // 字号
            'fontName',  // 字体
            'italic',  // 斜体
            'underline',  // 下划线
            'strikeThrough',  // 删除线
            'foreColor',  // 文字颜色
            'backColor',  // 背景颜色
            'link',  // 插入链接
            // 'list',  // 列表
            'justify',  // 对齐方式
            // 'quote',  // 引用
            // 'emoticon',  // 表情
            'image',  // 插入图片
            'table',  // 表格
            // 'video',  // 插入视频
            // 'code',  // 插入代码
            'undo',  // 撤销
            'redo'  // 重复
        ];
        editor.customConfig.colors = [
            '#000000',
            '#0000ff',
            '#800000',
            '#ff0000',
            '#f47920',
            '#ea66a6',
            '#afdfe4',
            '#563624',
            '#3e4145',
            '#90d7ec',
            '#ffffff'
        ];
        editor.customConfig.lang = {
            '设置标题': 'Title',
            '字号': 'Size',
            '文字颜色': 'Color',
            '设置列表': 'List',
            '有序列表': '',
            '无序列表': '',
            '对齐方式': 'Align',
            '靠左': '',
            '居中': '',
            '靠右': '',
            '正文': 'p',
            '链接文字': 'link text',
            '链接': 'link',
            '上传图片': 'Upload',
            '网络图片': 'Web',
            '图片link': 'image url',
            '插入视频': 'Video',
            '格式如': 'format',
            '上传': 'Upload',
            '创建': 'init'
        };
        editor.customConfig.customUploadImg = function (files, insert) {
            // files 是 input 中选中的文件列表
            // insert 是获取图片 url 后，插入到编辑器的方法
            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                insert(reader.result)
            };
            // 上传代码返回结果之后，将图片插入到编辑器中
        }
        editor.create();
        let value = '';
        if (this.state.model != null && Common.isNotEmpty(this.state.model.shopGraphics)) {
            value = this.state.model.shopGraphics;
        }
        editor.txt.html(value);
        this.setState({ editorFlag: 'end' })//已经初始化 
    }

    shopView() {
        if (this.state.model == null || this.state.model.shopId == null) {
            Message.openMsgError("错误", "请先设置店铺信息")
            return
        }
        fetch(Config.apiBackServer + "shop/searchShop3DImage", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                if(data.object == true) {
                    this.shopViewPopup.open();
                }else{
                    Message.openMsgError("错误", "请先设置店铺全景图片")
                    return
                }
            } else {
                Message.openMsgError("错误", data.msg, data.errorCode);
            }
        }).catch(err => { });
    }

    shopViewPopup = (ref) => {
        this.shopViewPopup = ref;
    }

    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        const columns = [
            {
                title: '店铺名称',
                dataIndex: 'shopName',
                width: '200px',
            },
            {
                title: '店铺类型',
                dataIndex: 'characteristicName',
                width: '200px',
            },
            {
                title: '店铺电话',
                dataIndex: 'shopPhone',
                width: '200px',
            },
            {
                title: '序号',
                dataIndex: 'dispSeq',
                width: '200px',
            },
            {
                title: '状态',
                dataIndex: 'active',
                width: '200px',
                render: (text) => ContextHandler.getActiveValue(text)
            },
        ];

        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onSelectChange,
            type: 'radio',
        };
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div>上传图片</div>
            </div>
        );
        return (
            <div className="shop">
                <div className="zhRoot">
                    <div className="zhTitle">我的店铺</div>
                    <div className="content">
                        <ShopViewModal stateRef={this.state} onRef={this.shopViewPopup} shopId={this.state.model.shopId} />
                        <Spin spinning={this.state.isLoading} size="large" tip="数据保存中...">
                        <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                            <TabPane tab="详情" key="1">
                                <Modal title="获取位置" maskClosable={false}
                                    width="50%" visible={this.state.isMap}
                                    onCancel={() => this.cancelMap(false)}
                                    onOk={() => this.cancelMap(true)}
                                >
                                    <div className="pickerBox" id="pickerBox">
                                        <input className="pickerInput" id="pickerInput" placeholder="输入关键字选取地点" onChange={(e) => { this.setState({ searchMapInput: e.target.value }) }} value={this.state.searchMapInput} />
                                        <div id="poiInfo"></div>
                                    </div>
                                    <div style={{ height: "500px", width: "100%" }} id="container">
                                    </div>
                                    <button style={{ display: "none" }} id="getadress" onClick={() => this.getadress()}></button>
                                </Modal>
                                <Modal title="放大图" visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                                </Modal>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>店铺名称<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={20}
                                            placeholder="店铺名称"
                                            value={this.state.model.shopName || ''}
                                            onChange={(e) => this.modelChange('shopName', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>店铺类型<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            value={this.state.model.characteristicId || ''}
                                            onChange={(e) => this.modelChange('characteristicId', e)} >
                                            <Option value=""> </Option>
                                            {this.state.classificationList.map((item) =>
                                                <Option key={item.code} value={item.sysParamId}>{item.parmValue1}</Option>
                                            )}
                                        </Select>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>店铺电话<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        {/* <InputNumber className="zhInputNumber" maxLength={12}
                                            min={1}
                                            precision={0}
                                            placeholder="店铺电话"
                                            value={this.state.model.shopPhone || ''}
                                            onChange={(e) => this.modelChange('shopPhone', e)} /> */}
                                        <Input className="zhInputNumber" maxLength={12}
                                            type="phone"
                                            min={1}
                                            precision={0}
                                            placeholder="店铺电话"
                                            value={this.state.model.shopPhone || ''}
                                            onChange={(e) => this.modelChange('shopPhone', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>营业时间<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={50}
                                            placeholder="营业时间"
                                            value={this.state.model.businessDate || ''}
                                            onChange={(e) => this.modelChange('businessDate', e.target.value)} />
                                    </Col>

                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>活动描述：</Col>
                                    <Col span={6} className='zhInput'>
                                        <TextArea
                                            value={this.state.model.activityDesc || ""}
                                            onChange={(e) => this.modelChange('activityDesc', e.target.value)}
                                            placeholder="活动描述"
                                            maxLength={100}
                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                        />
                                    </Col>
                                    <Col span={4} className='zhTxt'>店铺简介：</Col>
                                    <Col span={6} className='zhInput'>
                                        <TextArea
                                            value={this.state.model.shopDesc || ""}
                                            onChange={(e) => this.modelChange('shopDesc', e.target.value)}
                                            placeholder="店铺简介"
                                            maxLength={100}
                                            autoSize={{ minRows: 3, maxRows: 3 }}
                                        />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>位置信息<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Button type="primary" onClick={() => { this.openMap() }}>获取位置信息</Button>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>省市区<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="省市区"
                                            disabled
                                            value={this.state.model.area || ''}
                                            onChange={(e) => this.modelChange('area', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>详细地址<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="详细地址"
                                            value={this.state.model.address || ''}
                                            onChange={(e) => this.modelChange('address', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>经度<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="经度"
                                            disabled
                                            value={this.state.model.longitude || ''}
                                            onChange={(e) => this.modelChange('longitude', e.target.value)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>纬度<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Input maxLength={200}
                                            placeholder="纬度"
                                            disabled
                                            value={this.state.model.latitude || ''}
                                            onChange={(e) => this.modelChange('latitude', e.target.value)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>特别赠送会员上限<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <InputNumber className="zhInputNumber" maxLength={9}
                                            min={1}
                                            disabled
                                            precision={0}
                                            placeholder="卡上限"
                                            value={this.state.model.giveMembersLimit || ''}
                                            onChange={(e) => this.modelChange('giveMembersLimit', e)} />
                                    </Col>
                                    <Col span={4} className='zhTxt'>序号<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <InputNumber className="zhInputNumber" maxLength={9}
                                            min={1}
                                            disabled
                                            precision={0}
                                            placeholder="序号"
                                            value={this.state.model.dispSeq || ''}
                                            onChange={(e) => this.modelChange('dispSeq', e)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>激活<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Select
                                            className="zhSelect"
                                            disabled
                                            value={this.state.model.activeInd || ''}
                                            onChange={(e) => this.modelChange('activeInd', e)} >
                                            <Option value={"Y"}>是</Option>
                                            <Option value={"N"}>否</Option>
                                        </Select>
                                    </Col>
                                    <Col span={4} className='zhTxt'>收银员上限<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <InputNumber className="zhInputNumber" maxLength={9}
                                            min={1}
                                            precision={0}
                                            disabled
                                            placeholder="收银员上限"
                                            value={this.state.model.cashierLimit || ''}
                                            onChange={(e) => this.modelChange('cashierLimit', e)} />
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>店铺logo<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='zhInput'>
                                        <Upload
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            action={Config.apiBackServer + "test/test"}
                                            listType="picture-card"
                                            fileList={this.state.imageFileOne}
                                            onPreview={this.handlePreview}
                                            onChange={(file) => this.handleChange(file, "imageFileOne")}>
                                            {this.state.imageFileOne.length >= this.state.ImageNum ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                    <Col span={4} className='zhTxt'>店铺轮播图一<span className='redStar'>*</span>：</Col>
                                    <Col span={6} className='imgUpload zhInput'>
                                        <Upload
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            action={Config.apiBackServer + "test/test"}
                                            listType="picture-card"
                                            fileList={this.state.imageFileTwo}
                                            onPreview={this.handlePreview}
                                            onChange={(file) => this.handleChange(file, "imageFileTwo")}>
                                            {this.state.imageFileTwo.length >= this.state.ImageNum ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>店铺轮播图二：</Col>
                                    <Col span={6} className='imgUpload zhInput'>
                                        <Upload
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            action={Config.apiBackServer + "test/test"}
                                            listType="picture-card"
                                            fileList={this.state.imageFileThree}
                                            onPreview={this.handlePreview}
                                            onChange={(file) => this.handleChange(file, "imageFileThree")}>
                                            {this.state.imageFileThree.length >= this.state.ImageNum ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                    <Col span={4} className='zhTxt'>店铺轮播图三：</Col>
                                    <Col span={6} className='imgUpload zhInput'>
                                        <Upload
                                            key={"1"}
                                            accept=".png, .jpg, .jpeg"
                                            action={Config.apiBackServer + "test/test"}
                                            listType="picture-card"
                                            fileList={this.state.imageFileFour}
                                            onPreview={this.handlePreview}
                                            onChange={(file) => this.handleChange(file, "imageFileFour")}>
                                            {this.state.imageFileFour.length >= this.state.ImageNum ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                
                                <Row className='row'>
                                    <Col span={6} className='zhInput'>
                                        <Button type="primary" icon="eye" onClick={() => { this.shopView() }}>全景图片预览</Button>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片一</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage1}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片一"
                                                onChange={(e) => this.modelChange("shop3DImageName1", e.target.value)}
                                                value={this.state.model.shop3DImageName1 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"5"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList1}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList1", file, "shop3DImage1")}
                                            >
                                            {this.state.fileList1.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片二</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage2}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片二"
                                                onChange={(e) => this.modelChange("shop3DImageName2", e.target.value)}
                                                value={this.state.model.shop3DImageName2 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"6"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList2}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList2", file, "shop3DImage2")}
                                            >
                                            {this.state.fileList2.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片三</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage3}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片三"
                                                onChange={(e) => this.modelChange("shop3DImageName3", e.target.value)}
                                                value={this.state.model.shop3DImageName3 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"7"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList3}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList3", file, "shop3DImage3")}
                                            >
                                            {this.state.fileList3.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片四</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage4}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片四"
                                                onChange={(e) => this.modelChange("shop3DImageName4", e.target.value)}
                                                value={this.state.model.shop3DImageName4 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"8"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList4}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList4", file, "shop3DImage4")}
                                            >
                                            {this.state.fileList4.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片五</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage5}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片五"
                                                onChange={(e) => this.modelChange("shop3DImageName5", e.target.value)}
                                                value={this.state.model.shop3DImageName5 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"9"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList5}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList5", file, "shop3DImage5")}
                                            >
                                            {this.state.fileList5.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片六</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage6}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片六"
                                                onChange={(e) => this.modelChange("shop3DImageName6", e.target.value)}
                                                value={this.state.model.shop3DImageName6 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"10"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList6}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList6", file, "shop3DImage6")}
                                            >
                                            {this.state.fileList6.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片七</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage7}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片七"
                                                onChange={(e) => this.modelChange("shop3DImageName7", e.target.value)}
                                                value={this.state.model.shop3DImageName7 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"11"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList7}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList7", file, "shop3DImage7")}
                                            >
                                            {this.state.fileList7.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片八</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage8}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片八"
                                                onChange={(e) => this.modelChange("shop3DImageName8", e.target.value)}
                                                value={this.state.model.shop3DImageName8 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"12"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList8}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList8", file, "shop3DImage8")}
                                            >
                                            {this.state.fileList8.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row imgAll'>
                                    <Col span={4} className='zhTxt'>
                                        <Row className='zhTxt'>全景图片九</Row>
                                        <Row className='zhTxt'>图片名称：</Row>
                                    </Col>
                                    <Col span={6} className='zhInput'>
                                        <Row className='zhTxt'>{this.state.model.shop3DImage9}</Row>
                                        <Row className='zhTxt'>
                                            <Input type="text"
                                                maxLength={30}
                                                placeholder="全景图片九"
                                                onChange={(e) => this.modelChange("shop3DImageName9", e.target.value)}
                                                value={this.state.model.shop3DImageName9 || ''} />
                                        </Row>
                                    </Col>
                                    <Col span={10} className='imgUpload zhInput'>
                                        <Upload
                                            key={"13"}
                                            action={Config.apiBackServer + "test/test"}
                                            accept=".png, .jpg, .jpeg"
                                            listType="picture-card"
                                            fileList={this.state.fileList9}
                                            onPreview={this.handlePreview3DImg}
                                            onChange={(file) => this.handleChange3DImg("fileList9", file, "shop3DImage9")}
                                            >
                                            {this.state.fileList9.length >= 1 ? null : uploadButton}
                                        </Upload>
                                    </Col>
                                </Row>
                                <Row className='row'>
                                    <Col span={4} className='zhTxt'>店铺描述：</Col>
                                </Row>
                                <Row className='row'>
                                    <div>
                                        <div ref='editorElemMenu' />
                                    </div>
                                </Row>
                                <Row className='zhRow zhRowButtonRight'>
                                    <Button className="zhButtonLeft" type="primary" icon="check-circle" onClick={() => { this.save() }}>保存</Button>
                                    <Button className="zhButtonLeft" icon="close-circle" onClick={() => { this.cancel() }}>取消</Button>
                                </Row>
                            </TabPane>

                        </Tabs>
                        </Spin>
                    </div>
                </div>
            </div >
        )
    }
}