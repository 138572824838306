import React, { Component } from 'react';
import { Row, InputNumber,  Input, Button, Spin } from 'antd';
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import './changePassword.css';

export default class ChangePassword extends Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            model: {}
        };
    }

    componentDidMount() {
        let model = {phone:Config.userInfo.phone}
        this.setState({model})
    }

    onChange(k, e) {
        let newModel = this.state.model;
        newModel[k] = e;
        this.setState({model: newModel});
    }

    check(model){
        let msg = "";
        if(Common.isEmpty(model.phone)){
            msg += "手机号不能为空;";
        }
        if(Common.isEmpty(model.password)){
            msg += "原密码不能为空;";
        }
        if(Common.isEmpty(model.newPassword)){
            msg += "新密码不能为空;";
        }else if(model.newPassword.length < 6){
            msg += "新密码长度不能小于6位;";
        }
        if(Common.isEmpty(model.confirmPassword)){
            msg += "确认新密码不能为空;";
        }
        if(Common.isNotEmpty(model.newPassword) && Common.isNotEmpty(model.confirmPassword) && model.newPassword !== model.confirmPassword){
            msg += "新密码与确认新密码必须一致;";
        }
        return msg;
    }

    changePassword(){
        let {model} = this.state;
        console.log(model);
        let msg = this.check(model);
        if(Common.isNotEmpty(msg)){
            Message.openMsgError("错误", msg);
            return;
        }
        this.setState({ loading: true })
        fetch(Config.apiServer + "common/updatePassword", {
            method: 'POST',
            body: JSON.stringify(model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.json()).then((data) => {
            if ("SUCCESS" === data.msg) {
                Message.openMsgSuccess("修改成功");
                this.clearnModel();
            } else {
                Message.openMsgError("错误", data.msg)
            }
        }).catch(err => { Message.openMsgError("错误", err) }).finally(() => 
            this.setState({ loading: false })
        );
    }

    cancel(){
        this.clearnModel();
        if(Common.isEmpty(Config.userInfo.shopId)){
            this.props.history.push('/main/platformWelcome');
        }else{
            this.props.history.push('/main/shopWelcome');
        }
    }

    clearnModel(){
        let model = this.state.model;
        model.password = "";
        model.newPassword = "";
        model.confirmPassword = "";
        this.setState({model})
    }

    render() {
        const {model} = this.state;
        return (
            <div className="changePassword">
                <Spin spinning={this.state.loading}>
                    <Row className="changePasswordMain" >
                        <h1>修改密码</h1>
                        <div className="changePasswordBody">
                            <Row className="item">
                                <div className="rowLeft">手机号：</div>
                                <div className="rowRight">
                                    <InputNumber placeholder="手机号：" disabled className="changePasswordInput" value={model.phone ||''}/>
                                </div>
                            </Row>
                            <Row className="item">
                                <div className="rowLeft">原密码：</div>
                                <div className="rowRight">
                                    <Input placeholder="原密码" type="password" value={model.password||''}
                                        onChange={(e)=>this.onChange('password', e.target.value)}
                                    />
                                </div>
                            </Row>
                            <Row className="item">
                                <div className="rowLeft">新密码：</div>
                                <div className="rowRight">
                                    <Input placeholder="新密码" minLength={6} type="password"  value={model.newPassword||''}
                                        onChange={(e)=>this.onChange('newPassword', e.target.value)}
                                    />
                                </div>
                            </Row>
                            <Row className="item">
                                <div className="rowLeft">确认新密码：</div>
                                <div className="rowRight">
                                    <Input placeholder="确认新密码" type="password" value={model.confirmPassword||''}
                                        onChange={(e)=>this.onChange('confirmPassword', e.target.value)}
                                    />
                                </div>
                            </Row>
                            <Row className="buttonItem">
                                <Button type="primary" className="changePasswordButton" onClick={() => {this.changePassword()}}>确认修改</Button>
                                <Button className="changePasswordButton" onClick={() => {this.cancel()}} >取消</Button>
                            </Row>
                        </div>
                    </Row>
                </Spin>
            </div>
        );
    }

}
