import React, { Component } from 'react';
import { Button, Row, Col, Tabs, Spin, DatePicker } from 'antd';
import moment from 'moment';//日期格式化导包
import { Config } from '../../config';
import { Message } from '../../public/js/message';
import { Common } from '../../public/js/common';
import { Bar, } from "react-chartjs-2";
import { Chart } from "chart.js";


export default class MemberCostStatement extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: '1',
            searchModel: { startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date()},
            model: {startTime: new Date().setMonth(new Date().getMonth() - 11), endTime: new Date(),},
            isExport: false,
            isLoading: false,

            chartDayList: [],
            chartDayhData: {},
            newRebateAmountTotal: 0,
            dayReceiveAmountTotal: 0,

            chartMonthList: [],
            chartMonthData: {},
            monthtRadeAmountTotal: 0,
            receiveAmountTotal: 0,

        }
    }

    componentDidMount() {
        this.dayChartsearch()
    }

    check(model) {
        let Newmodel = model;
        if (Newmodel.startTime == null) {
            Message.openMsgError("错误", "开始日期不能为空;");
            return false;
        }
        if (Newmodel.endTime == null) {
            Message.openMsgError("错误", "结束日期不能为空;");
            return false;
        }
        let startTime = Newmodel.startTime != null ? moment(Newmodel.startTime).format("YYYYMMDD") : null;
        let endTime = Newmodel.endTime != null ? moment(Newmodel.endTime).format("YYYYMMDD") : null;
        if (startTime != null && endTime != null) {
            if (endTime < startTime) {
                Message.openMsgError("错误", "开始日期不能大于结束至日期;");
                return false;
            }
        }
        return true
    }

    dayChartsearch() {
        let model = this.state.searchModel;
        if(this.check(model)){
            this.setState({ isLoading:true });
            fetch(Config.apiBackServer + "memberCostStatement/dayChartsearch", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                if ("SUCCESS" === data.msg) {
                    let list = data.object;
                    let xAxisContentList = [];
                    let regionList = [];
                    let proceedsList = [];
                    let newRebateAmountTotal = 0;
                    let dayReceiveAmountTotal = 0;

                    list.forEach(element => {
                        newRebateAmountTotal += parseFloat(element.rebateAmountTotal || 0);
                        xAxisContentList.push(element.rebateTime != null ? moment(element.rebateTime).format("YYYY-MM-DD") : null);
                        dayReceiveAmountTotal += parseFloat(element.receiveAmountTotal || 0);
                        regionList.push(element.rebateAmount || 0);
                        proceedsList.push(element.receiveAmount || 0);
                    });
                    let datesetsItem = {
                        label: "奖励金额",
                        data: regionList,
                        backgroundColor: "#37a2da",
                        borderColor: "#37a2da",
                        borderWidth: 1,
                        hoverBackgroundColor: "#37a2da",
                        hoverBorderColor: "#37a2da",
                        barThickness: "flex",
                        fill: false
                    }
                    let proceedsDateItem = {
                        label: "注册金额",
                        data: proceedsList,
                        backgroundColor: "#ee3f4d",
                        borderColor: "#ee3f4d",
                        borderWidth: 1,
                        hoverBackgroundColor: "#ee3f4d",
                        hoverBorderColor: "#ee3f4d",
                        barThickness: "flex",
                        fill: false
                    }
                    let  chartData = {
                        labels: xAxisContentList,
                        datasets: [proceedsDateItem,datesetsItem]
                    }
                    this.setState({
                        chartDayList: data.object,
                        chartDayhData: chartData,
                        newRebateAmountTotal: newRebateAmountTotal,
                        dayReceiveAmountTotal: dayReceiveAmountTotal,
                    })
                } else {
                    Message.openMsgError("错误", data.msg, data.errorCode)
                }
            }).catch(err => {Message.openMsgError("错误", err)}).finally(() => this.setState({ isLoading: false }))
        }
    }

    monthChartsearch() {
        let model = this.state.model
        if (this.check(model)) {
            this.setState({ isLoading: true })
            fetch(Config.apiBackServer + "memberCostStatement/monthChartsearch", {
                method: 'POST',
                body: JSON.stringify(model),
                headers: Config.header,
                mode: 'cors',
                cache: 'default'
            }).then(res => res.json()).then((data) => {
                if ("SUCCESS" === data.msg) {
                    let list = data.object;
                    let xAxisContentList = [];
                    let regionList = [];
                    let proceedsList = [];
                    let monthtRadeAmountTotal = 0;
                    let receiveAmountTotal = 0;
                    list.forEach(element => {
                        monthtRadeAmountTotal += parseFloat(element.rebateAmountTotal || 0)
                        xAxisContentList.push(element.monthRebateTime)
                        regionList.push(element.rebateAmount || 0) ;
                        receiveAmountTotal += parseFloat(element.receiveAmountTotal || 0);
                        proceedsList.push(element.receiveAmount || 0)
                    });
                    let datesetsItem = {
                        label: "奖励金额",
                        data: regionList,
                        backgroundColor: "#37a2da",
                        borderColor: "#37a2da",
                        borderWidth: 1,
                        hoverBackgroundColor: "#37a2da",
                        hoverBorderColor: "#37a2da",
                        barThickness: "flex",
                        fill: false
                    }
                    let proceedsDateItem = {
                        label: "注册金额",
                        data: proceedsList,
                        backgroundColor: "#ee3f4d",
                        borderColor: "#ee3f4d",
                        borderWidth: 1,
                        hoverBackgroundColor: "#ee3f4d",
                        hoverBorderColor: "#ee3f4d",
                        barThickness: "flex",
                        fill: false
                    }
                    let chartData = {
                        labels: xAxisContentList,
                        datasets: [proceedsDateItem,datesetsItem]
                    }
                    this.setState({
                        chartMonthList: data.object,
                        chartMonthData: chartData,
                        monthtRadeAmountTotal: monthtRadeAmountTotal,
                        receiveAmountTotal : receiveAmountTotal,
                    })
                } else {
                    Message.openMsgError("错误", data.msg, data.errorCode)
                }
            }).catch(err => {Message.openMsgError("错误", err)}).finally(() => this.setState({ isLoading: false }))
        }
    }

    tableChange(value) {
        this.setState({
            tabIndex: value,
        }, () => {
            this.reset();
            if (value === '2') {
                this.monthChartsearch();
            } else {
                this.dayChartsearch()
            }
        })
    }

    reset() {
        this.setState({
            searchModel: { startTime: new Date().setMonth(new Date().getMonth() - 1), endTime: new Date()},
            model: {startTime: new Date().setMonth(new Date().getMonth() - 11), endTime: new Date(),},
        }, () => {
            if (this.state.tabIndex === "1") {
                this.dayChartsearch()
            } else {
                this.monthChartsearch();
            }
        })
    }

    searchChange(k,e) {
        let newModel = this.state.searchModel;
        newModel[k] = e;
        if (k === "startTime") {
            newModel.endTime = null;
        }
        this.setState({
            searchModel: newModel
        });
    }

    handleEndDisabledDate(current) {
        let {startTime} = this.state.searchModel
        if (!current || !startTime) {
            return true;
        }
        return current > moment(startTime).add(1, 'month') || current < moment(startTime);
    }

    handleMonthEndDisabledDate(current) {
        let {startTime} = this.state.model
        if (!current || !startTime) {
            return true;
        }
        return current > moment(startTime).add(12, 'month') || current < moment(startTime);
    }

    searchMonthChange(k,e) {
        let newModel = this.state.model;
        newModel[k] = e;
        if (k === "startTime") {
            newModel.endTime = null;
        }
        this.setState({
            model: newModel
        });
    }

    exportChartData() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "memberCostStatement/chartExportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.searchModel),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员费日报表" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {    // for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        })
    }

    exportMonthChartData() {
        this.setState({
            isExport: true
        })
        fetch(Config.apiBackServer + "memberCostStatement/chartMonthExportExcel", {
            method: 'POST',
            body: JSON.stringify(this.state.model),
            headers: Config.header,
            mode: 'cors',
            cache: 'default'
        }).then(res => res.blob()).then(blob => {
            if (blob.type === "application/json") {
                var reader = new FileReader();
                reader.onload = e => {
                    var data = JSON.parse(e.target.result);
                    if ("SUCCESS" !== data.msg) {
                        this.errFrameModalShow("danger", data.msg);
                    }
                }
                reader.readAsText(blob);
            } else {
                var filename = "会员费月报表" + Common.dateFormat(new Date(), "yyyyMMddhhmmss") + '.xlsx';
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {    // for IE
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {
                    var a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    var url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(err => ({ err })).finally(() => {
            this.setState({
                isExport: false
            })
        })
    }

    render() {
        const { TabPane } = Tabs;
        const { MonthPicker } = DatePicker;
        return (
            <div className="memberCostStatement">
                <div className="zhRoot">
                    <div className="zhTitle">会员费报表</div>
                    <div className="content">
                        <Spin spinning={this.state.isExport} tip="下载中...">
                            <Tabs defaultActiveKey='1' activeKey={this.state.tabIndex} onChange={(value) => this.tableChange(value)}>
                                <TabPane tab="日报表" key="1">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <DatePicker placeholder="由日期"
                                                allowClear={true}
                                                className="memberCostStatementDatePicker"
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMMdd") > Common.dateFormat(new Date(), "yyyyMMdd") }
                                                value={this.state.searchModel.startTime == null ? null : moment(new Date(this.state.searchModel.startTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("startTime", value)}
                                                format={Config.dateFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <DatePicker placeholder="至日期"
                                                allowClear={true}
                                                className="memberCostStatementDatePicker"
                                                disabledDate={(e) => this.handleEndDisabledDate(e)}
                                                value={this.state.searchModel.endTime == null ? null : moment(new Date(this.state.searchModel.endTime), Config.dateFormat)}
                                                onChange={(value) => this.searchChange("endTime", value)}
                                                format={Config.dateFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" icon="search" onClick = {() => this.dayChartsearch()}>搜索</Button>
                                            <Button className="zhButtonRight" icon="redo" onClick = {() => this.reset()}>重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" icon="download" onClick = {() => this.exportChartData()}>导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                        <Bar
                                            width={1200}
                                            data={this.state.chartDayhData}
                                            options={{
                                                title: {
                                                    display: true,
                                                    text: "日报表",
                                                    position: "top",
                                                },
                                                tooltips: {
                                                    enabled: true,
                                                    intersect: false
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: [{

                                                    }],
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            callback: function (value) { if (value % 1 === 0) { return value } }
                                                        }
                                                    }]
                                                },
                                                legend: {
                                                    position: "bottom",
                                                    align: "center",
                                                    labels: {
                                                        boxWidth: 20
                                                    }
                                                },
                                                hover: {
                                                    animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                },
                                                animation: {
                                                    onComplete: function() {
                                                        var chartInstance = this.chart,
                                                        ctx = chartInstance.ctx;
                                                        // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                        ctx.fillStyle = "black";
                                                        ctx.textAlign = 'center';
                                                        ctx.textBaseline = 'bottom';
                                                        this.data.datasets.forEach(function (dataset, i) {
                                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                                            meta.data.forEach(function (bar, index) {
                                                                var data = dataset.data[index];
                                                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                            });
                                                        });
                                                    }
                                                }
                                            }}
                                        />
                                    </Row>
                                    <Row><span>注册总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.dayReceiveAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>奖励总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.newRebateAmountTotal || 0, 2)}</span></Row>
                                </TabPane>
                                <TabPane tab="月报表" key="2">
                                    <Row className="row">
                                        <Col span={2} className="zhTxt">注册日期：</Col>
                                        <Col span={6} className="zhInput">
                                            <MonthPicker placeholder="由日期"
                                                allowClear={true}
                                                className="memberCostStatementDatePicker"
                                                // defaultValue={moment(this.state.model.startTime,Config.monthFormat)}
                                                disabledDate={(e) => Common.dateFormat(e, "yyyyMM") > Common.dateFormat(new Date(), "yyyyMM")}
                                                value={this.state.model.startTime == null ? null : moment(new Date(this.state.model.startTime), Config.monthFormat)}
                                                onChange={(value) => this.searchMonthChange("startTime", value)}
                                                format={Config.monthFormat}/>
                                            &nbsp;&nbsp;至&nbsp;&nbsp;
                                            <MonthPicker placeholder="至日期"
                                                allowClear={true}
                                                className="memberCostStatementDatePicker"
                                                disabledDate={(e) =>this.handleMonthEndDisabledDate(e)}
                                                value={this.state.model.endTime == null ? null : moment(new Date(this.state.model.endTime), Config.monthFormat)}
                                                onChange={(value) => this.searchMonthChange("endTime", value)}
                                                format={Config.monthFormat}/>
                                        </Col>
                                    </Row>
                                    <Row className="rowButton">
                                        <Col className="zhRowButtonRight">
                                            <Button type="primary" icon="search" onClick= {() => this.monthChartsearch()}>搜索</Button>
                                            <Button className="zhButtonRight" icon="redo" onClick={() => this.reset()}>重置</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button disabled={this.state.isLoading} className="zhButtonLeft" type="primary" icon="download" onClick= {() => this.exportMonthChartData()}>导出</Button>
                                    </Row>
                                    <Row style={{ height: "500px" }}>
                                        <Bar
                                             width={1200}
                                             data={this.state.chartMonthData}
                                             options={{
                                                title: {
                                                    display: true,
                                                    text: "月报表",
                                                    position: "top",
                                                },
                                                tooltips: {
                                                    enabled: true,
                                                    intersect: false
                                                },
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                scales: {
                                                    xAxes: [{

                                                    }],
                                                    yAxes: [{
                                                        ticks: {
                                                            beginAtZero: true,
                                                            callback: function (value) { if (value % 1 === 0) { return value } }
                                                        }
                                                    }]
                                                },
                                                legend: {
                                                    position: "bottom",
                                                    align: "center",
                                                    labels: {
                                                        boxWidth: 20
                                                    }
                                                },
                                                hover: {
                                                    animationDuration: 0  // 防止鼠标移上去，数字闪烁
                                                },
                                                animation: {
                                                    onComplete: function() {
                                                        var chartInstance = this.chart,
                                                        ctx = chartInstance.ctx;
                                                        // 以下属于canvas的属性（font、fillStyle、textAlign...）
                                                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                                                        ctx.fillStyle = "black";
                                                        ctx.textAlign = 'center';
                                                        ctx.textBaseline = 'bottom';

                                                        this.data.datasets.forEach(function(dataset, i) {
                                                            var meta = chartInstance.controller.getDatasetMeta(i);
                                                            meta.data.forEach(function (bar, index) {
                                                                var data = dataset.data[index];
                                                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                                                            });
                                                        });
                                                    }
                                                }
                                             }}
                                        />
                                    </Row>
                                    <Row><span>注册总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.receiveAmountTotal || 0, 2)}</span></Row>
                                    <Row><span>奖励总金额:</span><span style={{fontSize:"16px", fontWeight:"600",marginLeft:"12px"}}>{Common.numberFormat(this.state.monthtRadeAmountTotal || 0, 2)}</span></Row>
                                </TabPane>
                            </Tabs>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}